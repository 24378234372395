import { useEffect, useState } from "react";
import { useFetcher } from "react-router-dom";
import { useCreationStore } from "../../../store/creationStore";
import { useSignalR } from "../../../socket-services/useSignalR";
import { useGetCopilotChat } from "../../../api/copilot/chat/get";
import { ICopilotMessage } from "../../../utils/copilot-base/ICopilotBase";

export const useCreationSockets = ({
  connected,
  subscribeToEvent,
  sendEvent,
}: {
  connected: boolean;
  subscribeToEvent: (event: string, callback: () => void) => void;
  sendEvent: (event: string, data: any) => void;
}) => {
  const { postData, copilotSessionId, setSessionErrored, setBriefMessages } =
    useCreationStore();
  const { refetch: refetchCopilotMessages } = useGetCopilotChat({
    sessionId: copilotSessionId,
  });
  useEffect(() => {
    if (connected && postData) {
      sendEvent("JoinPostGroup", postData.id);
    }
  }, [connected]);

  useEffect(() => {
    if (connected) {
      subscribeToEvent("VideoAnalysisCompleted", () => {
        refetchCopilotMessages().then((res) => {
          if (res.isError) {
            if (postData?.id) {
              setSessionErrored(true);
            }
          } else {
            let tmp: ICopilotMessage[] = [];
            res!.data!.data.map((item) => {
              tmp.push({
                files: item.files,
                content: item.content,
                role: item.role,
              });
            });
            setBriefMessages(tmp);
          }
        });
      });
    }
  }, [connected]);

  //   useEffect(() => {
  //     if (connected && postData) {
  //       const connection = postSignalRService.getConnection();
  //       connection.invoke("JoinPostGroup", postData.id);
  //     }
  //     console.log("conn", connected);
  //   }, [connected]);
};
