import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-regular-svg-icons";
// @ts-ignore
import brandTip from "../../../assets/images/sign-up/brandTip.png";
// @ts-ignore
import assetsTip from "../../../assets/images/sign-up/assetsTip.png";
// @ts-ignore
import creationTip from "../../../assets/images/sign-up/creationTip.png";
// @ts-ignore
import integrationsTip from "../../../assets/images/sign-up/integrationsTip.png";

const sliderData = [
  {
    index: 1,
    heading: "Improved - Brand Creation",
    description:
      "STORI generates full  platform for your brand - including Brand Identity, Mission & Vision, Values,  Customer Personas, and more...",
    image: brandTip,
  },
  {
    index: 2,
    heading: "Improved - Visual Elements",
    description:
      "Generate, Choose from stock, or upload your own images to tell your stories.",
    image: assetsTip,
  },
  {
    index: 3,
    heading: "Improved - AI Copilot",
    description:
      "StoriGPT will help you with everything and anything your brand might need.",
    image: creationTip,
  },
  {
    index: 4,
    heading: "Improved - Integrations",
    description: "Everything and anything your brand needs.",
    image: integrationsTip,
  },
];

const TipsSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev === sliderData.length - 1 ? 0 : prev + 1));
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? sliderData.length - 1 : prev - 1));
  };

  const goToSlide = (index: number) => {
    setCurrentSlide(index);
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative w-full h-full flex flex-col items-center justify-center">
      <div className="w-3/4 h-[60%] overflow-hidden relative">
        {sliderData.map((slide, index) => (
          <div
            key={index}
            className={`absolute inset-0 flex flex-col items-center justify-center p-[32px] transition-opacity duration-500 ${
              index === currentSlide ? "opacity-100" : "opacity-0"
            }`}
          >
            <img
              src={slide.image}
              alt={slide.heading}
              className="w-2/3 h-auto mb-[50px]"
            />
            <h2 className="display-xs-sb text-white pb-[8px]">
              {slide.heading}
            </h2>
            <p className="text-md-m text-amethyst-200 text-center max-w-md">
              {slide.description}
            </p>
          </div>
        ))}
      </div>
      <div className="w-[50%] flex items-center justify-between p-2">
        <button onClick={prevSlide}>
          <FontAwesomeIcon
            color={"#FFFFFF"}
            icon={faChevronLeft}
            fontSize={"20px"}
          />
        </button>
        <div className={"flex gap-[16px]"}>
          {sliderData.map((_, index) => (
            <button
              key={index}
              className={`w-[10px] h-[10px] rounded-full transition-colors ${
                index === currentSlide ? "bg-white" : "bg-brand-500"
              }`}
              onClick={() => goToSlide(index)}
            />
          ))}
        </div>
        <button onClick={nextSlide}>
          <FontAwesomeIcon
            color={"#FFFFFF"}
            icon={faChevronRight}
            fontSize={"20px"}
          />
        </button>
      </div>
    </div>
  );
};

export default TipsSlider;
