function getLastSegmentFromUrl(url: string) {
  url = url.replace(/^"|"$/g, '');
  const segments = url.split('/');
  return segments[segments.length - 1].split('?')[0]; // Remove query parameters if any
}

export const useDropbox = () => {
  const dropboxSelector = async (files: any, setLoading?: any) => {
    let blobs: {
      name: string;
      blob: Blob;
    }[] = [];
    await Promise.all(
      files.map(async (item: any) => {
        let directLink = item.link
          .replace('www.dropbox.com', 'dl.dropboxusercontent.com')
          .replace('?dl=0', '');
        try {
          const res = await fetch(directLink);
          if (!res.ok) {
            throw new Error('Failed to fetch the image');
          }
          const blob = await res.blob();
          blobs.push({
            name: getLastSegmentFromUrl(res.url),
            blob: blob
          });
        } catch (error) {
          console.log("Failed to fetch the image")
        } finally {
          if (setLoading) {
            setLoading(false);
          }
        }
      })
    );
    const formData = new FormData();
    blobs.map((item) => {
      formData.append('files', item.blob, item.name);
    });
    return formData;
  };
  return { dropboxSelector };
};