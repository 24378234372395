import React, { useEffect, useCallback, useMemo, useState } from "react";
import SInput from "../../../../design-system/SInput";
import { useFramesStore } from "../../../../store/framesStore";
import { useTemplateRendererHelperFunctions } from "../../hooks/useTemplateRendererHelperFunctions";
import SButton from "../../../../design-system/SButton";
import _ from "lodash";
import { TemplateTokenType } from "../../../../utils/enums/Enums";
import { toast, closeAllToast } from "../../../../hooks/toast";

function SelectedFrameOptions() {
  const {
    selectedImage,
    selectedFrameTokens,
    activeFrame,
    whichFrameIsRendering,
  } = useFramesStore();
  const { changeValueOfToken, renderTemplate, saveTemplate } =
    useTemplateRendererHelperFunctions();

  const [localTokens, setLocalTokens] = useState(selectedFrameTokens);

  const haveInputsChanged = useMemo(() => {
    if (!selectedFrameTokens || !localTokens) {
      return false;
    }

    return localTokens.some((localToken, index) => {
      const originalToken = selectedFrameTokens[index];
      return originalToken && originalToken.value !== localToken.value;
    });
  }, [selectedFrameTokens, localTokens]);

  const handleTokenChange = useCallback((name: string, value: string) => {
    setLocalTokens((prevTokens) =>
      prevTokens.map((token) =>
        token.name === name ? { ...token, value } : token
      )
    );
  }, []);

  useEffect(() => {
    setLocalTokens(selectedFrameTokens);
  }, [selectedFrameTokens]);

  return (
    <div className="mt-1 px-[36px]">
      <p className="text-sm-m text-graycool-800">Selected Template</p>
      <div className="flex max-sm:flex-col gap-[24px] mt-[16px]">
        <img
          className="min-w-[150px] h-[150px] max-w-[150px] max-lg:max-w-[120px] max-lg:min-w-[120px] w-full aspect-square bg-[rgba(0,0,0,0.40)] rounded-lg object-cover"
          src={activeFrame?.thumbnail || selectedImage?.template?.thumbnail}
          alt="Selected Frame"
        />
        <div className="w-full pr-4 h-[150px] overflow-auto flex flex-col gap-[8px] justify-start">
          {localTokens.map(
            (item) =>
              item.type === TemplateTokenType.TEXT && (
                <SInput
                  key={item.id}
                  placeholder={item.value}
                  onChange={(e) => handleTokenChange(item.name, e.target.value)}
                  value={item.value}
                />
              )
          )}
        </div>
      </div>
      {haveInputsChanged && (
        <SButton
          onClick={() => {
            if (whichFrameIsRendering === undefined) {
              if (activeFrame || selectedImage?.template) {
                toast("Updating template...", "loading");
                renderTemplate({
                  template: (activeFrame || selectedImage?.template)!,
                  tokens: localTokens,
                })
                  .then((res) => {
                    saveTemplate(res?.data);
                  })
                  .finally(() => {
                    closeAllToast();
                    toast("Template updated successfully", "success");
                  });
              }
            }
            localTokens.forEach((token) => {
              changeValueOfToken(token.name, token.value);
            });
          }}
          disabled={whichFrameIsRendering !== undefined}
          type="secondaryColor"
          className="mt-[12px]"
        >
          Apply changes
        </SButton>
      )}
    </div>
  );
}

export default React.memo(SelectedFrameOptions);
