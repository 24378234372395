import { useMutation } from "@tanstack/react-query";
import { IBaseData } from "../../../../utils/interfaces/IBase";
import { makeRequest } from "../../../auth/axios";
import {
  IRenderTemplateCOE,
  IRenderTemplateVM,
} from "../../../../utils/interfaces/IFrames";
import { ISinglePostVM } from "../../../../utils/interfaces/IPost";

export const usePutAppendTemplate = () => {
  return useMutation<
    IBaseData<ISinglePostVM>,
    Error,
    {
      postId: number;
      data: {
        renderTemplateResponse: IRenderTemplateVM;
        socialNetworkPostFileIds?: number[];
      };
    }
  >({
    mutationFn: ({ postId, data }) =>
      makeRequest.put(`/Posts/${postId}/AddRenderTemplateResult`, {
        ...data,
      }),
  });
};
