import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../auth/axios";
import {
  ISinglePostVM,
  ISocialNetworkPostScore,
} from "../../../utils/interfaces/IPost";
import { IBaseData } from "../../../utils/interfaces/IBase";

export const usePostGenerateSocialScore = () => {
  const queryClient = useQueryClient();
  return useMutation<
    IBaseData<ISocialNetworkPostScore>,
    Error,
    { socialNetworkPostId: number }
  >({
    mutationFn: ({ socialNetworkPostId }) =>
      makeRequest.post(
        `/Posts/GenerateSocialScore?socialNetworkPostId=${socialNetworkPostId}`
      ),
  });
};
