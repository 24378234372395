import React, { useState } from "react";
import SButton from "../../../design-system/SButton";
import SInput from "../../../design-system/SInput";
import { AxiosError } from "axios";
import { useDeleteUser } from "../../../api/user/delete";
import SModalBase from "../../../design-system/SModalBase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationCircle,
} from "@fortawesome/pro-regular-svg-icons";

const RequestAccountDeleteModal = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (b: boolean) => void;
}) => {
  const {
    mutateAsync: deleteAccount,
    error,
    isSuccess,
    data,
  } = useDeleteUser();
  const [password, setPassword] = useState("");

  return (
    <SModalBase isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <div className={"flex flex-col gap-[16px]"}>
        <div
          className={`w-[48px] h-[48px] flex justify-center items-center 
            border-[8px] rounded-full
            border-error-50
            bg-error-100
          `}
        >
          <FontAwesomeIcon
            icon={faExclamationCircle}
            fontSize={"18px"}
            className={"text-error-600"}
          />
        </div>
        <h2 className={"text-lg-sb text-gray-900"}>Delete your account</h2>
        <p className={"text-sm text-gray-600"}>
          Are you sure you want to delete your account? This action cannot be
          undone and will delete all your data.
        </p>
        {isSuccess ? (
          <div className="text-center py-4 text-gray-700">
            {data?.data?.message}
          </div>
        ) : (
          <div className={`flex gap-[12px] mt-[12px]`}>
            <SInput
              type="password"
              error={
                error instanceof AxiosError && error?.response?.data?.message
              }
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <SButton
              type="tertiaryGray"
              className="text-white !bg-error-600 hover:!bg-error-700 hover:text-white"
              onClick={() => deleteAccount({ password })}
              size="lg"
            >
              {"Delete"}
            </SButton>
          </div>
        )}
      </div>
    </SModalBase>
  );
};

export default RequestAccountDeleteModal;
