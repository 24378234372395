import SButton from "../../../design-system/SButton";
import SInput from "../../../design-system/SInput";
import {
  faArrowLeft,
  faEnvelope,
  faKey,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
//@ts-ignore
import bgGrid from "../../../assets/images/grid-background.png";
import { useLocation, useNavigate } from "react-router-dom";
import { makeRequest } from "../../../api/auth/axios";
function Form({
  isAcceptedForStory = false,
}: {
  isAcceptedForStory?: boolean;
}) {
  const [isAccepted, setIsAccepted] = useState(isAcceptedForStory);
  const location = useLocation();
  const [email, setEmail] = useState(location?.state?.email || "");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = () => {
    setIsLoading(true);
    makeRequest
      .get(`/User/ForgotPassword?email=${email}`)
      .then((res) => {
        setIsLoading(false);
        if (res.data.isSuccess) {
          setIsAccepted(true);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  return (
    <div className="relative max-w-[360px] w-full flex justify-center flex-col items-center">
      <img
        className="absolute  z-0 top-[-10%] w-full h-[300px]"
        src={bgGrid}
        alt="bg"
      />
      {isAccepted ? (
        <div className="relative z-10 w-full flex flex-col items-center justify-center">
          <div className="flex justify-center items-center rounded-[8px] w-[40px] aspect-square bg-base-white border-[1px] border-gray-200 shadow-xs">
            <FontAwesomeIcon
              icon={faEnvelope}
              fontSize="16px"
              className="text-gray-600"
            />
          </div>
          <p className="display-sm-sb text-gray-900 mt-[24px]">
            Check your email
          </p>
          <p className="text-md text-gray-600 mt-[12px]">
            We sent a password reset link to{" "}
          </p>
          <p className="text-md-m text-gray-600">{email}</p>
          <SButton
            lIcon={faArrowLeft}
            size="lg"
            className="w-full mt-[24px] relative z-10"
            onClick={() => navigate("/login")}
          >
            Back to log in
          </SButton>
        </div>
      ) : (
        <div className="relative z-10 w-full flex flex-col items-center justify-center">
          <div className="flex justify-center items-center rounded-[8px] w-[40px] aspect-square bg-base-white border-[1px] border-gray-200 shadow-xs">
            <FontAwesomeIcon
              icon={faKey}
              fontSize="16px"
              className="text-gray-600"
            />
          </div>
          <p className="display-sm-sb text-gray-900 mt-[24px]">
            Forgot Password
          </p>
          <p className="text-md text-gray-600 mt-[12px]">Forgot Password</p>
          <SInput
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            label="Email"
            placeholder="Enter your email"
            className="w-full mt-[24px]"
            onKeyDown={(e) => {
              if (e.code === "Enter") handleSubmit();
            }}
          />
          <SButton
            isLoading={isLoading}
            onClick={handleSubmit}
            size="lg"
            className="w-full mt-[24px]"
          >
            Reset Password
          </SButton>
          <SButton
            onClick={() => navigate("/login")}
            size="sm"
            lIcon={faArrowLeft}
            type="linkGray"
            className="w-full !gap-[12px] mt-[24px]"
          >
            Back to log in
          </SButton>
        </div>
      )}
    </div>
  );
}

export default Form;
