import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import SButton from "../../design-system/SButton";
import SInput from "../../design-system/SInput";
import { useState } from "react";
import { useApplyPromoCode } from "../../api/subscriptions/promos/put";
import { toast } from "../../hooks/toast";

export const PromoCode = () => {
  const [promoCode, setPromoCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { mutateAsync: applyCode } = useApplyPromoCode();
  return (
    <div className="flex items-center gap-2">
      <SInput
        onChange={(event) => setPromoCode(event.target.value)}
        value={promoCode}
        placeholder="Your promo code"
      />
      <SButton
        isLoading={isLoading}
        disabled={!promoCode.length}
        onClick={() => {
          setIsLoading(true);
          applyCode({ promotionCode: promoCode })
            .then((res) => {
              setIsLoading(false);
              toast("Promo code activated successfully!", "success");
            })
            .catch((err) => {
              setIsLoading(false);
              toast(err.response.data.message, "error");
            });
        }}
        rIcon={faCheck}
        type="secondaryColor"
        size="md"
      >
        Apply
      </SButton>
    </div>
  );
};
