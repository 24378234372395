import { StylesConfig } from "react-select";

export const BrandsConnectSelectorStyles: StylesConfig = {
  control: (styles, state) => ({
    ...styles,
    boxShadow: state.isFocused ? "0 0 0 3px #e2d9fb" : "none",
    border: state.isFocused ? "1px solid #ccbaf8" : "1px solid #eaecf0",
    "&:hover": {
      border: state.isFocused ? "1px solid #ccbaf8" : "1px solid #eaecf0",
    },
    borderRadius: "8px",
    paddingLeft: "8px",
    paddingRight: "8px",
    minHeight: "46px",
  }),
  input: (styles, state) => ({
    ...styles,
    border: 0,
    "input[type='text']:focus": { boxShadow: "none" },
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: "transparent",
    transform: "translateY(10px)",
  }),
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isSelected ? "#f9fafb" : "#ffffff",
    "&:hover": {
      backgroundColor: "#f9fafb",
    },
    color: "#101828",
    borderRadius: "8px",
    marginBottom: "4px",
  }),
  menu: (styles) => ({
    ...styles,
    padding: "8px",
    borderRadius: "8px",
  }),
  menuList: (styles) => ({
    ...styles,
    paddingRight: "8px",
  }),
};
