import React from "react";
import { useUpdatePost } from "../hooks/useUpdatePost";
import SModal from "../../../design-system/SModal";

function RemoveScheduleAgreement({
  id,
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (p: boolean) => void;
  id: number | null;
}) {
  const { removeSchedule } = useUpdatePost();
  return (
    <SModal
      title={"Remove Schedule"}
      subtitle={
        "Are you sure you want to remove the schedule? Your post will be saved as a draft and you can publish it later."
      }
      type="error"
      isOpen={isOpen}
      setIsOpen={(p) => setIsOpen(p)}
      handleSubmit={() => {
        removeSchedule(id);
        setIsOpen(false);
      }}
      handleCancel={() => setIsOpen(false)}
      actionButtonText={"Remove"}
      actionButtonProps={{
        className: "w-[400px]",
      }}
    />
  );
}

export default RemoveScheduleAgreement;
