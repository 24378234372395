import React from "react";
import SModal from "../../design-system/SModal";
import { IBrandCreationData } from "../../pages/CreateBrand";
import { CopilotType, OrganizationType } from "../../utils/enums/Enums";

function ChangeBrandTypeAgreement({
  isOpen,
  setIsOpen,
  setData,
  handleCreateCopilotSession,
  setCurrentStep,
  data,
}: {
  data: IBrandCreationData;
  setData: React.Dispatch<React.SetStateAction<IBrandCreationData>>;
  handleCreateCopilotSession: (t: CopilotType) => void;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  isOpen: boolean;
  setIsOpen: (p: boolean) => void;
}) {
  return (
    <SModal
      title={"Brand Type"}
      subtitle={
        "Changing the brand type will remove all existing data and settings. Are you sure you want to proceed?"
      }
      type="warning"
      isOpen={isOpen}
      setIsOpen={(p) => setIsOpen(p)}
      handleSubmit={async () => {
        if (data.type === OrganizationType.PERSONAL) {
          setData((prev) => ({
            ...prev,
            type: OrganizationType.COMPANY,
          }));
          await handleCreateCopilotSession(
            CopilotType.ORGANIZATIONCREATIONCOMPANY
          );
          setCurrentStep(1);
          setIsOpen(false);
        } else if (data.type === OrganizationType.COMPANY) {
          setData((prev) => ({
            ...prev,
            type: OrganizationType.PERSONAL,
          }));
          await handleCreateCopilotSession(
            CopilotType.ORGANIZATIONCREATIONPERSONAL
          );
          setCurrentStep(1);
          setIsOpen(false);
        }
      }}
      handleCancel={() => setIsOpen(false)}
      actionButtonText={"Confirm"}
      actionButtonProps={{
        className: "w-[400px]",
      }}
    />
  );
}

export default ChangeBrandTypeAgreement;
