import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { SocialNetworkType } from "../../../utils/enums/Enums";
import { makeRequest } from "../../auth/axios";

export const usePostConnectPage = () => {
  return useMutation<
    IBaseData<string>,
    Error,
    { socialNetworkType: SocialNetworkType }
  >({
    mutationFn: ({ socialNetworkType }) =>
      makeRequest.post(
        `/SocialNetworks/Connect?socialNetworkType=${socialNetworkType}`
      ),
  });
};
