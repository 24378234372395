import { FastAverageColor } from "fast-average-color";
import React, { useEffect, useState } from "react";

function ContainImage({
  src,
  alt,
  className,
  onClick,
}: {
  src: string;
  alt?: string;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}) {
  const [gradientColors, setGradientColors] = useState([
    "rgb(255, 255, 255)",
    "rgb(255, 255, 255)",
    "rgb(255, 255, 255)",
  ]);

  useEffect(() => {
    const fac = new FastAverageColor();
    fac
      .getColorAsync(src, { mode: "precision", algorithm: "dominant" })
      .then((color) => {
        const dominantColor = color.rgb;
        const lighterColor = `rgba(${color.value[0] + 50}, ${color.value[1] + 50}, ${color.value[2] + 50}, 1)`;
        const darkerColor = `rgba(${color.value[0] - 50}, ${color.value[1] - 50}, ${color.value[2] - 50}, 1)`;
        setGradientColors([dominantColor, lighterColor, darkerColor]);
      });
    return () => {
      fac.destroy();
    };
  }, [src]);

  const gradientStyle = {
    background: `linear-gradient(135deg, ${gradientColors[0]}, ${gradientColors[1]}, ${gradientColors[2]})`,
  };

  return (
    <div
      onClick={onClick}
      className={`flex justify-center items-center w-full h-full`}
      style={gradientStyle}
    >
      <img
        src={src}
        alt={alt}
        className={`${className} object-contain max-w-full max-h-full`}
      />
    </div>
  );
}

export default ContainImage;
