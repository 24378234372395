import SButton from "../../../design-system/SButton";
import SInput from "../../../design-system/SInput";
import {
  faArrowLeft,
  faCheckCircle,
  faEnvelope,
  faKey,
  faLock,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
//@ts-ignore
import bgGrid from "../../../assets/images/grid-background.png";
import { getQuery } from "../../../hooks/functions/queries";
import { useNavigate } from "react-router-dom";
import { makeRequest } from "../../../api/auth/axios";
function Form({
  isAcceptedForStory = false,
}: {
  isAcceptedForStory?: boolean;
}) {
  const [isAccepted, setIsAccepted] = useState(isAcceptedForStory);

  const email = getQuery("email");
  const code = getQuery("code");

  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = () => {
    setIsLoading(true);
    makeRequest
      .post("/User/ForgotPasswordConfirmation", {
        email: email,
        code: code,
        password: password,
        confirmPassword: rePassword,
      })
      .then((res) => {
        setIsLoading(false);
        if (res.data.isSuccess) {
          setIsAccepted(true);
        }
        setError("");
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.response.data.message);
      });
  };

  return (
    <div className="relative max-w-[360px] w-full flex justify-center flex-col items-center">
      <img
        className="absolute top-[-10%] w-full h-[300px]"
        src={bgGrid}
        alt="bg"
      />
      {isAccepted ? (
        <div className="relative z-10 w-full flex flex-col items-center justify-center">
          <div className="flex justify-center items-center rounded-[8px] w-[40px] aspect-square bg-base-white border-[1px] border-gray-200 shadow-xs">
            <FontAwesomeIcon
              icon={faCheckCircle}
              fontSize="16px"
              className="text-gray-600"
            />
          </div>
          <p className="display-sm-sb text-gray-900 mt-[24px]">
            Password Reset
          </p>
          <p className="text-center text-md text-gray-600 mt-[12px]">
            Your password has been successfully reset. Click below to log in
            magically.
          </p>
          <SButton
            lIcon={faArrowLeft}
            size="lg"
            className="w-full mt-[24px] relative z-10 !gap-[12px]"
            onClick={() => navigate("/login")}
          >
            Back to log in
          </SButton>
        </div>
      ) : (
        <div className="relative z-10 w-full flex flex-col items-center justify-center">
          <div className="relative z-10 flex justify-center items-center rounded-[8px] w-[40px] aspect-square bg-base-white border-[1px] border-gray-200 shadow-xs">
            <FontAwesomeIcon
              icon={faLock}
              fontSize="16px"
              className="text-gray-600"
            />
          </div>
          <p className="display-sm-sb text-gray-900 mt-[24px]">
            Set new password
          </p>
          <p className="text-center text-md text-gray-600 mt-[12px]">
            Your new password must be different to previously used passwords.
          </p>
          <SInput
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            label="Password"
            placeholder="Enter your new password"
            type="passwrd"
            className="w-full mt-[24px]"
            onKeyDown={(e) => {
              if (e.code === "Enter") handleSubmit();
            }}
          />
          <SInput
            value={rePassword}
            onChange={(e) => setRePassword(e.target.value)}
            type="passwrd"
            label="Confirm Password"
            placeholder="Repeat password"
            className="w-full mt-[10px]"
            onKeyDown={(e) => {
              if (e.code === "Enter") handleSubmit();
            }}
          />
          {error && (
            <div className="w-full">
              <pre className=" whitespace-pre-wrap text-error-500 mt-[12px]">
                {error}
              </pre>
            </div>
          )}
          <SButton
            isLoading={isLoading}
            size="lg"
            className="w-full mt-[24px]"
            onClick={handleSubmit}
          >
            Reset Password
          </SButton>
          <SButton
            size="sm"
            lIcon={faArrowLeft}
            type="linkGray"
            className="w-full !gap-[12px] mt-[24px]"
            onClick={() => navigate("/login")}
          >
            Back to log in
          </SButton>
        </div>
      )}
    </div>
  );
}

export default Form;
