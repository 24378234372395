import { useState } from "react";
import { useCreationHelperFunctions } from "./hooks/useCreationHelperFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faImage,
  faPenToSquare,
  faSquareList,
  faStar,
  faX,
} from "@fortawesome/pro-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useCreationStore } from "../../store/creationStore";
import ScheduleModal from "./modals/ScheduleModal";
import { Tooltip } from "react-tooltip";
import { useGetWindowWidth } from "../../hooks/useGetWindowWidth";
import SBadge from "../../design-system/SBadge";
import SButton from "../../design-system/SButton";

const NavItem = ({
  icon,
  isActive,
  text,
  onClick,
  disabled,
  badge,
  ...props
}: {
  icon: IconProp;
  text: string;
  onClick: () => void;
  isActive: boolean;
  disabled?: boolean;
  badge?: string;
}) => {
  return (
    <div
      {...props}
      onClick={!disabled ? onClick : undefined}
      className={`cursor-pointer relative flex gap-[6px] px-[12px] py-[8px] items-center ${isActive && "bg-graycool-100"} rounded-[6px] ${disabled && "opacity-20"} `}
    >
      <FontAwesomeIcon
        className="text-gray-600 max-md:text-[18px]"
        fontSize={"20px"}
        icon={icon}
      />
      <p
        className={`hidden lg:block ${isActive ? "text-sm-sb" : "text-sm-m"} text-gray-600`}
      >
        {text}
        {badge && (
          <SBadge size="sm" hasBackground={false} className="shadow-none ml-2">
            {badge}
          </SBadge>
        )}
      </p>
    </div>
  );
};

function CreationHeader({ onClose }: { onClose: () => void }) {
  const { isPostPublishedOrScheduled } = useCreationHelperFunctions();
  const {
    activeTab,
    setActiveTab,
    isMobileSectionsDrawerOpen,
    setIsMobileSectionsDrawerOpen,
  } = useCreationStore();
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const { windowWidth } = useGetWindowWidth();

  return (
    <>
      <ScheduleModal
        isOpen={isScheduleModalOpen}
        onClose={() => setIsScheduleModalOpen(false)}
      />
      <Tooltip id="creation-header-frames" place="top" disableStyleInjection>
        We're working to bring you better templates. Stay tuned!
      </Tooltip>
      <div className="px-[36px] py-[16px] border-b-[1px] border-b-graycool-200 flex justify-between items-center max-md:px-[16px] max-md:items-between max-md:border-b-0">
        <p className="display-xs-sb text-graycool-600 max-md:hidden">Creator</p>
        <div className="flex-1 flex justify-center">
          <div className={`flex gap-[2px]`}>
            <NavItem
              icon={faPenToSquare}
              text="Creation"
              isActive={activeTab === "brief"}
              onClick={() => {
                if (activeTab !== "brief") setActiveTab("brief");
                if (!isMobileSectionsDrawerOpen)
                  setIsMobileSectionsDrawerOpen(true);
              }}
            />
            <div
              className={`${isPostPublishedOrScheduled() && "opacity-50 pointer-events-none"} flex gap-[2px]`}
            >
              <NavItem
                icon={faImage}
                text="Assets"
                isActive={activeTab === "media"}
                onClick={() => {
                  if (activeTab !== "media") setActiveTab("media");
                  if (!isMobileSectionsDrawerOpen)
                    setIsMobileSectionsDrawerOpen(true);
                }}
              />
              <NavItem
                // data-tooltip-id="creation-header-frames"
                // disabled
                icon={faStar}
                text="Templates"
                badge={windowWidth > 1286 ? "Beta" : undefined}
                isActive={activeTab === "elements"}
                onClick={() => {
                  if (activeTab !== "elements") setActiveTab("elements");
                  if (!isMobileSectionsDrawerOpen)
                    setIsMobileSectionsDrawerOpen(true);
                }}
              />
            </div>
            <NavItem
              icon={faSquareList}
              text="Accounts"
              isActive={activeTab === "accounts"}
              onClick={() => {
                if (activeTab !== "accounts") setActiveTab("accounts");
                if (!isMobileSectionsDrawerOpen)
                  setIsMobileSectionsDrawerOpen(true);
              }}
            />
          </div>
        </div>
        {windowWidth < 900 && (
          <SButton
            icon={faX}
            size="md"
            type="secondaryGray"
            onClick={async () => {
              await onClose();
            }}
          />
        )}
      </div>
    </>
  );
}

export default CreationHeader;
