import React, { useEffect } from "react";
import { usePutBrandLogo } from "../../../api/brand/logo/put";
import { useUploadFiles } from "../../../hooks/useUploadFiles";
import { useBrandStore } from "../../../store/brandStore";
import { toast } from "../../../hooks/toast";
import {
  OrganizationType,
  OrganizationUserRole,
} from "../../../utils/enums/Enums";
import { useAccountStore } from "../../../store/accountStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUpload, faPlus } from "@fortawesome/pro-regular-svg-icons";

function Logo() {
  const { currentBrand } = useBrandStore();
  const { personalInfo } = useAccountStore();
  const { mutateAsync: uploadLogo, isPending: isUploadLogoLoading } =
    usePutBrandLogo();
  const { files: logoFiles, selectFile: selectLogoFiles } = useUploadFiles();
  const { files: logomarkFiles, selectFile: selectLogomarkFiles } =
    useUploadFiles();
  useEffect(() => {
    if (logoFiles) {
      const formData = new FormData();
      logoFiles.map((item) => formData.append("file", item));
      uploadLogo({ file: formData, isNewOrganization: false })
        .then(() => {
          currentBrand?.organizationType === OrganizationType.COMPANY &&
            toast(`Logo has been uploaded successfully`, "success");
          currentBrand?.organizationType === OrganizationType.PERSONAL &&
            toast(`Profile picture has been uploaded successfully`, "success");
        })
        .catch((err) => {
          toast(err.response.data.message, "error");
        });
    }
  }, [logoFiles]);
  useEffect(() => {
    if (logomarkFiles) {
      const formData = new FormData();
      logomarkFiles.map((item) => formData.append("file", item));
      uploadLogo({
        file: formData,
        isNewOrganization: false,
        isSecondLogo: true,
      })
        .then(() => {
          toast(`Logomark has been uploaded successfully`, "success");
        })
        .catch((err) => {
          toast(err.response.data.message, "error");
        });
    }
  }, [logomarkFiles]);
  return (
    <div className="flex px-[32px] py-[20px] bg-base-white border-b-[1px] border-b-graycool-100 max-md:py-[16px] max-md:px-[20px]">
      <div
        className="cursor-pointer flex flex-col gap-[12px] w-[50%] items-center pb-[12px]"
        onClick={() =>
          personalInfo?.userRole !== OrganizationUserRole.VIEWER &&
          selectLogoFiles({ multiple: false, accept: "images" })
        }
      >
        <p className="text-md-sb text-gray-800 w-full text-left mb-[14px]">
          {currentBrand?.organizationType === OrganizationType.COMPANY
            ? "Logo"
            : "Profile Picture"}
        </p>
        <img
          src={currentBrand?.logo}
          alt="brandlogo"
          className="max-w-[140px] max-h-[75px] object-contain rounded-[4px] max-md:max-w-[100px] my-auto"
        />
      </div>
      {currentBrand?.organizationType === OrganizationType.COMPANY && (
        <div
          className="cursor-pointer flex flex-col gap-[12px] w-[50%] items-center pb-[12px]"
          onClick={() =>
            personalInfo?.userRole !== OrganizationUserRole.VIEWER &&
            selectLogomarkFiles({ multiple: false, accept: "images" })
          }
        >
          <p className="text-md-sb text-gray-800 w-full text-left mb-[14px]">
            Logomark
          </p>
          {currentBrand?.secondLogo ? (
            <img
              src={currentBrand?.secondLogo}
              alt="logomark"
              className="max-w-[140px] max-h-[75px] object-contain rounded-[4px] my-auto"
            />
          ) : (
            <div className="flex flex-col gap-2 max-w-[200px] max-h-[75px] w-full p-4 max-md:p-1 max-md:py-4 bg-brand-25 border border-brand-100 rounded-[12px]">
              <FontAwesomeIcon
                icon={faCloudUpload}
                className="text-brand-800 text-sm"
              />
              <p className="text-sm text-center text-brand-800 w-full">
                Upload Logomark
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Logo;
