import React, { useEffect, useState } from "react";
import SBadge from "../../../design-system/SBadge";
import { useBrandStore } from "../../../store/brandStore";
// @ts-ignore
import WebFont from "webfontloader";
import _ from "lodash";
import SSelectInputRadioDropdown, {
  ISSelectInputDropdownItem,
} from "../../../design-system/SSelectInputRadioDropdown";
import { usePutBrand } from "../../../api/brand/put";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { useAccountStore } from "../../../store/accountStore";
import { OrganizationUserRole } from "../../../utils/enums/Enums";
function Typography() {
  const { currentBrand } = useBrandStore();
  let FONTS: ISSelectInputDropdownItem[] = [
    { index: 0, text: "Roboto" },
    { index: 1, text: "Open Sans" },
    { index: 2, text: "Poppins" },
    { index: 3, text: "Montserrat" },
    { index: 4, text: "Oswald" },
    { index: 5, text: "Noto Sans" },
    { index: 6, text: "Playfair Display" },
    { index: 7, text: "Merriweather" },
    { index: 8, text: "Noto Serif" },
    { index: 9, text: "Josefin Sans" },
    { index: 10, text: "Anton" },
    { index: 11, text: "Dancing Script" },
    { index: 12, text: "Space Grotesk" },
    { index: 13, text: "Pacifico" },
    { index: 14, text: "Archivo Black" },
    { index: 15, text: "Lobster" },
    { index: 16, text: "Shadows Into Light" },
    { index: 17, text: "DM Serif Display" },
    { index: 18, text: "Courgette" },
    { index: 19, text: "Philosopher" },
    { index: 20, text: "Poiret One" },
  ];
  const { mutateAsync: updateBrand } = usePutBrand();
  const [primaryFont, setPrimaryFont] = useState<string | null>(null);
  const [secondaryFont, setSecondaryFont] = useState<string | null>(null);
  const { personalInfo } = useAccountStore();
  const [isPrimarySelectorOpen, setIsPrimarySelectorOpen] = useState(false);
  const [selectedPrimary, setSelectedPrimary] = useState<number | null>(null);
  const [isSecondarySelectorOpen, setIsSecondarySelectorOpen] = useState(false);
  const [selectedSecondary, setSelectedSecondary] = useState<number | null>(
    null
  );

  useEffect(() => {
    if (currentBrand) {
      FONTS.map((font) => {
        if (
          currentBrand?.brandingData?.brandTypography?.primaryFont === font.text
        ) {
          setSelectedPrimary(font.index);
        }
        if (
          currentBrand?.brandingData?.brandTypography?.secondaryFont ===
          font.text
        ) {
          setSelectedSecondary(font.index);
        }
      });
    }
  }, [currentBrand]);

  useEffect(() => {
    if (currentBrand) {
      FONTS.map((item) => {
        if (
          item.index === selectedPrimary &&
          item.text !== currentBrand?.brandingData?.brandTypography?.primaryFont
        ) {
          let tmpBrandingData = _.cloneDeep(currentBrand!.brandingData);
          tmpBrandingData.brandTypography.primaryFont = item.text;
          updateBrand({
            brandId: currentBrand!.id,
            brandingData: tmpBrandingData,
          });
        }
      });
    }
  }, [selectedPrimary]);

  useEffect(() => {
    if (currentBrand) {
      FONTS.map((item) => {
        if (
          item.index === selectedSecondary &&
          item.text !==
            currentBrand?.brandingData?.brandTypography?.secondaryFont
        ) {
          let tmpBrandingData = _.cloneDeep(currentBrand!.brandingData);
          tmpBrandingData.brandTypography.secondaryFont = item.text;
          updateBrand({
            brandId: currentBrand!.id,
            brandingData: tmpBrandingData,
          });
        }
      });
    }
  }, [selectedSecondary]);
  useEffect(() => {
    if (currentBrand?.brandingData?.brandTypography?.primaryFont) {
      WebFont.load({
        google: {
          families: [
            currentBrand?.brandingData?.brandTypography?.primaryFont,
            currentBrand?.brandingData?.brandTypography?.secondaryFont,
          ],
        },
        active: () => {
          setPrimaryFont(
            currentBrand?.brandingData?.brandTypography?.primaryFont
          );
          setSecondaryFont(
            currentBrand?.brandingData?.brandTypography?.secondaryFont
          );
        },
      });
    }
  }, [currentBrand]);
  return (
    <div className="group flex px-[32px] bg-base-white max-md:flex-col max-md:py-[16px] max-md:px-[20px] rounded-b-[8px]">
      <div className="flex flex-col py-[20px] gap-[12px] w-[50%] items-center pb-[12px] max-md:w-full">
        <p className="text-md-sb text-gray-800 w-full text-left mb-[14px]">
          Typography
        </p>
        <div className="relative mb-[30px] flex flex-col items-center">
          <SBadge dotted={true}>Primary</SBadge>
          {currentBrand?.isBrandDataGenerated &&
          currentBrand?.brandingData?.brandTypography?.primaryFont ? (
            <div className="flex flex-col items-center">
              <p
                id="primary-font-selector"
                onClick={() => setIsPrimarySelectorOpen(true)}
                className="mt-[4px] text-[36px] font-medium text-center cursor-pointer"
                style={{ fontFamily: primaryFont || "Inter" }}
              >
                {currentBrand?.brandingData?.brandTypography?.primaryFont}
              </p>
              {personalInfo?.userRole !== OrganizationUserRole.VIEWER && (
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="text-[12px] text-gray-400 opacity-0 group-hover:opacity-100 transition-all"
                />
              )}
            </div>
          ) : (
            <div className="animate-pulse mt-[12px]">
              <div className="w-[120px] h-[26px] rounded-full bg-gray-200" />
            </div>
          )}
          {personalInfo?.userRole !== OrganizationUserRole.VIEWER && (
            <SSelectInputRadioDropdown
              className="absolute top-[100%] z-[1000] min-w-[220px] w-full"
              excludedIds={["primary-font-selector"]}
              isOpen={isPrimarySelectorOpen}
              setIsOpen={setIsPrimarySelectorOpen}
              selected={selectedPrimary}
              setSelected={(e) => setSelectedPrimary(e)}
              items={FONTS}
            />
          )}
        </div>
      </div>
      <div className="flex flex-col py-[20px] gap-[12px] w-[50%] items-center pb-[12px] border-l-[1px] border-l-graycool-100  max-md:w-full max-md:border-l-0">
        <p className="h-[24px] text-md-sb text-gray-800 w-full text-left mb-[14px] max-md:hidden"></p>
        <div className="mb-[30px] flex flex-col items-center relative">
          <SBadge dotted={false}>Secondary</SBadge>
          {currentBrand?.isBrandDataGenerated &&
          currentBrand?.brandingData?.brandTypography?.primaryFont ? (
            <div className="flex flex-col items-center">
              <p
                id="secondary-font-selector"
                onClick={() => setIsSecondarySelectorOpen(true)}
                className="mt-[4px] text-[36px] font-medium text-center cursor-pointer"
                style={{ fontFamily: secondaryFont || "Inter" }}
              >
                {currentBrand?.brandingData?.brandTypography?.secondaryFont}
              </p>
              {personalInfo?.userRole !== OrganizationUserRole.VIEWER && (
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="text-[12px] text-gray-400 opacity-0 group-hover:opacity-100 transition-all"
                />
              )}
            </div>
          ) : (
            <div className="animate-pulse mt-[12px]">
              <div className="w-[120px] h-[26px] rounded-full bg-gray-200" />
            </div>
          )}
          {personalInfo?.userRole !== OrganizationUserRole.VIEWER && (
            <SSelectInputRadioDropdown
              className="absolute top-[100%] z-[1000] min-w-[220px] w-full"
              excludedIds={["secondary-font-selector"]}
              isOpen={isSecondarySelectorOpen}
              setIsOpen={setIsSecondarySelectorOpen}
              selected={selectedSecondary}
              setSelected={(e) => setSelectedSecondary(e)}
              items={FONTS}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Typography;
