import { Dispatch, SetStateAction } from "react";
import { IBreadcrumbsItem } from "../../../design-system/SBreadcrumbs";
import { ISingleFoldersAndFilesVM } from "../../../utils/interfaces/IAssets";

export interface item {
  id: number;
  cardType: "Folder" | "Image" | "PDF" | "Video";
  cardTitle: string;
  setParentFolder?: Dispatch<SetStateAction<null | {id: number | null, title: string}>>;
  setFolderHistory?: Dispatch<SetStateAction<IBreadcrumbsItem[]>>;
  setPage: Dispatch<SetStateAction<number>>;
  setFoldersAndFiles: Dispatch<SetStateAction<ISingleFoldersAndFilesVM[]>>;
}

export const handleFolderChange = ({cardType, setParentFolder, setFolderHistory, id, cardTitle, setFoldersAndFiles, setPage}: item) => {
  if (cardType === 'Folder' && setParentFolder && setFolderHistory) {
    setParentFolder({id: id, title: cardTitle});
    setFolderHistory((prevCards) => {
      return [...prevCards, {
        id: id,
        title: cardTitle,
        type: "transparent",
        callback: () => {
          setFoldersAndFiles([])
          setPage(1)
          setParentFolder({id: id, title: cardTitle})
        }
      }];
    });
  }
}