import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { ISingleBrandVM } from '../utils/interfaces/IBrand';
import { IAccountVM } from '../utils/interfaces/IAccount';

export interface ICreationStoreInitialInterface {
  personalInfo?: null | IAccountVM;
}

const initialState: ICreationStoreInitialInterface = {
  personalInfo: null
};

const store = (set: any) => ({
  ...initialState,
  setPersonalInfo: (personalInfo: null | IAccountVM | undefined) => set({ personalInfo }),
  reset: () => set(initialState)
});
export const useAccountStore = create(devtools(store));
