import { format, isToday } from "date-fns";
import React from "react";

function WeekDays({ days }: { days: Date[] }) {
  return (
    <div className="flex">
      <div className="w-16 border-r border-b border-gray-100"></div>
      <div className="flex-1 grid grid-cols-7 divide-x divide-gray-100 border-b">
        {days.map((day) => (
          <div key={day.toISOString()} className="p-4 text-left">
            <div className="text-sm-m text-gray-400 uppercase">
              {format(day, "EEE")}
            </div>
            <div className="mt-1 flex items-center justify-start">
              <span className="text-sm-m text-gray-900">
                {format(day, "dd")}
              </span>
              {isToday(day) && (
                <span className="ml-[4px] h-[8px] w-[8px] rounded-full bg-amethyst-400 inline-block"></span>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default WeekDays;
