import { faImage, faVideo, faText } from "@fortawesome/pro-regular-svg-icons";
import { useState } from "react";
import { useLibraryStore } from "../../../../store/libraryStore";
import { FileType } from "../../../../utils/enums/Enums";
import { IAssetTypeFilterProps } from "../../filters/AssetTypeFilter";

export const useFilterAssetType = ({
  fileType,
  setFileType,
}: IAssetTypeFilterProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { setFoldersAndFiles, setPage } = useLibraryStore();
  const items = [
    {
      text: "Images",
      handleClick: () => {
        if (fileType === FileType.IMAGE) return;
        setFoldersAndFiles([]);
        setPage(1);
        setFileType(FileType.IMAGE);
      },
      icon: faImage,
    },
    {
      text: "Videos",
      handleClick: () => {
        if (fileType === FileType.VIDEO) return;
        setFoldersAndFiles([]);
        setPage(1);
        setFileType(FileType.VIDEO);
      },
      icon: faVideo,
    },
    {
      text: "Docs",
      handleClick: () => {
        if (fileType === FileType.TEXT) return;
        setPage(1);
        setFoldersAndFiles([]);
        setFileType(FileType.TEXT);
      },
      icon: faText,
    },
  ];

  const displayText = () => {
    if (fileType === undefined) return "Type";
    if (fileType === FileType.TEXT) return "Docs";
    return FileType[fileType].toLowerCase() + "s";
  };

  return { displayText, isOpen, setIsOpen, items, setFoldersAndFiles, setPage };
};
