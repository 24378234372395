import CurrentPlan from "../components/billing/CurrentPlan";
import BillingListView from "../components/tables/BillingListView";
import BillingListSkeleton from "../components/Skeletons/BillingListSkeleton";
import CurrentPlanSkeleton from "../components/Skeletons/CurrentPlanSkeleton";
import { useSubscriptionActions } from "../hooks/useSubscriptionActions";
import {
  faArrowUpRight,
  faGearComplex,
} from "@fortawesome/pro-regular-svg-icons";

const Billing = () => {
  const {
    upgradePlan,
    managePlan,
    currentPlan,
    isCurrentPlanLoading,
    invoices,
    isWorkspaceInvoicesLoading,
    limits,
  } = useSubscriptionActions();

  return (
    <div className="flex flex-col gap-[32px] w-full">
      {isCurrentPlanLoading ? (
        <CurrentPlanSkeleton />
      ) : (
        <CurrentPlan
          plan={currentPlan?.data.package}
          currentPrice={currentPlan?.data.currentPrice}
          limits={limits}
          buttons={[
            {
              buttonText: "Manage",
              buttonCallback: managePlan,
              icon: faGearComplex,
            },
            ...(!currentPlan?.data.isAppsumo
              ? [
                  {
                    buttonText: "Upgrade",
                    buttonCallback: upgradePlan,
                    icon: faArrowUpRight,
                  },
                ]
              : []),
          ]}
        />
      )}
      {isWorkspaceInvoicesLoading ? (
        <BillingListSkeleton />
      ) : (
        <BillingListView items={invoices} />
      )}
    </div>
  );
};

export default Billing;
