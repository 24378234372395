import React from "react";
import PageHeading from "../components/PageHeading";
import IntegrationCard from "../components/integrations/IntegrationCard";
//@ts-ignore
import dropboxLogo from "../assets/images/integrations-logo/dropbox.svg";
//@ts-ignore
import canvaLogo from "../assets/images/integrations-logo/canva.svg";
//@ts-ignore
import googleDriveLogo from "../assets/images/integrations-logo/google-drive.svg";
//@ts-ignore
import hubspotLogo from "../assets/images/integrations-logo/hubspot.svg";
function Integrations() {
  return (
    <div className="w-full py-[24px]">
      <PageHeading
        title="Integrations and connected apps"
        subtitle="Supercharge your workflow and connect the tool you use every day."
      />
      <div className="grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-[12px] mt-[32px] max-md:grid-cols-1">
        <IntegrationCard
          name="Dropbox"
          logo={dropboxLogo}
          description="Upload your Dropbox marketing materials to STORI easily."
          isToggled={true}
        />
        {/* <IntegrationCard
          name="Google Drive"
          logo={googleDriveLogo}
          description="Access and manage your Google Drive files directly from STORI."
          isToggled={false}
        />
        <IntegrationCard
          name="Canva"
          logo={canvaLogo}
          description="Create and import your Canva designs seamlessly into STORI."
          isToggled={false}
        />
        <IntegrationCard
          name="Hubspot"
          logo={hubspotLogo}
          description="Integrate Hubspot to manage your customer relationships within STORI."
          isToggled={false}
        /> */}
      </div>
    </div>
  );
}

export default Integrations;
