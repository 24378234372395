import { useEffect, useState } from "react";
//@ts-ignore
import illustration from "../visuals/create-brand-last-step.png";
import { useBrandStore } from "../../../store/brandStore";

function Visual({ handleCreatBrand }: { handleCreatBrand: () => void }) {
  const [progress, setProgress] = useState(0);
  const { setIsProgressLoading } = useBrandStore();

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prev + 1;
      });
    }, 40);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (progress > 0 && progress < 100) {
      setIsProgressLoading(true);
    }
    if (progress === 100) {
      handleCreatBrand();
    }
  }, [progress]);

  return (
    <div className="max-w-[762px] w-full flex flex-col items-center border border-gray-200 bg-base-white rounded-[8px] p-[36px]">
      <p className="display-sm-sb max-md:text-2xl text-center text-gray-700">
        Your brand is ready!
      </p>
      <p className="text-sm text-gray-500 mt-[12px]">
        Redirecting to a brand page...
      </p>
      <img
        src={illustration}
        className="my-[16px] max-w-[400px] max-md:max-w-[100%]"
        alt="brand-illustration"
      />
      <div className="max-w-[320px] w-full bg-gray-200 rounded-full h-[12px] mb-4">
        <div
          className="h-full rounded-full bg-gradient-to-r from-brand-400 to-brand-600"
          style={{
            width: `${progress}%`,
          }}
        ></div>
      </div>
    </div>
  );
}

export default Visual;
