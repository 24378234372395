import { useState } from "react";
import SModalBase from "../../../../design-system/SModalBase";
import { useCreationHelperFunctions } from "../../hooks/useCreationHelperFunctions";
import { faDownload, faImage, faX } from "@fortawesome/pro-regular-svg-icons";
import SButton from "../../../../design-system/SButton";
import { useCreationLibraryHelperFunctions } from "./hooks/useCreationLibraryHelperFunctions";
import { ISingleFIleVM } from "../../../../utils/interfaces/IAssets";
import ExpandedImageModal from "../../../utils/ExpandedImageModal";
import { isVideo } from "../../../Library/hooks/getFileType";
import { useReorder } from "./hooks/useReorder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConfirmDeleteFrame } from "../Frames/ConfirmDeleteFrame";
import { ISocialNetworkPostFileVM } from "../../../../utils/interfaces/IPost";
import { useFramesStore } from "../../../../store/framesStore";

function ExpandedImages({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const {
    insertMediaForAllSocialNetworkType,
    downloadMediasFromActiveSocialNetworkType,
    setSelectedImageFromResponse,
  } = useCreationLibraryHelperFunctions();
  const { getActiveSocialNetworkPost } = useCreationHelperFunctions();
  const [expandedImage, setExpandedImage] = useState<string | null>(null);
  const {
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    isDragging,
    position,
    positions,
    setPositions,
    itemRefs,
    isClickable,
    scrollContainerRef,
  } = useReorder();
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const {
    whichFrameIsRendering,
    selectedImage,
    setActiveRenderedFrame,
    setActiveFrame,
    setSelectedImage,
  } = useFramesStore();

  const remove = async (item: ISocialNetworkPostFileVM) => {
    const response = await insertMediaForAllSocialNetworkType(
      item as ISingleFIleVM,
      false,
      true,
      true,
      true
    );
    setPositions(
      getActiveSocialNetworkPost()?.files.filter(
        (file) => file.id !== item.id
      ) || []
    );
    if (response) setSelectedImageFromResponse(response);
    if (getActiveSocialNetworkPost()?.files.length === 1) {
      onClose();
    }
  };
  return (
    <>
      {expandedImage && (
        <ExpandedImageModal
          isOpen={!!expandedImage}
          onClose={() => setExpandedImage(null)}
          source={expandedImage}
        />
      )}
      {confirmDelete && selectedImage && selectedImage.url && (
        <ConfirmDeleteFrame
          url={selectedImage.url}
          isOpen={confirmDelete}
          setIsOpen={setConfirmDelete}
          onRemove={() =>
            remove(selectedImage || ({} as ISocialNetworkPostFileVM))
          }
        />
      )}
      <SModalBase
        dialogPanelClassName={"max-w-[600px] max-md:max-w-[350px] w-full"}
        isOpen={isOpen}
        onClose={onClose}
        showX={false}
      >
        <div>
          <SButton
            onClick={onClose}
            icon={faX}
            size="sm"
            type="secondaryGray"
            className="absolute left-[calc(100%+6px)] top-0"
          />
          <SButton
            title="Download all"
            onClick={downloadMediasFromActiveSocialNetworkType}
            icon={faDownload}
            size="sm"
            type="secondaryGray"
            className="absolute left-[calc(100%+6px)] top-11"
          />
        </div>
        <div
          ref={scrollContainerRef}
          className="flex flex-wrap select-none max-h-[80dvh] overflow-y-auto gap-[30px] justify-center h-full w-full"
        >
          {positions.length > 0 &&
            positions.map((item) => {
              return (
                <div className="w-[40%] aspect-square mx-[12px] relative rounded-[8px] cursor-pointer">
                  <div className="absolute top-0 left-0 w-full h-full bg-gray-50 border-4 border-gray-200 rounded-[8px] border-dashed flex items-center justify-center">
                    <FontAwesomeIcon
                      icon={faImage}
                      className="text-[100px] text-gray-200"
                    />
                  </div>
                  <div
                    ref={(el) => item.id && (itemRefs.current[item.id] = el)}
                    key={item.id}
                    className={`w-full h-full relative rounded-[8px] ${!isDragging && "transition-all"}`}
                    style={{
                      transform: `translate(${item.id === isDragging ? position.x : 0}px, ${item.id === isDragging ? position.y : 0}px)`,
                      zIndex: item.id === isDragging ? 100 : 0,
                      cursor: isDragging ? "grabbing" : "pointer",
                    }}
                    onMouseDown={(e) => item.id && handleMouseDown(e, item.id)}
                    onMouseMove={(e) => handleMouseMove(e)}
                    onMouseUp={(e) => {
                      e.stopPropagation();
                      handleMouseUp();
                    }}
                    onClick={async (e) => {
                      if (isDragging || !isClickable) return;
                      if (!isVideo((item.templateOutputUrl || item.url)!)) {
                        setExpandedImage((item.templateOutputUrl || item.url)!);
                      }
                    }}
                    onContextMenu={(e) => {
                      if (isDragging) {
                        e.stopPropagation();
                      }
                    }}
                  >
                    <div className="absolute z-10 top-[12px] right-[12px] flex flex-col gap-[12px]">
                      <SButton
                        icon={faX}
                        type="secondaryGray"
                        className="opacity-80 relative z-[100] rounded-full w-[32px] !h-[32px]"
                        iconProps={{
                          className: "text-[14px]",
                        }}
                        onClick={async (e) => {
                          e.stopPropagation();
                          if (!item.isTemplateUsed) remove(item);
                          if (item.isTemplateUsed) {
                            if (whichFrameIsRendering !== undefined) return;
                            setActiveRenderedFrame(undefined);
                            setActiveFrame(undefined);
                            setSelectedImage(item);
                            setConfirmDelete(true);
                          }
                        }}
                      />
                    </div>
                    {isVideo((item.templateOutputUrl || item.url)!) ? (
                      <video
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        className="w-full h-full object-cover rounded-lg"
                        controls
                      >
                        <source
                          src={item.templateOutputUrl || item.url}
                          type="video/mp4"
                        />
                      </video>
                    ) : (
                      <img
                        className="w-full h-full rounded-lg object-cover"
                        src={item.templateOutputUrl || item.thumbnailMediumUrl}
                        alt="thumbnail"
                        draggable={false}
                      />
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </SModalBase>
    </>
  );
}

export default ExpandedImages;
