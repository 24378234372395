import SBadge from "../../design-system/SBadge";
import SButton from "../../design-system/SButton";
import { IPackageVM } from "../../utils/interfaces/ISubscriptions";
import { IWorkspaceUsageVM } from "../../utils/interfaces/IWorkspace";
import { IBaseData } from "../../utils/interfaces/IBase";
import { BillingPeriod } from "../../utils/enums/Enums";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

import { PromoCode } from "./PromoCode";
import { Limits } from "./Limits";

interface CurrentPlanProps {
  plan?: IPackageVM;
  limits?: IBaseData<IWorkspaceUsageVM>;
  buttons?: {
    buttonText: string;
    buttonCallback: () => void;
    icon: IconDefinition;
  }[];
  currentPrice?: string;
}

const CurrentPlan = ({
  plan,
  limits,
  buttons,
  currentPrice,
}: CurrentPlanProps) => {
  return (
    <div className="rounded-[12px] border-[1px] border-gray-200 bg-white shadow-xs">
      <div className="flex flex-col p-[24px] gap-[24px]">
        <div className="flex max-md:flex-col items-center justify-between gap-[12px]">
          <div className="flex flex-col gap-[4px]">
            <p className="text-lg-sb text-gray-900 content-center">
              {plan?.name}
              <SBadge className={"ml-[12px]"} color={"violet"}>
                {plan?.billingPeriod === BillingPeriod.MONTHLY
                  ? "Per month"
                  : "Annually"}
              </SBadge>
            </p>
            <p className="text-sm text-gray-600">{plan?.description}</p>
          </div>
          <p className="text-[48px] font-semibold text-gray-900 max-md:text-[32px]">
            ${currentPrice?.substring(0, currentPrice.indexOf(" / "))}
            <span className={"text-md-m text-gray-600"}>
              {plan?.billingPeriod === BillingPeriod.MONTHLY
                ? "/Per month"
                : "/Annually"}
            </span>
          </p>
        </div>
        <Limits limits={limits?.data} />
      </div>
      <div className="w-full border-t border-gray-200 flex items-center justify-between py-[16px] px-[24px] max-md:flex-col max-md:gap-[12px]">
        <PromoCode />
        <div className={"flex items-center"}>
          {buttons?.map((button, index) => {
            return (
              <SButton
                key={index}
                type={"tertiaryColor"}
                rIcon={button.icon}
                onClick={button.buttonCallback}
              >
                {button.buttonText}
              </SButton>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CurrentPlan;
