import { TeamRole } from "../../utils/enums/Enums";

export interface IMemberRolesDropdownProps {
  setSelected: (role: TeamRole) => void;
  setIsOpen: (open: boolean) => void;
  selected: TeamRole | "Select Role";
  className?: string;
}

export const MemberRolesDropdown = ({setSelected, setIsOpen, className, selected}: IMemberRolesDropdownProps) => {
  return (
    <div className={`${className} absolute top-1/2 translate-y-1/2 border border-gray-200 text-gray-700 bg-white flex flex-col gap-[9px] rounded-[8px] shadow-lg`}>
      {selected === TeamRole.MEMBER && <h1
        onClick={() => {
          setSelected(TeamRole.VIEWER);
          setIsOpen(false);
        }}
        className="hover:bg-gray-100 px-4 cursor-pointer rounded-[8px] transition-all capitalize py-2"
      >
        {TeamRole[TeamRole.VIEWER].toLowerCase()}
      </h1>}
      {selected === TeamRole.VIEWER && <h1
        onClick={() => {
          setSelected(TeamRole.MEMBER);
          setIsOpen(false);
        }}
        className="hover:bg-gray-100 px-4 cursor-pointer rounded-[8px] transition-all capitalize py-2"
      >
        {TeamRole[TeamRole.MEMBER].toLowerCase()}
      </h1>}
    </div>
  );
};
