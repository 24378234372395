import React from "react";
import { IPostSingleCommentVM } from "../../../utils/interfaces/IPost";
import { format, parseISO, setHours, setMinutes } from "date-fns";

const parseTaggedText = (text: string) => {
  const mentionPattern =
    /@\{\s*"name"\s*:\s*"(.*?)"\s*,\s*"id"\s*:\s*"(.*?)"\s*\}/g;
  const parts = [];
  let lastIndex = 0;
  let match;

  // Loop through all mentions
  while ((match = mentionPattern.exec(text)) !== null) {
    const beforeTag = text.slice(lastIndex, match.index);
    const name = match[1];
    const afterTag = match.index + match[0].length;
    lastIndex = afterTag;

    // Add part before the tag
    if (beforeTag) {
      parts.push(beforeTag);
    }
    // Add the tagged name
    parts.push({ name });
  }

  // Add remaining text after the last tag, if any
  if (lastIndex < text.length) {
    parts.push(text.slice(lastIndex));
  }

  return parts;
};

function Message({ comment }: { comment: IPostSingleCommentVM }) {
  const parsedComment = parseTaggedText(comment.commentText);

  return (
    <div className="flex gap-[12px]">
      <img
        className="w-[35px] h-[35px] min-w-[35px] min-h-[35px] rounded-full object-cover"
        src={comment.userPhotoUrl}
        alt="avatar"
      />
      <div className="w-full">
        <div className="flex justify-between items-center">
          <p className="text-xs-m text-gray-700">{comment.userName}</p>
          <p className="text-xs text-gray-600 opacity-75">
            {format(comment.created, "EE h:mm a")}
          </p>
        </div>
        <p
          className="
            text-sm text-gray-800 px-[14px] py-[10px] mt-[6px]
            bg-gray-50 break-all border border-gray-200 rounded-tr-[8px] rounded-br-[8px] rounded-bl-[8px]
            "
        >
          {parsedComment.map((part, index) =>
            typeof part === "string" ? (
              <span key={index}>{part}</span>
            ) : (
              <span key={index} className="font-bold">
                @{part.name}
              </span>
            )
          )}
        </p>
      </div>
    </div>
  );
}

export default Message;
