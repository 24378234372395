import React, { useRef } from "react";
//@ts-ignore
import bgSvg from "../../../assets/images/creation-background.png";
import {
  OrganizationUserRole,
  TeamPackageStatus,
} from "../../../utils/enums/Enums";

import DefaultPreview from "./DefaultPreview";
import SocMediaSwitcher from "./SocMediaSwitcher";
import { useCheckHasScroll } from "../../../hooks/useCheckHasScroll";
import { useIsHovered } from "../../../hooks/useIsHovered";
import { useAccountStore } from "../../../store/accountStore";
import { useGetWindowWidth } from "../../../hooks/useGetWindowWidth";
import { useCreationStore } from "../../../store/creationStore";
import { MobilePublishedTab } from "../Tabs/MobilePublishedTab";
import { useCreationHelperFunctions } from "../hooks/useCreationHelperFunctions";
import { BillingInfo } from "../../billing/BillingInfo";
import { useSubscriptionActions } from "../../../hooks/useSubscriptionActions";

function PreviewSide({
  setIsUpdatedByCopilot,
  isUpdatedByCopilot,
}: {
  setIsUpdatedByCopilot: (value: boolean) => void;
  isUpdatedByCopilot: boolean;
}) {
  const previewSideRef = useRef<any>();
  const { hasScroll } = useCheckHasScroll(previewSideRef);
  const { isHovered } = useIsHovered(previewSideRef);
  const { personalInfo } = useAccountStore();
  const { activeTab } = useCreationStore();
  const { currentPlan } = useSubscriptionActions();
  const { windowWidth } = useGetWindowWidth();
  const { isPostPublished, isPostScheduled } = useCreationHelperFunctions();

  return (
    <div
      ref={previewSideRef}
      id="preview-side"
      className={`
      w-[40%]
      h-full block bg-graycool-50 relative rounded-tr-[24px] items-center justify-center overflow-auto max-md:w-full max-md:rounded-t-[24px] transition-all duration-300 ease-in-out ${personalInfo?.userRole === OrganizationUserRole.VIEWER ? "w-full rounded-tl-[24px] " : "w-[40%]"} ${hasScroll ? "smooth-scroll" : ""} max-md:rounded-[0px]`}
      style={{
        boxShadow: "2px 4px 12px 0px rgba(0, 0, 0, 0.04) inset",
        backgroundImage: `url(${bgSvg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div
        className={`
          ${!hasScroll && "absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] w-full z-10"} 
          py-[62px]
          flex items-center flex-col max-md:py-[80px]`}
      >
        {(isPostPublished() || isPostScheduled()) && windowWidth <= 1000 && (
          <MobilePublishedTab />
        )}
        <SocMediaSwitcher isHovered={isHovered} />
        <DefaultPreview
          isUpdatedByCopilot={isUpdatedByCopilot}
          setIsUpdatedByCopilot={setIsUpdatedByCopilot}
          isHovered={activeTab === "elements" ? true : isHovered}
        />
        {currentPlan &&
          windowWidth < 1000 &&
          (currentPlan?.data.isTrial ||
            currentPlan?.data.status === TeamPackageStatus.CANCELED ||
            currentPlan?.data.status === TeamPackageStatus.PAUSED) && (
            <BillingInfo className="rounded-none" />
          )}
      </div>
    </div>
  );
}

export default PreviewSide;
