import { faCloudUpload, faSpinner } from "@fortawesome/pro-regular-svg-icons";
import { useGetFiles } from "../../../api/library/files/get";
import SBadge from "../../../design-system/SBadge";
import { useCreationStore } from "../../../store/creationStore";
import { useCreationLibraryHelperFunctions } from "../Tabs/Media/hooks/useCreationLibraryHelperFunctions";
import { FramesPreviewAssetSkeleton } from "./FramesPreviewAssetSkeleton";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const FramesPreviewAssets = () => {
  const { insertMediaForAllSocialNetworkType, setSelectedImageFromResponse } =
    useCreationLibraryHelperFunctions();
  const { setActiveTab } = useCreationStore();
  const [pageSize, setPageSize] = useState(4);
  const [isLoading, setIsLoading] = useState<number | null>(null);
  const { data: files, isFetching } = useGetFiles({
    page: 1,
    pageSize: pageSize,
    fileType: 1,
  });
  const determineEmptyState = () => {
    if (!isFetching && files?.data.files.length === 0) {
      return true;
    }
  };
  return (
    <div
      className={`${(!determineEmptyState() || isFetching) && "bg-brand-100 px-4 pt-4 pb-3 flex flex-col items-center gap-3 rounded-lg"}`}
    >
      <div
        className={`grid grid-cols-3 ${pageSize > 4 ? "h-[200px]" : "h-auto"}  overflow-y-auto ${pageSize > 4 && "pr-3"} gap-2`}
      >
        {!isFetching &&
        files?.data.files.length &&
        files?.data.files?.length > 0
          ? files?.data.files.map((file, index) => {
              if (index === 3 && pageSize === 4) return "";
              return (
                <div className="w-20 h-20 relative">
                  {isLoading === file.id && (
                    <div className="absolute w-full h-full z-20 rounded-lg bg-black/50 flex items-center justify-center">
                      <FontAwesomeIcon
                        icon={faSpinner}
                        className="animate-spin text-brand-200 text-2xl"
                      />
                    </div>
                  )}
                  <img
                    key={file.id}
                    onClick={async (e) => {
                      e.stopPropagation();
                      if (!file.id || isLoading) return;
                      setIsLoading(file.id);
                      const response = await insertMediaForAllSocialNetworkType(
                        file,
                        false,
                        true
                      );
                      if (response) setSelectedImageFromResponse(response);
                      setIsLoading(null);
                    }}
                    className={`w-full object-cover h-full ${isLoading ? "cursor-default" : "hover:brightness-75 cursor-pointer"} transition-all rounded-lg`}
                    src={file.thumbnailMediumUrl}
                    alt={"Preview Asset Thumbnail"}
                  />
                </div>
              );
            })
          : ""}
        {isFetching && (
          <>
            <FramesPreviewAssetSkeleton />
            <FramesPreviewAssetSkeleton />
            <FramesPreviewAssetSkeleton />
          </>
        )}
      </div>
      <SBadge
        color="purple"
        size="sm"
        icon={faCloudUpload}
        className={"cursor-pointer"}
        onClick={(e) => {
          e.stopPropagation();
          if (pageSize === 4 && files?.data.files.length === 4) {
            setPageSize(21);
          } else {
            setActiveTab("media");
          }
        }}
      >
        {pageSize === 4 ? "Choose from assets" : "View all"}
      </SBadge>
    </div>
  );
};
