import { faCircleQuestion } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "react-tooltip";

export const FieldToolTip = ({
  id,
  toolTipContent,
}: {
  toolTipContent?: string;
  id: string;
}) => {
  return (
    <>
      {toolTipContent && (
        <div className="flex items-center">
          <FontAwesomeIcon
            className="text-gray-700"
            size="sm"
            data-tooltip-id={id}
            icon={faCircleQuestion}
          />
          <Tooltip
            id={id}
            place="top"
            style={{ zIndex: 1 }}
            disableStyleInjection
            className="text-sm w-[200px]"
          >
            {toolTipContent}
          </Tooltip>
        </div>
      )}
    </>
  );
};
