import SProgress from "../../design-system/SProgress";
import { IWorkspaceUsageVM } from "../../utils/interfaces/IWorkspace";

export const Limits = ({ limits }: { limits?: IWorkspaceUsageVM }) => {
  return (
    <div
      className={
        "grid grid-cols-3 auto-rows-auto w-full gap-[24px] max-md:grid-cols-1"
      }
    >
      {limits && limits.numberOfOrganizations >= 0 && (
        <SProgress
          current={limits.numberOfOrganizations}
          total={
            limits.numberOfOrganizations + limits.numberOfOrganizationsLeft
          }
          unit={"Brands"}
        />
      )}
      {limits && limits.numberOfMembers >= 0 && (
        <SProgress
          current={limits.numberOfMembers}
          total={limits.numberOfMembers + limits.numberOfMembersLeft}
          unit={"Members"}
        />
      )}
      {limits && limits.numberOfViewers >= 0 && (
        <SProgress
          current={limits.numberOfViewers}
          total={limits.numberOfViewers + limits.numberOfViewersLeft}
          unit={"Viewers"}
        />
      )}
      {limits && limits.numberOfPostsPerMonth >= 0 && (
        <SProgress
          current={limits.numberOfPostsPerMonth}
          total={limits.numberOfPostsPerMonth + limits.numberOfPostsLeft}
          unit={"Posts"}
        />
      )}
      {limits && limits.numberOfGeneratedImagesMonthly >= 0 && (
        <SProgress
          current={limits.numberOfGeneratedImagesMonthly}
          total={
            limits.numberOfGeneratedImagesMonthly +
            limits.numberOfGeneratedImagesLeft
          }
          unit={"AI Generated Images (Monthly)"}
        />
      )}
      {limits && limits.numberOfSocialNetworkAccounts >= 0 && (
        <SProgress
          current={limits.numberOfSocialNetworkAccounts}
          total={
            limits.numberOfSocialNetworkAccounts +
            limits.numberOfSocialNetworkAccountsLeft
          }
          unit={"Social Accounts"}
        />
      )}
    </div>
  );
};
