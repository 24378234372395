import React, { useEffect, useRef, useState } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import { useHandleOutsideClick } from "../../../../hooks/useHandleOutsideClick";
import { formatDate, isBefore } from "date-fns";
import { useCreationStore } from "../../../../store/creationStore";

function DatePickerModal({
  setIsDatePickerOpen,
  selected,
  setSelected,
  setSelectedTime,
}: {
  setIsDatePickerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelected: React.Dispatch<React.SetStateAction<Date>>;
  selected: Date;
  setSelectedTime: React.Dispatch<React.SetStateAction<Date>>;
}) {
  const wrapperRef = useRef<any>();
  useHandleOutsideClick({
    ref: wrapperRef,
    callBack: () => {
      setIsDatePickerOpen(false);
    },
    excludedIds: ["schedule-date-picker-opener"],
  });
  const [openUpwards, setOpenUpwards] = useState(false);
  const { inAdvanceDate, setInAdvanceDate } = useCreationStore();
  useEffect(() => {
    const handlePosition = () => {
      if (wrapperRef.current) {
        const rect = wrapperRef.current.getBoundingClientRect();
        const spaceBelow = window.innerHeight - rect.bottom;
        const spaceAbove = rect.top;

        // Set openUpwards if there's less space below than above
        setOpenUpwards(spaceBelow < 200 && spaceAbove > spaceBelow);
      }
    };

    handlePosition();
    window.addEventListener("resize", handlePosition);

    return () => {
      window.removeEventListener("resize", handlePosition);
    };
  }, []);
  return (
    <div
      ref={wrapperRef}
      className={`absolute top-[calc(100%+12px)] max-md:scale-[0.8] max-md:-translate-x-[12%] max-md:top-[calc(100%-20px)] z-[102] bg-base-white border border-gray-200 left-0 rounded-[8px] shadow-xl3 p-[16px]`}
    >
      <DayPicker
        disabled={(date) => date < new Date(new Date().setHours(0, 0, 0, 0))}
        mode="single"
        required={true}
        selected={selected}
        onSelect={(date) => {
          if (
            inAdvanceDate &&
            formatDate(inAdvanceDate, "yyyy-MM-dd") <
              formatDate(new Date(), "yyyy-MM-dd")
          ) {
            setSelectedTime(new Date());
            setInAdvanceDate(null);
          }
          setSelected(date);
        }}
      />
    </div>
  );
}

export default DatePickerModal;
