import React from "react";
interface ImageGridProps extends React.HTMLAttributes<HTMLDivElement> {
  images: string[];
}

const ImagesGrid = ({ images, ...props }: ImageGridProps) => {
  return (
    <div
      className="grid gap-0.5 w-full h-full grid-cols-2 grid-rows-2"
      {...props}
    >
      {images.length === 1 && (
        <img
          src={images[0]}
          className="col-span-2 row-span-2 object-cover w-full h-full"
          alt=""
        />
      )}
      {images.length === 2 && (
        <>
          <img
            src={images[0]}
            className="col-span-1 row-span-2 object-cover w-full h-full"
            alt=""
          />
          <img
            src={images[1]}
            className="col-span-1 row-span-2 object-cover w-full h-full"
            alt=""
          />
        </>
      )}
      {images.length >= 3 && (
        <>
          <img
            src={images[0]}
            className="col-span-1 row-span-2 object-cover w-full h-full"
            alt=""
          />
          <img
            src={images[1]}
            className="col-span-1 row-span-1 object-cover w-full h-full"
            alt=""
          />
          {images.length === 3 ? (
            <img
              src={images[2]}
              className="col-span-1 row-span-1 object-cover w-full h-full"
              alt=""
            />
          ) : (
            <div className="col-span-1 row-span-1 w-full h-full relative">
              <img
                src={images[2]}
                className="w-full h-full object-cover"
                alt=""
              />
              <div
                style={{ backgroundColor: "rgba(0,0,0,0.6)" }}
                className="w-full h-full absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
              />
              <p className="display-sm-sb text-base-white absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                +{images.length - 3}
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default ImagesGrid;
