import { useEffect, useState } from "react";
import { usePostStockImages } from "../../../../../../api/creation/stocks/post";
import useScrollStatus from "../../../../../../hooks/useScrollStatus";
import { useCreationStore } from "../../../../../../store/creationStore";

export const useStocksInfiniteScroll = ({
  ref,
  inputValue,
}: {
  ref: any;
  inputValue: string;
}) => {
  const { isScrolledToBottom, isFullyOnScreen } = useScrollStatus(ref);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const { mutateAsync: getStockImages } = usePostStockImages();
  const {
    stocks,
    setStocks,
    stocksResponse,
    postData,
    stocksPage,
    setStocksPage,
    isStocksFetching,
    defaultStockStory,
  } = useCreationStore();
  useEffect(() => {
    if (
      initialLoadComplete &&
      !isStocksFetching &&
      stocksResponse &&
      stocksResponse.data.showNext
    ) {
      if (isScrolledToBottom || isFullyOnScreen) {
        getStockImages({
          page: stocksPage + 1,
          story:
            inputValue.length > 0
              ? inputValue
              : postData?.story || defaultStockStory,
        }).then((res) => {
          setStocks([...stocks, ...res.data.images]);
        });
        setStocksPage(stocksPage + 1);
      }
    }
  }, [isScrolledToBottom, initialLoadComplete, stocks, isFullyOnScreen]);
  useEffect(() => {
    if (stocksResponse && !isStocksFetching) {
      if (!initialLoadComplete) {
        setInitialLoadComplete(true);
      }
    }
  }, [stocksResponse]);
};
