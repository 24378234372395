import { useEffect, useState } from "react";
import { useCreationHelperFunctions } from "../hooks/useCreationHelperFunctions";
import { useFramesStore } from "../../../store/framesStore";
import { ISocialNetworkPostFileVM } from "../../../utils/interfaces/IPost";
import ContainImage from "../../utils/ContainImage";
import { isVideo } from "../../Library/hooks/getFileType";
import { FramesPreviewAssets } from "./FramesPreviewAssets";
import ExpandedImages from "../Tabs/Media/ExpandedImages";
import { motion } from "framer-motion";

function FramesPreview() {
  const {
    selectedImage,
    activeRenderedFrame,
    setSelectedImage,
    whichFrameIsRendering,
    setActiveRenderedFrame,
    setActiveFrame,
  } = useFramesStore();
  const { getActiveSocialNetworkPost } = useCreationHelperFunctions();
  const [images, setImages] = useState<ISocialNetworkPostFileVM[]>([]);
  const [isExpandedImagesOpen, setIsExpandedImagesOpen] = useState(false);

  useEffect(() => {
    if (getActiveSocialNetworkPost()?.files) {
      const updatedImages =
        getActiveSocialNetworkPost()?.files.filter(
          (file) => file.url !== selectedImage?.url && !isVideo(file.url || "")
        ) || [];
      setImages(updatedImages);
    }
  }, [getActiveSocialNetworkPost()?.files, selectedImage]);

  const determineExpandedImagesOpen = () => {
    if (images.length === 0 && !selectedImage) return false;
    if (selectedImage && isVideo(selectedImage.url || "")) return false;
    return true;
  };

  const handleImageClick = (image: ISocialNetworkPostFileVM) => {
    if (whichFrameIsRendering !== undefined) return;
    setActiveRenderedFrame(undefined);
    setActiveFrame(undefined);
    setSelectedImage(image);
  };

  return (
    <>
      {isExpandedImagesOpen && (
        <ExpandedImages
          isOpen={isExpandedImagesOpen}
          onClose={() => setIsExpandedImagesOpen(false)}
        />
      )}
      <div
        onClick={() => {
          if (determineExpandedImagesOpen()) {
            setIsExpandedImagesOpen(true);
          }
        }}
        className={`relative ${determineExpandedImagesOpen() ? "cursor-pointer" : "cursor-default"} w-full aspect-square [&_*_#save-template-button]:hover:opacity-100`}
      >
        {getActiveSocialNetworkPost()?.files && (
          <>
            {images.length >= 2 && (
              <motion.div
                initial={{ opacity: 0, x: 0, y: 0 }}
                whileHover={{ x: 100, y: 32, transition: { duration: 0.1 } }}
                animate={{ opacity: 1, x: 32, y: 32 }}
                transition={{ duration: 0.25, delay: 0.25 }}
                className="w-full border-transparent border-[3px] hover:border-brand-300 transition-colors h-[90%] absolute top-0 left-0 transform translate-x-8 translate-y-8 opacity-50"
              >
                <ContainImage
                  onClick={(e) => {
                    e.stopPropagation();
                    handleImageClick(images[1]);
                  }}
                  src={
                    images[1]?.templateOutputUrl || images[1].thumbnailLargeUrl
                  }
                  alt="Layer 1"
                  className="w-full h-full shadow-lg"
                />
              </motion.div>
            )}
            {images.length >= 1 && (
              <motion.div
                initial={{ opacity: 0, x: 0, y: 0 }}
                whileHover={{ x: 60, y: 16, transition: { duration: 0.1 } }}
                animate={{ opacity: 1, x: 16, y: 16 }}
                transition={{ duration: 0.25, delay: 0.15 }}
                className="w-full border-transparent border-[3px] hover:border-brand-300 transition-colors h-[90%] absolute top-0 left-0 transform translate-x-4 translate-y-4 opacity-75"
              >
                <ContainImage
                  src={
                    images[0]?.templateOutputUrl || images[0].thumbnailLargeUrl
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    handleImageClick(images[0]);
                  }}
                  alt="Layer 2"
                  className="w-full h-full shadow-lg"
                />
              </motion.div>
            )}
            {(selectedImage || activeRenderedFrame) &&
              !isVideo(selectedImage?.url || "") && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.25 }}
                  className="relative border-transparent border-[3px] hover:border-brand-300 transition-colors w-full h-[90%]"
                >
                  <ContainImage
                    src={
                      activeRenderedFrame?.url ||
                      selectedImage?.templateOutputUrl ||
                      selectedImage?.thumbnailLargeUrl ||
                      ""
                    }
                    key={
                      activeRenderedFrame?.url ||
                      selectedImage?.templateOutputUrl ||
                      selectedImage?.thumbnailLargeUrl
                    }
                    alt="Layer 3"
                    className="w-full h-full shadow-lg"
                  />
                </motion.div>
              )}
            {!determineExpandedImagesOpen() && (
              <div className="w-full h-full flex flex-col gap-3 items-center justify-center bg-brand-50 border-2 border-dashed border-brand-200">
                <p className="text-sm text-center text-brand-800 w-full">
                  Select a template or...
                </p>
                <div className="flex items-center gap-1 ">
                  <FramesPreviewAssets />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default FramesPreview;
