import { useMutation } from "@tanstack/react-query";
import { IBaseData } from "../../../../utils/interfaces/IBase";
import { makeRequest } from "../../../auth/axios";
import {
  IRenderTemplateCOE,
  IRenderTemplateVM,
} from "../../../../utils/interfaces/IFrames";
import { ISinglePostVM } from "../../../../utils/interfaces/IPost";

export const usePostRenderTemplate = () => {
  return useMutation<IBaseData<IRenderTemplateVM>, Error, IRenderTemplateCOE>({
    mutationFn: (data) =>
      makeRequest.post(`/Templates/Render`, {
        ...data,
      }),
  });
};
