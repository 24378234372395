import { useEffect, useRef, useState } from "react";
import { useCreationHelperFunctions } from "../../../hooks/useCreationHelperFunctions";
import { ISocialNetworkPostFileVM } from "../../../../../utils/interfaces/IPost";
import _ from "lodash";
import { useCreationStore } from "../../../../../store/creationStore";
import { useUpdatePost } from "../../../hooks/useUpdatePost";

export const useReorder = () => {
  const [isDragging, setIsDragging] = useState<false | number>(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const { getActiveSocialNetworkPost } = useCreationHelperFunctions();
  const { postData, positions, setPositions } = useCreationStore();
  const itemRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});
  const [hoveredItemId, setHoveredItemId] = useState<number | null>(null);
  const { updateFilesUI } = useUpdatePost();
  const [isClickable, setIsClickable] = useState<boolean>(true);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleDocumentMouseUp = async () => {
      if (isDragging) {
        setIsDragging(false);
        setPosition({ x: 0, y: 0 });
        await setTimeout(() => {
          setIsClickable(true);
        }, 10);
      }
    };

    document.addEventListener("mouseup", handleDocumentMouseUp);

    return () => {
      document.removeEventListener("mouseup", handleDocumentMouseUp);
    };
  }, [isDragging]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (isDragging) {
      timeoutId = setTimeout(() => {
        setIsClickable(false);
      }, 100);
    }
    return () => clearTimeout(timeoutId);
  }, [isDragging]);

  const handleSave = async (newPositions: ISocialNetworkPostFileVM[]) => {
    if (!postData) return;
    newPositions.forEach((item, index) => {
      item.orderId = index + 1;
    });
    updateFilesUI({
      files: newPositions,
      sendRequest: true,
      socialNetworkPostId: getActiveSocialNetworkPost()?.id,
    });
  };

  const handleMouseDown = (e: any, index: number) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(index);
    const container = scrollContainerRef.current;
    if (!container) return;

    const scrollLeft = container.scrollLeft;
    const scrollTop = container.scrollTop;

    setIsDragging(index);
    setStartX(e.clientX - position.x + scrollLeft);
    setStartY(e.clientY - position.y + scrollTop);
  };

  const handleMouseMove = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if (!isDragging) return;

    const draggedItemRef = itemRefs.current[isDragging];
    if (!draggedItemRef) return;

    const draggedRect = draggedItemRef.getBoundingClientRect();
    let foundOverlap = false;

    const container = scrollContainerRef.current;
    if (!container) return;

    const scrollLeft = container.scrollLeft;
    const scrollTop = container.scrollTop;

    positions.forEach((item) => {
      if (item.id === isDragging) return false;
      const itemRef = item.id && itemRefs.current[item.id];
      if (!itemRef) return false;

      const itemRect = itemRef.getBoundingClientRect();
      const isOverlapping = !(
        draggedRect.right < itemRect.left ||
        draggedRect.left > itemRect.right ||
        draggedRect.bottom < itemRect.top ||
        draggedRect.top > itemRect.bottom
      );
      if (isOverlapping && !foundOverlap) {
        if (hoveredItemId !== item.id) {
          if (hoveredItemId) {
            itemRefs.current[hoveredItemId]!.style.border = "none";
          }
          itemRef.style.border = "4px solid #8f58e9";
          item.id && setHoveredItemId(item.id);
        }
        foundOverlap = true;
      } else {
        itemRef.style.border = "none";
      }
    });

    if (!foundOverlap && hoveredItemId && itemRefs.current[hoveredItemId]) {
      itemRefs.current[hoveredItemId]!.style.border = "none";
      setHoveredItemId(null);
    }

    const x = e.clientX - startX + scrollLeft;
    const y = e.clientY - startY + scrollTop;
    setPosition({ x, y });
  };

  const handleMouseUp = async () => {
    if (!isDragging) return;

    const draggedItemIndex = positions.findIndex(
      (item) => item.id === isDragging
    );
    const draggedItemRef = itemRefs.current[isDragging];
    if (!draggedItemRef) return;

    const draggedRect = draggedItemRef.getBoundingClientRect();

    const overlappedItemIndex = positions.findIndex((item) => {
      if (item.id === isDragging) return false;
      const itemRef = item.id && itemRefs.current[item.id];
      if (!itemRef) return false;

      const itemRect = itemRef.getBoundingClientRect();
      return !(
        draggedRect.right < itemRect.left ||
        draggedRect.left > itemRect.right ||
        draggedRect.bottom < itemRect.top ||
        draggedRect.top > itemRect.bottom
      );
    });

    if (overlappedItemIndex !== -1) {
      const updatedPositions = [...positions];
      [
        updatedPositions[draggedItemIndex],
        updatedPositions[overlappedItemIndex],
      ] = [
        updatedPositions[overlappedItemIndex],
        updatedPositions[draggedItemIndex],
      ];
      setPositions(updatedPositions);
      handleSave(updatedPositions);
    }

    setIsDragging(false);
    setPosition({ x: 0, y: 0 });
    await setTimeout(() => {
      setIsClickable(true);
    }, 10);
  };

  return {
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    isDragging,
    setIsDragging,
    position,
    positions,
    setPositions,
    itemRefs,
    isClickable,
    scrollContainerRef,
  };
};
