import { useMutation, useQueryClient } from "@tanstack/react-query";
import { SocialNetworkType } from "../../../utils/enums/Enums";
import { ISingleBrandVM } from "../../../utils/interfaces/IBrand";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { makeRequest } from "../../auth/axios";

export const usePutBrandActiveSocialNetworks = () => {
  const queryClient = useQueryClient();
  return useMutation<
    IBaseData<ISingleBrandVM>,
    Error,
    { brandId: number; socialNetworkTypes: SocialNetworkType[] }
  >({
    mutationFn: ({ brandId, socialNetworkTypes }) =>
      makeRequest.put(
        `/Organizations/${brandId}/ActiveSocialNetworks`,
        socialNetworkTypes
      ),
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["useGetBrand"] });
      queryClient.invalidateQueries({ queryKey: ["useGetCurrentBrand"] });
    },
  });
};
