import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { makeRequest } from "../../auth/axios";
import { Dispatch, SetStateAction, useState } from "react";
import {
  IFileCOE,
  ISingleFIleVM,
  ISingleFoldersAndFilesVM,
  IUploadFIleVM,
} from "../../../utils/interfaces/IAssets";
import { toast } from "../../../hooks/toast";

export const usePostFile = () => {
  const queryClient = useQueryClient();
  return useMutation<IBaseData<IUploadFIleVM>, any, IFileCOE>({
    mutationFn: ({
      folderId = null,
      isTemplateUsed,
      files,
      shouldRefetch = true,
    }) => {
      const queryParams = new URLSearchParams();
      if (folderId) queryParams.append("folderId", String(folderId));
      if (isTemplateUsed)
        queryParams.append("isTemplateUsed", String(isTemplateUsed));
      const url = `/Files?${queryParams.toString()}`;
      return makeRequest.post(url, files, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "",
        },
      });
    },
    onSuccess: (res) => {
      let isTemplateUsed = false;
      res.data.uploadedFiles.map((item) => {
        if (item.isTemplateUsed) isTemplateUsed = true;
      });
      if (!isTemplateUsed)
        if (res.data.uploadedFiles.length > 0)
          queryClient.invalidateQueries({
            queryKey: ["useGetFoldersAndFiles"],
          });
      if (res.data.errors && res.data.errors.length > 0) {
        res.data.errors.map((error) => {
          toast(error);
        });
      }
    },
    onError: (err) => {
      toast(err?.response?.data?.message);
    },
  });
};
