import { useQuery } from "@tanstack/react-query";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { ITeamUserVM } from "../../../utils/interfaces/ITeamInvitation";
import { makeRequest } from "../../auth/axios";

export const useGetActiveMembers = () => {
  return useQuery<IBaseData<ITeamUserVM[]>>({
    queryKey: ["useGetActiveMembers"],
    queryFn: async () => {
      const response = await makeRequest.get(`/OrganizationUsers`);
      return response as IBaseData<ITeamUserVM[]>;
    },
  });
};
