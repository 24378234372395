import { forwardRef, useEffect } from "react";
import { useCreationStore } from "../../../../../store/creationStore";
import { useUpdatePost } from "../../../hooks/useUpdatePost";
import { SocialNetworkType } from "../../../../../utils/enums/Enums";
import { ISinglePostVM } from "../../../../../utils/interfaces/IPost";
import { usePutPost } from "../../../../../api/posts/put";
import { useCreationHelperFunctions } from "../../../hooks/useCreationHelperFunctions";
import _ from "lodash";
import { useSocialScore } from "../../../hooks/useSocialScore";
import { useGetPostById } from "../../../../../api/posts/getById";
import { useBrandStore } from "../../../../../store/brandStore";

export const useReturnedTextVersions = (
  initialLoad: boolean,
  setIsUpdatedByCopilot: (value: boolean) => void
) => {
  const { updateTextUI } = useUpdatePost();
  const { mutateAsync: putPost } = usePutPost();
  const { briefMessages, postData, setPostData, activeSoc, setActiveSoc } =
    useCreationStore();
  const {
    getSocialNetworkPostWithSocialNetworkType,
    getSocialNetworkTypeByName,
  } = useCreationHelperFunctions();
  const { currentBrand } = useBrandStore();
  const { generateSocialScore } = useSocialScore();
  useEffect(() => {
    const lastMessage = briefMessages[briefMessages.length - 1];
    initialLoad && setIsUpdatedByCopilot(true);
    let isActiveInBrandButNotInPost = false;
    if (
      initialLoad &&
      lastMessage?.content &&
      lastMessage.content.Type === "TextVersions"
    ) {
      lastMessage.content.Data.TextVersions!.map((item) => {
        const socialNetworkType = getSocialNetworkTypeByName(
          item.SocialNetworkType
        );
        if (socialNetworkType) {
          const isActiveInBrand =
            currentBrand?.activeSocialNetworks.includes(socialNetworkType);
          const isActiveInPost =
            postData?.socialNetworks.includes(socialNetworkType);
          if (isActiveInBrand && !isActiveInPost) {
            isActiveInBrandButNotInPost = true;
          }
        }
      });
      // daabruna xutive soc kselistvis
      // davsete tmpPostDatashi kvela text versia kvela soc kselistvis da bolos gavushvi backshi. jer state davaaupdate
      if (
        lastMessage.content.Data.TextVersions!.length > 1 &&
        !isActiveInBrandButNotInPost
      ) {
        let tmpPostData = _.cloneDeep(postData);
        lastMessage.content.Data.TextVersions!.map((item) => {
          tmpPostData?.socialNetworkPosts.map((socItem) => {
            if (
              getSocialNetworkTypeByName(item.SocialNetworkType) ===
              socItem.socialNetworkType
            ) {
              socItem.text = item.TextVersion;
            }
          });
        });
        setPostData(tmpPostData as ISinglePostVM);
        // putPost({
        //   postData: tmpPostData as ISinglePostVM,
        //   postId: postData!.id,
        // }).then((res) => {
        //   generateSocialScore();
        // });
      }
      // daabruna erti soc kselistvis
      else {
        if (
          initialLoad &&
          lastMessage.content.Data.TextVersions?.length === 1 &&
          !isActiveInBrandButNotInPost
        ) {
          const socialNetworkType = getSocialNetworkTypeByName(
            lastMessage.content.Data.TextVersions[0].SocialNetworkType
          ) as SocialNetworkType;
          if (activeSoc !== socialNetworkType) setActiveSoc(socialNetworkType);
          updateTextUI({
            text: lastMessage.content.Data.TextVersions[0].TextVersion,
            socialNetworkPostId:
              getSocialNetworkPostWithSocialNetworkType(socialNetworkType)?.id,
          });
        }
        let tmpPostData = _.cloneDeep(postData);
        let forWhichSocialNetworkTypeToGenerateScore: number | null = null;
        lastMessage.content.Data.TextVersions!.map((item) => {
          tmpPostData?.socialNetworkPosts.map((socItem) => {
            if (
              getSocialNetworkTypeByName(item.SocialNetworkType) ===
              socItem.socialNetworkType
            ) {
              socItem.text = item.TextVersion;
              forWhichSocialNetworkTypeToGenerateScore =
                socItem.socialNetworkType;
            }
          });
        });
        setPostData(tmpPostData as ISinglePostVM);
        // putPost({
        //   postData: tmpPostData as ISinglePostVM,
        //   postId: postData!.id,
        // }).then((res) => {
        //   if (
        //     useCreationStore.getState().activeSoc ===
        //     forWhichSocialNetworkTypeToGenerateScore
        //   ) {
        generateSocialScore();
        // }
        // });
      }
    }
  }, [briefMessages]);
};
