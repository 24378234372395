export const FolderSkeletons = () => {
  return (
    <div className=" bg-gray-100 animate-pulse flex items-center justify-between rounded-lg border px-5 py-3">
      <div className="w-full flex items-center gap-2">
        <div className="w-6 h-5 rounded-xl bg-gray-200" />
        <div className="w-full rounded-full bg-gray-300 h-4" />
      </div>
    </div>
  );
};
