import React, { useRef } from "react";
import { LibraryOptionsButton } from "../../../../Library/LibraryOptionsButton";
import {
  IHandleDeleteProps,
  IHandleDownloadProps,
} from "../../../../Library/hooks/useGridCardActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay } from "@fortawesome/pro-regular-svg-icons";

interface IImageView extends React.HTMLAttributes<HTMLDivElement> {
  source: string;
  isSelected: boolean;
  type: "Image" | "Video";
  cardId: number;
  cardTitle: string;
  extension?: string;
  setOpenConfirmation?: (value: boolean) => void;
  handleDelete?: (value: IHandleDeleteProps) => void;
  handleDownload?: (value: IHandleDownloadProps) => void;
}

function ImageView({
  source,
  isSelected,
  type,
  cardId,
  cardTitle,
  setOpenConfirmation,
  handleDelete,
  handleDownload,
  extension,
  ...props
}: IImageView) {
  const dropdownRef = useRef(null);
  return (
    <div
      className={`
        ${isSelected && "border-[4px] border-brand-600"}
        w-full cursor-pointer relative [&_button]:hover:inline h-[240px] lg:h-[260px] 2xl:h-[180px] rounded-[8px]`}
      {...props}
    >
      {type === "Image" ? (
        <img
          className="w-full h-full object-cover rounded-[8px]"
          src={source}
          draggable={false}
          alt="asset thumbnail"
        />
      ) : (
        <div className="w-full h-full relative rounded-lg">
          <FontAwesomeIcon
            className="text-white text-opacity-35 text-[54px] absolute z-20 right-1/2 translate-x-1/2 top-1/2 -translate-y-1/2"
            icon={faCirclePlay}
          />
          <video muted className="w-full h-full object-cover rounded-lg">
            <source src={source} type="video/mp4" />
          </video>
        </div>
      )}
      <div className="absolute bg-gray-25 rounded-lg right-3 top-3">
        <LibraryOptionsButton
          id={cardId}
          isContent={true}
          className="px-3 py-1"
          cardType={"Image"}
          imageClassName="right-[2px]"
          cardTitle={cardTitle}
          imageSrc={source}
          hasOptions={true}
          dropdownRef={dropdownRef}
          handleDelete={(e) => {
            setOpenConfirmation && setOpenConfirmation(true);
            handleDelete && handleDelete({ e, id: cardId, cardType: "File" });
          }}
          handleDownload={(e) => {
            source &&
              handleDownload &&
              handleDownload({
                e,
                url: source,
                name: cardTitle + extension,
              });
          }}
        />
      </div>
    </div>
  );
}

export default ImageView;
