import { useEffect, useState } from "react";
import { useGetAllPosts } from "../../../api/posts/getAll";
import { useContentStore } from "../../../store/contentStore";
import useScrollStatus from "../../../hooks/useScrollStatus";
import { useGetCurrentWorkspace } from "../../../api/workspaces/current/get";
import { useNavigate } from "react-router-dom";

export const useContentScroll = () => {
  const { isScrolledToBottom, isFullyOnScreen } = useScrollStatus(null);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const { data: currentWorkspace } = useGetCurrentWorkspace();
  const {
    posts,
    setPosts,
    page,
    setPage,
    sortByModifiedDate,
    searchValue,
    selected,
    setSelected,
  } = useContentStore();
  const navigate = useNavigate();
  const [isSetToAll, setIsSetToAll] = useState(false);

  const { data, isFetching } = useGetAllPosts({
    page,
    pageSize: 30,
    postStatus: selected === 0 ? undefined : selected,
    keyword: searchValue.length === 0 ? undefined : searchValue,
    sortByModifiedDate,
  });

  const [totalCount, setTotalCount] = useState(
    selected === 0 ? data?.data.totalCount : 0
  );

  useEffect(() => {
    if (initialLoadComplete && !isFetching && data && data.data.showNext) {
      if (isScrolledToBottom || isFullyOnScreen) {
        setPage(page + 1);
      }
    }
  }, [isScrolledToBottom, initialLoadComplete, data, isFullyOnScreen]);

  useEffect(() => {
    if (data && !isFetching) {
      setPosts([...posts, ...data.data.posts]);
      if (!initialLoadComplete) {
        setInitialLoadComplete(true);
      }
    }
  }, [data, currentWorkspace?.data.id, isFetching]);

  useEffect(() => {
    if (selected === 0) {
      setTotalCount(data?.data.totalCount);
      initialLoadComplete && setIsSetToAll(true);
    }

    if (selected !== 0 && !totalCount && !isFetching && !isSetToAll) {
      setSelected(0);
      navigate("/?section=all");
      setPage(1);
      setPosts([]);
    }
  }, [data, setTotalCount, selected, isFetching, isSetToAll]);

  const calculateTotalCount = () => {
    if (!totalCount) return 0;
    if (selected !== 0) {
      return data?.data.totalCount ?? 0;
    }
    return totalCount;
  };

  return {
    posts,
    setPosts,
    setPage,
    isFetching,
    calculateTotalCount,
    totalCount,
  };
};
