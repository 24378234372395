import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ISocialNetworkPostVM } from "../../../utils/interfaces/IPost";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { makeRequest } from "../../auth/axios";

export const usePutRemoveSchedule = () => {
  const queryClient = useQueryClient();
  return useMutation<
    IBaseData<{ data: ISocialNetworkPostVM; message: string }>,
    Error,
    {
      socialNetworkPostId: number;
    }
  >({
    mutationFn: ({ socialNetworkPostId }) =>
      makeRequest.put(
        `/Posts/SocialNetworkPost/${socialNetworkPostId}/PrepareForEditing`
      ),

    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["useGetFiles"] });
    },
  });
};
