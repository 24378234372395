import { useEffect } from "react";

export function useHandleOutsideClick({
  ref,
  callBack,
  excludedIds = [],
  excludedClassNames = [],
}: {
  ref: any;
  callBack: () => void;
  excludedIds?: string[];
  excludedClassNames?: string[];
}) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !excludedIds?.some((excludedId) => {
          const excludedElement = document.getElementById(excludedId);
          return excludedElement && excludedElement.contains(event.target);
        }) &&
        !excludedClassNames?.some((className) => {
          const elements = document.getElementsByClassName(className);
          for (let i = 0; i < elements.length; i++) {
            if (elements[i].contains(event.target)) {
              return true;
            }
          }
          return false;
        })
      ) {
        callBack();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, excludedIds, excludedClassNames, callBack]);
}
