import { useQuery } from "@tanstack/react-query";
import { makeRequest } from "../auth/axios";
import { IBaseData } from "../../utils/interfaces/IBase";
import { ISingleFrameVM } from "../../utils/interfaces/IFrames";

export const useGetFrames = ({ keywords, templateGroupId }: { keywords?: string, templateGroupId?: number }) => {
  return useQuery<IBaseData<ISingleFrameVM[]>>({
    queryKey: ["useGetFrames", keywords, templateGroupId],
    queryFn: async () => {
      let url = `/Templates?`;
      if (keywords) url += `keywords=${keywords}&`;
      if (templateGroupId) url += `templateGroupId=${templateGroupId}&`;
      const response = await makeRequest.get(url);
      return response as IBaseData<ISingleFrameVM[]>;
    },
  });
};
