import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { IAccountVM } from "../utils/interfaces/IAccount";
import { ISocialNetworkPostFileVM } from "../utils/interfaces/IPost";
import {
  IFrameMetadataTokens,
  IFrameMetadataTokenVM,
  IRenderTemplateVM,
  ISingleFrameVM,
} from "../utils/interfaces/IFrames";
import { useTemplateRendererHelperFunctions } from "../components/creation/hooks/useTemplateRendererHelperFunctions";

export interface ICreationStoreInitialInterface {
  selectedImage: ISocialNetworkPostFileVM | null;
  whichFrameIsRendering?: number;
  selectedFrameTokens: IFrameMetadataTokenVM[];
  activeFrame?: ISingleFrameVM;
  activeRenderedFrame?: IRenderTemplateVM;
  searchValue?: string;
  groupFilter?: { name: string; id: number };
}

const initialState: ICreationStoreInitialInterface = {
  selectedImage: null,
  whichFrameIsRendering: undefined,
  selectedFrameTokens: [],
  activeFrame: undefined,
  activeRenderedFrame: undefined,
  searchValue: "",
  groupFilter: undefined,
};

const store = (set: any) => ({
  ...initialState,
  setActiveFrame: (activeFrame: ISingleFrameVM | undefined) =>
    set({ activeFrame }),
  setActiveRenderedFrame: (
    activeRenderedFrame: IRenderTemplateVM | undefined
  ) => set({ activeRenderedFrame }),
  setSelectedFrameTokens: (selectedFrameTokens: IFrameMetadataTokenVM[]) =>
    set({ selectedFrameTokens }),
  setWhichFrameIsRendering: (whichFrameIsRendering: undefined | number) =>
    set({ whichFrameIsRendering }),
  setSelectedImage: (selectedImage: ISocialNetworkPostFileVM | null) =>
    set({ selectedImage }),
  setSearchValue: (searchValue: string) => set({ searchValue }),
  setGroupFilter: (groupFilter?: { name: string; id: number }) =>
    set({ groupFilter }),
  reset: () => set(initialState),
});
export const useFramesStore = create(devtools(store));
