import React, { useEffect, useState } from "react";
import SModalBase from "../../../design-system/SModalBase";
import { ISingleCustomFieldVM } from "../../../utils/interfaces/IBrand";
import { usePostBrandCustomField } from "../../../api/brand/customField/post";
import { usePutBrandCustomField } from "../../../api/brand/customField/put";
import { useBrandStore } from "../../../store/brandStore";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import SInput from "../../../design-system/SInput";
import { useQueryClient } from "@tanstack/react-query";
import SButton from "../../../design-system/SButton";
import { useDeleteBrandCustomField } from "../../../api/brand/customField/delete";
function CreateCustomFieldModal({
  isOpen,
  onClose,
  field,
}: {
  field?: ISingleCustomFieldVM;
  isOpen: boolean;
  onClose: (value: boolean) => void;
}) {
  const { mutateAsync: createField, isPending: isCreatingLoading } =
    usePostBrandCustomField();
  const { mutateAsync: updateField, isPending: isUpdateLoading } =
    usePutBrandCustomField();
  const { currentBrand } = useBrandStore();
  let example = "<p>Example:</p>";
  const [title, setTitle] = useState<string>("");
  const [editor, setEditor] = useState<ClassicEditor | null>(null);
  const [editorData, setEditorData] = useState(example);
  const [error, setError] = useState<string | null>(null);
  const { mutateAsync: deleteField, isPending: deleteIsLoading } =
    useDeleteBrandCustomField();
  const handleDelete = () => {
    if (currentBrand && field)
      deleteField({ fieldId: field.id }).then((res) => {
        onClose(false);
      });
  };
  useEffect(() => {
    if (isOpen && field) {
      setEditorData(field.text);
      setTitle(field.name);
    } else {
      setEditorData(example);
      setTitle("");
    }
  }, [isOpen]);
  const handleCreate = async () => {
    if (editor) {
      const editorData = editor?.getData();
      if (title.length === 0) {
        setError("Title is required.");
        return;
      }
      if (editorData.length === 0) {
        setError("Content is required.");
        return;
      }
      if (field) {
        if (currentBrand)
          updateField({
            fieldId: field.id,
            fieldData: {
              name: title,
              text: editorData,
              organizationId: currentBrand.id,
            },
          }).then((res) => {
            setTitle("");
            setEditorData(example);
            setError(null);
            onClose(false);
          });
      } else {
        if (currentBrand)
          createField({
            name: title,
            text: editorData,
            organizationId: currentBrand.id,
          }).then((res) => {
            setTitle("");
            setEditorData(example);
            setError(null);
            onClose(false);
          });
        // Do something with the editorData
      }
    }
  };
  return (
    <SModalBase
      dialogPanelClassName="min-w-[644px] z-10 py-4 text-gray-600 max-md:min-w-[100%] max-h-[70dvh] overflow-y-auto"
      isOpen={isOpen}
      onClose={onClose}
    >
      <h1 className="text-md-m mb-3">Create custom field</h1>
      <hr className="w-auto mx-[-24px]" />
      <div className="flex w-full flex-col gap-3 my-6 max-md:mb-0">
        <SInput
          label="Title"
          placeholder="Enter title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <CKEditor
          config={{
            toolbar: {
              items: [
                "heading",
                "|",
                "bold",
                "italic",
                "link",
                "bulletedList",
                "numberedList",
                "blockQuote",
                "insertTable",
                // 'mediaEmbed',
                "undo",
                "redo",
              ],
            },
          }}
          editor={ClassicEditor}
          data={editorData}
          onReady={(editor) => {
            setEditor(editor);
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            setEditorData(data);
          }}
        />
        {error && <p className="text-sm-m text-error-500">{error}</p>}
        <div className={`flex ${field ? "justify-between" : "justify-end"}`}>
          {field && (
            <SButton
              isLoading={deleteIsLoading}
              onClick={handleDelete}
              className="bg-error-600 border-error-600 hover:bg-error-700 hover:border-error-700"
            >
              Delete field
            </SButton>
          )}
          <div className="flex justify-end gap-[12px]">
            <SButton onClick={() => onClose(false)} type="secondaryGray">
              Cancel
            </SButton>
            <SButton
              onClick={handleCreate}
              isLoading={isCreatingLoading || isUpdateLoading}
              type="primary"
            >
              {field ? "Update" : "Create field"}
            </SButton>
          </div>
        </div>
      </div>
    </SModalBase>
  );
}

export default CreateCustomFieldModal;
