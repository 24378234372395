import { faCamera } from "@fortawesome/pro-regular-svg-icons";
import { faPaperPlaneAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useRef } from "react";
import SButton from "../../../../design-system/SButton";
import { useSpeechRecognition } from "react-speech-recognition";
import { toast } from "react-toastify";
import { SelectFileOptions } from "../../../../hooks/useUploadFiles";
import { Tooltip } from "react-tooltip";
import { useCreationStore } from "../../../../store/creationStore";
import VoiceRecorder from "./VoiceRecorder";
import { useCreationHelperFunctions } from "../../hooks/useCreationHelperFunctions";
import UploadedFilesView from "./UploadedFilesView";

function Textarea({
  files,
  value,
  setValue,
  sendMessage,
  disable,
  selectFile,
  setFiles,
  setIsExpandedImageModalOpen,
  setIsExpandedVideoModalOpen,
}: {
  files: File[] | null;
  selectFile: (options: SelectFileOptions) => void;
  disable: boolean;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  sendMessage: (t: string) => void;
  setFiles: React.Dispatch<React.SetStateAction<File[] | null>>;
  setIsExpandedImageModalOpen: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  setIsExpandedVideoModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { transcript, resetTranscript, listening } = useSpeechRecognition();
  const { suggestedPrompts, setSuggestedPrompts, postData, activeSoc } =
    useCreationStore();
  const [lastState, setLastState] = useState("");
  const lastKeyPressRef = useRef<string | null>(null);
  const textareaRef = useRef<any>(null);
  useEffect(() => {
    if (listening) setValue(transcript);
  }, [transcript]);

  useEffect(() => {
    if (!disable) {
      document.getElementById("create-brand-chat-textarea")?.focus();
    }
  }, [disable]);

  const handleSend = () => {
    if (value && value.trim().length > 0) {
      if (files && files.length > 5) {
        toast.error("You can't upload more than 5 images");
        return;
      }
      setSuggestedPrompts([]);
      sendMessage(value);
    } else if (files && files.length > 0) {
      sendMessage(value);
      setSuggestedPrompts([]);
    }
  };

  let mtCondition = files && files.length > 0;

  useEffect(() => {
    if (textareaRef.current) {
      adjustTextareaHeight();
    }
  }, [value, files]);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${Math.min(textarea.scrollHeight, 300)}px`;
    }
  };
  const { isPostPublishedOrScheduled } = useCreationHelperFunctions();
  return (
    <div
      className={`${mtCondition ? "mt-[12px]" : "mt-[0px]"} relative focus:border-gray-200 bg-gray-25 border border-gray-100 rounded-b-[10px]`}
    >
      <textarea
        ref={textareaRef}
        id="create-brand-chat-textarea"
        disabled={disable || isPostPublishedOrScheduled()}
        placeholder={
          isPostPublishedOrScheduled()
            ? "You cannot message Jason on a scheduled or published post"
            : "Message Jason"
        }
        className={`-mb-2 px-[18px] bg-gray-25 h-full focus-visible:outline-[0] focus:ring-0 w-full text-md rounded-b-[10px] border-[0px] resize-none`}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          setValue(e.target.value);
        }}
        value={listening ? lastState + " " + value : value}
        onKeyDown={(e) => {
          lastKeyPressRef.current = e.code;
          if (e.shiftKey && e.key === "Enter") return;
          if (e.code === "Enter") {
            e.preventDefault();
            handleSend();
            resetTranscript();
          }
        }}
      />
      <div className="flex justify-end max-sm:flex-col items-end gap-[6px] p-2">
        <div className="mr-auto">
          <UploadedFilesView
            setIsExpandedImageModalOpen={setIsExpandedImageModalOpen}
            setIsExpandedVideoModalOpen={setIsExpandedVideoModalOpen}
            files={files}
            setFiles={setFiles}
          />
        </div>
        <div className="flex items-end gap-[6px]">
          <div
            onClick={() => {
              if (isPostPublishedOrScheduled()) return;
              selectFile({ multiple: true, accept: "media" });
            }}
            className={`w-[40px] h-[40px] rounded-[8px] flex justify-center items-center bg-transparent ${isPostPublishedOrScheduled() ? "cursor-default" : "cursor-pointer"} text-gray-500`}
          >
            <FontAwesomeIcon icon={faCamera} />
          </div>
          <VoiceRecorder
            inputRef={textareaRef}
            value={value}
            setValue={setValue}
            setLastState={setLastState}
            lastState={lastState}
            listening={listening}
            transcript={transcript}
            resetTranscript={resetTranscript}
            additionalFunction={handleSend}
          />
          {files && files.length > 5 && (
            <Tooltip id="brief-send-message-button" place="top">
              You can't upload more than 5 images
            </Tooltip>
          )}
          <SButton
            type={"secondaryColor"}
            data-tooltip-id="brief-send-message-button"
            disabled={
              disable ||
              !!(files && files?.length > 5) ||
              isPostPublishedOrScheduled()
            }
            size="md"
            style={{
              border: "none",
              background: "none",
            }}
            onClick={() => {
              handleSend();
              resetTranscript();
            }}
          >
            <FontAwesomeIcon icon={faPaperPlaneAlt} />
          </SButton>
        </div>
      </div>
    </div>
  );
}

export default Textarea;
