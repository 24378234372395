import { useGetAllBrands } from "../../../api/brand/getAll";
import { usePutSwitchBrand } from "../../../api/brand/switch/put";
import { toast } from "../../../hooks/toast";
import { useNavigate } from "react-router-dom";
import { useGetWorkspaceUsers } from "../../../api/workspaces/users/get";

export const useDeleteSwitchBrand = () => {
  const {
    data: brands,
    isLoading: isBrandsLoading,
  } = useGetAllBrands({
    includeAllWorkspaces: false,
  });
  const { data: workspaceUsers } = useGetWorkspaceUsers();
  const { mutateAsync: switchBrand } = usePutSwitchBrand();
  const navigate = useNavigate();

  const handleSwitch = (chosenBrand: number) => {
    switchBrand({ id: chosenBrand }).catch((res) => {
      toast(res.message, "error");
    });
    setTimeout(() => {
      navigate("/brand", {
        state: { selected: 2 },
      });
      window.scrollTo(0, 0);
    });
  };

  return {
    brands,
    isBrandsLoading,
    handleSwitch,
    workspaceUsers,
  };
};
