import React from "react";

interface ISTextareaProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "onChange" | "onKeyDown"> {
  label?: string;
  placeholder?: string;
  helperText?: string | React.ReactNode;
  inputProps?: React.InputHTMLAttributes<HTMLTextAreaElement>;
  disabled?: boolean;
  error?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  isOptional?: boolean;
  textareaClassname?: string;
}

function STextarea({
  label,
  placeholder,
  helperText,
  inputProps,
  disabled,
  error,
  value,
  onChange,
  onKeyDown,
  isOptional,
  textareaClassname,
  ...props
}: ISTextareaProps) {
  return (
    <div {...props}>
      {label && (
        <label className="text-sm-m font-medium text-gray-700">
          {label}
          {isOptional && (
            <span className="!font-normal text-gray-400"> (Optional)</span>
          )}
        </label>
      )}
      <textarea
        disabled={disabled}
        type="text"
        className={`
            ${textareaClassname} h-[128px]
            ${label && "mt-[6px]"}
            outline-none
            w-full rounded-[8px] py-[6px] px-[12px] border-[1px] 
            ${error ? "border-error-300" : "border-gray-300"} text-gray-900 text-m
            placeholder:text-gray-500 ${error ? "focus-visible:border-error-400" : "focus-visible:border-gray-400"} 
            disabled:bg-gray-50 disabled:cursor-not-allowed disabled:text-gray-400
            `}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={onKeyDown}
        {...inputProps}
      />
      {(error || helperText) && (
        <p className={`text-sm ${error ? "text-error-500" : "text-gray-600"} `}>
          {error || helperText}
        </p>
      )}
    </div>
  );
}

export default STextarea;
