import { ReactNode } from "react";
import { NotificationType } from "../../utils/enums/Enums";
import { ISingleNotification } from "../../utils/interfaces/INotifications";
import { EmptyState } from "../Library/EmptyState";
import { notificationValueByType } from "./functions/notificationValueByType";
import NotificationItem from "./NotificationItem";
import NotificationSkeleton from "./NotificationSkeleton";

export interface IRenderNotifications {
  emptyState?: boolean;
  isPending: boolean;
  isFetching: boolean;
  isLoading: boolean;
  notifications: ISingleNotification[];
  handleDeleteNotification: (id: number) => void;
  handleClick: (
    notificationId: number,
    organizationId: number,
    statusId: number,
    isOrganization: boolean
  ) => void;
  getValue: (
    item: ISingleNotification,
    type: NotificationType,
    organizationOrWorkspace: string
  ) => ReactNode | string;
  isResponding: boolean;
  action: "accept" | "reject" | null;
}

export const RenderNotifications = ({
  emptyState = true,
  handleClick,
  getValue,
  handleDeleteNotification,
  notifications,
  isLoading,
  isFetching,
  isPending,
  isResponding,
  action,
}: IRenderNotifications) => {
  return (
    <>
      {notifications.map((item) => {
        const organizationName =
          item.customProperty.organizations &&
          item.customProperty.organizations[0]?.name;
        const organizationOrWorkspace = organizationName
          ? organizationName + " brand "
          : item.customProperty.workspace?.name;

        return (
          <NotificationItem
            title={getValue(item, item.type, organizationOrWorkspace)}
            isResponding={isResponding}
            action={action}
            systemNotification={
              item.type === NotificationType.SystemNotification
                ? item
                : undefined
            }
            message={notificationValueByType(item.type, [
              "Check it out and join the conversation!",
              `You've received an invitation to join a new workspace. Don't miss out!`,
              "An example post has been created for your reference. Check it out for inspiration!",
              `Your post is ready for publishing. Review it and hit publish when you're ready`,
              `Draft post has been successfully created. Feel free to continue editing!`,
              `Your post is now live! Share it with your network and engage with your audience.`,
              `Your post has been scheduled for future publication. Stay tuned!`,
              `Your post is now live! Share it with your network and engage with your audience.`,
              `A new member has joined our workspace. Welcome them aboard!`,
            ])}
            type={
              item.type === NotificationType.INVITATION ? "warning" : "info"
            }
            notificationType={item.type}
            date={item.created}
            handleCancel={() =>
              item.type === NotificationType.INVITATION &&
              handleClick(
                item.id,
                item.customProperty.id,
                3,
                !!organizationName
              )
            }
            handleSubmit={() =>
              item.type === NotificationType.INVITATION &&
              handleClick(
                item.id,
                item.customProperty.id,
                2,
                !!organizationName
              )
            }
            handleDeleteNotification={() => handleDeleteNotification(item.id)}
          />
        );
      })}
      {(isLoading || isFetching || isPending) && (
        <>
          <NotificationSkeleton />
          {notifications.length === 0 && (
            <>
              <NotificationSkeleton />
              <NotificationSkeleton />
            </>
          )}
        </>
      )}
      {emptyState &&
        !isPending &&
        !isFetching &&
        !isLoading &&
        notifications.length === 0 && (
          <EmptyState
            title="No notifications"
            subtitle="You're all caught up! No new notifications here."
          />
        )}
    </>
  );
};
