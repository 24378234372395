import axios from "axios";
import {
  IFrameMetadataTokens,
  IFrameMetadataTokenVM,
  IRenderTemplateCOE,
  IRenderTemplateVM,
  ISingleFrameVM,
} from "../../../utils/interfaces/IFrames";
import { useFramesStore } from "../../../store/framesStore";
import _ from "lodash";
import { useCreationStore } from "../../../store/creationStore";
import { getBearerToken } from "../../../axios";
import { useQueryClient } from "@tanstack/react-query";
import { useGetPostById } from "../../../api/posts/getById";
import { usePostRenderTemplate } from "../../../api/creation/frames/render/post";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { ISinglePostVM } from "../../../utils/interfaces/IPost";
import { TemplateTokenType } from "../../../utils/enums/Enums";
import { usePutAppendTemplate } from "../../../api/creation/frames/append/put";
import { toast } from "../../../hooks/toast";
export const useTemplateRendererHelperFunctions = () => {
  const { postData, setPostData } = useCreationStore();
  const { mutateAsync: postAppendTemplate } = usePutAppendTemplate();
  const queryClient = useQueryClient();
  const { mutateAsync: postRenderTemplate } = usePostRenderTemplate();
  const {
    setWhichFrameIsRendering,
    setSelectedFrameTokens,
    activeRenderedFrame,
    setActiveFrame,
    activeFrame,
    setActiveRenderedFrame,
    selectedFrameTokens,
    selectedImage,
  } = useFramesStore();

  const renderTemplate = async ({
    template,
    tokens,
  }: {
    template: ISingleFrameVM;
    tokens?: IFrameMetadataTokens[];
  }) => {
    if (!postData) throw new Error("Post data is missing");
    const lastActiveRenderedFrame = _.cloneDeep(activeRenderedFrame);
    setActiveFrame({
      ...template,
      metadata: {
        ...template.metadata,
        tokens: (tokens || template.metadata.tokens) as IFrameMetadataTokenVM[],
      },
    });
    setWhichFrameIsRendering(template.id);
    const newTokens = tokens || template.metadata.tokens;
    newTokens.map((token) => {
      if (token.type === TemplateTokenType.IMAGE) {
        token.value = selectedImage?.url;
      }
    });
    setSelectedFrameTokens(newTokens as IFrameMetadataTokenVM[]);
    return postRenderTemplate({
      templateId: template.id,
      tokens: newTokens,
    })
      .then((res) => {
        setActiveRenderedFrame(res.data);
        setWhichFrameIsRendering(undefined);
        return res;
      })
      .catch((err) => {
        toast("Something went wrong. Please try again.", "error");
        if (selectedImage && selectedImage?.template) {
          setActiveFrame(selectedImage?.template);
          setActiveRenderedFrame(lastActiveRenderedFrame);
        } else {
          setActiveFrame(undefined);
        }
        setWhichFrameIsRendering(undefined);
      });
  };

  const saveTemplate = (data?: IRenderTemplateVM) => {
    if (!postData) return;
    const newActiveRenderedFrame =
      data !== undefined ? data : activeRenderedFrame;
    if (!newActiveRenderedFrame) return;
    const socialNetworkPostFileIds = postData.socialNetworkPosts
      .flatMap((post) => post.files)
      .filter((file) => file.url === selectedImage?.url)
      .map((file) => file.id) as number[];
    postAppendTemplate({
      postId: postData.id,
      data: {
        renderTemplateResponse: {
          templateId: newActiveRenderedFrame.templateId,
          tokens: newActiveRenderedFrame.tokens,
          url: newActiveRenderedFrame.url,
        },
        socialNetworkPostFileIds: socialNetworkPostFileIds || undefined,
      },
    }).then((res) => {
      setPostData(res.data);
    });
  };

  const changeValueOfToken = (name: string, value: string) => {
    let tmpSelectedFrameTokens = _.cloneDeep(selectedFrameTokens);
    if (
      tmpSelectedFrameTokens.find((token) => token.name === name) !== undefined
    ) {
      tmpSelectedFrameTokens.find((token) => token.name === name)!.value =
        value;
    }
    setSelectedFrameTokens(tmpSelectedFrameTokens);
  };

  return {
    renderTemplate,
    changeValueOfToken,
    saveTemplate,
  };
};
