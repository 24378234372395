import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IBase } from "../../../utils/interfaces/IBase";
import { makeRequest } from "../../auth/axios";

export const useRemoveWorkspaceMember = () => {
  const queryClient = useQueryClient();
  return useMutation<IBase<{data: string}>, Error, {email?: string; userId?: string;}>({
    mutationFn: ({email, userId}) => {
      return makeRequest.delete(`/WorkspaceUsers/?email=${email}&userId=${userId}`);
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["useGetActiveMembers"] });
      queryClient.invalidateQueries({ queryKey: ["useGetWorkspaceUsers"] });
    },
  });
};
