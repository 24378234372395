import { makeRequest } from "../../axios";
import { useQuery } from "@tanstack/react-query";
import { IBaseData } from "../../utils/interfaces/IBase";
import { IWorkspaceVM } from "../../utils/interfaces/IWorkspace";

export const useGetWorkspaces = () => {
  return useQuery<IBaseData<IWorkspaceVM[]>>({
    queryKey: ["useGetWorkspaces"],
    queryFn: async () => {
      const response = await makeRequest.get("/Workspaces");
      return response as IBaseData<IWorkspaceVM[]>;
    },
  });
};
