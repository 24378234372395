import { IAccountVM, IChangeEmailCOE } from "../../../utils/interfaces/IAccount";
import { IBase } from "../../../utils/interfaces/IBase";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../../axios";

export const usePutChangeEmail = () => {
  const queryClient = useQueryClient();
  return useMutation<IBase<IAccountVM>, Error, IChangeEmailCOE>({
    mutationFn: (data) =>
      makeRequest.put("/Account/ChangeEmail", { ...data }),
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["useGetUser"] });
    },
  });
};
