import React from "react";

export interface BrandsListSkeletonProps {
  className?: string;
}

function BrandsListSkeleton({ className }: BrandsListSkeletonProps) {
  return (
    <div className={`${className} w-full bg-white rounded-[12px] shadow-xs`}>
      <table className="w-full animate-pulse">
        <thead>
          <tr className="text-left">
            <th className="px-6 py-4 text-sm font-semibold text-gray-600">
              Company
            </th>
            <th className="px-6 py-4 text-sm font-semibold text-gray-600">
              About
            </th>
            <th className="w-10"></th>
          </tr>
        </thead>
        <tbody>
          {[1, 2, 3, 4, 5].map((_, index) => (
            <tr key={index} className="border-b border-gray-200">
              <td className="px-6 py-4">
                <div className="flex items-center">
                  <div className="w-10 h-10 rounded-full bg-gray-300 mr-4"></div>
                  <div className="flex flex-col">
                    <span className="h-4 bg-gray-300 rounded w-24 mb-2"></span>
                    <span className="h-3 bg-gray-200 rounded w-32"></span>
                  </div>
                </div>
              </td>
              <td className="px-6 py-4">
                <span className="h-3 bg-gray-200 rounded w-32"></span>
              </td>
              <td className="px-6 py-4 flex items-center gap-2">
                <div className="w-4 h-4 bg-gray-300 rounded-full"></div>
                <div className="w-4 h-4 bg-gray-300 rounded-full"></div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default BrandsListSkeleton;
