import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IBaseData } from "../../utils/interfaces/IBase";
import { makeRequest } from "../auth/axios";
import { ISingleNotification } from "../../utils/interfaces/INotifications";
import useNotificationSocketStore from "../../store/notificationsStore";

export const useDeleteNotification = () => {
  const { setPage, setNotifications } = useNotificationSocketStore();
  const queryClient = useQueryClient();
  return useMutation<IBaseData<ISingleNotification>, Error, { id: number }>({
    mutationFn: ({ id }) => {
      return makeRequest.delete(`/Notifications/${id}`);
    },
    onSuccess: (res) => {
      setNotifications([])
      setPage(1);
      queryClient.invalidateQueries({ queryKey: ["useGetNotifications"] });
    },
  });
};
