import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../../auth/axios";
import { IBaseData } from "../../../../utils/interfaces/IBase";
import { ISinglePostVM } from "../../../../utils/interfaces/IPost";

export const useDeleteTemplate = () => {
  const queryClient = useQueryClient();
  return useMutation<
    IBaseData<ISinglePostVM>,
    Error,
    { postId: number; fileIds: number[] }
  >({
    mutationFn: ({ postId, fileIds }) =>
      makeRequest.delete(`/Posts/${postId}/RemoveTemplateFromPost`, {
        data: fileIds,
      }),
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["useGetFrames"] });
    },
  });
};
