import React, { useState } from "react";
import ExpandedImageModal from "../../../utils/ExpandedImageModal";
import { ICopilotMessage } from "../../../../utils/copilot-base/ICopilotBase";
import AgentInfo from "./AgentInfo";
//@ts-ignore
import jason from "../.../../../../../assets/images/agents/jason.png";
import { Tooltip } from "react-tooltip";
import SButton from "../../../../design-system/SButton";
import { faCheck, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { useCreationLibraryHelperFunctions } from "../Media/hooks/useCreationLibraryHelperFunctions";

const ToolMessage = ({ msg }: { msg: ICopilotMessage }) => {
  const [imageAdded, setImageAdded] = useState(false);
  const [expandedImage, setExpandedImage] = useState<string | null>(null);
  const { insertMediaForAllSocialNetworkType, isMediaAlreadyInPost } =
    useCreationLibraryHelperFunctions();
  return (
    <>
      {expandedImage && (
        <ExpandedImageModal
          isOpen={!!expandedImage}
          onClose={() => setExpandedImage(null)}
          source={expandedImage}
          fileObj={msg.files && msg.files.length > 0 ? msg.files[0] : undefined}
        />
      )}
      <div className="h-fit flex gap-[12px] relative max-md:gap-[4px]">
        <AgentInfo
          logo={jason}
          agentName={"Jason"}
          agentDescription={
            "Hi there, I'm Jason!" +
            "\n \n" +
            "- I can format, change, adapt text\n" +
            "- I can generate images\n" +
            "- I can work on multiple social media\n" +
            "- I know your brand already\n" +
            "- I can recognize image and video content\n" +
            "- I can perform actions while talking with you \n" +
            "- I can open links you send me and gather info" +
            "\n \n" +
            "Just tell me what you need, and I'm happy to help!"
          }
          agentRole={"Content Creator"}
        >
          <img
            src={jason}
            alt="jason-avatar"
            className="max-w-[40px] max-h-[40px] w-[40px] h-[40px] max-md:max-w-[30px] max-md:max-h-[30px] max-md:w-[30px] max-md:h-[30px] rounded-full cursor-pointer aspect-square"
          />
        </AgentInfo>
        <div
          className={`bg-gray-50 flex flex-col justify-center items-start border rounded-[8px] rounded-tl-[0] border-gray-200 py-[10px] px-[14px] max-w-[456px] w-full`}
        >
          {msg.files &&
            msg.files.map((file, index) => (
              <div
                onClick={() => setExpandedImage(file.url)}
                key={index}
                className="w-full max-w-[400px] mb-[16px] last:mb-0 relative group"
              >
                <div className="cursor-pointer w-full h-full rounded-md absolute top-0 left-0 bg-black opacity-0 md:group-hover:opacity-20 max-md:opacity-20 transition-opacity duration-300"></div>
                <div className="transition-all duration-300 absolute top-[6px] right-[6px] flex gap-[12px] opacity-0 md:group-hover:opacity-100 max-md:opacity-100">
                  <Tooltip id="add-to-post" className="max-md:hidden">
                    Add to post
                  </Tooltip>
                  <SButton
                    onClick={(e) => {
                      e.stopPropagation();
                      if (isMediaAlreadyInPost(file)) return;
                      insertMediaForAllSocialNetworkType(
                        file,
                        false,
                        false,
                        true
                      );
                      setImageAdded(true);
                    }}
                    lIcon={imageAdded ? faCheck : faPlus}
                    size="sm"
                    type="secondaryGray"
                    data-tooltip-id="add-to-post"
                  >
                    <span className="md:hidden">
                      {imageAdded ? "Image Added" : "Add to Post"}
                    </span>
                  </SButton>
                </div>
                <img
                  src={file.thumbnailLargeUrl}
                  alt={`Generated image ${index + 1}`}
                  className="w-full h-auto rounded-[8px] cursor-pointer"
                />
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default ToolMessage;
