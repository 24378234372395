import SButton from "../../../design-system/SButton";
import {
  faArrowLeft,
  faEnvelopeBadge,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
//@ts-ignore
import bgGrid from "../../../assets/images/grid-background.png";
import { makeRequest } from "../../../api/auth/axios";
import { useGetCurrentWorkspace } from "../../../api/workspaces/current/get";
import { useNavigate } from "react-router-dom";
import { usePutSwitchTeam } from "../../../api/workspaces/swich/put";
function Form() {
  const { mutateAsync: switchWorkspace } = usePutSwitchTeam();
  const { data: currentWorkspace } = useGetCurrentWorkspace();
  const navigate = useNavigate();

  function getQuery(name: string) {
    let match = RegExp("[?&]" + name + "=([^&]*)").exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, " "));
  }

  const email = getQuery("email");
  const code = getQuery("code");

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (email && code) {
      makeRequest
        .get(`/User/ConfirmAccount?email=${email}&code=${code}`)
        .then((res) => {
          if (currentWorkspace) {
            switchWorkspace({ teamId: currentWorkspace.data.id });
          }
        })
        .catch((err) => {
          setError(err.response.data.message);
        });
    } else {
      setError("Something went wrong!");
    }
  }, [email, code]);

  return (
    <div className="relative max-w-[360px] w-full flex justify-center flex-col items-center">
      <img
        className="absolute  z-0 top-[-10%] w-full h-[300px]"
        src={bgGrid}
        alt="bg"
      />

      <div className="relative z-10 w-full flex flex-col items-center justify-center">
        <div className="flex justify-center items-center rounded-[8px] w-[40px] aspect-square bg-base-white border-[1px] border-gray-200 shadow-xs">
          <FontAwesomeIcon
            icon={faEnvelopeBadge}
            fontSize="16px"
            className="text-gray-600"
          />
        </div>
        <p className="display-sm-sb text-gray-900 mt-[24px] text-center">
          {!error ? "Email confirmed successfully!" : "Something went wrong"}
        </p>
        <p className="text-md text-gray-600 mt-[12px] text-center">
          {!error
            ? "Thanks for confirmation! 🎉 You can continue using STORI now."
            : error}
        </p>
        <SButton
          onClick={() => navigate("/account")}
          size="sm"
          lIcon={faArrowLeft}
          type="linkGray"
          className="w-full !gap-[12px] mt-[24px]"
        >
          Back to STORI
        </SButton>
      </div>
    </div>
  );
}

export default Form;
