import { useEffect, useLayoutEffect, useState } from "react";

const useScrollStatus = (ref: any) => {
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const [isFullyOnScreen, setIsFullyOnScreen] = useState(false);

  const handleScroll = () => {
    let element;
    if (ref && ref.current) {
      element = ref.current;
    } else {
      element = document.documentElement || document.body;
    }

    if (element) {
      const scrollTop = element.scrollTop || document.documentElement.scrollTop;
      const scrollHeight =
        element.scrollHeight || document.documentElement.scrollHeight;
      const clientHeight = element.clientHeight || window.innerHeight;

      // Check if the element is scrolled to the bottom
      const scrolledToBottom = scrollHeight - (scrollTop + clientHeight) <= 1;

      // Check if the element cannot be scrolled further
      const cannotBeScrolled = scrollHeight <= clientHeight;

      setIsScrolledToBottom(scrolledToBottom);
      setIsFullyOnScreen(cannotBeScrolled);
    }
  };

  useLayoutEffect(() => {
    // Initial check
    handleScroll();
  }, [ref]);

  useEffect(() => {
    let element: any;
    if (ref && ref.current) {
      element = ref.current;
    } else {
      element = document.documentElement || document.body;
    }

    const resizeObserver = new ResizeObserver(handleScroll);
    const mutationObserver = new MutationObserver(handleScroll);

    if (element) {
      resizeObserver.observe(element);
      mutationObserver.observe(element, { childList: true, subtree: true });

      element.addEventListener("scroll", handleScroll);
      window.addEventListener("scroll", handleScroll); // For document scrolling

      // Initial check
      handleScroll();

      return () => {
        resizeObserver.disconnect();
        mutationObserver.disconnect();
        element.removeEventListener("scroll", handleScroll);
        window.removeEventListener("scroll", handleScroll); // For document scrolling
      };
    }
  }, [ref]);

  return { isScrolledToBottom, isFullyOnScreen, handleScroll };
};

export default useScrollStatus;
