//@ts-ignore
import { saveAs } from "file-saver";
import { getLastSegment } from "./queries";

export const downloadFile = async (
  url: string,
  filename: string
): Promise<void> => {
  const response = await fetch(url);
  const blob = await response.blob();
  saveAs(blob, getLastSegment(filename));
};
