import { useState, useEffect } from 'react';
import i18n from '../utils/i18n';

const useLanguage = () => {
  const [selectedLanguage, setSelectedLanguage] = useState<string>(() => {
    const savedLanguage = localStorage.getItem('la');
    return savedLanguage || 'en';
  });

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage).catch((err: any) => {
      console.error('Failed to change language:', err);
    });
    localStorage.setItem('la', selectedLanguage);
  }, [selectedLanguage]);

  const languageOptions = {
    "en": "English",
    "ge": "ქართული",
    "pt": "Português",
    "es": "Español",
    "po": "Polski",
    "jp": "日本",
    "zh": "中文",
    "de": "Deutsch",
    "fr": "Français",
    "nl": "Nederlands",
    "tr": "Türkçe",
    "it": "Italiano",
    "sv": "Svenska",
  };

  const dropdownItems = Object.entries(languageOptions).map(([code, name], index) => ({
    index: index + 1,
    text: name,
    value: code
  }));

  const getSelectedIndex = () => {
    return dropdownItems.find(item => item.value === selectedLanguage)?.index ?? null;
  };

  return { selectedLanguage, setSelectedLanguage, dropdownItems, getSelectedIndex };
};

export default useLanguage;
