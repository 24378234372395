import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IBaseData } from "../../utils/interfaces/IBase";
import { IPostCOE, ISinglePostVM } from "../../utils/interfaces/IPost";
import { makeRequest } from "../auth/axios";
import { useCreationStore } from "../../store/creationStore";

export const usePostPost = () => {
  const queryClient = useQueryClient();
  const { setPostUpdating } = useCreationStore();
  return useMutation<IBaseData<ISinglePostVM>, Error, IPostCOE>({
    mutationFn: (postData) => makeRequest.post("/Posts", postData),
    onMutate: () => {
      setPostUpdating(true);
    },
    onSettled: () => {
      setPostUpdating(false);
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["useGetOnboardingInfo"] });
      queryClient.invalidateQueries({ queryKey: ["useGetPost"] });
      queryClient.invalidateQueries({ queryKey: ["useGetPostGroups"] });
    },
  });
};
