import { format, isToday } from "date-fns";

function DayOfMonth({ day }: { day: Date }) {
  return (
    <div className="flex items-center justify-start gap-[8px]">
      <time
        dateTime={format(day, "yyyy-MM-dd")}
        className={"text-lg-sb text-gray-500"}
      >
        {format(day, "d")}
      </time>
      <div
        className={
          isToday(day) ? "w-[8px] h-[8px] bg-brand-400 rounded-full" : "hidden"
        }
      />
    </div>
  );
}

export default DayOfMonth;
