import React from "react";
import { ISingleBrandVM } from "../../utils/interfaces/IBrand";
import { IBaseData } from "../../utils/interfaces/IBase";
import SRadio from "../../design-system/SRadio";
import SButton from "../../design-system/SButton";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { useNavigate } from "react-router-dom";
import { useGetAllBrands } from "../../api/brand/getAll";
import { OrganizationType } from "../../utils/enums/Enums";
import { useLibraryStore } from "../../store/libraryStore";

function BrandSelector({
  switchBrand,
  isPending,
  setIsBrandSelectorOpen,
  currentBrand,
  setIsMobileMenuOpen,
}: {
  switchBrand: ({ id }: { id: number }) => Promise<any>;
  isPending: boolean;
  setIsBrandSelectorOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentBrand: IBaseData<ISingleBrandVM>;
  setIsMobileMenuOpen?: (open: boolean) => void;
}) {
  const navigate = useNavigate();
  const { data: brands, isLoading } = useGetAllBrands({
    includeAllWorkspaces: false,
  });
  const { setFoldersAndFiles, setPage, setParentFolder } = useLibraryStore();
  return (
    <div
      className={`
      ${brands ? (brands.data.length > 1 ? "" : "px-0") : "px-0"} 
      flex flex-col items-center w-[450%] max-md:w-full absolute top-[110%] max-md:top-[120%] left-0 bg-base-white px-[8px] pt-[8px] rounded-[12px] shadow-lg
      `}
    >
      <div className="flex flex-col w-full gap-[10px] max-h-[300px]  pr-2 overflow-auto">
        {brands &&
          brands.data.map((item) => (
            <div
              onClick={() => {
                if (item.id !== currentBrand.data.id) {
                  switchBrand({ id: item.id }).then((res) => {
                    setFoldersAndFiles([]);
                    setPage(1);
                    setParentFolder(null);
                  });
                  setIsBrandSelectorOpen(false);
                  setIsMobileMenuOpen && setIsMobileMenuOpen(false);
                }
              }}
              className={`bg-gray-25 border border-gray-200 py-[8px] px-[10px] rounded-[8px] flex justify-between items-center cursor-pointer gap-2`}
            >
              <div className="flex items-center gap-[10px] overflow-hidden">
                {item.secondLogo ? (
                  <img
                    className="max-w-[30px] min-w-[30px] min-h-[30px] max-h-[30px] w-full object-contain rounded-[6px]"
                    src={item.secondLogo}
                    alt="logo"
                  />
                ) : item.organizationType === OrganizationType.PERSONAL ? (
                  <img
                    className="max-w-[30px] min-w-[30px] min-h-[30px] max-h-[30px] w-full object-contain rounded-[6px]"
                    src={item.logo}
                    alt="logo"
                  />
                ) : (
                  <div className="flex aspect-square w-[30px] max-w-[30px] bg--gray-50 rounded-full items-center justify-center text-sm-sb">
                    {item.brandingData?.name.slice(0, 2).toUpperCase()}
                  </div>
                )}
                <div>
                  <p className="text-xs text-gray-400">
                    {item.organizationType === OrganizationType.COMPANY &&
                      "Company"}
                    {item.organizationType === OrganizationType.PERSONAL &&
                      "Personal"}
                  </p>
                  <p
                    className="max-md:w-full text-sm-sb text-gray-700"
                    title={item.brandingData?.name}
                  >
                    {item.brandingData?.name}
                  </p>
                </div>
              </div>
              <SRadio
                size="sm"
                disabled={isPending}
                checked={item.id === currentBrand.data.id}
              />
            </div>
          ))}
      </div>
      {(isLoading || isPending) && (
        <div className="w-full bg-gray-25 border border-gray-100 rounded-[8px] flex justify-between items-center py-2 px-2.5 animate-pulse">
          <div className="flex items-center gap-2 overflow-hidden animate-pulse">
            <div className="bg-gray-200 rounded-full w-8 h-8 animate-pulse"></div>
            <div>
              <div className="bg-gray-200 rounded w-20 h-4 mb-1 animate-pulse"></div>
              <div className="bg-gray-200 rounded w-32 h-4 animate-pulse"></div>
            </div>
          </div>
          <div className="bg-gray-200 rounded-full w-5 h-5 animate-pulse"></div>
        </div>
      )}
      <SButton
        onClick={() => {
          setIsBrandSelectorOpen(false);
          setIsMobileMenuOpen && setIsMobileMenuOpen(false);
          navigate("/create-brand");
        }}
        type="secondaryGray"
        rIcon={faPlus}
        size="sm"
        className={`
           ${brands ? (brands.data.length >= 1 ? "mt-[18px] mb-[18px]" : "mt-0 mb-[8px]") : "mt-[18px] mb-[18px]"} 
          `}
      >
        Create Brand
      </SButton>
    </div>
  );
}

export default BrandSelector;
