import { Dispatch, SetStateAction } from "react";
import SModal from "../../design-system/SModal";

export interface IConfirmDeleteProps {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  onConfirm?: () => void;
  onCancel?: () => void;
  title: string;
  children?: React.ReactNode;
  loading?: boolean;
}

export const ConfirmDelete = ({
  openModal,
  setOpenModal,
  onConfirm,
  onCancel,
  title,
  loading,
  children,
}: IConfirmDeleteProps) => {
  return (
    <SModal
      isOpen={openModal}
      setIsOpen={setOpenModal}
      handleCancel={onCancel || (() => {})}
      handleSubmit={onConfirm || (() => {})}
      title={title}
      type="error"
      loading={loading}
      actionButtonText="Confirm"
      actionButtonProps={{
        onClick: onConfirm,
      }}
    >
      {children}
    </SModal>
  );
};
