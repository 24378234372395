import { useState, useRef, useEffect } from "react";
import { usePostFile } from "../../../../../../api/library/files/post";
import { getFileFromUrl } from "../../../../../../hooks/functions/fileFunctions";
import useScrollStatus from "../../../../../../hooks/useScrollStatus";
import {
  IMediaVM,
  ISingleMediaVM,
  KlipyType,
} from "../../../../../../utils/interfaces/IKlipyGifs";
import { convertLibraryFileIntoSocialNetworkPostFile } from "../../hooks/creationLibraryConverters";
import { useCreationLibraryHelperFunctions } from "../../hooks/useCreationLibraryHelperFunctions";
import { ISingleFIleVM } from "../../../../../../utils/interfaces/IAssets";
import { useCreationStore } from "../../../../../../store/creationStore";
import { useLibraryStore } from "../../../../../../store/libraryStore";

export const useGifs = (gifSearch: string = "", type: KlipyType) => {
  const [mediaData, setMediaData] = useState<IMediaVM>();
  const [isSmallMenuOpen, setIsSmallMenuOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [mediaList, setMediaList] = useState<ISingleMediaVM[]>([]);
  const { mutateAsync: createFile } = usePostFile();
  const { insertMediaForAllSocialNetworkType } =
    useCreationLibraryHelperFunctions();
  const { setWhichGifIsLoading } = useCreationStore();
  const { setFoldersAndFiles, setPage: setLibraryPage } = useLibraryStore();
  const [initialLoad, setInitialLoad] = useState(false);

  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const getClips = (type: KlipyType, currentPage: number) => {
    setIsFetching(true);
    const api_key = process.env.REACT_APP_KLIPI_API_KEY;
    const customer_id = "1234567890";
    const per_page = 20;
    const locale = "en";
    const endpoint = gifSearch ? "search" : "trending";
    const searchParams = gifSearch ? `&q=${encodeURIComponent(gifSearch)}` : "";

    fetch(
      `https://api.klipy.co/api/v1/${api_key}/${type === KlipyType.CLIP ? "clips" : "gifs"}/${endpoint}?page=${currentPage}&per_page=${per_page}&customer_id=${customer_id}&locale=${locale}${searchParams}`
    )
      .then((res) => res.json())
      .then((data: IMediaVM) => {
        setMediaData(data);
      })
      .finally(() => setIsFetching(false));
  };

  const wrapperRef = useRef<any>(null);

  const { isScrolledToBottom, isFullyOnScreen } = useScrollStatus(wrapperRef);

  useEffect(() => {
    if (!isFetching && mediaData && mediaData.data.has_next && initialLoad) {
      if (isScrolledToBottom || isFullyOnScreen) {
        setPage(page + 1);
        getClips(type, page + 1);
      }
    }
  }, [isScrolledToBottom, isFullyOnScreen, type]);

  useEffect(() => {
    if (!initialLoad) {
      getClips(type, page);
      setInitialLoad(true);
    }
  }, []);

  useEffect(() => {
    if (mediaData) {
      setMediaList([...mediaList, ...mediaData.data.data]);
    }
  }, [mediaData, gifSearch]);

  useEffect(() => {
    setMediaList([]);
    setPage(1);
    getClips(type, 1);
  }, [type]);

  useEffect(() => {
    if (initialLoad) {
      setIsLoading(true);
      const delayDebounceFn = setTimeout(() => {
        setMediaList([]);
        setPage(1);
        getClips(type, 1);
        setIsLoading(false);
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [gifSearch]);

  const handleSubmit = async (url: string) => {
    try {
      const formData = new FormData();
      const file = await getFileFromUrl(url);
      formData.append("files", file);
      await createFile({ files: formData, isTemplateUsed: true })
        .then((res) => {
          const newFile = convertLibraryFileIntoSocialNetworkPostFile(
            res.data.uploadedFiles[0]
          );
          insertMediaForAllSocialNetworkType(
            newFile as ISingleFIleVM,
            true,
            false
          );
          setLibraryPage(1);
          setFoldersAndFiles([]);
        })
        .finally(() => {
          setWhichGifIsLoading(null);
        });
    } catch (error) {
      setWhichGifIsLoading(null);
    }
  };

  return {
    isSmallMenuOpen,
    setIsSmallMenuOpen,
    handleSubmit,
    isLoading,
    isFetching,
    mediaData,
    mediaList,
    wrapperRef,
  };
};
