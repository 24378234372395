import React, { useRef } from "react";
import "react-day-picker/style.css";
import { useHandleOutsideClick } from "../../../../hooks/useHandleOutsideClick";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import { setHours, setMinutes } from "date-fns";
import moment from "moment";

function TimePickerModal({
  setIsTimePickerOpen,
  selected,
  setSelected,
}: {
  setIsTimePickerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelected: React.Dispatch<React.SetStateAction<Date>>;
  selected: Date;
}) {
  const wrapperRef = useRef<any>();
  useHandleOutsideClick({
    ref: wrapperRef,
    callBack: () => {
      setIsTimePickerOpen(false);
    },
    excludedIds: ["schedule-time-picker-opener"],
    excludedClassNames: ["rc-time-picker-panel"],
  });
  return (
    <div
      ref={wrapperRef}
      className="absolute z-[102] bg-base-white border border-gray-200 top-[calc(100%+12px)] right-0 rounded-[8px] shadow-xl3 p-[16px]"
    >
      <div className="h-[185px]">
        <TimePicker
          showSecond={false}
          defaultValue={moment(selected)}
          className="xxx"
          open={true}
          onChange={(e) => {
            setSelected(e.toDate());
          }}
          format={"h:mm a"}
          use12Hours
          inputReadOnly
        />
      </div>
    </div>
  );
}

export default TimePickerModal;
