import React from "react";
import Sidebar from "./Sidebar";
import Header from "../header/Header";
import { BillingInfo } from "../billing/BillingInfo";
import { useGetCurrentPackage } from "../../api/subscriptions/current/get";
import { useSubscriptionActions } from "../../hooks/useSubscriptionActions";
import { useLocation } from "react-router-dom";
import { TeamPackageStatus } from "../../utils/enums/Enums";

function SidebarWrapper({ children }: { children: React.ReactNode }) {
  const { data: currentPlan } = useGetCurrentPackage();
  const { handleClick } = useSubscriptionActions();
  const location = useLocation();
  return (
    <div className="flex ">
      <Sidebar />
      <div className="w-full">
        <Header />
        {currentPlan &&
          (currentPlan?.data.status === TeamPackageStatus.PAUSED ||
            currentPlan?.data.status === TeamPackageStatus.CANCELED ||
            (currentPlan?.data.status === TeamPackageStatus.ACTIVE &&
              currentPlan?.data.isTrial)) &&
          location.pathname !== "/workspace" && (
            <div className="w-[calc(100%-64px)] max-sm:w-[calc(100%-16px)] right-0 translate-x-[32px] max-md:translate-x-[8px] top-[12px] mt-[12px] z-[15]">
              <BillingInfo className="relative" />
            </div>
          )}
        <div className="w-full px-[32px] max-md:px-[16px]">{children}</div>
      </div>
    </div>
  );
}

export default SidebarWrapper;
