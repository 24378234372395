import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../../axios";
import { IBaseData } from "../../../utils/interfaces/IBase";
import {
  ICustomFieldCOE,
  ISingleCustomFieldVM,
} from "../../../utils/interfaces/IBrand";

export const usePutBrandCustomField = () => {
  const queryClient = useQueryClient();
  return useMutation<
    IBaseData<ISingleCustomFieldVM>,
    Error,
    { fieldId: number; fieldData: ICustomFieldCOE }
  >({
    mutationFn: ({ fieldData, fieldId }) =>
      makeRequest.put(`/Organizations/CustomField/${fieldId}`, {
        ...fieldData,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["useGetCurrentBrand"] });
    },
  });
};
