import SButton from "../../design-system/SButton";
import SInput from "../../design-system/SInput";
import SModalBase from "../../design-system/SModalBase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faRefresh } from "@fortawesome/pro-solid-svg-icons";
import { TeamRole } from "../../utils/enums/Enums";
import { useInviteMemberModal } from "./hooks/useInviteMemberModal";
import { useBrandStore } from "../../store/brandStore";
import { useGetInviteLink } from "../../api/invitations/invitationLink/get";
import SSmallMenu from "../../design-system/SSmallMenu";
import { toast } from "../../hooks/toast";
import { useGetAllBrands } from "../../api/brand/getAll";
import SCheckbox from "../../design-system/SCheckbox";
import { useState } from "react";
import { BrandInviteWorkspaceMembers } from "./BrandInviteWorkspaceMembers";
import {
  faCircleQuestion,
  faCopy,
  faUserPlus,
} from "@fortawesome/pro-regular-svg-icons";
import { useUpdateGlobalLink } from "../../api/invitations/invitationLink/put";
import SModal from "../../design-system/SModal";
import { Tooltip } from "react-tooltip";

export interface IBrandInviteMemberModalProps {
  setInviteModal: (open: boolean) => void;
  inviteModal: boolean;
  invitationLink?: string;
  isWorkspaceOnly?: boolean;
}

export const BrandInviteMemberModal = ({
  setInviteModal,
  inviteModal,
  isWorkspaceOnly = false,
}: IBrandInviteMemberModalProps) => {
  const {
    email,
    setEmail,
    openRoles,
    setOpenRoles,
    role,
    setRole,
    inviteMemberByEmail,
    dropdownRef,
    error,
    setConfirmModal,
    confirmModal,
  } = useInviteMemberModal();
  const { currentBrand } = useBrandStore();
  const { data: brands } = useGetAllBrands({ includeAllWorkspaces: false });
  const { data: invitationLink } = useGetInviteLink();
  const [addToBrands, setAddToBrands] = useState<number[]>([]);
  const { mutateAsync: updateLink, isPending } = useUpdateGlobalLink();

  return (
    <>
      {confirmModal && (
        <SModal
          isOpen={confirmModal}
          setIsOpen={setConfirmModal}
          handleCancel={() => setConfirmModal(false)}
          handleSubmit={() => {
            updateLink()
              .then(() => {
                toast("Invite link has been changed", "success");
                setConfirmModal(false);
              })
              .catch((e) => {
                toast(e.message);
              });
          }}
          title="Are you sure to expire the current invite link and generate a new one?"
          type="warning"
          actionButtonText="Confirm"
          actionButtonProps={{
            onClick: () => {
              updateLink()
                .then(() => {
                  toast("Invite link has been changed", "success");
                  setConfirmModal(false);
                })
                .catch((e) => {
                  toast(e.message);
                });
            },
          }}
        />
      )}
      <SModalBase
        dialogPanelClassName="min-w-[544px] z-10 py-4 text-gray-600 max-md:min-w-[100%]"
        isOpen={inviteModal}
        onClose={setInviteModal}
      >
        <h1 className="text-md-m mb-3">Invite new members</h1>
        <hr className="w-auto mx-[-24px]" />
        <div className="flex w-full flex-col gap-3 my-6 max-md:mb-0">
          {isWorkspaceOnly && (
            <div className="flex gap-[10px] items-end">
              <SInput
                disabled={isPending || currentBrand?.workspaceId === undefined}
                defaultValue={
                  invitationLink?.data.data +
                  `&workspaceId=${currentBrand?.workspaceId}`
                }
                value={
                  invitationLink?.data.data +
                  `&workspaceId=${currentBrand?.workspaceId}`
                }
                rightAdditionalContent={
                  <h1 className="text-xs px-3 py-2 bg-white">Member</h1>
                }
                className="text-gray-600 w-full"
                label={
                  <div className="flex w-full flex-col justify-start">
                    <Tooltip id="invite-link-tooltip">
                      Users invited by this link will have Member roles by
                      default.
                    </Tooltip>
                    <p className="w-fit" data-tooltip-id="invite-link-tooltip">
                      Invite link{" "}
                      <FontAwesomeIcon icon={faCircleQuestion} size="sm" />
                    </p>
                    <p className="text-gray-600 w-auto max-sm:mx-[-40px] max-sm:translate-x-[40px] mx-[-70px] translate-x-[70px] text-sm">
                      After accepting the invitation, the user will be assigned
                      to all brands{" "}
                    </p>
                  </div>
                }
              />
              <SButton
                type="secondaryColor"
                isLoading={isPending}
                className="mb-[2px]"
                icon={faRefresh}
                onClick={() => setConfirmModal(true)}
              />
              <SButton
                type="secondaryColor"
                className="mb-[2px] w-[170px] max-md:w-fit"
                disabled={isPending || currentBrand?.workspaceId === undefined}
                children={
                  <>
                    <FontAwesomeIcon icon={faCopy} className={"md:hidden"} />
                    <span className={"max-md:hidden"}>Copy Link</span>
                  </>
                }
                size="md"
                onClick={() => {
                  navigator.clipboard.writeText(
                    invitationLink?.data.data +
                      `&workspaceId=${currentBrand?.workspaceId}` || ""
                  );
                  toast(`Invite link copied`, "info");
                }}
              />
            </div>
          )}
          {isWorkspaceOnly && <hr className="mt-2" />}
          <div className="flex w-full gap-[10px] items-end">
            <SInput
              placeholder="example@yourband.com"
              className="text-gray-600 w-full"
              label="Invite by email"
              onChange={(e) => setEmail(e.target.value)}
              rightAdditionalContent={
                <button
                  id={"roles-dropdown"}
                  ref={dropdownRef}
                  onClick={() => setOpenRoles(!openRoles)}
                  className="flex gap-1 text-xs items-center bg-base-white pl-2 border-l border-gray-200"
                >
                  <span className="capitalize">
                    {TeamRole[role].toLowerCase()}
                  </span>
                  <FontAwesomeIcon fontSize={"12px"} icon={faChevronDown} />
                  {openRoles && (
                    <SSmallMenu
                      className="left-[unset] right-[-10px] top-[40px]"
                      isOpen={openRoles}
                      setIsOpen={setOpenRoles}
                      excludedId="roles-dropdown"
                      data={[
                        [
                          {
                            text: TeamRole[TeamRole.MEMBER].toLowerCase(),
                            handleClick: () => setRole(TeamRole.MEMBER),
                          },
                          {
                            text: TeamRole[TeamRole.VIEWER].toLowerCase(),
                            handleClick: () => setRole(TeamRole.VIEWER),
                          },
                        ],
                      ]}
                    />
                  )}
                </button>
              }
            />
            <SButton
              type="secondaryColor"
              className="mb-[2px] w-[150px] max-md:w-fit"
              children={
                <>
                  <FontAwesomeIcon icon={faUserPlus} className={"md:hidden"} />
                  <span className={"max-md:hidden"}>Send Invite</span>
                </>
              }
              disabled={
                (isWorkspaceOnly &&
                  role === TeamRole.VIEWER &&
                  addToBrands.length === 0) ||
                !email.length
              }
              size="md"
              value={email}
              onClick={() =>
                inviteMemberByEmail({
                  email: email,
                  role: role,
                  organizationIds: !isWorkspaceOnly
                    ? currentBrand?.id
                      ? [currentBrand.id]
                      : []
                    : addToBrands,
                }).then(() => {
                  toast(`Invite Sent`, "success");
                  setInviteModal(false);
                })
              }
            />
          </div>
          {!isWorkspaceOnly && (
            <BrandInviteWorkspaceMembers brandId={currentBrand?.id} />
          )}
          {isWorkspaceOnly && (
            <div className="w-full mt-2 h-[300px] flex flex-col">
              {role === TeamRole.VIEWER && (
                <p className="px-2 mb-2 text-warning-600 text-xs">
                  Please make sure to select at least one brand when inviting a
                  member with the Viewer role.
                </p>
              )}
              <h1 className="w-full py-[13px] px-3 text-gray-600 text-xs-m rounded-t-[12px] text-start bg-gray-50 border-x border-t border-gray-200">
                Brands
              </h1>
              <div className="h-full border border-gray-200 rounded-b-[12px] overflow-y-auto max-md:max-h-[200px]">
                {brands?.data.map((brand, index) => (
                  <div
                    key={brand.id}
                    className={`flex items-center justify-between py-4 px-6 border-b border-gray-200 ${(index + 1) % 2 === 0 ? "bg-gray-50" : ""}`}
                  >
                    <div className="flex items-center gap-3">
                      <img
                        className="w-10 h-10 rounded-full object-cover"
                        src={brand.logo}
                        alt={brand.brandingData?.name}
                      />
                      <h1 className="text-sm-m text-gray-900">
                        {brand.brandingData?.name}
                      </h1>
                    </div>
                    <SCheckbox
                      onChange={() => {
                        setAddToBrands((prev) => {
                          if (prev.includes(brand.id)) {
                            return prev.filter((id) => id !== brand.id);
                          } else {
                            return [...prev, brand.id];
                          }
                        });
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
          <p className="text-sm-m text-error-500">{error}</p>
        </div>
      </SModalBase>
    </>
  );
};
