import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const SetupIntentSuccessLoadingPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const originalTitle = document.title;
    document.title = "Loading...";
    const timer = setTimeout(() => {
      document.title = originalTitle;
      navigate("/");
    }, 3000);
    return () => clearTimeout(timer);
  }, [navigate]);

  return null; // Render nothing
};

export default SetupIntentSuccessLoadingPage;
