import {
  faFaceFrown,
  faFaceMeh,
  faFaceSmile,
} from "@fortawesome/pro-regular-svg-icons";

export const socialScoreIcons = (socialScore?: number) => {
  const icons: any = [faFaceFrown, faFaceMeh, faFaceSmile];
  let icon = icons[0];
  if (socialScore && socialScore >= 0 && socialScore <= 4) icon = icons[0];
  if (socialScore && socialScore >= 5 && socialScore <= 6) icon = icons[1];
  if (socialScore && socialScore >= 7) icon = icons[2];
  return icon;
};
