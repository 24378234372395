import { useState, useMemo } from "react";
import timezones from "../components/account/Timezones";
import { ISSelectInputDropdownItem } from "../design-system/SSelectInputDropdown";

interface Timezone {
  label: string;
  value: string;
}

const useTimezone = () => {
  const [selectedTimezone, setSelectedTimezone] = useState<string>();

  const timezoneItems: ISSelectInputDropdownItem[] = useMemo(
    () =>
      timezones.map((timezone: Timezone, index) => ({
        index: index + 1,
        text: timezone.label,
        value: timezone.value, // We keep this for internal use
      })),
    [],
  );

  const getTimezoneIndex = () => {
    return (
      timezoneItems.findIndex((item) => item.text === selectedTimezone) + 1 ||
      null
    );
  };

  const setSelectedTimezoneByIndex = (index: number | null) => {
    if (index !== null) {
      const selectedItem = timezoneItems[index - 1];
      if (selectedItem) {
        setSelectedTimezone(selectedItem.text);
      }
    }
  };

  const getSelectedTimezoneLabel = () => {
    const selectedItem = timezoneItems.find(
      (item) => item.text === selectedTimezone,
    );
    return selectedItem ? selectedItem.text : "Select a timezone";
  };

  const getTimezoneLabelFromValue = (value?: string) => {
    return value ? timezones.find(
      (timezone) => timezone.value === value
    )?.label : ''
  }

  return {
    selectedTimezone,
    setSelectedTimezone: setSelectedTimezoneByIndex,
    timezoneItems,
    getTimezoneIndex,
    getSelectedTimezoneLabel,
    getTimezoneLabelFromValue
  };
};

export default useTimezone;
