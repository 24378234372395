import { makeRequest } from "../../../axios";
import { useQuery } from "@tanstack/react-query";
import { IWorkspaceInvoices } from "../../../utils/interfaces/IWorkspace";
import { IBaseData } from "../../../utils/interfaces/IBase";

export const useGetInvoices = () => {
  return useQuery<IBaseData<IWorkspaceInvoices[]>>({
    queryKey: ["useGetInvoices"],
    queryFn: async () => {
      const response = await makeRequest.get(
        "/Subscriptions/WorkspaceInvoices",
      );
      return response as IBaseData<IWorkspaceInvoices[]>;
    },
    retry: false,
  });
};
