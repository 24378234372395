import { useState } from "react";
import Navigation from "./Navigation";
import BrandSwitcher from "./BrandSwitcher";
import { faGear } from "@fortawesome/pro-regular-svg-icons";
import { faGear as faSolidGear } from "@fortawesome/pro-solid-svg-icons";
import NavigationGroupItem from "./NavigationGroupItem";

function Sidebar() {
  const [isBrandSelectorOpen, setIsBrandSelectorOpen] = useState(false);
  return (
    <div
      className={`sticky top-[6px] mt-[6px] left-[6px] z-[99] rounded-[12px] transition-all ${isBrandSelectorOpen ? "bg-[rgba(0,0,0,0.04)]" : "bg-base-white"}  flex flex-col 
        h-[calc(100dvh-12px)] max-w-[80px] min-w-[80px] border border-gray-200 max-md:min-w-[200px] max-md:max-w-[200px] max-md:hidden`}
    >
      <div className="md:border-b md:border-gray-200 ">
        <BrandSwitcher
          setIsBrandSelectorOpen={setIsBrandSelectorOpen}
          isBrandSelectorOpen={isBrandSelectorOpen}
        />
      </div>
      <div className="h-full overflow-auto">
        <Navigation />
      </div>
      <div className="m-[12px]">
        <NavigationGroupItem
          title="Settings"
          pathTitle="workspace"
          icon={faGear}
          solidIcon={faSolidGear}
          path="/workspace"
        />
      </div>
    </div>
  );
}

export default Sidebar;
