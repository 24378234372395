// components/LibraryListCard/index.tsx
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/pro-regular-svg-icons";
import "../../../index.css";
import SBadge from "../../../design-system/SBadge";
//@ts-ignore
import AvatarIcon from "../../../assets/icons/avatar.svg";
import { OrganizationUserRole, TeamRole } from "../../../utils/enums/Enums";
import { getRole } from "../../workspace/functions/getRole";

export interface IMembersListRow {
  userId?: string;
  userName?: string;
  userImg?: string;
  userRole?: string;
  userEmail?: string;
  userStatus: "Active" | "Pending" | "Accepted" | "Rejected" | "Canceled";
  id?: number;
  className?: string;
  onDelete?: () => void;
  onEdit?: () => void;
  setChosenUser?: (value: {
    userId: string;
    name: string;
    email: string;
    invitationId?: number | null;
    isPending: boolean;
    role: TeamRole;
  }) => void;
  loggedInUserRole?: OrganizationUserRole;
}

function MembersListRow({
  userName,
  userImg,
  userRole,
  userEmail,
  userStatus,
  className,
  userId,
  id,
  onDelete,
  onEdit,
  setChosenUser,
  loggedInUserRole
}: IMembersListRow) {
  return (
    <tr className={`${className} border-b border-gray-200 items-center`}>
      <td className="px-[24px] py-[16px] w-2/12">
        <div className="flex items-center">
          {userImg && (
            <img
              src={userImg}
              alt={userName}
              className="w-[40px] h-[40px] rounded-full mr-[12px]"
            />
          )}
          {!userImg && (
            <img
              src={AvatarIcon}
              alt={userName}
              className="w-[40px] h-[40px] rounded-full mr-[12px]"
            />
          )}
          <div className="flex flex-col">
            <span className="text-gray-900 text-sm-m">{userName}</span>
            <span className="text-gray-600 text-sm">{userEmail}</span>
          </div>
        </div>
      </td>
      <td className="py-[16px] w-2/12">
        <div className="flex items-center">
          {userStatus === "Active" && (
            <SBadge
              children={"Active"}
              size={"sm"}
              color={"success"}
              hasBackground={false}
              dotted={true}
            />
          )}
          {userStatus === "Pending" && (
            <SBadge
              children={"Pending"}
              size={"sm"}
              color={"warning"}
              hasBackground={false}
              dotted={true}
            />
          )}
        </div>
      </td>
      <td className="py-[16px] w-2/12 text-sm text-slate-600">{userRole}</td>
      <td className="p-[16px] w-min flex items-center gap-[2px]">
        {userRole !== "Owner" && loggedInUserRole !== OrganizationUserRole.VIEWER && (
          <button
            onClick={() => {
              setChosenUser &&
                setChosenUser({
                  userId: userId ? userId : "",
                  name: userName ? userName : "John Doe",
                  email: userEmail ? userEmail : "example@gmail.com",
                  invitationId: id ? id : undefined,
                  isPending: userStatus !== "Active" ? true : false,
                  role: userRole
                    ? TeamRole[getRole(userRole)]
                    : TeamRole.MEMBER,
                });
              onEdit && onEdit();
            }}
            aria-label="Edit"
            className="py-[10px] px-[20px] text-gray-600 hover:text-gray-800"
          >
            <FontAwesomeIcon icon={faPen} />
          </button>
        )}
      </td>
    </tr>
  );
}

export default MembersListRow;
