import React, { useRef, useState } from "react";
import SButton, { ISButtonProps, SButtonSize, SButtonType } from "../SButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { useHandleOutsideClick } from "../../hooks/useHandleOutsideClick";
import { isDisabled } from "@testing-library/user-event/dist/utils";

function valueByType(type: SButtonType, arr: string[]) {
  switch (type) {
    case "primary":
      return arr[0];
      break;
    case "secondaryColor":
      return arr[1];
      break;
  }
}
function valueBySize(size: SButtonSize, arr: string[]) {
  switch (size) {
    case "sm":
      return arr[0];
      break;
    case "md":
      return arr[1];
      break;
    case "lg":
      return arr[2];
      break;
    case "xl":
      return arr[3];
      break;
    case "2xl":
      return arr[4];
      break;
  }
}

interface ISOptionButon extends React.HTMLAttributes<HTMLDivElement> {
  type?: SButtonType;
  size?: SButtonSize;
  items: SOptionButtonItem[];
  label: string;
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
}
interface SOptionButtonItem {
  buttonProps?: ISButtonProps;
  label: string;
  onClick: () => void;
}

function SOptionButon({
  isLoading = false,
  disabled = false,
  items,
  label,
  onClick,
  type = "primary",
  size = "md",
  className,
  ...props
}: ISOptionButon) {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef<any>();
  let id = `optionButtonOpener-${Math.random()}`;
  useHandleOutsideClick({
    callBack: () => setIsOpen(false),
    ref: wrapperRef,
    excludedIds: [id],
  });

  return (
    <div
      ref={wrapperRef}
      className={`${className} relative h-auto flex w-fit`}
      id={id}
      {...props}
    >
      <div className="relative h-auto flex group w-fit">
        <SButton
          onClick={onClick}
          isLoading={isLoading}
          disabled={disabled}
          size={size}
          className={`
            rounded-br-none rounded-tr-none disabled:bg-gray-100`}
          type={type}
        >
          {label}
        </SButton>
        <div
          onClick={() => !disabled && setIsOpen((prev) => !prev)}
          className={`
            ${disabled && "!cursor-default bg-gray-100 border-gray-200"}
            ${!disabled && valueByType(type, ["hover:bg-brand-300", "hover:bg-brand-100"])}
        transition-all cursor-pointer h-auto flex items-center rounded-tr-[8px] rounded-br-[8px] w-fit
            ${valueBySize(size, ["px-[8px]", "px-[10px]", "px-[12px]", "px-[14px]", "px-[16px]"])}
            ${valueByType(type, ["", "border-t-[1px] border-r-[1px] border-b-[1px] border-brand-300"])}
            ${valueByType(type, ["bg-brand-400", "bg-brand-25"])}`}
        >
          <FontAwesomeIcon
            icon={faChevronDown}
            fontSize={"14px"}
            className={`
            ${
              disabled
                ? "text-gray-400"
                : `${valueByType(type, ["text-white", "text-brand-600"])}`
            }
            `}
          />
        </div>
      </div>
      {isOpen && (
        <div className="z-[100] shadow-xl border-[1px] border-gray-200 flex flex-col gap-[4px] rounded-[12px] absolute top-[100%] mt-[4px] left-[0] p-[4px] w-full bg-base-white">
          {items &&
            items.map((item) => (
              <SButton
                size={size}
                className="w-full"
                onClick={() => {
                  setIsOpen(false);
                  item?.onClick && item.onClick();
                }}
                {...item.buttonProps}
              >
                {item.label}
              </SButton>
            ))}
        </div>
      )}
    </div>
  );
}

export default SOptionButon;
