import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { ISocialNetworkPostVM } from "../../../utils/interfaces/IPost";
import { makeRequest } from "../../auth/axios";
import { useCreationStore } from "../../../store/creationStore";

export const usePatchSocialNetworkPost = () => {
  const queryClient = useQueryClient();
  const { setPostUpdating } = useCreationStore();
  return useMutation<
    IBaseData<ISocialNetworkPostVM[]>,
    any,
    { socialNetworkPostIds: number[]; value: any; path: string; op?: string }
  >({
    mutationFn: ({ socialNetworkPostIds, value, path, op = "replace" }) =>
      makeRequest.patch(
        `/Posts/socialNetworkPost?${socialNetworkPostIds.map((id) => `ids=${id}`).join("&")}`,
        [
          {
            value,
            path,
            op,
          },
        ]
      ),
    onMutate: () => {
      setPostUpdating(true);
    },
    onSettled: () => {
      setPostUpdating(false);
    },
    onSuccess: async (res) => {
      await queryClient.invalidateQueries({
        queryKey: ["useGetPost", res.data[0].postId],
      });
      return res;
    },
  });
};
