import React from "react";

function MessageSkeleton({ isUser }: { isUser?: boolean }) {
  return (
    <div
      className={`animate-pulse flex ${isUser && "flex-row-reverse justify-items-end w-full"} gap-[12px]`}
    >
      <div className="w-[40px] h-[40px] rounded-full bg-gray-300" />
      <div className="w-[50%] h-[60px] flex flex-col gap-[6px] p-[12px] rounded-[12px] bg-gray-100">
        <div className="w-[90%] h-[16px] rounded-[12px] bg-gray-300" />
        <div className="w-[70%] h-[16px] rounded-[12px] bg-gray-300" />
      </div>
    </div>
  );
}

export default MessageSkeleton;
