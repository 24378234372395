import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { ISingleBrandVM } from "../utils/interfaces/IBrand";
import { BrandOption } from "../pages/brand/hooks/useConnectBrandsHelpers";

export interface IContentStoreInitialInterface {
  currentBrand?: ISingleBrandVM | null;
  isBrandFetching: boolean;
  isBrandLoading: boolean;
  isProgressLoading: boolean;
  connectBrandIds: number[];
  disconnectBrandIds: number[];
  selectedBrands: BrandOption[];
}

const initialState: IContentStoreInitialInterface = {
  currentBrand: null,
  isBrandLoading: false,
  isBrandFetching: true,
  isProgressLoading: false,
  connectBrandIds: [],
  disconnectBrandIds: [],
  selectedBrands: [],
};

const store = (set: any) => ({
  ...initialState,
  setCurrentBrand: (currentBrand: ISingleBrandVM | null | undefined) =>
    set({ currentBrand }),
  setIsBrandFetching: (isBrandFetching: boolean) => set({ isBrandFetching }),
  setIsBrandLoading: (isBrandLoading: boolean) => set({ isBrandLoading }),
  setIsProgressLoading: (isProgressLoading: boolean) =>
    set({ isProgressLoading }),
  setConnectBrandIds: (connectBrandIds: number[]) => set({ connectBrandIds }),
  setDisconnectBrandIds: (disconnectBrandIds: number[]) =>
    set({ disconnectBrandIds }),
  setSelectedBrands: (selectedBrands: BrandOption[]) => set({ selectedBrands }),
  reset: () => set(initialState),
});
export const useBrandStore = create(devtools(store));
