import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { makeRequest } from "../../../axios";

export const useApplyPromoCode = () => {
  const queryClient = useQueryClient();
  return useMutation<IBaseData<null>, Error, {promotionCode: string}>({
    mutationFn: (data) => 
      makeRequest.put(`/Subscriptions/ApplyPromotion?promotionCode=${data.promotionCode}`),
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["useGetCurrentPackage"] });
      queryClient.invalidateQueries({ queryKey: ["useGetInvoices"] });
    },
  });
};
