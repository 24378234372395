import ImageSelectorItem from "./ImageSelectorItem";
import { useCreationHelperFunctions } from "../../hooks/useCreationHelperFunctions";
import { isVideo } from "../../../Library/hooks/getFileType";

function ImageSelectorSidebar() {
  const { getActiveSocialNetworkPost } = useCreationHelperFunctions();

  return (
    <div className="max-sm:w-full">
      <div className="h-full max-sm:rounded max-sm:border-0 max-sm:w-full sm:max-w-[224px] sm:min-w-[224px] pt-[20px] w-full bg-graycool-50 border-r border-r-gray-200">
        <p className="text-sm-sb max-sm:pl-[20px] max-sm:pr-0 text-gray-800 px-[36px]">
          Selected Images
        </p>
        <div
          className="flex px-[36px] max-md:px-[18px]  max-sm:w-full max-sm:flex-row max-sm:pl-[20px] flex-col gap-[24px] mt-[16px] pb-[20px] overflow-auto items-center"
          style={{ height: "calc(100% - 32px)" }}
        >
          {getActiveSocialNetworkPost() &&
            getActiveSocialNetworkPost()!
              .files.filter((item) => !isVideo(item?.url || ""))
              .sort((a, b) => (a.id || 0) - (b.id || 0))
              .map((item) => <ImageSelectorItem image={item} />)}
        </div>
      </div>
    </div>
  );
}

export default ImageSelectorSidebar;
