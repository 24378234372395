import { usePutSwitchBrand } from "../../../api/brand/switch/put";
import { useDeleteNotification } from "../../../api/notifications/delete";
import { useRespondInvitation } from "../../../api/invitations/invitationPending/put";
import { usePutSwitchTeam } from "../../../api/workspaces/swich/put";
import { useState } from "react";

export const useRespondFromNotification = () => {
  const { mutateAsync: respondInvite, isPending: isResponding } =
    useRespondInvitation();
  const { mutateAsync: deleteNotification } = useDeleteNotification();
  const { mutateAsync: switchWorkspace } = usePutSwitchTeam();
  const { mutateAsync: switchBrand } = usePutSwitchBrand();
  const [action, setAction] = useState<"accept" | "reject" | null>(null);
  const handleClick = async (
    notificationId: number,
    organizationId: number,
    statusId: number,
    isOrganization: boolean
  ) => {
    if (statusId === 2) setAction("accept");
    if (statusId === 3) setAction("reject");
    await respondInvite({
      id: organizationId,
      statusId: statusId,
    }).then((res) => {
      if (statusId === 2) {
        if (res.data.organizations.length > 0) {
          switchBrand({
            id: res.data.organizations[0].id,
          });
        } else {
          if (res?.data?.workspace?.id)
            switchWorkspace({
              teamId: res.data.workspace.id,
            });
        }
      }
    });
    deleteNotification({ id: notificationId });
    setAction(null);
  };

  return { handleClick, isResponding, action };
};
