import React, { useEffect, useState } from "react";
import MessageLoader from "../../creation/Tabs/Brief/MessageLoader";
import { IBrandCreationData } from "../../../pages/CreateBrand";
import Textarea from "../brand-chat/Textarea";
import AiMessage from "../brand-chat/AiMessage";
import UserMessage from "../brand-chat/UserMessage";

import { CopilotRole } from "../../../utils/enums/Enums";
import { useFillChat } from "../hooks/useFillChat";
import { useCreateBrandChatHelper } from "../hooks/useCreateBrandChatHelper";
import { ICopilotSessionVM } from "../../../utils/interfaces/ICopilot";
import { ICopilotMessage } from "../../../utils/copilot-base/ICopilotBase";
import AgentInfo from "../../creation/Tabs/Brief/AgentInfo";
//@ts-ignore
import brenda from "../../../assets/images/agents/brenda.jpeg";

function CreateBrandChat({
  setData,
  setCurrentStep,
  data,
  activeSession,
  setActiveSession,
}: {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  activeSession: ICopilotSessionVM | null;
  setActiveSession: React.Dispatch<
    React.SetStateAction<ICopilotSessionVM | null>
  >;
  setData: React.Dispatch<React.SetStateAction<IBrandCreationData>>;
  data: IBrandCreationData;
}) {
  const [value, setValue] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [messages, setMessages] = useState<ICopilotMessage[]>([]);
  const [chatResponse, setChatResponse] = useState("");
  const { sendMessage } = useCreateBrandChatHelper({
    setDisable: setDisabled,
    setChatResponse,
    setValue,
  });
  const handleSendMessage = (str?: string) => {
    if (activeSession)
      sendMessage({
        copilotSessionId: activeSession.id,
        messages,
        setMessages,
        text: str || value,
      });
  };
  useFillChat({
    disabled,
    setActiveSession,
    activeSession,
    chatResponse,
    setChatResponse,
    setMessages,
    messages,
  });
  useEffect(() => {
    document.getElementById("copilotCreateBrandChat")?.scrollTo({
      top: 1000000,
      left: 0,
      behavior: "smooth",
    });
  }, [messages]);

  const handleNextStep = ({
    summery,
    website,
    name,
  }: {
    name: string;
    summery?: string;
    website?: string;
  }) => {
    if (summery) {
      setData({ ...data, name, about: summery });
      setCurrentStep(2);
    } else if (website) {
      setData({ ...data, name, website });
      setCurrentStep(2);
    } else {
      handleSendMessage("Something went wrong, please try again");
    }
  };
  useEffect(() => {
    const lastMessage = messages[messages.length - 1];
    if (
      lastMessage?.role === CopilotRole.ASSISTANT &&
      lastMessage?.content?.Type === "WebsiteScraping"
    ) {
      handleNextStep({
        website: lastMessage.content.Data.WebsiteToScrape,
        name: lastMessage.content.Data.BrandName,
      });
    }
    if (
      lastMessage?.role === CopilotRole.ASSISTANT &&
      lastMessage?.content?.Type === "BrandCreationSummary"
    ) {
      handleNextStep({
        summery: lastMessage.content.Data.SummerizedBrandInformation,
        name: lastMessage.content.Data.BrandName,
      });
    }
  }, [messages]);

  useEffect(() => {
    if (!disabled) {
      document.getElementById("create-brand-chat-textarea")?.focus();
    }
  }, [disabled]);

  return (
    <div className="max-w-[770px] w-full h-[60dvh] max-md:h-[70dvh] bg-base-white border-[1px] rounded-[12px] border-gray-200 flex flex-col">
      <div
        id="copilotCreateBrandChatHeader"
        className="flex bg-gray-25 max-md:bg-white rounded-t-[12px] gap-[8px] items-center border-b border-b-gray-100 px-[24px] py-[10px] max-md:px-[16px] max-md:py-[8px] max-md:gap-[4px] max-md:pt-[8px] max-md:pb-[8px]"
      >
        <AgentInfo
          logo={brenda}
          agentName={"Brenda"}
          agentRole={"Brand Specialist"}
          agentDescription={
            "Hi, AI'm, Brenda, your brand expert.\n\n" +
            "I'm here to help you set up, manage and evolve your brand."
          }
        >
          <div className="flex gap-[12px] items-center">
            <img
              src={brenda}
              alt="brad-avatar"
              className="w-[35px] h-[35px] rounded-full cursor-pointer"
            />
            <p className="text-md-sb text-gray-900">Brenda</p>
          </div>
        </AgentInfo>
      </div>
      <div
        id="copilotCreateBrandChat"
        className="relative flex flex-col gap-[16px] px-[24px] py-[24px] overflow-y-auto flex-grow hide__scroll max-md:px-[16px] max-md:py-[16px] max-md:gap-[8px]"
      >
        {messages.length === 0 && <MessageLoader />}
        {messages.map((item, index) => (
          <>
            {item.role === CopilotRole.ASSISTANT && (
              <AiMessage
                handleNextStep={handleNextStep}
                sendMessage={handleSendMessage}
                index={index}
                messages={messages}
                msg={item}
              />
            )}
            {item.role === CopilotRole.USER && <UserMessage msg={item} />}
          </>
        ))}
      </div>
      <Textarea
        disable={disabled}
        sendMessage={handleSendMessage}
        value={value}
        setValue={setValue}
      />
    </div>
  );
}

export default CreateBrandChat;
