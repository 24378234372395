import { InvitationStatus, TeamRole } from "../../../utils/enums/Enums";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { ISingleBrandVM } from "../../../utils/interfaces/IBrand";
import { IInviteVM } from "../../../utils/interfaces/ITeamInvitation";

export const displayPendingMembers = (data?: IBaseData<IInviteVM[]>, currentOrganization?: IBaseData<ISingleBrandVM>) => {
  
  const defineUserStatus = (status: number) => {
    let userStatus: 'Active' | 'Pending' | 'Accepted' | 'Rejected' | 'Canceled' = 'Active';
    userStatus = status === InvitationStatus.SENT ? 'Pending' : 'Active';
    userStatus = status === InvitationStatus.ACCEPTED ? 'Accepted' : userStatus
    userStatus = status === InvitationStatus.REJECTED ? 'Rejected' : userStatus
    userStatus = status === InvitationStatus.CANCELED ? 'Canceled' : userStatus
    return userStatus;
  }
  
  const pendingMemberItems = data ? data?.data.map((item, index: number) => ({
    key: index,
    organizations: item.organizations,
    userImg: '',
    userName:  item.userEmail,
    userRole: TeamRole[item.role].toLowerCase(),
    userEmail: item.userEmail,
    userStatus: defineUserStatus(item.invitationStatus),
    userId: '',
    id: item.id,
  })).filter((item) => {
    if (item.organizations) {
      return item.organizations.find((org) => org.id === currentOrganization?.data.id);
    }
    return null;
  }) : [];

  return pendingMemberItems;
}