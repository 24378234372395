import React, { useState } from "react";
import FrameViewItem from "./FrameViewItem";
import { ISocialNetworkPostFileVM } from "../../../../utils/interfaces/IPost";
import { useFramesStore } from "../../../../store/framesStore";
import { ConfirmDeleteFrame } from "./ConfirmDeleteFrame";

function ImageSelectorItem({ image }: { image: ISocialNetworkPostFileVM }) {
  const {
    whichFrameIsRendering,
    selectedImage,
    activeFrame,
    setActiveRenderedFrame,
    setActiveFrame,
    setSelectedImage,
  } = useFramesStore();

  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleImageClick = () => {
    if (whichFrameIsRendering !== undefined) return;
    setActiveRenderedFrame(undefined);
    setActiveFrame(undefined);
    setSelectedImage(image);
  };

  return (
    <>
      {confirmDelete && (
        <ConfirmDeleteFrame
          url={image.url || ""}
          isOpen={confirmDelete}
          setIsOpen={setConfirmDelete}
        />
      )}
      <div
        className={`w-[152px] min-h-[152px] max-sm:w-[80px] max-sm:min-h-[80px] relative aspect-square ${
          !whichFrameIsRendering ? "cursor-pointer" : "cursor-default"
        }`}
        onClick={handleImageClick}
      >
        <img
          className={`
        w-[124px] h-[124px] max-sm:w-[84px] max-sm:h-[84px]
        object-cover absolute top-0 left-0 rounded-[12px] `}
          src={
            image.thumbnailSmallUrl ||
            image?.template?.thumbnail ||
            activeFrame?.thumbnail
          }
          alt=""
        />
        <FrameViewItem
          isActive={selectedImage?.url === image.url}
          className="absolute bottom-0 right-0 w-[112px] h-[112px] max-sm:w-[64px] max-sm:h-[64px]"
          file={image}
          setConfirmDelete={setConfirmDelete}
        />
      </div>
    </>
  );
}

export default ImageSelectorItem;
