import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { makeRequest } from "../../auth/axios";
import { Dispatch, SetStateAction } from "react";
import {
  IFoldersAndFilesDelete,
  ISingleFoldersAndFilesVM,
} from "../../../utils/interfaces/IAssets";

export const useDeleteFoldersAndFiles = ({
  setFoldersAndFiles,
  setPage,
  setSelectedItems,
}: {
  setFoldersAndFiles?: Dispatch<SetStateAction<ISingleFoldersAndFilesVM[]>>;
  setPage?: Dispatch<SetStateAction<number>>;
  setSelectedItems?: ([{ id, isFolder }]: {
    id: number;
    isFolder: boolean;
  }[]) => void;
}) => {
  const queryClient = useQueryClient();
  return useMutation<
    IBaseData<ISingleFoldersAndFilesVM>,
    Error,
    IFoldersAndFilesDelete
  >({
    mutationFn: ({ folderIds = [], fileIds = [] }) => {
      const folders = folderIds.map((id) => `folderIds=${id}`).join("&");
      const files = fileIds.map((id) => `fileIds=${id}`).join("&");
      let url = `/FoldersAndFiles?`;
      if (folders) url += `${folders}&`;
      if (files) url += `${files}&`;

      return makeRequest.delete(url);
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["useGetFoldersAndFiles"] });
      setPage && setPage(1);
      setFoldersAndFiles && setFoldersAndFiles([]);
      setSelectedItems && setSelectedItems([]);
    },
  });
};
