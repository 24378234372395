import { format, formatInTimeZone, toZonedTime } from "date-fns-tz";

export const formatTimeFromDate = (date: Date) => {
  let hour = new Date(date).getHours().toString();
  let minutes = new Date(date).getMinutes().toString();
  if (hour.length === 1) hour = `0${hour}`;
  if (minutes.length === 1) minutes = `0${minutes}`;
  return `${hour}:${minutes}`;
};

export const formatDate = (dateTimeString: string) => {
  const date = new Date(dateTimeString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};
