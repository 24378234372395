import React, { useEffect, useState } from "react";
import PageHeading from "../components/PageHeading";
import { useNavigate } from "react-router-dom";
import PlanItem, { Plan } from "../components/billing/PlanItem";
import SButton from "../design-system/SButton";
import { faArrowUpRight } from "@fortawesome/pro-regular-svg-icons";
import { useGetPackages } from "../api/subscriptions/packages/get";
import { usePutSubscription } from "../api/subscriptions/put";
import { useGetHasPaymentMethod } from "../api/workspaces/hasPaymentMethod/get";
import { BillingPeriod } from "../utils/enums/Enums";
import { useGetCurrentPackage } from "../api/subscriptions/current/get";
import { toast } from "../hooks/toast";
import { useGetCurrentWorkspace } from "../api/workspaces/current/get";
import { IPackageVM } from "../utils/interfaces/ISubscriptions";
import SUnderlineTabs from "../design-system/SUnderlineTabs";
import { useManageSubscription } from "../api/subscriptions/manage/get";
import { manageOrUpdate } from "../components/billing/functions/manageOrUpdate";

export interface IPlanData {
  details: null | IPackageVM;
  name: "Essential" | "Growth" | "Professional";
  price: string | null;
  brandNumber: number;
  postsNumber: string;
  storageNumber: number;
  generatedImagesNumber: number;
  numberOfSocialNetworkAccounts: number
  editorsNumber: string;
  popular: boolean;
  saving?: number;
  socialSet?: number;
}

const UpdatePlan = () => {
  const [selectedPlan, setSelectedPlan] = useState<IPlanData | undefined>();

  const { data: packages } = useGetPackages();
  const { data: currentPackage } = useGetCurrentPackage();

  const [packagesData, setPackagesData] = useState<null | IPlanData[]>(null);
  const [billingType, setBillingType] = useState<BillingPeriod>(
    BillingPeriod.ANNUALLY
  );

  const { mutateAsync: updateSubscription } = usePutSubscription();
  const { refetch: refetchPaymentMethod } = useGetHasPaymentMethod();

  const { data: currentWorkspace } = useGetCurrentWorkspace();
  const navigate = useNavigate();

  useEffect(() => {
    refetchPaymentMethod();
  }, [currentPackage]);

  const changePlan = ({ packageId }: { packageId: number }) => {
    if (currentWorkspace)
      updateSubscription({ workspaceId: currentWorkspace.data.id, packageId })
        .then((res) => {
          window.open(res.data.data, "_self");
        })
        .catch((err) => {
          toast(err.response.data.message, "error");
        });
  };
  useEffect(() => {
    if (packages) {
      const tmp: IPlanData[] = [
        {
          details: null,
          name: "Essential",
          price: null,
          brandNumber: 1,
          postsNumber: "50",
          storageNumber: 5,
          generatedImagesNumber: 50,
          numberOfSocialNetworkAccounts: 5,
          editorsNumber: "1",
          popular: false,
          saving: 120,
          socialSet: 1,
        },
        {
          details: null,
          name: "Growth",
          price: null,
          brandNumber: 3,
          postsNumber: "500",
          storageNumber: 25,
          generatedImagesNumber: 200,
          numberOfSocialNetworkAccounts: 15,
          editorsNumber: "10",
          popular: true,
          saving: 240,
          socialSet: 3,
        },
        {
          details: null,
          name: "Professional",
          price: null,
          brandNumber: 5,
          postsNumber: "Unlimited",
          storageNumber: 100,
          generatedImagesNumber: 500,
          numberOfSocialNetworkAccounts: 25,
          editorsNumber: "Unlimited",
          popular: false,
          saving: 600,
          socialSet: 5,
        },
      ];

      tmp.forEach((plan) => {
        const matchingPlan = packages.data.find(
          (planA) =>
            planA.name.includes(plan.name) &&
            !planA.name.toLowerCase().includes("old") &&
            planA.billingPeriod === billingType
        );
        if (matchingPlan) {
          plan.details = matchingPlan;
          if (plan.name === "Essential") {
            plan.details.numberOfOrganizations = 1;
            plan.details.numberOfSocialNetworkAccounts = 1;
          }
          if (plan.name === 'Growth') {
            plan.details.numberOfOrganizations = 3;
            plan.details.numberOfSocialNetworkAccounts = 15;
          }
          if (plan.name === 'Professional') {
            plan.details.numberOfOrganizations = 5;
            plan.details.numberOfSocialNetworkAccounts = 25;
          }
        }
      });
      setPackagesData(tmp);
    }
  }, [packages, billingType]);

  useEffect(() => {
    if (currentPackage) {
      if (
        currentPackage.data.package.billingPeriod === BillingPeriod.ANNUALLY
      ) {
        setBillingType(BillingPeriod.ANNUALLY);
      } else {
        setBillingType(BillingPeriod.MONTHLY);
      }
    }
  }, [currentPackage]);

  const { data: manageSubscription, isSuccess } = useManageSubscription();
  const {data: currentPlan} = useGetCurrentPackage();

  useEffect(() => {
    if (!isSuccess || currentPlan?.data.isAppsumo) {
      navigate("/");
    }
  }, [manageSubscription]);
  
  return (
    <div className="flex flex-col gap-[32px] w-full pt-[24px] pb-[32px] max-md:gap-[16px]">
      <PageHeading
        title="Update Plan"
        subtitle="Update your billing."
        withInput={false}
      />
      <SUnderlineTabs
        items={[
          {
            text: "Monthly",
            index: 1,
          },
          {
            text: "Annual",
            index: 2,
          },
        ]}
        selected={billingType}
        setSelected={setBillingType}
      />
      <div className={"flex gap-[14px] max-md:flex-col"}>
        {packagesData &&
          packagesData[0].details &&
          packagesData?.map((planData, index) => (
            <PlanItem
              key={index}
              plan={planData}
              index={index}
              selected={selectedPlan}
              setSelected={setSelectedPlan}
              currentPackage={currentPackage?.data.package}
            />
          ))}
      </div>
      <div className={"flex justify-end"}>
        <SButton
          type={"primary"}
          rIcon={faArrowUpRight}
          onClick={() =>
            manageOrUpdate(
              changePlan,
              billingType,
              manageSubscription?.data,
              currentPackage?.data,
              selectedPlan
            )
          }
        >
          {selectedPlan?.name !==
          currentPackage?.data.package.name.split(" ")[0]
            ? "Update Plan"
            : currentPackage?.data.package.billingPeriod !== billingType
              ? "Update Plan"
              : "Manage"}
        </SButton>
      </div>
    </div>
  );
};

export default UpdatePlan;
