import React, { useState, useEffect, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";

interface AgentInfoProps {
  children: React.ReactNode;
  logo: string;
  agentName: string;
  agentRole: string;
  agentDescription: string;
}

const AgentInfo = ({
  children,
  logo,
  agentName,
  agentRole,
  agentDescription,
}: AgentInfoProps) => {
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [isHovered, setIsHovered] = useState(false);
  const triggerRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);

  const SPACE_ABOVE = 80;
  const SPACE_BELOW = -30;

  useEffect(() => {
    const handlePosition = () => {
      if (triggerRef.current && popoverRef.current) {
        const parent =
          document.getElementById("copilotPostBriefChat") ||
          document.getElementById("copilotCreateBrandChat");
        if (parent) {
          const parentRect = parent.getBoundingClientRect();
          const triggerRect = triggerRef.current.getBoundingClientRect();
          const popoverRect = popoverRef.current.getBoundingClientRect();

          let top: number;
          let left = triggerRect.left;

          if (
            triggerRect.bottom + popoverRect.height + SPACE_BELOW <=
            parentRect.bottom
          ) {
            top = triggerRect.bottom + SPACE_BELOW;
          } else {
            top = triggerRect.top - popoverRect.height - SPACE_ABOVE;
          }

          if (left + popoverRect.width > parentRect.right) {
            left = parentRect.right - popoverRect.width - 5;
          }

          top = Math.max(
            parentRect.top,
            Math.min(top, parentRect.bottom - popoverRect.height),
          );
          left = Math.max(parentRect.left, left);

          setPosition({ top, left });
        }
      }
    };

    if (isHovered) {
      handlePosition();
      window.addEventListener("resize", handlePosition);
      window.addEventListener("scroll", handlePosition);
    }

    return () => {
      window.removeEventListener("resize", handlePosition);
      window.removeEventListener("scroll", handlePosition);
    };
  }, [isHovered]);

  return (
    <div
      ref={triggerRef}
      className="inline-block"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {children}
      <AnimatePresence>
        {isHovered && (
          <motion.div
            ref={popoverRef}
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{ duration: 0.1 }}
            style={{
              position: "fixed",
              top: `${position.top}px`,
              left: `${position.left}px`,
            }}
            className="bg-white border border-gray-200 rounded-lg p-4 shadow-lg w-[380px] max-md:w-[80%] z-50"
          >
            <div className="flex flex-col gap-3">
              <div className="flex items-center gap-3 border-b border-gray-100 pb-3">
                <motion.img
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{
                    type: "spring",
                    stiffness: 260,
                    damping: 20,
                  }}
                  src={logo}
                  alt="logo"
                  className="w-12 h-12 rounded-full"
                />
                <div className="flex flex-col">
                  <motion.p
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.1 }}
                    className="text-md-b text-gray-800"
                  >
                    {agentName}
                  </motion.p>
                  <motion.p
                    initial={{ opacity: 0, y: -5 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2 }}
                    className="text-sm text-gray-500"
                  >
                    {agentRole}
                  </motion.p>
                </div>
              </div>
              <motion.pre
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.3 }}
                className="text-sm text-gray-800 whitespace-pre-wrap break-words"
              >
                {agentDescription}
              </motion.pre>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default AgentInfo;
