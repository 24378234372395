import { escapeLeadingUnderscores } from "typescript";
import { useEffect, useState } from "react";
import { useFramesStore } from "../../../store/framesStore";
import { useCreationHelperFunctions } from "./useCreationHelperFunctions";
import _ from "lodash";
import { useTemplateRendererHelperFunctions } from "./useTemplateRendererHelperFunctions";
import { useGetSingleFrame } from "../../../api/frames/groups/single/get";
export const useTemplateRenderer = () => {
  const { getActiveSocialNetworkPost } = useCreationHelperFunctions();
  const {
    selectedImage,
    setActiveFrame,
    whichFrameIsRendering,
    setSelectedImage,
    selectedFrameTokens,
    setSelectedFrameTokens,
    activeRenderedFrame,
    activeFrame,
  } = useFramesStore();

  useEffect(() => {
    if (
      getActiveSocialNetworkPost()?.files &&
      getActiveSocialNetworkPost()!.files.length > 0 &&
      selectedImage
    ) {
      getActiveSocialNetworkPost()!.files.map((file) => {
        if (file.id === selectedImage.id) {
          setSelectedImage(file);
        }
      });
    }
  }, [getActiveSocialNetworkPost()]);

  // useEffect(() => {
  //   if (getActiveSocialNetworkPost()?.files) {
  //     const firstImage = _.first(getActiveSocialNetworkPost()?.files);
  //     if (!firstImage?.frame) return;
  //     if (!_.isEqual(firstImage, selectedImage)) {
  //       setSelectedFrameTokens(firstImage!.frame!.tokens);
  //     }
  //   }
  // }, [getActiveSocialNetworkPost()?.files]);

  useEffect(() => {
    if (selectedImage?.tokens || activeFrame) {
      if (activeFrame && activeFrame.metadata?.tokens) {
        setSelectedFrameTokens(activeFrame.metadata.tokens);
      } else if (selectedImage?.tokens && selectedImage.tokens.length > 0) {
        setSelectedFrameTokens(selectedImage?.tokens);
      } else {
        setSelectedFrameTokens([]);
      }
    }
  }, [selectedImage, activeFrame]);
  useEffect(() => {
    if (
      !selectedImage &&
      getActiveSocialNetworkPost()?.files &&
      getActiveSocialNetworkPost()!.files.length > 0
    ) {
      setSelectedImage(getActiveSocialNetworkPost()!.files[0]);
    }
  }, [getActiveSocialNetworkPost()?.files, selectedImage]);
};
