export const brandSubMenu = [
  {
    title: "Brandbook",
    path: "/brand?subsection=brandbook",
  },
  {
    title: "Members",
    path: "/brand?subsection=members",
  },
  {
    title: "Accounts",
    path: "/brand?subsection=accounts",
  },
  {
    title: "Settings",
    path: "/brand?subsection=settings",
  },
];

export const contentSubMenu = [
  {
    title: "All",
    path: "/?subsection=all",
  },
  {
    title: "Drafts",
    path: "/?subsection=drafts",
  },
  {
    title: "Published",
    path: "/?subsection=published",
  },
  {
    title: "Scheduled",
    path: "/?subsection=scheduled",
  },
];

export const workspaceSubMenu = [
  {
    title: "Overview",
    path: "/workspace?subsection=overview",
  },
  {
    title: "Members",
    path: "/workspace?subsection=members",
  },
  {
    title: "Brands",
    path: "/workspace?subsection=brands",
  },
  {
    title: "Billing",
    path: "/workspace?subsection=billing",
  },
];

export const calendarSubMenu = [
  {
    title: "Monthly",
    path: "/calendar?subsection=month",
  },
  {
    title: "Weekly",
    path: "/calendar?subsection=week",
  },
  {
    title: "Daily",
    path: "/calendar?subsection=day",
  },
];
