import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { IWorkspaceUsageVM } from "../utils/interfaces/IWorkspace";

export interface ILimitsStoreInitialInterface {
  limits: IWorkspaceUsageVM | null | undefined;
}

const initialState: ILimitsStoreInitialInterface = {
  limits: undefined,
};

const store = (set: any) => ({
  ...initialState,
  setLimits: (limits: null | IWorkspaceUsageVM | undefined) => set({ limits }),
  reset: () => set(initialState),
});
export const useLimitsStore = create(devtools(store));
