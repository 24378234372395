import React, { InputHTMLAttributes } from "react";

interface SRadioProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "size"> {
  className?: string;
  size: "sm" | "md";
}

const SRadio = ({ className, size = "sm", ...props }: SRadioProps) => {
  return (
    <input
      type={"radio"}
      className={`${className} border-gray-300 text-amethyst-600 focus:ring-amethyst-600 ${size === "md" ? "w-[20px] h-[20px]" : "w-[16px] h-[16px]"}`}
      {...props}
    />
  );
};

export default SRadio;
