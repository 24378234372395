import { useEffect, useState, useCallback } from "react";
import {
  HubConnectionBuilder,
  HubConnection,
  LogLevel,
} from "@microsoft/signalr";
import * as signalR from "@microsoft/signalr";

export const useSignalR = (hubUrl: string) => {
  const [connection, setConnection] = useState<HubConnection | null>(null);
  const [connected, setConnected] = useState(false);
  const [eventListeners, setEventListeners] = useState<
    Array<{ event: string; callback: (data: any) => void }>
  >([]);

  // Initialize SignalR connection when the hook is used
  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(hubUrl, {
        accessTokenFactory: () => localStorage.getItem("token") || "",
      })
      .withHubProtocol(new signalR.JsonHubProtocol())
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Information)
      .build();

    setConnection(newConnection);

    return () => {
      // Clean up connection on component unmount
      if (newConnection) {
        newConnection.stop();
      }
    };
  }, [hubUrl]);

  // Start the connection
  useEffect(() => {
    if (connection) {
      const startConnection = async () => {
        try {
          await connection.start();
          setConnected(true);
          // Re-register any event listeners added before connection was established
          eventListeners.forEach(({ event, callback }) => {
            connection.on(event, callback);
          });
        } catch (err) {
          console.error(
            "POST SIGNALR: Error while establishing connection: ",
            err
          );
        }

        connection.onclose(() => {
          console.log("POST SIGNALR: SignalR Disconnected.");
          setConnected(false);
        });
      };

      startConnection();
    }
  }, [connection, eventListeners]);

  // Subscribe to an event
  const subscribeToEvent = useCallback(
    (event: string, callback: (data: any) => void) => {
      if (connection && connected) {
        connection.on(event, callback);
      } else {
        setEventListeners((prev) => [...prev, { event, callback }]);
      }
    },
    [connection, connected]
  );

  // Unsubscribe from an event
  const unsubscribeFromEvent = useCallback(
    (event: string, callback: (...args: any[]) => void) => {
      if (connection) {
        connection.off(event, callback);
      }
    },
    [connection]
  );

  // Send event through SignalR
  const sendEvent = useCallback(
    async (event: string, data?: any) => {
      if (connection && connected) {
        try {
          await connection.send(event, data);
          console.log(`POST SIGNALR: Sent event: ${event} with data:`, data);
        } catch (err) {
          console.error("POST SIGNALR: Error while sending event: ", err);
        }
      }
    },
    [connection, connected]
  );

  const disconnect = useCallback(async () => {
    if (connection) {
      try {
        await connection.stop();
        setConnected(false);
      } catch (err) {
        console.error("POST SIGNALR: Error while closing connection: ", err);
      }
    }
  }, [connection]);

  return {
    connected,
    subscribeToEvent,
    unsubscribeFromEvent,
    sendEvent,
    disconnect,
  };
};
