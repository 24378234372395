import { useNotifications } from "../components/notifications/hooks/useNotifications";
import { RenderNotifications } from "../components/notifications/RenderNotifications";
import PageHeading from "../components/PageHeading";

function Notifications() {
  const {
    handleClick,
    getValue,
    handleDeleteNotification,
    notifications,
    isLoading,
    isFetching,
    isPending,
    isResponding,
    action,
  } = useNotifications();
  return (
    <div className="w-full py-[24px]">
      <PageHeading
        title="Notifications"
        subtitle="Keep up with your team activities."
      />
      <div className="mt-[28px] flex flex-col gap-[8px] mb-[40px]">
        <RenderNotifications
          handleClick={handleClick}
          getValue={getValue}
          handleDeleteNotification={handleDeleteNotification}
          notifications={notifications}
          isLoading={isLoading}
          isFetching={isFetching}
          isPending={isPending}
          isResponding={isResponding}
          action={action}
        />
      </div>
    </div>
  );
}

export default Notifications;
