import React, { useRef, useState } from "react";
import { useCreationStore } from "../../../../../store/creationStore";
import { useStocksInfiniteScroll } from "./hooks/useStocksInfiniteScroll";
import { useCreationLibraryHelperFunctions } from "../hooks/useCreationLibraryHelperFunctions";
import SButton from "../../../../../design-system/SButton";
import {
  faExpand,
  faSpinner,
} from "@fortawesome/pro-regular-svg-icons";
import ExpandedImageModal from "../../../../utils/ExpandedImageModal";
import { toast } from "../../../../../hooks/toast";
import { useGetWorkspaceUsage } from "../../../../../api/workspaces/limits/get";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ImageSkeleton = () => (
  <div className="w-full pb-[100%] rounded-[12px] bg-gray-200" />
);

function StocksView({
  inputValue,
  setInputValue,
  activeTab,
}: {
  activeTab: "generator" | "stocks" | "search" | "selector";
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  inputValue: string;
}) {
  const { stocks, isStocksFetching } = useCreationStore();
  const [expandedImage, setExpandedImage] = useState<string | null>(null);
  const wrapperRef = useRef<any>(null);
  useStocksInfiniteScroll({
    ref: wrapperRef,
    inputValue,
  });
  const { selectStockImage } = useCreationLibraryHelperFunctions();
  const [loadingList, setLoadingList] = useState<string[]>([]);
  const { data: workspaceUsage } = useGetWorkspaceUsage();

  return (
    <>
      {expandedImage && (
        <ExpandedImageModal
          isOpen={!!expandedImage}
          onClose={() => setExpandedImage(null)}
          source={expandedImage}
        />
      )}
      <div className="w-full h-[1px] bg-gray-100 mt-[16px] mb-[12px]" />
      <div ref={wrapperRef} className="px-[24px] max-h-[400px] overflow-y-auto">
        <div className="grid grid-cols-4 gap-[20px] max-md:gap-[8px] max-md:grid-cols-3">
          {stocks.map((item) => (
            <div
              onClick={() => {
                setLoadingList((prev) => [...prev, item.url]);
                selectStockImage(item).finally(() => {
                  setLoadingList((prev) =>
                    prev.filter((fItem) => fItem !== item.url)
                  );
                });
              }}
              key={item.url}
              className={`relative w-full pb-[100%] rounded-[12px] overflow-hidden group ${!loadingList.includes(item.url) ? "cursor-pointer" : "cursor-default"}`}
            >
              {loadingList.includes(item.url) && <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-10">
                <FontAwesomeIcon
                  icon={faSpinner}
                  size="lg"
                  className="text-white text-2xl animate-spin"
                />
              </div>}
              <img
                className="absolute top-0 left-0 w-full h-full object-cover"
                src={item?.thumbnailMediumUrl || item.url}
                alt="stock-image"
                draggable={false}
              />
              <div className="absolute top-[8px] right-[8px] flex gap-[6px] opacity-0 group-hover:opacity-100 transition-opacity">
                <SButton
                  icon={faExpand}
                  type="secondaryGray"
                  size="sm"
                  onClick={(e) => {
                    e.stopPropagation();
                    setExpandedImage(item.url)
                  }}
                />
              </div>
            </div>
          ))}
          {isStocksFetching && (
            <>
              <ImageSkeleton />
              <ImageSkeleton />
              <ImageSkeleton />
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default StocksView;
