import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IBase } from "../../../utils/interfaces/IBase";
import { makeRequest } from "../../auth/axios";

export const useAddMemberToMultipleBrands = () => {
  const queryClient = useQueryClient();
  return useMutation<IBase<{data: string}>, Error, {userId?: string, organizationIds: number[]}>({
    mutationFn: ({organizationIds, userId}) => {
      const organizations = organizationIds.map((id) => `organizationIds=${id}`).join("&");
      return makeRequest.post(`/OrganizationUsers/Organizations?userId=${userId}&${organizations}`);
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["useGetActiveMembers"] });
      queryClient.invalidateQueries({ queryKey: ["useGetWorkspaceUsers"] });
    },
  });
};
