import React, { useState } from "react";
import { useGetImageIdeas } from "../../../../../../api/creation/imageIdeas/get";
import { useHelperInputHelperFunctions } from "../useHelperInputHelperFunctions";
import { faCircle } from "@fortawesome/pro-regular-svg-icons";
import { faCircle as faCircleSolid } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function SuggestedPrompts({
  setInputValue,
}: {
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
}) {
  const { imageIdeas } = useHelperInputHelperFunctions();
  const [selectedPrompt, setSelectedPrompt] = useState<string | null>(null);

  return (
    <>
      {imageIdeas && imageIdeas.data.length > 0 ? (
        <>
          <div className="px-[24px] mt-[22px] mb-[12px]">
            <p className="text-xs-m text-gray-400">Suggested Prompts:</p>
            <div className="flex flex-col gap-[5px] mt-[8px]">
              {imageIdeas.data.slice(0, 3).map((item) => (
                <p
                  onClick={() => {
                    setInputValue(item.idea);
                    setSelectedPrompt(item.id);
                  }}
                  className={`cursor-pointer text-sm text-gray-600 px-[12px] py-[6px] hover:bg-gray-100 rounded-[8px] ${
                    selectedPrompt === item.id ? "bg-gray-200" : ""
                  }`}
                  key={item.id}
                >
                  {selectedPrompt === item.id ? (
                    <FontAwesomeIcon
                      icon={faCircleSolid}
                      className={"text-[10px] pr-[5px] text-gray-500"}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faCircle}
                      className={"text-[10px] pr-[5px] text-gray-300"}
                    />
                  )}{" "}
                  <i>{item.idea}</i>
                </p>
              ))}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default SuggestedPrompts;
