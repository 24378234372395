import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  IParentBrandCOE,
  ISingleBrandVM,
} from "../../../utils/interfaces/IBrand";
import { makeRequest } from "../../auth/axios";
import { IBaseData } from "../../../utils/interfaces/IBase";

export const usePostParentBrand = () => {
  const queryClient = useQueryClient();
  return useMutation<IBaseData<ISingleBrandVM>, Error, IParentBrandCOE>({
    mutationFn: ({ id, parentOrganizationIds }) => {
      const query = parentOrganizationIds
        .map((id) => `parentOrganizationIds=${id}`)
        .join("&");
      return makeRequest.post(
        `/Organizations/${id}/ParentOrganizations?${query}`
      );
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["useGetCurrentBrand"] });
      queryClient.invalidateQueries({ queryKey: ["useGetAllBrands"] });
    },
  });
};
