import React, { useState } from "react";
import PageHeading from "../components/PageHeading";
import { useNavigate } from "react-router-dom";
import { ITabItem } from "../design-system/STertiaryTabs";
import PersonalInfo from "../components/account/PersonalInfo";
import Security from "../components/account/Security";
import { useGetUser } from "../hooks/useUserInfo";
import SButton from "../design-system/SButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/pro-regular-svg-icons";
import { usePutResendEmailConfirmation } from "../api/resendEmail/put";
import { toast } from "../hooks/toast";

function Account() {
  const { mutateAsync: resendEmailConfirmation } =
    usePutResendEmailConfirmation();

  const { data: userInfo } = useGetUser();
  const [selected, setSelected] = useState(0);
  const tabItems: ITabItem[] = [
    {
      index: 0,
      text: "Personal Info",
    },
    {
      index: 1,
      text: "Security",
    },
  ];
  return (
    <div className="w-full py-[24px]">
      {!userInfo?.data.data.emailConfirmed && (
        <div className="w-full flex justify-between items-center py-2 px-[10px] rounded-lg bg-warning-25 mb-[16px] max-md:flex-col max-md:items-start max-md:gap-[16px]">
          <div className="flex gap-3 items-center">
            <FontAwesomeIcon icon={faWarning} className="text-warning-500" />
            <h1 className="text-gray-700 text-sm">
              Please check your email inbox and activate your account by
              clicking the confirmation link.
            </h1>
          </div>
          <SButton
            onClick={() => {
              resendEmailConfirmation().then(() => {
                toast(
                  "An email confirmation has been sent to the provided email address ✉️",
                  "success",
                );
              });
            }}
            size="sm"
            type="secondaryGray"
          >
            Resend email
          </SButton>
        </div>
      )}
      <PageHeading
        title="Account"
        subtitle="Manage your personal details and security."
        tabsProps={{
          items: tabItems,
          selected: selected,
          setSelected: (i) => setSelected(i),
        }}
      />
      {selected === 0 && <PersonalInfo userInfo={userInfo} />}
      {selected === 1 && <Security userInfo={userInfo} />}
    </div>
  );
}

export default Account;
