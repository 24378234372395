import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../../axios";
import { IBaseData } from "../../../utils/interfaces/IBase";
import {
  ICustomFieldCOE,
  ISingleCustomFieldVM,
} from "../../../utils/interfaces/IBrand";

export const usePostBrandCustomField = () => {
  const queryClient = useQueryClient();
  return useMutation<IBaseData<ISingleCustomFieldVM>, Error, ICustomFieldCOE>({
    mutationFn: (data) =>
      makeRequest.post("/Organizations/CustomField", { ...data }),
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["useGetCurrentBrand"] });
      return res;
    },
  });
};
