import { useCreationHelperFunctions } from "../hooks/useCreationHelperFunctions";
export const MobilePublishedTab = () => {
  const {
    isPostPublished,
    isPostScheduled,
    getSocialNetworkNameByType,
    activeSoc,
  } = useCreationHelperFunctions();
  const publishedOrScheduled = isPostPublished() ? "published" : "scheduled";
  return (
    <>
      {(isPostPublished() || isPostScheduled()) && activeSoc && (
        <div className="overflow-hidden px-8 flex items-center gap-2 py-3">
          <p className="text-start text-sm text-gray-600">
            Your post has been {publishedOrScheduled} on{" "}
            {getSocialNetworkNameByType(activeSoc)} 🎉
          </p>
        </div>
      )}
    </>
  );
};
