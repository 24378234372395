import React, { useEffect, useState } from "react";
import SModalBase from "../../../design-system/SModalBase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/pro-regular-svg-icons";
import STextarea from "../../../design-system/STextarea";
import { useBrandStore } from "../../../store/brandStore";
import SButton from "../../../design-system/SButton";
import { useBrandHelperFunctions } from "../hooks/useBrandHelperFunctions";
import SQuaternaryTabs from "../../../design-system/SQuaternaryTabs";
import STertiaryTabs from "../../../design-system/STertiaryTabs";
import SUnderlineTabs from "../../../design-system/SUnderlineTabs";
import SInput from "../../../design-system/SInput";
import { set } from "lodash";

function RegenerateBrandModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const { currentBrand } = useBrandStore();
  const [value, setValue] = useState("");
  const [website, setWebsite] = useState("");
  const [error, setError] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const { handleRegenerateBrand } = useBrandHelperFunctions();
  const [selectedType, setSelectedType] = useState(0);
  useEffect(() => {
    setError(undefined);
  }, [selectedType]);
  useEffect(() => {
    if (currentBrand?.websiteToScrape) setWebsite(currentBrand.websiteToScrape);
  }, [currentBrand]);
  return (
    <SModalBase
      dialogPanelClassName={
        "max-w-[544px] w-full !p-0 rounded-[12px] overflow-hidden max-h-[80dvh] overflow-y-auto"
      }
      isOpen={isOpen}
      onClose={onClose}
      showX={false}
    >
      {currentBrand && (
        <>
          <div className="w-full h-[56px] flex justify-between items-center px-[24px] py-[16px] bg-base-white border-b border-b-gray-200">
            <p className="text-md-m text-gray-600">Regenerate Brand</p>
            <FontAwesomeIcon
              icon={faX}
              onClick={onClose}
              className="text-[12px] cursor-pointer text-gray-400"
            />
          </div>
          <div className="p-[24px]">
            <STextarea
              disabled={true}
              className="w-full max-w-unset"
              label="Current Description"
              value={currentBrand.about as string}
            />
            <SUnderlineTabs
              className="mt-[12px]"
              items={[
                {
                  index: 0,
                  text: "Brand Description",
                },
                {
                  index: 1,
                  text: "Brand Website",
                },
              ]}
              selected={selectedType}
              setSelected={setSelectedType}
            />
            {selectedType === 0 && (
              <STextarea
                error={error}
                className="w-full max-w-unset mt-[12px]"
                label="New Description"
                placeholder="Enter new description"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            )}
            {selectedType === 1 && (
              <SInput
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
                className="w-full max-w-unset mt-[12px]"
                error={error}
                label="New Website"
                placeholder="Enter new website"
              />
            )}
            <div className="flex justify-end gap-[12px] mt-[12px]">
              <SButton type="secondaryGray" onClick={onClose}>
                Cancel
              </SButton>
              <SButton
                isLoading={isLoading}
                className="max-w-[204px]"
                onClick={() => {
                  if (value.trim().length === 0 && selectedType === 0) return;
                  setError(undefined);
                  setIsLoading(true);
                  let obj = {};
                  if (selectedType === 0) {
                    obj = { newDescription: value, website: undefined };
                  } else {
                    obj = { newDescription: undefined, website };
                  }
                  handleRegenerateBrand(obj)
                    .catch((err) => {
                      setError(err.response.data.message);
                    })
                    .finally(() => setIsLoading(false));
                }}
              >
                Save & Regenerate
              </SButton>
            </div>
          </div>
        </>
      )}
    </SModalBase>
  );
}

export default RegenerateBrandModal;
