import React from "react";

function CalendarHead() {
  return (
    <div className="grid grid-cols-7">
      {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
        <div
          key={day}
          className="bg-white p-[12px] uppercase text-left text-md-m text-gray-800"
        >
          {day}
        </div>
      ))}
    </div>
  );
}

export default CalendarHead;
