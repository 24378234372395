import React, { useEffect, useState } from "react";
import TextsSection, { IBrandSectionTextsProps } from "../TextsSection";
import _ from "lodash";
import { useBrandStore } from "../../../store/brandStore";
import { usePutBrand } from "../../../api/brand/put";
import { toast } from "../../../hooks/toast";

function Personas() {
  const { currentBrand } = useBrandStore();
  const { mutateAsync: updateBrand } = usePutBrand();
  const [personas, setPersonas] = useState<IBrandSectionTextsProps[]>([]);
  let a = false;
  useEffect(() => {
    if (
      currentBrand?.brandingData?.brandPersonas &&
      currentBrand?.brandingData?.brandPersonas.length > 0
    ) {
      let tmpPersonas: IBrandSectionTextsProps[] = [];
      currentBrand.brandingData.brandPersonas.forEach((item, index) => {
        tmpPersonas.push({
          isTitleEditable: true,
          key: `personas-${index}`,
          content: item.description,
          title: item.name,
          showBorder: index !== currentBrand.brandingData.brandPersonas.length - 1,
          handleChangeText: (text: string) => {
            if (
              currentBrand &&
              currentBrand?.brandingData?.brandPersonas[index].description !== text
            ) {
              let tmpBrandingData = _.cloneDeep(currentBrand.brandingData);
              tmpBrandingData.brandPersonas[index].description = text;
              updateBrand({
                brandId: currentBrand.id,
                brandingData: tmpBrandingData,
              })
                .then(() => {
                  toast("Persona description updated successfully", "success");
                })
                .catch((err) => {
                  toast(
                    `Failed to update persona description, ${err.response.data.message}`,
                    "error",
                  );
                });
            }
          },
          handleChangeTitle: ({
            text,
            sKey,
          }: {
            text: string;
            sKey: string;
          }) => {
            if (
              currentBrand &&
              currentBrand?.brandingData?.brandPersonas[
                Number(sKey[sKey.length - 1])
              ].name !== text
            ) {
              let tmpBrandingData = _.cloneDeep(currentBrand.brandingData);
              tmpBrandingData.brandPersonas[Number(sKey[sKey.length - 1])].name =
                text;
              updateBrand({
                brandId: currentBrand.id,
                brandingData: tmpBrandingData,
              })
                .then(() => {
                  toast("Persona name updated successfully", "success");
                })
                .catch((err) => {
                  toast(
                    `Failed to update persona name, ${err.response.data.message}`,
                    "error",
                  );
                });
            }
          },
        });
      });
      setPersonas(tmpPersonas);
    }
  }, [currentBrand]);

  return (
    <>
      <TextsSection
        fieldName="Persona"
        loadingCount={3}
        title="👤 Customer Personas"
        subtitle="Examples of people who are interested in your brand"
        isLoading={!currentBrand?.brandingData?.brandPersonas}
        texts={personas}
      />
    </>
  );
}

export default Personas;
