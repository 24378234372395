import { faImages } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const FrameSkeleton = () => {
  return (
    <div className="p-3 h-[200px] rounded-lg bg-gray-50 animate-pulse">
      <div className="w-full h-full rounded-lg border-8 border-gray-100 flex justify-center items-center">
        <FontAwesomeIcon className="text-gray-200 text-6xl" icon={faImages} />
      </div>
    </div>
  );
};
