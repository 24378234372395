import {
  getDate,
  getHours,
  getMinutes,
  getMonth,
  getSeconds,
  getYear,
} from "date-fns";
import { usePostPublish } from "../../../api/creation/publish/post";
import { useCreationStore } from "../../../store/creationStore";
import { IPublishPostCOE } from "../../../utils/interfaces/IPost";
import { useCreationHelperFunctions } from "./useCreationHelperFunctions";
import { useCalendarHelper } from "../../Calendar/hooks/useCalendarHelper";
import { useUpdatePost } from "./useUpdatePost";
import { PostStatus } from "../../../utils/enums/Enums";
import { toast } from "../../../hooks/toast";
import { useGetPostById } from "../../../api/posts/getById";

export const usePublishing = () => {
  const { mutateAsync: postPublish } = usePostPublish();
  const { selectedSocialNetworks, setIsPublishing, postData, setPostData } =
    useCreationStore();
  const { refetch: refetchPostById } = useGetPostById({
    postId: postData?.id || null,
  });
  const { convertToISOAndChangeTimezone } = useCalendarHelper();
  const {
    getSocialNetworkPostWithSocialNetworkType,
    getActiveSocialNetworkPost,
  } = useCreationHelperFunctions();
  const { updateSocialNetworkPostStatus } = useUpdatePost();
  const sendPublishRequest = async ({
    publishDateTime,
    isPlanned = false,
  }: {
    publishDateTime?: string;
    isPlanned?: boolean;
  }): Promise<IPublishPostCOE[]> => {
    try {
      let tmpArr: IPublishPostCOE[] = [];
      selectedSocialNetworks
        .filter((network) => {
          const post = getSocialNetworkPostWithSocialNetworkType(
            network.socialNetworkType
          );
          if (post?.postStatus === PostStatus.PUBLISHED) {
            return false;
          }
          if (post?.postStatus === PostStatus.SCHEDULED) {
            return false;
          }
          return true;
        })
        .forEach((item) => {
          let socPost = getSocialNetworkPostWithSocialNetworkType(
            item.socialNetworkType
          );
          if (socPost) {
            tmpArr.push({
              ayrshareSocialAccountId: item.id,
              isPlanned: isPlanned,
              publishDateTime,
              socialNetworkPostId: socPost.id,
            });
          }
        });

      await postPublish(tmpArr);
      setIsPublishing(null);
      return tmpArr;
    } catch (error) {
      setIsPublishing(null);
      throw error;
    } finally {
      await refetchPostById().then((res) => {
        if (res.data) setPostData(res.data.data);
      });
    }
  };
  //@ts-ignore
  const handlePublishNow = async (): Promise<IPublishPostCOE[]> => {
    setIsPublishing("publishing");
    try {
      const res = await sendPublishRequest({});
      res.map((item) => {
        updateSocialNetworkPostStatus({
          socialNetworkPostId: item.socialNetworkPostId as number,
          status: PostStatus.PUBLISHED,
        });
      });
      toast("Post published successfully", "success");
      return res;
    } catch (err) {
      //@ts-ignore
      toast(err?.response?.data?.message);
      throw err;
    }
  };
  const handleSchedule = async ({
    date,
    time,
  }: {
    date: Date;
    time: Date;
  }): Promise<IPublishPostCOE[]> => {
    setIsPublishing("scheduling");
    const scheduleDate = convertToISOAndChangeTimezone(
      new Date(
        getYear(date),
        getMonth(date),
        getDate(date),
        getHours(time),
        getMinutes(time),
        getSeconds(time)
      )
    );

    try {
      const res = await sendPublishRequest({
        publishDateTime: scheduleDate,
      });
      res.map(async (item) => {
        await updateSocialNetworkPostStatus({
          socialNetworkPostId: item.socialNetworkPostId as number,
          status: PostStatus.SCHEDULED,
        });
      });

      return res;
    } catch (e) {
      //@ts-ignore
      toast(e?.response?.data?.message);
      throw e;
    }
  };

  return { handleSchedule, handlePublishNow };
};
