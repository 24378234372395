import SModalBase from "../../design-system/SModalBase";
import SInput from "../../design-system/SInput";
import SButton from "../../design-system/SButton";
import { ChangeEvent } from "react";

export interface IOpenNewFolderModal {
  openModal: boolean;
  setOpenModal: () => void;
  name?: string;
  setName?: (e: ChangeEvent<HTMLInputElement>) => void;
  onSave?: () => void;
  type: "Folder" | "File" | "Workspace";
  action: "Create" | "Rename" | "Put Selected Items in";
}

export const LibraryCreateFolderModal = ({
  openModal,
  setOpenModal,
  name,
  setName,
  onSave,
  action,
  type,
}: IOpenNewFolderModal) => {
  return (
    <SModalBase
      dialogPanelClassName="w-[544px] py-4"
      isOpen={openModal}
      onClose={setOpenModal}
    >
      <div onDoubleClick={(e) => e.stopPropagation()} className="w-full h-full">
        <h1 className="text-md-m mb-3 text-gray-500">
          {action} {type}
        </h1>
        <hr className="w-auto mx-[-24px]" />
        <div className="w-full flex flex-col gap-3 mt-3">
          <SInput
            value={name}
            defaultValue={name}
            onChange={setName}
            label={`${type} Name`}
            placeholder={`${type} Name...`}
          />
          <div className="w-full flex items-center justify-end gap-3">
            <SButton
              onClick={setOpenModal}
              children="Cancel"
              type="secondaryGray"
            />
            <SButton onClick={onSave} children="Save" />
          </div>
        </div>
      </div>
    </SModalBase>
  );
};
