import { faPaperPlaneTop, faRefresh } from "@fortawesome/pro-regular-svg-icons";
import SButton from "../../../design-system/SButton";
import { EmptyContentSuggestions } from "./EmptyContentSuggestions";
import { useEffect, useRef, useState } from "react";
import { EmptyStateChatMessage } from "./EmptyStateChatMessage";
import { useCreationHelperFunctions } from "../hooks/useCreationHelperFunctions";
//@ts-ignore
import jason from "../../../assets/images/agents/jason.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetCurrentBrand } from "../../../api/brand/current/get";
import { useNavigate } from "react-router-dom";
import { toast } from "../../../hooks/toast";
import { useGetWorkspaceUsage } from "../../../api/workspaces/limits/get";
import { useCreationStore } from "../../../store/creationStore";

export const EmptyContentState = ({
  setIsWhichSocialNetworksSelectorOpen,
}: {
  setIsWhichSocialNetworksSelectorOpen: (value: boolean) => void;
}) => {
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [userMessage, setUserMessage] = useState<string | undefined>(undefined);
  const { data: currentBrand, isError: isCurrentBrandError } =
    useGetCurrentBrand();
  const { data: workspaceUsage } = useGetWorkspaceUsage();

  const { handleOpenPost } = useCreationHelperFunctions();

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef.current) {
      adjustTextareaHeight();
    }
  }, [value]);
  const { setPostIdea } = useCreationStore();
  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${Math.min(textarea.scrollHeight, 70)}px`;
    }
  };

  const handleSubmit = () => {
    if (value.length === 0) return;
    if (!workspaceUsage?.data.canUserAddPosts) {
      toast("You have reached the limit of posts for your workspace.", "error");
      return;
    }
    if (currentBrand?.data.activeSocialNetworks.length === 0) {
      setUserMessage(value);
    }
    setTimeout(() => {
      setPostIdea(value);
      handleOpenPost({
        postIdea: `Create a post about the following idea: ${value}`,
        onSocialNetworkSelectorOpen: () =>
          setIsWhichSocialNetworksSelectorOpen(true),
      });
    }, 1000);
    setValue("");
  };

  const isTextareaEnabled =
    currentBrand?.data.isBrandDataGenerated && !currentBrand?.data.hasErrors;

  return (
    <div className="mt-8 py-7 max-sm-m:px-3 px-12 max-sm-m:py-3 rounded-[10px] w-full max-sm-m:h-auto h-fit grid lg2:grid-cols-3 max-sm-m:flex max-sm-m:flex-col-reverse grid-cols-2 gap-10 animate-gradient bg-gradient-to-r from-purple-200 via-brand-100 to-brand-300 bg-[length:400%_400%]">
      {currentBrand?.data.isBrandDataGenerated &&
        !currentBrand?.data.hasErrors && (
          <EmptyContentSuggestions setValue={setValue} />
        )}
      {!isCurrentBrandError && !currentBrand?.data.isBrandDataGenerated && (
        <div className="flex items-center gap-3">
          <FontAwesomeIcon
            icon={faRefresh}
            className="text-brand-500 animate-spin"
          />
          <p className="text-md-b xl:text-xl text-gray-800">
            Please wait, your brand is being generated...
          </p>
        </div>
      )}
      {currentBrand?.data.hasErrors &&
        currentBrand?.data.isBrandDataGenerated && (
          <div className="my-auto flex flex-col items-center gap-3">
            <FontAwesomeIcon
              icon={faRefresh}
              className="text-brand-500 animate-spin"
            />
            <p className="text-center text-md-b xl:text-xl text-gray-800">
              There's a problem with generating your brand
            </p>
            <SButton
              type="primary"
              size="sm"
              onClick={() => navigate("/brand")}
            >
              Check your brand
            </SButton>
          </div>
        )}
      <div className="w-full h-full border-4 border-brand-200 bg-base-white shadow-lg rounded-2xl p-[4px]">
        <div className="w-full relative h-full flex flex-col rounded-lg pt-3">
          <p className="text-md-sb px-4 text-gray-900 capitalize">
            Create your first post 🚀
          </p>
          <div className="flex flex-col gap-0 w-full h-[50vh] overflow-y-auto overflow-x-hidden">
            <div className="flex px-[20px] py-[20px] w-full h-full flex-col gap-5 overflow-y-auto overflow-x-hidden">
              <EmptyStateChatMessage
                image={jason}
                message="Hey, what you wanna post about?"
                name="Jason"
              />
              {userMessage && (
                <EmptyStateChatMessage
                  message={userMessage}
                  name="You"
                  isUser
                />
              )}
            </div>
            <div className="flex w-full border-t border-gray-100 bg-gray-25 rounded-b-2xl">
              <textarea
                disabled={!isTextareaEnabled}
                ref={textareaRef}
                placeholder={
                  isTextareaEnabled
                    ? "Write something"
                    : "Brand generation in progress..."
                }
                className={`w-full text-sm rounded-lg bg-gray-25 resize-none border-none focus:outline-none focus:ring-0 h-full max-h-[90px] overflow-y-auto overflow-x-hidden transition-colors duration-200 max-md:text-[16px] ${
                  isTextareaEnabled
                    ? "text-gray-900 placeholder-gray-500"
                    : "text-gray-400 placeholder-gray-400 cursor-not-allowed"
                }`}
                value={value}
                onChange={(e) => isTextareaEnabled && setValue(e.target.value)}
                onKeyDown={(e) => {
                  if (!isTextareaEnabled) return;
                  if (e.shiftKey && e.key === "Enter") return;
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleSubmit();
                  }
                }}
              />
              <SButton
                disabled={!isTextareaEnabled}
                onClick={handleSubmit}
                type="secondaryColor"
                style={{
                  border: "none",
                  background: "none",
                  opacity: isTextareaEnabled ? 1 : 0.5,
                }}
                className={`h-2 self-end transition-opacity duration-200 ${
                  isTextareaEnabled ? "cursor-pointer" : "cursor-not-allowed"
                }`}
                icon={faPaperPlaneTop}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
