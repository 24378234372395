import { useEffect } from "react";
import { usePostFolder } from "../../../api/library/folders/post";
import { usePostFile } from "../../../api/library/files/post";
import { useUploadFiles } from "../../../hooks/useUploadFiles";
import {

  IUploadFIleVM,
} from "../../../utils/interfaces/IAssets";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { useLibraryStore } from "../../../store/libraryStore";

export const useUploadAssets = ({
  parentFolderId,
  uploadFileAdditionalFunction,
}: {
  uploadFileAdditionalFunction?: (res: IBaseData<IUploadFIleVM>) => void;
  parentFolderId?: number | null;
}) => {
  const {setFoldersAndFiles, setPage, setUploadErrors} = useLibraryStore();
  const { mutateAsync: createFolder } = usePostFolder({
    setFoldersAndFiles,
    setPage,
  });
  const { mutateAsync: createFile, isPending } = usePostFile();
  const { selectFile, files, setFiles } = useUploadFiles();

  useEffect(() => {
    if (files) {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("files", file);
      });
      createFile({ folderId: parentFolderId, files: formData }).then((res) => {
        setPage(1);
        setFoldersAndFiles([]);
        uploadFileAdditionalFunction && uploadFileAdditionalFunction(res);
        setUploadErrors(res.data.errors);
      });
    }
  }, [files]);

  return { selectFile, createFolder, isPending, files, createFile, setFiles};
};
