import { useQuery } from "@tanstack/react-query";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { IWorkspaceUsageVM } from "../../../utils/interfaces/IWorkspace";
import { makeRequest } from "../../../axios";
import { useLimitsStore } from "../../../store/limitsStore";

export const useGetWorkspaceUsage = () => {
  const { setLimits } = useLimitsStore();
  return useQuery<IBaseData<IWorkspaceUsageVM>>({
    queryKey: ["useGetWorkspaceUsage"],
    queryFn: async () => {
      const response = await makeRequest.get("/Workspaces/GetWorkspaceUsage");
      setLimits(response.data);
      return response as IBaseData<IWorkspaceUsageVM>;
    },
  });
};
