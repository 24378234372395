import {
  faChevronUp,
  faGear,
  faQuestionCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef } from "react";
import SButton from "../../design-system/SButton";
import { useNavigate } from "react-router-dom";
import WorkspaceSwitcher from "./WorkspaceSwitcher";
import { useHandleOutsideClick } from "../../hooks/useHandleOutsideClick";
import LettersAvatar from "../utils/LettersAvatar";
import { useGetCurrentWorkspace } from "../../api/workspaces/current/get";
import { useGetWorkspaces } from "../../api/workspaces/get";

function ActiveWorkspace({
  setIsWorkspaceSelectorOpen,
  isWorkspaceSelectorOpen,
  setIsMobileMenuOpen,
}: {
  setIsWorkspaceSelectorOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isWorkspaceSelectorOpen: boolean;
  setIsMobileMenuOpen?: (open: boolean) => void;
}) {
  const { data: currentTeam } = useGetCurrentWorkspace();
  const wrapperRef = useRef<any>();
  const navigate = useNavigate();
  useHandleOutsideClick({
    callBack: () => setIsWorkspaceSelectorOpen(false),
    ref: wrapperRef,
    excludedIds: ["sidebar-workspace-switcher"],
  });
  const { data: teams } = useGetWorkspaces();

  return (
    <div className="relative" ref={wrapperRef}>
      {isWorkspaceSelectorOpen && (
        <WorkspaceSwitcher
          teams={teams}
          currentTeam={currentTeam}
          setIsMobileMenuOpen={setIsMobileMenuOpen}
          setWorkspaceSelectorOpen={setIsWorkspaceSelectorOpen}
        />
      )}
      <div
        id="sidebar-workspace-switcher"
        onClick={() => {
          if (teams && teams.data.length > 1)
            setIsWorkspaceSelectorOpen((prev) => !prev);
          if (teams && teams.data.length === 1) navigate("/workspace");
        }}
        className="md:pt-[12px] md:pb-[14px] px-[14px] pt-[16px] pb-[20px] border-t cursor-pointer bg-base-white rounded-b-[12px]"
      >
        <div className="flex items-center max-md:justify-between md:justify-center">
          <p className="md:hidden text-xs-m text-gray-400">Workspace</p>
          {teams && teams.data.length > 1 && (
            <FontAwesomeIcon
              icon={faChevronUp}
              className="text-[14px] text-gray-400"
            />
          )}
        </div>
        <div
          className={`flex max-md:justify-between md:justify-center ${teams && teams.data.length === 1 ? "mt-[0px]" : "mt-[12px]"} max-sm:pb-[10px]`}
        >
          <div className="flex items-center  gap-[8px]">
            <LettersAvatar text={currentTeam?.data?.name || ""} />
            <p className="md:hidden text-sm-sb text-gray-700 line-clamp-2">
              {currentTeam?.data?.name}
            </p>
          </div>
          {/* <SButton
            icon={faGear}
            size="sm"
            type="tertiaryGray"
            onClick={(e) => {
              e.stopPropagation();
              setIsMobileMenuOpen && setIsMobileMenuOpen(false);
              navigate("/workspace");
            }}
          /> */}
        </div>
      </div>
    </div>
  );
}

export default ActiveWorkspace;
