import { PostStatus } from "../../../utils/enums/Enums";

export const getCalendarItemTextColor = (status: PostStatus) => {
  switch (status) {
    case PostStatus.DRAFT:
      return "text-graymodern-500";
    case PostStatus.PUBLISHED:
      return "text-green-500";
    case PostStatus.SCHEDULED:
      return "text-yellow-500";
    case PostStatus.REMINDER:
      return "text-brand-500";
  }
};
export const getCalendarItemOpacityBGColor = (status: PostStatus) => {
  switch (status) {
    case PostStatus.DRAFT:
      return "bg-graymodern-50";
    case PostStatus.PUBLISHED:
      return "bg-green-50";
    case PostStatus.SCHEDULED:
      return "bg-yellow-50";
    case PostStatus.REMINDER:
      return "bg-brand-50";
  }
};
export const getCalendarItemBGColor = (status: PostStatus) => {
  switch (status) {
    case PostStatus.DRAFT:
      return "bg-graymodern-500";
    case PostStatus.PUBLISHED:
      return "bg-green-500";
    case PostStatus.SCHEDULED:
      return "bg-yellow-500";
    case PostStatus.REMINDER:
      return "bg-brand-500";
  }
};
export const calendarStringByStatus = (status: PostStatus) => {
  switch (status) {
    case PostStatus.DRAFT:
      return "Draft";
    case PostStatus.PUBLISHED:
      return "Published";
    case PostStatus.SCHEDULED:
      return "Scheduled";
    case PostStatus.REMINDER:
      return "Planned";
  }
};
