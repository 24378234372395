import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { ISingleFolderVM } from "../../../utils/interfaces/IFolders";
import { makeRequest } from "../../auth/axios";
import { Dispatch, SetStateAction } from "react";
import {
  IFoldersAndFilesUpdate,
  ISingleFoldersAndFilesVM,
} from "../../../utils/interfaces/IAssets";

export const usePutFoldersAndFiles = ({
  setFoldersAndFiles,
  setPage,
}: {
  setFoldersAndFiles?: Dispatch<SetStateAction<ISingleFoldersAndFilesVM[]>>;
  setPage?: Dispatch<SetStateAction<number>>;
}) => {
  const queryClient = useQueryClient();
  return useMutation<IBaseData<ISingleFolderVM>, Error, IFoldersAndFilesUpdate>({
    mutationFn: ({folderIds, fileIds, parentFolderId}) => {
      const folders = folderIds.map((id) => `folderIds=${id}`).join("&");
      const files = fileIds.map((id) => `fileIds=${id}`).join("&");
      let url = `/FoldersAndFiles?parentFolderId=${parentFolderId}&`;
      if (folders) url += `${folders}&`;
      if (files) url += `${files}&`;

      return makeRequest.put(url);
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["useGetFoldersAndFiles"] });
      setPage && setPage(1);
      setFoldersAndFiles && setFoldersAndFiles([]);
    },
  });
};
