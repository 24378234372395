import React from "react";
import { Navigate, useLocation } from "react-router-dom";

// tu isRestricted true aris mashin daloginebuli useri ver shemova am pageze

const PublicRouter = ({
  isRestricted = true,
  children,
}: {
  isRestricted?: boolean;
  children: React.ReactNode;
}) => {
  const isAuthenticated = !!localStorage.getItem("token");
  const location = useLocation();

  return isAuthenticated && isRestricted ? (
    <Navigate to={`/${location.search}`} state={{ from: location }} replace />
  ) : (
    <>{children}</>
  );
};

export default PublicRouter;
