import MembersList from "../../components/tables/MembersListView";
import { useDisplayMembers } from "../../components/brand/hooks/useDisplayMembers";
import { useState } from "react";
import { BrandEditMemberModal } from "../../components/brand/BrandEditMemberModal";
import MembersListSkeleton from "../../components/Skeletons/MembersListSkeleton";
import { OrganizationUserRole, TeamRole } from "../../utils/enums/Enums";
import { useGetUser } from "../../hooks/useUserInfo";

function BrandMembers({
  setInviteModal,
}: {
  setInviteModal: (value: boolean) => void;
}) {
  const {
    activeMembers,
    pendingMembers,
    activeMemberItems,
    pendingMemberItems,
    isMembersLoading,
  } = useDisplayMembers();
  const [editModal, setEditModal] = useState(false);
  const [chosenUser, setChosenUser] = useState<{
    userId: string;
    name: string;
    email: string;
    invitationId?: number | null;
    isPending: boolean;
    role: TeamRole;
  }>();
  const { data: userInfo } = useGetUser();
  return (
    <div>
      {editModal && userInfo?.data.data.userRole !== OrganizationUserRole.VIEWER && (
        <BrandEditMemberModal
          editModal={editModal}
          setEditModal={setEditModal}
          chosenUser={chosenUser}
        />
      )}
      {activeMembers && pendingMembers && (
        <MembersList
          className="mt-5 mb-10"
          items={activeMemberItems.concat(pendingMemberItems)}
          loggedInUserRole={userInfo?.data.data.userRole}
          setEditModal={setEditModal}
          setChosenUser={setChosenUser}
        />
      )}
      {isMembersLoading && <MembersListSkeleton className={"my-5"} />}
    </div>
  );
}

export default BrandMembers;
