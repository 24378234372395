import { makeRequest } from "../../../axios";
import { useQuery } from "@tanstack/react-query";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { ISubscriptionVM } from "../../../utils/interfaces/ISubscriptions";
// import { useNavigate } from "react-router-dom";

export const useGetCurrentPackage = () => {
  // const navigate = useNavigate();
  return useQuery<IBaseData<ISubscriptionVM>>({
    queryKey: ["useGetCurrentPackage"],
    queryFn: async () => {
      // eslint-disable-next-line no-useless-catch
      try {
        const response = await makeRequest.get(
          "/Subscriptions/CurrentSubscription",
        );
        return response as IBaseData<ISubscriptionVM>;
      } catch (error) {
        // if (
        //   window.location.pathname.split('/').pop() !== 'onboarding' &&
        //   window.location.pathname.split('/').pop() !== 'external-signin-callback' &&
        //   window.location.pathname.split('/').pop() !== 'canva-redirect'
        // ) {
        //   // @ts-ignore
        //   if (error?.response?.status !== 401) navigate('/billing');
        // }
        throw error;
      }
    },
    retry: false,
  });
};
