import { ImageGenerationAspectRatio } from "../../../../../../utils/enums/Enums";

interface AspectRatioVisualProps {
  aspectRatio: ImageGenerationAspectRatio;
  activeAspectRatio: ImageGenerationAspectRatio;
}

export const AspectRatioVisuals = ({
  aspectRatio,
  activeAspectRatio,
}: AspectRatioVisualProps) => {
  const getBorderStyle = (ratio: ImageGenerationAspectRatio) =>
    activeAspectRatio === ratio ? "border-white" : "border-gray-600";

  return (
    <>
      {aspectRatio === ImageGenerationAspectRatio["21:9"] && (
        <div
          className={`w-5 h-3 border-2 rounded-sm ${getBorderStyle(ImageGenerationAspectRatio[aspectRatio])} mx-1`}
        />
      )}
      {aspectRatio === ImageGenerationAspectRatio["1:1"] && (
        <div
          className={`w-4 h-4 border-2 rounded-sm ${getBorderStyle(ImageGenerationAspectRatio[aspectRatio])} mx-1`}
        />
      )}
      {aspectRatio === ImageGenerationAspectRatio["16:9"] && (
        <div
          className={`w-4 h-3 border-2 rounded-sm ${getBorderStyle(ImageGenerationAspectRatio[aspectRatio])} mx-1`}
        />
      )}
      {aspectRatio === ImageGenerationAspectRatio["4:3"] && (
        <div
          className={`w-4 h-3.5 border-2 rounded-sm ${getBorderStyle(ImageGenerationAspectRatio[aspectRatio])} mx-1`}
        />
      )}
      {aspectRatio === ImageGenerationAspectRatio["3:4"] && (
        <div
          className={`w-3.5 h-4 border-2 rounded-sm ${getBorderStyle(ImageGenerationAspectRatio[aspectRatio])} mx-1`}
        />
      )}
      {aspectRatio === ImageGenerationAspectRatio["9:16"] && (
        <div
          className={`w-3 h-4 border-2 rounded-sm ${getBorderStyle(ImageGenerationAspectRatio[aspectRatio])} mx-1`}
        />
      )}
      {aspectRatio === ImageGenerationAspectRatio["9:21"] && (
        <div
          className={`w-3 h-5 border-2 rounded-sm ${getBorderStyle(ImageGenerationAspectRatio[aspectRatio])} mx-1`}
        />
      )}
    </>
  );
};
