import { useGetWorkspaces } from "../../api/workspaces/get";
import SBadge from "../../design-system/SBadge";
import { faPencil, faRefresh } from "@fortawesome/pro-regular-svg-icons";
import { usePutSwitchTeam } from "../../api/workspaces/swich/put";
import { useContentStore } from "../../store/contentStore";
import { useGetCurrentWorkspace } from "../../api/workspaces/current/get";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAccountStore } from "../../store/accountStore";
import { usePutWorkspace } from "../../api/workspaces/put";
import { toast } from "../../hooks/toast";
import { useEffect, useState } from "react";
import { OrganizationUserRole } from "../../utils/enums/Enums";
import { LibraryCreateFolderModal } from "../Library/LibraryCreateFolderModal";
import { useLibraryStore } from "../../store/libraryStore";
import SRadio from "../../design-system/SRadio";
import { formatDistanceToNowStrict, parseISO } from "date-fns";
import LettersAvatar from "../utils/LettersAvatar";
import SButton from "../../design-system/SButton";
import { useSubscriptionActions } from "../../hooks/useSubscriptionActions";

export const WorkspaceSettings = () => {
  const { data: workspaces } = useGetWorkspaces();
  const { data: currentWorkspace } = useGetCurrentWorkspace();
  const { mutateAsync: updateWorkspace } = usePutWorkspace();
  const { mutateAsync: switchWorkspace, isPending } = usePutSwitchTeam();
  const { setPosts, setPage } = useContentStore();
  const { personalInfo } = useAccountStore();
  const [value, setValue] = useState<string>("");
  const [loadingId, setLoadingId] = useState<number | null>(null);
  const [openRename, setOpenRename] = useState<boolean>(false);
  const { activatePlan } = useSubscriptionActions();

  const {
    setFoldersAndFiles,
    setPage: setLibraryPage,
    setParentFolder,
  } = useLibraryStore();

  useEffect(() => {
    if (workspaces && personalInfo) {
      const ownerWorkspace = workspaces?.data.find((workspace) => {
        return (
          currentWorkspace?.data.id === workspace.id &&
          personalInfo?.userRole === OrganizationUserRole.OWNER
        );
      });
      if (ownerWorkspace) {
        setValue(ownerWorkspace.name);
      }
    }
  }, [currentWorkspace, workspaces, personalInfo]);

  return (
    <div className="flex flex-col mt-1 w-full pb-[60px]">
      {openRename && (
        <LibraryCreateFolderModal
          action="Rename"
          type="Workspace"
          name={value}
          setName={(e) => setValue(e.target!.value)}
          openModal={openRename}
          setOpenModal={() => setOpenRename(false)}
          onSave={() => {
            if (!currentWorkspace) return;
            updateWorkspace({
              workspaceId: currentWorkspace?.data.id,
              data: {
                name: value,
                color: currentWorkspace?.data.color,
              },
            })
              .then((res) =>
                toast("Successfully updated workspace name", "success")
              )
              .catch((err) => toast(err.response.data.message))
              .finally(() => setOpenRename(false));
          }}
        />
      )}
      <div className="w-full grid grid-cols-3  bg-gray-50 py-4 px-6 border-t border-x border-gray-200 rounded-t-[12px]">
        <p className="text-lg-b text-sm font-semibold text-gray-600">
          Workspaces
        </p>
        <p className="text-lg-b max-sm:justify-self-end text-sm font-semibold text-gray-600">
          Subscription
        </p>
        <p className="text-lg-b text-sm font-semibold text-gray-600 max-sm:justify-self-end">
          Last Modified
        </p>
      </div>
      <div className="w-full max-sm:overflow-x-auto flex flex-col border-x border-t border-gray-200 rounded-b-[12px]">
        {workspaces?.data.map((workspace, index) => (
          <div
            className={`${
              (index + 1) % 2 === 0 ? "bg-gray-50" : "bg-white"
            } py-4 px-6 w-full max-sm:min-w-fit border-b border-gray-200 ${
              index === workspaces.data.length - 1 && "rounded-b-[12px]"
            } grid grid-cols-3 max-sm:flex max-sm:gap-10`}
            key={workspace.id}
          >
            <div
              onClick={() => {
                setLoadingId(workspace.id);
                switchWorkspace({ teamId: workspace.id })
                  .then((res) => {
                    setPosts([]);
                    setPage(1);
                    setFoldersAndFiles([]);
                    setLibraryPage(1);
                    setParentFolder(null);
                  })
                  .finally(() => setLoadingId(null));
              }}
              className="flex cursor-pointer w-full items-center gap-2"
            >
              {loadingId !== workspace.id && (
                <SRadio
                  name="workspace-switcher"
                  className="m-[10px] cursor-pointer"
                  size={"sm"}
                  checked={workspace.id === currentWorkspace?.data.id}
                />
              )}
              {isPending && workspace.id === loadingId && (
                <FontAwesomeIcon
                  className="animate-spin text-gray-600 m-[10px] "
                  icon={faRefresh}
                />
              )}
              <LettersAvatar text={workspace.name} />

              <div className="flex max-sm:flex-col gap-3 max-sm:gap-0 items-center max-sm:w-[100px] max-sm:items-start">
                <p className="text-gray-900 text-sm-m">
                  {workspace.name}{" "}
                  {currentWorkspace?.data.id === workspace.id &&
                    personalInfo?.userRole === OrganizationUserRole.OWNER &&
                    "(Owner)"}
                </p>
                {currentWorkspace?.data.id === workspace.id &&
                  personalInfo?.userRole === OrganizationUserRole.OWNER && (
                    <FontAwesomeIcon
                      size="sm"
                      className="text-gray-500 py-3 pr-3 cursor-pointer"
                      icon={faPencil}
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenRename(true);
                      }}
                    />
                  )}
              </div>
            </div>
            <div className="flex max-sm:flex-col items-center gap-2">
              <SBadge
                color={`${workspace.hasActiveSubscription ? "success" : "warning"}`}
                hasBackground={false}
                dotted={true}
                children={`${workspace.hasActiveSubscription ? "Active" : "Inactive"}`}
              />
              {!workspace.hasActiveSubscription &&
                currentWorkspace?.data.id === workspace.id &&
                personalInfo?.userRole === OrganizationUserRole.OWNER && (
                  <SButton
                    onClick={activatePlan}
                    type="tertiaryColor"
                    className="text-error-500 hover:text-error-600 hover:bg-error-500/10"
                    children="Activate"
                  />
                )}
            </div>
            <p className="text-sm whitespace-nowrap text-gray-700">
              {workspace.lastModified &&
                formatDistanceToNowStrict(
                  parseISO(workspace.lastModified.toString())
                )}{" "}
              ago
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};
