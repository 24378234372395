import { useState } from "react";
import { faBell } from "@fortawesome/pro-regular-svg-icons";
import NotificationsTabInHeader from "./NotificationsTabInHeader";
import { useValueByType } from "../notifications/hooks/useValueByType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetNotifications } from "../../api/notifications/get";

const NotificationsInHeader = ({ ...props }) => {
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const { getValue } = useValueByType();
  const { data: notifications } = useGetNotifications({
    page: 1,
    pageSize: 10,
  });
  return (
    <>
      <div className={"relative"}>
        <div
          className={
            "w-[36px] h-[36px] flex items-center justify-around border border-gray-200 rounded-[8px] hover:bg-gray-200 hover:border-gray-300 transition-all cursor-pointer"
          }
          id={"header__notifications-opener"}
          onClick={() => setIsNotificationsOpen(!isNotificationsOpen)}
        >
          {notifications?.data.notifications.find((item) => !item.isRead) && (
            <div
              className={
                "absolute top-[10px] right-[10px] w-[8px] h-[8px] bg-orange-500 rounded-full"
              }
            ></div>
          )}
          <FontAwesomeIcon
            icon={faBell}
            className={"text-[16px] text-gray-700"}
          />
        </div>
      </div>
      {isNotificationsOpen && (
        <NotificationsTabInHeader
          isNotificationsOpen={isNotificationsOpen}
          setIsNotificationsOpen={setIsNotificationsOpen}
          getValue={getValue}
        />
      )}
    </>
  );
};

export default NotificationsInHeader;
