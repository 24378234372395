import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetCanUserRegisterWithInvite } from "../components/auth/canUserRegistrateWithInvite/get";
import { getQuery } from "../hooks/functions/queries";
import SModalBase from "../design-system/SModalBase";
//@ts-ignore
import Tick from "../assets/icons/tick.svg";
import SButton from "../design-system/SButton";
import { useGetInviteInfoByCode } from "../api/invitations/invitationByCode/get";
import { useRespondInvitation } from "../api/invitations/invitationPending/put";

const InvitationRedirectPage = () => {
  const code = getQuery("code");
  const { data: userDontExist } = useGetCanUserRegisterWithInvite({ code });
  const navigate = useNavigate();
  const location = useLocation();
  const isAuthenticated = !!localStorage.getItem("token");
  useEffect(() => {
    if (userDontExist) {
      if (userDontExist.data.isSuccess) {
        navigate(`/signup${location.search}`);
      }
    } else {
      if (isAuthenticated) {
        navigate(`/${location.search}`);
      } else {
        navigate(`/login${location.search}`);
      }
    }
  }, [userDontExist]);
  const { data: inviteInfo } = useGetInviteInfoByCode({ code });
  const { mutateAsync: acceptInvite } = useRespondInvitation();
  return (
    <div></div>
  );
};

export default InvitationRedirectPage;
