import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IStockGroupVM } from "../../../utils/interfaces/IPost";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { makeRequest } from "../../auth/axios";
import { useCreationStore } from "../../../store/creationStore";
import { ImageGenerationStyle } from "../../../utils/enums/Enums";
import { ISingleFIleVM } from "../../../utils/interfaces/IAssets";

export const usePostGeneratePostName = () => {
  return useMutation<
    IBaseData<{ name: string }>,
    Error,
    {
      story: string;
    }
  >({
    mutationFn: (story) =>
      makeRequest.post(`/Posts/GeneratePostName`, { ...story }),
  });
};
