import BrandsListView from "../tables/BrandsListView";
import BrandsListSkeleton from "../Skeletons/BrandListSkeleton";
import { useDeleteSwitchBrand } from "./hooks/useDeleteSwitchBrand";
import { ConfirmDelete } from "../Library/ConfirmDelete";
import { useState } from "react";
import { useDeleteBrand } from "../../api/brand/delete";
import { toast } from "../../hooks/toast";

const WorkspaceBrands = () => {
  const { brands, isBrandsLoading, handleSwitch, workspaceUsers } =
    useDeleteSwitchBrand();
  const { mutateAsync: deleteBrand, isPending } = useDeleteBrand();
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState<
    number | boolean
  >(false);
  return (
    <>
      {isConfirmDeleteOpen && (
        <ConfirmDelete
          openModal={!!isConfirmDeleteOpen}
          loading={isPending}
          setOpenModal={() => setIsConfirmDeleteOpen(false)}
          title="Are you sure you want to delete this brand?"
          onCancel={() => setIsConfirmDeleteOpen(false)}
          onConfirm={() => {
            deleteBrand({ brandId: isConfirmDeleteOpen as number })
              .then((res) => {
                toast("Successfully deleted the brand!", "success");
              })
              .catch((err) => {
                toast(err.data.response.message);
              })
              .finally(() => setIsConfirmDeleteOpen(false));
          }}
        >
          <p className="text-md-m mt-4 text-gray-700">
            This will delete all associated assets.
          </p>
        </ConfirmDelete>
      )}
      <div className="flex flex-col gap-[32px] w-full pb-[60px]">
        {isBrandsLoading ? (
          <BrandsListSkeleton />
        ) : (
          <BrandsListView
            handleSwitch={handleSwitch}
            workspaceUsers={workspaceUsers}
            items={brands}
            setIsConfirmDeleteOpen={setIsConfirmDeleteOpen}
          />
        )}
      </div>
    </>
  );
};

export default WorkspaceBrands;
