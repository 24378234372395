import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../../axios";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { ICopilotSessionVM } from "../../../utils/interfaces/ICopilot";

export const usePutCopilotSession = () => {
  const queryClient = useQueryClient();
  return useMutation<
    IBaseData<ICopilotSessionVM>,
    any,
    { id: string; name: string }
  >({
    mutationFn: (data) =>
      makeRequest.put(`/Copilot/${data.id}?name=${data.name}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["useGetCopilotSessions"] });
    },
  });
};
