import React, { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

const ProtectedRoute = ({
  children,
  needBrand,
  needSubscription = true,
}: {
  needSubscription?: boolean;
  children: React.ReactNode;
  needBrand?: boolean;
}) => {
  const isAuthenticated = !!localStorage.getItem("token");

  const location = useLocation();
  const navigate = useNavigate();

  if (!needBrand) {
    return isAuthenticated ? (
      <>{children}</>
    ) : (
      <Navigate
        to={`/login${location.search}`}
        state={{ from: location, query: location?.search }}
        replace
      />
    );
  }

  return isAuthenticated ? (
    <>{children}</>
  ) : (
    <Navigate
      to={`/login${location.search}`}
      state={{ from: location }}
      replace
    />
  );
};

export default ProtectedRoute;
