import React, { useState } from "react";

interface ISToggle extends React.HTMLAttributes<HTMLButtonElement> {
  size?: "sm" | "md";
  isToggled: boolean;
  setIsToggled: (p: boolean) => void;
}

function valueBySize(size: "sm" | "md", arr: string[]) {
  switch (size) {
    case "sm":
      return arr[0];
      break;
    case "md":
      return arr[1];
      break;
  }
}

function SToggle({ isToggled, setIsToggled, size = "md", ...props }: ISToggle) {
  return (
    <button
      type="button"
      className={`
        ${valueBySize(size, ["w-[36px]", "w-[44px]"])}
        ${valueBySize(size, ["h-[20px]", "h-[24px]"])}
        relative inline-flex h-[20px] w-[36px] flex-shrink-0 cursor-pointer rounded-full 
        transition-colors duration-200 ease-in-out focus:outline-none  focus:ring-transparent
        focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 ${
          isToggled ? "bg-brand-600" : "bg-gray-100"
        }`}
      role="switch"
      aria-checked={isToggled}
      onClick={() => setIsToggled(!isToggled)}
      {...props}
    >
      <span
        aria-hidden="true"
        className={`
            ${valueBySize(size, ["w-[16px]", "w-[20px]"])}
        ${valueBySize(size, ["h-[16px]", "h-[20px]"])}
            top-[50%] absolute translate-y-[-50%] pointer-events-none inline-block transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${
              isToggled
                ? `${valueBySize(size, ["translate-x-[18px]", "translate-x-[22px]"])}`
                : "translate-x-[2px]"
            }`}
      ></span>
    </button>
  );
}

export default SToggle;
