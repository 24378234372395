import { useQuery } from "@tanstack/react-query";
import { ICheckRegisterInvitationCodeCOE } from "../../../utils/interfaces/IAccount";
import { IBase } from "../../../utils/interfaces/IBase";
import { makeRequest } from "../../../api/auth/axios";

export const useGetCanUserRegisterWithInvite = (
  d: ICheckRegisterInvitationCodeCOE
) => {
  return useQuery<IBase<null>>({
    queryKey: ["useGetCanUserRegisterWithInvite"],
    queryFn: async () => {
      const response = await makeRequest.get(
        `/User/CanUserRegisterWithInvite?&code=${d.code}`
      );
      return response as IBase<null>;
    },
    enabled: !!d.code,
  });
};
