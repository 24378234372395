import React, { useEffect, useState } from "react";
import { useBrandStore } from "../../../store/brandStore";
import { generateShades } from "../functions/generateColorShades";
import ColorsGrid from "../ColorsGrid";
import ColorsGridSkeleton from "../ColorsGridSkeleton";
import SButton from "../../../design-system/SButton";
import { faEdit, faSparkles } from "@fortawesome/pro-regular-svg-icons";
import ColorsEditModal from "../modals/ColorsEditModal";
import { BrandingSectionName } from "../../../utils/interfaces/IBrand";
import { useAccountStore } from "../../../store/accountStore";
import { OrganizationUserRole } from "../../../utils/enums/Enums";

function Colors({
  setConfirmRegenerate,
}: {
  setConfirmRegenerate: (value: BrandingSectionName | false) => void;
}) {
  const [primaryShades, setPrimaryShades] = useState<string[]>([]);
  const [secondaryShades, setSecondaryShades] = useState<string[]>([]);
  const { currentBrand } = useBrandStore();
  useEffect(() => {
    if (currentBrand?.brandingData?.brandColors?.primary) {
      setPrimaryShades(
        generateShades(currentBrand.brandingData.brandColors?.primary)
      );
    }
    if (currentBrand?.brandingData?.brandColors?.secondary) {
      setSecondaryShades(
        generateShades(currentBrand.brandingData.brandColors?.secondary)
      );
    }
  }, [currentBrand]);
  const { personalInfo } = useAccountStore();
  const [isColorsEditModalOpen, setIsColorsEditModalOpen] = useState(false);
  return (
    <>
      {isColorsEditModalOpen && (
        <ColorsEditModal
          isOpen={isColorsEditModalOpen}
          onClose={() => setIsColorsEditModalOpen(false)}
        />
      )}
      <div className="group bg-base-white border-[1px] rounded-[8px] border-gray-200">
        <div className="bg-gray-50 rounded-t-[8px] px-[32px] py-[20px] flex justify-between items-center max-md:py-[16px] max-md:px-[20px] max-md:gap-[15px]">
          <div>
            <p className="display-xs-b text-graycool-800">🎨 Colors</p>
            <p className="text-md text-graycool-600 mt-[2px]">
              Which colors describe your brand?
            </p>
          </div>
          {personalInfo?.userRole !== OrganizationUserRole.VIEWER && (
            <div className="flex gap-[12px]">
              <SButton
                onClick={() => setConfirmRegenerate("Colors")}
                lIcon={faSparkles}
                type="secondaryColor"
                className="group-hover:opacity-100 group-hover:pointer-events-auto opacity-0 pointer-events-none max-md:opacity-100 max-md:pointer-events-auto"
              >
                <span className="max-md:hidden">Regenerate</span>
              </SButton>
              <SButton
                onClick={() => setIsColorsEditModalOpen(true)}
                type="secondaryGray"
                className="group-hover:opacity-100 group-hover:pointer-events-auto opacity-0 pointer-events-none max-md:opacity-100 max-md:pointer-events-auto"
                icon={faEdit}
              />
            </div>
          )}
        </div>
        <div className="px-[32px] py-[24px] bg-base-white max-md:py-[16px] max-md:px-[20px]">
          <div className="flex flex-col gap-[16px]">
            {currentBrand?.isBrandDataGenerated &&
            currentBrand?.brandingData?.brandColors?.primary ? (
              <ColorsGrid
                title="Primary"
                baseColor={
                  currentBrand.brandingData.brandColors?.primary
                }
                shades={primaryShades}
              />
            ) : (
              <ColorsGridSkeleton />
            )}
            {currentBrand?.isBrandDataGenerated &&
            currentBrand?.brandingData?.brandColors?.secondary ? (
              <ColorsGrid
                title="Secondary"
                baseColor={
                  currentBrand.brandingData.brandColors?.secondary
                }
                shades={secondaryShades}
              />
            ) : (
              <ColorsGridSkeleton />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Colors;
