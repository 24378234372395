import { addDays, addMonths, addWeeks } from "date-fns";
import { useCalendarStore } from "../../../store/calendarStore";
import { useAccountStore } from "../../../store/accountStore";
import { formatInTimeZone } from "date-fns-tz";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faPinterest,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { SocialNetworkType } from "../../../utils/enums/Enums";
import { useGetPostById } from "../../../api/posts/getById";
import { useState } from "react";
import { makeRequest } from "../../../axios";
import { useCreationHelperFunctions } from "../../creation/hooks/useCreationHelperFunctions";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { ISinglePostVM } from "../../../utils/interfaces/IPost";

export const useCalendarHelper = () => {
  const { currentDate, setCurrentDate, activeView } = useCalendarStore();
  const { personalInfo } = useAccountStore();
  const { handleOpenPost } = useCreationHelperFunctions();
  const convertToISOAndChangeTimezone = (date: Date): string => {
    const userTimeZone =
      personalInfo?.timezone ||
      Intl.DateTimeFormat().resolvedOptions().timeZone;
    const pattern = "yyyy-MM-dd'T'HH:mm:ssxxx";
    return formatInTimeZone(date, userTimeZone, pattern);
  };

  const getIcon = (soc: SocialNetworkType) => {
    switch (soc) {
      case SocialNetworkType.FACEBOOK:
        return faFacebook;
      case SocialNetworkType.INSTAGRAM:
        return faInstagram;
      case SocialNetworkType.LINKEDIN:
        return faLinkedin;
      case SocialNetworkType.TWITTER:
        return faTwitter;
      case SocialNetworkType.PINTEREST:
        return faPinterest;
    }
  };

  const nextMonth = () => {
    setCurrentDate(addMonths(currentDate, 1));
  };
  const prevMonth = () => {
    setCurrentDate(addMonths(currentDate, -1));
  };
  const nextWeek = () => {
    setCurrentDate(addWeeks(currentDate, 1));
  };
  const prevWeek = () => {
    setCurrentDate(addWeeks(currentDate, -1));
  };
  const nextDay = () => {
    setCurrentDate(addDays(currentDate, 1));
  };
  const prevDay = () => {
    setCurrentDate(addDays(currentDate, -1));
  };
  const handleNext = () => {
    switch (activeView) {
      case "month":
        nextMonth();
        break;
      case "week":
        nextWeek();
        break;
      case "day":
        nextDay();
        break;
    }
  };
  const handlePrev = () => {
    switch (activeView) {
      case "month":
        prevMonth();
        break;
      case "week":
        prevWeek();
        break;
      case "day":
        prevDay();
        break;
    }
  };

  const openPostFromCalendar = async (postId: number) => {
    await makeRequest.get(`/Posts/${postId}`).then((res) => {
      handleOpenPost({
        postId: res.data.id,
        onSocialNetworkSelectorOpen: () => undefined,
      });
    });
  };

  return {
    openPostFromCalendar,
    handleNext,
    getIcon,
    convertToISOAndChangeTimezone,
    handlePrev,
  };
};
