import { FieldToolTip } from "../../components/account/FieldToolTip";
import { OrganizationType } from "../../utils/enums/Enums";
import { BrandsConnectSelectorStyles } from "./config/BrandsConnectSelectorStyles";
import { useConnectBrands } from "./hooks/useConnectBrands";
import {
  DropdownIndicator,
  MultiValue,
  OptionComponent,
} from "./ReactSelectComponents";
import Select, { ActionMeta } from "react-select";

export const BrandsConnectSelector = ({
  className,
}: {
  className?: string;
}) => {
  const {
    getPersonalBrands,
    getCompanyBrands,
    selectedBrands,
    setSelectedBrands,
    currentBrand,
  } = useConnectBrands();

  return (
    <div
      className={`${className} flex items-center justify-between py-[20px] border-b-[1px] border-gray-200 max-md:flex-col max-md:items-start max-md:gap-[10px] max-md:py-[12px]`}
    >
      <div className="flex gap-1 relative w-[140px] z-20">
        <span className="text-left whitespace-nowrap text-sm-sb text-gray-700">
          Associated{" "}
          {currentBrand?.data.organizationType !== OrganizationType.PERSONAL
            ? "Personal "
            : "Company "}
          Brands
        </span>
        <FieldToolTip
          id={"personal-brands-tooltip"}
          toolTipContent={
            "You can connect your personal brands to the company brand"
          }
        />
      </div>
      <div className="flex-grow flex justify-center items-center w-full flex-col relative max-md:items-start">
        <Select
          components={{
            DropdownIndicator,
            MultiValue,
            Option: OptionComponent,
          }}
          styles={BrandsConnectSelectorStyles}
          placeholder="Search brands"
          menuPlacement="top"
          isMulti
          backspaceRemovesValue={true}
          hideSelectedOptions={false}
          name="brand_connections"
          onChange={(newValue: any, actionMeta: ActionMeta<any>) => {
            if (actionMeta.action === "select-option") {
              setSelectedBrands(newValue);
            }
            if (actionMeta.action === "remove-value") {
              setSelectedBrands(
                selectedBrands.filter(
                  (brand) => brand.value !== actionMeta.removedValue.value
                )
              );
            }
            if (actionMeta.action === "clear") {
              setSelectedBrands([]);
            }
          }}
          options={
            currentBrand?.data.organizationType === OrganizationType.PERSONAL
              ? getCompanyBrands()
              : getPersonalBrands()
          }
          value={selectedBrands}
          closeMenuOnSelect={false}
          className="basic-multi-select max-sm:w-full max-md:w-3/4 w-1/2"
          classNamePrefix="select"
        />
      </div>
      <div className="flex-none w-1/4 max-md:hidden"></div>
    </div>
  );
};
