import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IAccountCOE, IAccountVM } from "../../utils/interfaces/IAccount";
import { makeRequest } from "../auth/axios";
import { IBase } from "../../utils/interfaces/IBase";

export const usePutAccount = () => {
  const queryClient = useQueryClient();
  return useMutation<IBase<IAccountVM>, any, IAccountCOE>({
    mutationFn: (userInfo: IAccountCOE) => {
      return makeRequest.put("/Account", { ...userInfo });
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["useGetUser"] });
    },
  });
};
