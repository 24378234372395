export enum KlipyType {
  GIF = "gif",
  CLIP = "clip",
}

export interface IMediaVM {
  result: boolean;
  data: {
    current_page: number;
    has_next: boolean;
    per_page: number;
    data: ISingleMediaVM[];
  };
}

export interface IBaseMediaVM {
  blur_preview: string;
  slug: string;
  title: string;
  type: KlipyType;
}

export interface IClipVM extends IBaseMediaVM {
  type: KlipyType.CLIP;
  file: {
    gif: string;
    mp4: string;
    webp: string;
  };
  file_meta: {
    gif: { width: number; height: number };
    mp4: { width: number; height: number };
    webp: { width: number; height: number };
  };
  url: string;
}

export interface IGifVM extends IBaseMediaVM {
  type: KlipyType.GIF;
  file: IGifExtensionFileVM;
  id: string;
}

export type ISingleMediaVM = IClipVM | IGifVM;

export interface IGifExtensionFileVM {
  hd: IGifExtensions;
  md: IGifExtensions;
  sm: IGifExtensions;
  xs: IGifExtensions;
}
export interface IGifExtensions {
  gif: IGifFileVM;
  mp4: IGifFileVM;
  webp: IGifFileVM;
  jpg: IGifFileVM;
}
export interface IGifFileVM {
  height: number;
  size: number;
  width: number;
  url: string;
}
