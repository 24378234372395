import { useEffect, useState } from "react";
import { ICopilotMessage } from "../../../../../utils/copilot-base/ICopilotBase";

export const useThoughtStatus = (msg: ICopilotMessage) => {
  const [thoughts, setThoughts] = useState<null | string>(null);
  useEffect(() => {
    let thoughtSwitcher: ReturnType<typeof setInterval> | undefined = undefined;
    if (!msg.content) {
      setThoughts("Thinking...");
      thoughtSwitcher = setInterval(() => {
        setThoughts((prev) => {
          if (prev === "Thinking...") return "Analyzing...";
          if (prev === "Analyzing...") return "Creating...";
          return "Thinking...";
        });
      }, 4000);
    } else {
      clearInterval(thoughtSwitcher);
      setThoughts(null);
    }

    return () => {
      clearInterval(thoughtSwitcher);
      setThoughts(null);
    };
  }, [msg]);

  return { thoughts }
};
