import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { makeRequest } from "../../auth/axios";
import { ITeamUserVM } from "../../../utils/interfaces/ITeamInvitation";
import { ITeamMemberUpdate } from "../../../utils/interfaces/ITeam";

export const useUpdateMemberRole = () => {
  const queryClient = useQueryClient();
  return useMutation<IBaseData<ITeamUserVM>, Error, ITeamMemberUpdate>({
    mutationFn: (data) => {
      return makeRequest.put("/WorkspaceUsers/Role", data);
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["useGetActiveMembers"] });
      queryClient.invalidateQueries({ queryKey: ["useGetWorkspaceUsers"] });
    },
  });
};
