import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IBaseData } from "../../utils/interfaces/IBase";
import { ISinglePostVM } from "../../utils/interfaces/IPost";
import { makeRequest } from "../auth/axios";
import { useCreationStore } from "../../store/creationStore";

export const usePatchPost = () => {
  const queryClient = useQueryClient();
  const { setPostUpdating } = useCreationStore();
  return useMutation<
    IBaseData<ISinglePostVM>,
    Error,
    { postId: number; value: any; path: string }
  >({
    mutationFn: ({ postId, value, path }) =>
      makeRequest.patch(`/Posts/${postId}?patch`, [
        {
          value,
          path,
          op: "replace",
        },
      ]),
    onMutate: () => {
      setPostUpdating(true);
    },
    onSettled: () => {
      setPostUpdating(false);
    },
    onSuccess: async (res) => {
      await queryClient.invalidateQueries({
        queryKey: ["useGetPost", res.data.id],
      });
    },
    onError: () => {
      setPostUpdating(false);
    },
  });
};
