import React from "react";

export interface WorkspaceMembersSkeletonProps {
  className?: string;
}

function WorkspaceMembersSkeleton({
  className,
}: WorkspaceMembersSkeletonProps) {
  return (
    <div className={`${className} w-full bg-white rounded-[12px] shadow-xs`}>
      <table className="w-full animate-pulse">
        <thead className="border-b border-gray-200">
          <tr className="text-left">
            <th className="px-[24px] py-[16px] w-7/12 text-xs-m text-gray-600">
              Name
            </th>
            <th className="py-[16px] w-2/12 text-xs-m text-gray-600">Role</th>
            <th className="py-[16px] w-2/12 text-xs-m text-gray-600">Brands</th>
            <th className="p-2 w-min text-left"></th>
          </tr>
        </thead>
        <tbody>
          {[1, 2, 3, 4, 5].map((_, index) => (
            <tr key={index} className="border-b border-gray-200">
              <td className="px-[24px] py-[16px] w-7/12">
                <div className="flex items-center">
                  <div className="w-[40px] h-[40px] rounded-full bg-gray-300 mr-[12px]"></div>
                  <div className="flex flex-col">
                    <span className="h-4 bg-gray-300 rounded w-24 mb-2"></span>
                    <span className="h-3 bg-gray-200 rounded w-32"></span>
                  </div>
                </div>
              </td>
              <td className="py-[16px] w-2/12">
                <span className="h-3 bg-gray-200 rounded w-24"></span>
              </td>
              <td className="py-[16px] w-2/12">
                <div className="flex items-center">
                  {[1, 2, 3, 4, 5].map((_, index) => (
                    <div
                      key={index}
                      className="w-[24px] h-[24px] bg-gray-300 rounded-full mr-[-5px]"
                    ></div>
                  ))}
                  <div className="w-[24px] h-[24px] bg-gray-200 rounded-full flex items-center justify-center border-[1px] border-white">
                    <span className="text-xs-sb text-gray-600">+0</span>
                  </div>
                </div>
              </td>
              <td className="p-[16px] w-min flex items-center gap-[2px]">
                <div className="w-[24px] h-[24px] bg-gray-300 rounded-full"></div>
                <div className="w-[24px] h-[24px] bg-gray-300 rounded-full"></div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default WorkspaceMembersSkeleton;
