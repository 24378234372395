import { ILibraryProps } from "../../../pages/Library";

export interface IHandleSelectClickProps {
  e: any;
  handleSelectItem: (
    id: number,
    isFolder: boolean,
    multiple?: { id: number; isFolder: boolean }[]
  ) => void;
  item: ILibraryProps;
  items: ILibraryProps[];
  selectedItems: { id: number; isFolder: boolean }[];
  folders?: boolean;
}

export const handleSelectClick = ({
  e,
  handleSelectItem,
  item,
  selectedItems,
  items,
  folders = false,
}: IHandleSelectClickProps) => {
  if (!handleSelectItem) return;
  handleSelectItem(item.id, !item.extension);
  if (e && e.shiftKey && selectedItems.length > 0) {
    if (selectedItems[0].isFolder && item.cardType !== "Folder") return;
    if (!selectedItems[0].isFolder && item.cardType === "Folder") return;
    const firstSelected = selectedItems[0].id;
    const inBetweenIds = items
      .filter((asset) => {
        if (item.id < firstSelected) {
          return asset.id < firstSelected && asset.id > item.id;
        }
        if (item.id > firstSelected) {
          return asset.id > firstSelected && asset.id < item.id;
        }
      })
      .map((item) => ({ id: item.id, isFolder: folders }));
    handleSelectItem(item.id, folders, inBetweenIds);
  }
};
