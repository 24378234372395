import React from "react";

function TableTrItemSkeleton({ index }: { index: number }) {
  return (
    <tr
      className={`bg-base-white text-left relative ${index !== 4 && "border-b-[1px]"} border-b-gray-200`}
    >
      <td className="px-[24px] py-[18px] flex gap-[12px]">
        <div
          role="status"
          className="w-full animate-pulse relative flex gap-[12px] items-center"
        >
          <div className="h-[40px] w-[40px] bg-gray-300 rounded-full"></div>
          <div className="flex flex-col gap-[6px]">
            <div className="h-[12px] w-[90px] bg-gray-300 rounded-full"></div>
            <div className="h-[12px] w-[140px] bg-gray-300 rounded-full"></div>
          </div>
        </div>
      </td>
      <td>
        <div
          role="status"
          className="w-full animate-pulse relative flex gap-[12px] items-center py-[12px] flex-wrap"
        >
          <div className="h-[40px] w-[200px] bg-gray-300 rounded-full"></div>
          <div className="h-[40px] w-[200px] bg-gray-300 rounded-full"></div>
        </div>
      </td>
    </tr>
  );
}

export default TableTrItemSkeleton;
