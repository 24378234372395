import { useNavigate } from "react-router-dom";
import { useGetCurrentPackage } from "../api/subscriptions/current/get";
import { useManageSubscription } from "../api/subscriptions/manage/get";
import { usePutSubscription } from "../api/subscriptions/put";
import { useGetCurrentWorkspace } from "../api/workspaces/current/get";
import { useAccountStore } from "../store/accountStore";
import { useGetInvoices } from "../api/subscriptions/invoices/get";
import { useGetWorkspaceUsage } from "../api/workspaces/limits/get";
import { TeamPackageStatus } from "../utils/enums/Enums";
import { toast } from "./toast";

export const useSubscriptionActions = () => {
  const { data: currentPlan, isLoading: isCurrentPlanLoading } =
    useGetCurrentPackage();
  const { data: invoices, isLoading: isWorkspaceInvoicesLoading } =
    useGetInvoices();
  const { data: limits } = useGetWorkspaceUsage();
  const { mutateAsync: updateSubscription } = usePutSubscription();
  const { personalInfo } = useAccountStore();
  const { data: currentWorkspace } = useGetCurrentWorkspace();
  const { data: currentSubscription } = useGetCurrentPackage();
  const { data: manageSubscription, isSuccess } = useManageSubscription();

  const activatePlan = () => {
    if (!currentSubscription) return;
    if (currentPlan && personalInfo) {
      if (
        currentPlan.data.isAppsumo &&
        currentPlan.data.package.appsumoChangePackageUrl
      ) {
        window.open(currentPlan.data.package.appsumoChangePackageUrl, "_blank");
      } else {
        if (currentWorkspace)
          updateSubscription({
            workspaceId: currentSubscription.data.workspaceId,
            packageId: currentSubscription.data.package.id,
          }).then((res) => {
            window.open(res.data.data, "_self");
          });
      }
    }
  };

  const managePlan = () => {
    if (!isSuccess) {
      toast("Only owner can manage the plan", "error");
      return;
    }
    window.open(manageSubscription?.data.data, "_self");
  };

  const upgradePlan = () => {
    if (!isSuccess) {
      toast("Only owner can upgrade the plan", "error");
      return;
    }
    navigate("/update-plan");
  };
  const navigate = useNavigate();

  const handleClick = () => {
    if (
      currentPlan?.data.status === TeamPackageStatus.ACTIVE &&
      !currentPlan?.data.isTrial
    ) {
      return upgradePlan();
    }
    if (
      currentPlan?.data.status === TeamPackageStatus.PAUSED ||
      currentPlan?.data.isTrial
    ) {
      return managePlan();
    }
    if (currentPlan?.data.status === TeamPackageStatus.CANCELED) {
      return activatePlan();
    }
  };

  return {
    activatePlan,
    managePlan,
    upgradePlan,
    currentPlan,
    isCurrentPlanLoading,
    invoices,
    isWorkspaceInvoicesLoading,
    limits,
    handleClick,
  };
};
