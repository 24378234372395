import React from "react";
import Form from "../components/auth/confirmForm/Form";

const EmailConfirm = () => {
  return (
    <div className="w-full h-[70dvh] flex justify-center items-center">
      <Form />
    </div>
  );
};

export default EmailConfirm;
