import { faImage } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const FramesPreviewAssetSkeleton = () => {
  return (
    <div className="w-20 flex animate-pulse items-center justify-center h-20 rounded-lg bg-brand-200">
      <FontAwesomeIcon className="text-brand-300 text-3xl" icon={faImage} />
    </div>
  );
};
