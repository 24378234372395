import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { ISingleFolderVM } from "../../../utils/interfaces/IFolders";
import { makeRequest } from "../../auth/axios";
import { Dispatch, SetStateAction } from "react";
import { ISingleFoldersAndFilesVM } from "../../../utils/interfaces/IAssets";


export const useDeleteFolder = ({setFoldersAndFiles, setPage}: {
  setFoldersAndFiles?: Dispatch<SetStateAction<ISingleFoldersAndFilesVM[]>>;
  setPage?: Dispatch<SetStateAction<number>>;
}) => {
  const queryClient = useQueryClient();
  return useMutation<IBaseData<ISingleFolderVM>, Error, {id: number}>({
    mutationFn: ({id}) => makeRequest.delete(`/Folders/${id}`),
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["useGetFoldersAndFiles"] });
      setPage && setPage(1);
      setFoldersAndFiles && setFoldersAndFiles([]);
    },
  });
};
