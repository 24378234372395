export const FolderRightSVG = ({ stroke }: { stroke: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      className=" stroke-gray-700 hover:stroke-gray-800"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.44"
        d="M9 13h6m0 0l-2-2m2 2l-2 2m-.937-8.937l-.126-.126c-.346-.346-.519-.519-.72-.642a2.001 2.001 0 00-.579-.24C10.409 5 10.165 5 9.676 5H6.2c-1.12 0-1.68 0-2.108.218a2 2 0 00-.874.874C3 6.52 3 7.08 3 8.2v7.6c0 1.12 0 1.68.218 2.108a2 2 0 00.874.874C4.52 19 5.08 19 6.2 19h11.6c1.12 0 1.68 0 2.108-.218a2 2 0 00.874-.874C21 17.48 21 16.92 21 15.8v-5.6c0-1.12 0-1.68-.218-2.108a2 2 0 00-.874-.874C19.48 7 18.92 7 17.8 7h-3.475c-.489 0-.733 0-.963-.055-.204-.05-.4-.13-.579-.24-.201-.123-.374-.296-.72-.642z"
      ></path>
    </svg>
  );
};
