export const SuggestionSkeleton = () => {
  return (
    <div
      className={`cursor-default animate-pulse flex gap-3 items-start border-2 bg-gray-50 transition-all p-3 rounded-lg`}
    >
      <div className="w-5 h-5 bg-gray-200 rounded-full" />
      <div className=" flex flex-col h-full w-full gap-2">
        <div className="w-full h-3 bg-gray-100 rounded-full" />
        <div className="w-full h-3 bg-gray-100 rounded-full" />
      </div>
    </div>
  );
};
