import { useEffect } from "react";
import { ISinglePostVM } from "../../../utils/interfaces/IPost";
import { tabItems } from "../utils/tabItems";
import { getQuery } from "../../../hooks/functions/queries";

export const useSetDefaultSection = ({
  setSelected,
  setPage,
  setPosts,
}: {
  setSelected: (value: number) => void;
  setPage: (value: number) => void;
  setPosts: (value: ISinglePostVM[]) => void;
}) => {
  const querySection = getQuery("section") || getQuery("subsection");
  useEffect(() => {
    if (querySection) {
      const tabItem = tabItems.find(
        (item) => item.text?.toLocaleLowerCase() === querySection
      );
      setSelected(tabItem?.index || 0);
      setPosts([]);
      setPage(1);
    }
  }, [querySection, setPage, setPosts, setSelected]);
};
