import React from "react";

function TextsSectionSkeleton({ count }: { count: number }) {
  return (
    <div
      role="status"
      className="w-full animate-pulse relative rounded-b-[8px] overflow-hidden"
    >
      <div className="w-full h-[98px] bg-gray-300 gap-[16px] px-[32px] py-[20px]">
        <div className="h-[16px] w-[30%] bg-gray-400 rounded-[12px]"></div>
        <div className="h-[12px] w-[20%] mt-[14px] bg-gray-400 rounded-[12px]"></div>
      </div>
      <div className="w-full flex flex-col gap-[12px] bg-gray-200 px-[32px] py-[24px]">
        {count &&
          Array.from({ length: count }).map((_, index) => (
            <div>
              <div className="h-[16px] w-[120px] bg-gray-500 rounded-[12px]"></div>
              <div className="h-[10px] w-[100%] mt-[14px] bg-gray-400 rounded-[12px]"></div>
              <div className="h-[10px] w-[80%] mt-[14px] bg-gray-400 rounded-[12px]"></div>
              <div className="h-[10px] w-[60%] mt-[14px] bg-gray-400 rounded-[12px]"></div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default TextsSectionSkeleton;
