import SButton from "../../design-system/SButton";
import {
  faCheckCircle,
  faInfoCircle,
} from "@fortawesome/pro-regular-svg-icons";
import React, { useState } from "react";
import STooltip from "../../design-system/STooltip";
import SModal from "../../design-system/SModal";
import RequestAccountDeleteModal from "../auth/accountDelete/requestAccountDeleteModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function RequestDeleteAccount() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <RequestAccountDeleteModal isOpen={isOpen} setIsOpen={setIsOpen} />
      <div className="mt-[20px]">
        <p className="text-sm-sb text-gray-700">Request Delete</p>
        <p className="text-sm text-gray-600">
          Your request will be processed and account will be deleted soon after
          request.
        </p>
        <SButton
          type="tertiaryGray"
          className="mt-[16px] text-white !bg-error-600 hover:!bg-error-700 hover:text-white"
          rIcon={faInfoCircle}
          size="sm"
          onClick={() => setIsOpen(true)}
        >
          Delete Account
        </SButton>
      </div>
    </>
  );
}

export default RequestDeleteAccount;
