import React, { useEffect, useState } from "react";
//@ts-ignore
import jason from "../.../../../../../assets/images/agents/jason.png";
import { ICopilotMessage } from "../../../../utils/copilot-base/ICopilotBase";
import AgentInfo from "./AgentInfo";
import { AnimatePresence, motion } from "framer-motion";
import { useThoughtStatus } from "./hooks/useThoughtStatus";
import Markdown from "react-markdown";
function AIMessage({
  handleGetStory,
  index,
  msg,
  messages,
  sendMessage,
}: {
  messages: ICopilotMessage[];
  index: number;
  sendMessage: (t: string) => void;
  handleGetStory: (str: string) => void;
  msg: ICopilotMessage;
}) {
  const { thoughts } = useThoughtStatus(msg);
  return (
    <>
      <div className="h-fit flex gap-[12px] relative max-md:gap-[4px]">
        <div>
          <AgentInfo
            logo={jason}
            agentName={"Jason"}
            agentDescription={
              "Hi there, I'm Jason!" +
              "\n \n" +
              "- I can format, change, adapt text\n" +
              "- I can generate images\n" +
              "- I can work on multiple social media\n" +
              "- I know your brand already\n" +
              "- I can recognize image and video content\n" +
              "- I can perform actions while talking with you \n" +
              "- I can open links you send me and gather info" +
              "\n \n" +
              "Just tell me what you need, and I'm happy to help!"
            }
            agentRole={"Content Creator"}
          >
            <img
              src={jason}
              alt="jason-avatar"
              className="min-w-[40px] h-[40px] max-md:min-w-[30px] max-md:h-[30px] aspect-square object-cover rounded-full cursor-pointer"
            />
          </AgentInfo>
        </div>

        <div className="flex flex-col gap-1">
          <div className="">
            <AnimatePresence mode="wait">
              {thoughts !== null && (
                <motion.p
                  key={thoughts}
                  initial={{ x: -10, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  exit={{ x: 20, opacity: 0 }}
                  transition={{ duration: 0.3 }}
                  className="text-xs text-gray-400"
                >
                  {thoughts}
                </motion.p>
              )}
            </AnimatePresence>
          </div>
          <div
            className={`bg-gray-50 flex flex-col justify-center items-start border rounded-[8px] rounded-tl-[0] border-gray-200 py-[10px] px-[14px] max-w-[456px] 
            ${!msg.content ? "w-fit" : "w-full"}`}
          >
            {!msg.content ? (
              <div className="typing__animation">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
            ) : (
              <>
                {msg.content &&
                  (msg.content.Type === "CopilotMessage" ||
                    msg.content?.Type === "TextVersions") &&
                  msg.content.Message.length > 0 && (
                    <pre className="whitespace-pre-wrap break-words text-md text-gray-900 max-md:text-sm">
                      <Markdown>{msg.content.Message.trim()}</Markdown>
                    </pre>
                  )}
                {/*{msg.content && msg.content.Type === "Brief" && (*/}
                {/*  <BriefSelector*/}
                {/*    handleGetStory={handleGetStory}*/}
                {/*    index={index}*/}
                {/*    messages={messages}*/}
                {/*    sendMessage={sendMessage}*/}
                {/*    content={msg.content}*/}
                {/*  />*/}
                {/*)}*/}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AIMessage;
