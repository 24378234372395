import React from "react";
import Form from "../components/auth/passwordReset/Form";

function ForgottedPasswordReset() {
  return (
    <div className="w-[100dvw] h-[100dvh] flex justify-center items-center">
      <Form />
    </div>
  );
}

export default ForgottedPasswordReset;
