import { useMutation } from "@tanstack/react-query";
import { IBase } from "../../../utils/interfaces/IBase";
import { IAccountGAClientIDCOE } from "../../../utils/interfaces/IAccount";
import { makeRequest } from "../axios";

export const useUpdateGAClientId = () => {
  return useMutation<
    IBase<IAccountGAClientIDCOE>,
    Error,
    IAccountGAClientIDCOE
  >({
    mutationFn: (clientId: IAccountGAClientIDCOE) =>
      makeRequest.put("/Account/UpdateGaClientId", { ...clientId }),
  });
};
