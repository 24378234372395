import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../axios";
import { IBaseData } from "../../utils/interfaces/IBase";
import {
  IWorkspaceUpdate,
  IWorkspaceVM,
} from "../../utils/interfaces/IWorkspace";

export const usePutWorkspace = () => {
  const queryClient = useQueryClient();

  return useMutation<
    IBaseData<IWorkspaceVM>,
    any,
    { workspaceId: number; data: IWorkspaceUpdate }
  >({
    mutationFn: ({ workspaceId, data }) =>
      makeRequest.put(`/Workspaces/${workspaceId}`, { ...data }),
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["useGetWorkspaces"] });
      queryClient.invalidateQueries({ queryKey: ["useGetCurrentWorkspace"] });
    },
  });
};
