import React from "react";
import SModal from "../../../design-system/SModal";
import { ICopilotSessionVM } from "../../../utils/interfaces/ICopilot";
import { useDeleteCopilotSession } from "../../../api/copilot/session/delete";
import { useCopilotStore } from "../../../store/copilotStore";

function DeleteAgreementModal({
  isOpen,
  setIsOpen,
  actionSession,
}: {
  actionSession: ICopilotSessionVM | null;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { setActiveSession } = useCopilotStore();
  const { mutateAsync: deleteSession, isPending } = useDeleteCopilotSession();
  const handleDelete = () => {
    if (actionSession)
      deleteSession({ id: actionSession.id }).then((res) => {
        setActiveSession(null);
        setIsOpen(false);
      });
  };
  return (
    <SModal
      title={"Delete session"}
      subtitle={
        "Are you sure you want to delete this session? This action cannot be undone."
      }
      type="error"
      isOpen={isOpen}
      setIsOpen={(p) => setIsOpen(p)}
      handleSubmit={() => handleDelete()}
      handleCancel={() => setIsOpen(false)}
      actionButtonText={"Remove"}
      actionButtonProps={{
        isLoading: isPending,
        className: "w-[400px]",
      }}
    />
  );
}

export default DeleteAgreementModal;
