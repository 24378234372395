import { makeRequest } from "../../../axios";
import { useQuery } from "@tanstack/react-query";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { IWorkspaceVM } from "../../../utils/interfaces/IWorkspace";

export const useGetCurrentWorkspace = () => {
  return useQuery<IBaseData<IWorkspaceVM>>({
    queryKey: ["useGetCurrentWorkspace"],
    queryFn: async () => {
      const response = await makeRequest.get("/Workspaces/GetCurrentWorkspace");
      return response as IBaseData<IWorkspaceVM>;
    },
  });
};
