import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { makeRequest } from "../../auth/axios";
import { InvitationStatus } from "../../../utils/enums/Enums";
import { IInviteVM } from "../../../utils/interfaces/ITeamInvitation";

export const useRespondInvitation = () => {
  const queryClient = useQueryClient();
  return useMutation<IBaseData<IInviteVM>, Error,     {
    id: number;
    statusId: InvitationStatus;
  }>({
    mutationFn: ({id, statusId}) => {
      return makeRequest.put(`/WorkspaceUsers/Invitations/${id}?status=${statusId}`);
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["useGetInvitationPending"] });
      queryClient.invalidateQueries({ queryKey: ["useGetActiveMembers"] });
    },
  });
};
