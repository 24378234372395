import React from "react";

function ColorsGridSkeleton() {
  return (
    <div className={`w-full animate-pulse`}>
      <div className="w-[48px] h-[16px] rounded-full bg-gray-300 " />
      <div className="flex mt-[8px] gap-[8px]">
        <div className="w-[60%] h-[80px] rounded-[8px] bg-gray-200 " />
        <div className="w-[40%] h-[80px] flex flex-col gap-[8px]">
          <div className="grid grid-cols-3 gap-[8px] h-[100%]">
            {[1, 2, 3].map((item, i) => (
              <div className="w-full h-full rounded-[8px] bg-gray-200 " />
            ))}
          </div>
          <div className="grid grid-cols-3 gap-[8px] h-[100%]">
            {[1, 2, 3].map((item, i) => (
              <div className="w-full h-full rounded-[8px] bg-gray-200 " />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ColorsGridSkeleton;
