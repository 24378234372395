import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { Dispatch, SetStateAction } from "react";
import { IBreadcrumbsItem } from "../design-system/SBreadcrumbs";
import { ISingleFoldersAndFilesVM } from "../utils/interfaces/IAssets";

export interface ILibraryStoreInitialInterface {
  folderHistory: IBreadcrumbsItem[];
  setFolderHistory: Dispatch<SetStateAction<IBreadcrumbsItem[]>>;
  foldersAndFiles: ISingleFoldersAndFilesVM[];
  page: number;
  parentFolder: {
    id: number | null;
    title: string;
  } | null;
  selectedItems: { id: number; isFolder: boolean }[];
  setFoldersAndFiles: Dispatch<SetStateAction<ISingleFoldersAndFilesVM[]>>;
  setPage: Dispatch<SetStateAction<number>>;
  setParentFolder: Dispatch<
    SetStateAction<{
      id: number | null;
      title: string;
    } | null>
  >;
  setSelectedItems: Dispatch<
    SetStateAction<{ id: number; isFolder: boolean }[]>
  >;
  uploadErrors?: string[] | null;
}

const initialState: ILibraryStoreInitialInterface = {
  folderHistory: [],
  foldersAndFiles: [],
  page: 1,
  parentFolder: null,
  selectedItems: [],
  setFolderHistory: () => {},
  setFoldersAndFiles: () => {},
  setPage: () => {},
  setParentFolder: () => {},
  setSelectedItems: () => [],
  uploadErrors: null,
};

const store = (set: any) => ({
  ...initialState,
  setFolderHistory: (
    folderHistory:
      | IBreadcrumbsItem[]
      | ((prevFolderHistory: IBreadcrumbsItem[]) => IBreadcrumbsItem[])
  ) =>
    set((state: ILibraryStoreInitialInterface) => ({
      folderHistory:
        typeof folderHistory === "function"
          ? folderHistory(state.folderHistory)
          : folderHistory,
    })),
  setFoldersAndFiles: (
    foldersAndFiles:
      | ISingleFoldersAndFilesVM[]
      | ((
          prevFoldersAndFiles: ISingleFoldersAndFilesVM[]
        ) => ISingleFoldersAndFilesVM[])
  ) =>
    set((state: ILibraryStoreInitialInterface) => ({
      foldersAndFiles:
        typeof foldersAndFiles === "function"
          ? foldersAndFiles(state.foldersAndFiles)
          : foldersAndFiles,
    })),
  setPage: (page: number | ((prevPage: number) => number)) =>
    set((state: ILibraryStoreInitialInterface) => ({
      page: typeof page === "function" ? page(state.page) : page,
    })),
  setParentFolder: (
    parentFolder:
      | { id: number | null; title: string }
      | null
      | ((
          prevParentFolder: { id: number | null; title: string } | null
        ) => { id: number | null; title: string } | null)
  ) =>
    set((state: ILibraryStoreInitialInterface) => ({
      parentFolder:
        typeof parentFolder === "function"
          ? parentFolder(state.parentFolder)
          : parentFolder,
    })),
  setSelectedItems: (
    selectedItems:
      | { id: number; isFolder: boolean }[]
      | ((
          prevSelectedItems: { id: number; isFolder: boolean }[]
        ) => { id: number; isFolder: boolean }[])
  ) =>
    set((state: ILibraryStoreInitialInterface) => ({
      selectedItems:
        typeof selectedItems === "function"
          ? selectedItems(state.selectedItems)
          : selectedItems,
    })),
    setUploadErrors: (uploadErrors?: string[] | null) => set({ uploadErrors }),
  reset: () => set(initialState),
});
export const useLibraryStore = create(devtools(store));
