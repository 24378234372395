import { usePostRegenerateBrand } from "../../../api/brand/regenerate/post";
import { useBrandStore } from "../../../store/brandStore";
import { BrandingSectionName } from "../../../utils/interfaces/IBrand";
import { closeAllToast, toast } from "../../../hooks/toast";

export const useBrandHelperFunctions = () => {
  const { mutateAsync: regenerateBrand, error: regenerateBrandError } =
    usePostRegenerateBrand();
  const { currentBrand } = useBrandStore();
  const fieldNameToStringMap: { [key in BrandingSectionName]: string } = {
    Colors: "Brand Colors",
    Tagline: "Brand Tagline",
    Persona: "Brand Persona",
    BrandVoice: "Brand Voice",
    BrandIdentity: "Brand Identity",
    BrandPromise: "Brand Promise",
    Audience: "Brand Audience",
    Typography: "Brand Typography",
  };
  const handleRegenerateSection = async (fieldName: BrandingSectionName) => {
    toast(`Regenerating ${fieldNameToStringMap[fieldName]}`, "loading");
    if (currentBrand)
      regenerateBrand({
        brandId: currentBrand.id,
        regenerationData: {
          about: null,
          brandingSectionName: fieldName || null,
        },
      })
        .then(() => {
          closeAllToast();
          toast(
            `${fieldNameToStringMap[fieldName]} Regenerated Successfully`,
            "success"
          );
        })
        .catch((error) => {
          closeAllToast();
          toast(`Error Regenerating ${fieldNameToStringMap[fieldName]}`);
        });
  };

  const handleRegenerateBrand = async ({
    newDescription,
    website,
  }: {
    newDescription?: string;
    website?: string;
  }): Promise<void> => {
    if (newDescription === currentBrand?.about) return;
    if (currentBrand)
      try {
        await regenerateBrand({
          brandId: currentBrand.id,
          regenerationData: {
            websiteToScrape: website,
            about: newDescription,
            brandingSectionName: undefined,
          },
        });
        await window.location.reload();
      } catch (err) {
        throw err;
      }
  };
  return { handleRegenerateBrand, handleRegenerateSection };
};
