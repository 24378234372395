import React, { useEffect, useState } from "react";
//@ts-ignore
import logo from "../../assets/images/logos/stori-s-square.svg";
import { useBrandStore } from "../../store/brandStore";
import SButton from "../../design-system/SButton";
import { AnimatePresence, motion } from "framer-motion";

const BrandCreatingLoader = ({
  onRegenerateBrandOpen,
}: {
  onRegenerateBrandOpen: () => void;
}) => {
  const { currentBrand } = useBrandStore();
  const [progressValue, setProgressValue] = useState(0);
  const LOADING_TEXTS = [
    "Analyzing your company description for key insights.",
    "Forming your brand archetype to capture your essence.",
    "Defining a unique tone of voice for your brand.",
    "Crafting a memorable tagline to represent your brand.",
    "Selecting primary and secondary colors that embody your brand.",
    "Designing typography that speaks to your audience.",
    "Compiling explanations to clarify your brand elements.",
    "Assembling your personalized brand book.",
    "Your brand is taking shape, and it's looking great!",
    "Analyzing your company description for key insights.",
    "Forming your brand archetype to capture your essence.",
    "Defining a unique tone of voice for your brand.",
    "Crafting a memorable tagline to represent your brand.",
    "Selecting primary and secondary colors that embody your brand.",
    "Designing typography that speaks to your audience.",
    "Compiling explanations to clarify your brand elements.",
    "Assembling your personalized brand book.",
    "Your brand is taking shape, and it's looking great!",
    "Analyzing your company description for key insights.",
    "Forming your brand archetype to capture your essence.",
    "Defining a unique tone of voice for your brand.",
    "Crafting a memorable tagline to represent your brand.",
    "Selecting primary and secondary colors that embody your brand.",
    "Designing typography that speaks to your audience.",
    "Compiling explanations to clarify your brand elements.",
    "Assembling your personalized brand book.",
    "Your brand is taking shape, and it's looking great!",
    "Analyzing your company description for key insights.",
    "Forming your brand archetype to capture your essence.",
    "Defining a unique tone of voice for your brand.",
    "Crafting a memorable tagline to represent your brand.",
    "Selecting primary and secondary colors that embody your brand.",
    "Designing typography that speaks to your audience.",
    "Compiling explanations to clarify your brand elements.",
    "Assembling your personalized brand book.",
    "Your brand is taking shape, and it's looking great!",
    "Analyzing your company description for key insights.",
    "Forming your brand archetype to capture your essence.",
    "Defining a unique tone of voice for your brand.",
    "Crafting a memorable tagline to represent your brand.",
    "Selecting primary and secondary colors that embody your brand.",
    "Designing typography that speaks to your audience.",
    "Compiling explanations to clarify your brand elements.",
    "Assembling your personalized brand book.",
    "Your brand is taking shape, and it's looking great!",
  ];
  const [currentLine, setCurrentLine] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentLine((prevLine) => (prevLine + 1) % LOADING_TEXTS.length);
    }, 6000);

    return () => clearInterval(timer);
  }, []);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setProgressValue((prevValue) => {
        if (prevValue < 100) {
          return prevValue + 1;
        } else {
          clearInterval(intervalId);
          return prevValue;
        }
      });
    }, 800);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="fixed inset-0 z-10 h-full flex flex-col items-center justify-center w-full bg-gray-fill-s backdrop-blur-sm">
      <div className="loader"></div>
      <div className="flex flex-col items-center justify-center gap-7">
        <img src={logo.toString()} className="loader-logo" alt="logo" />
        <div className="h-[65px] flex items-center justify-center">
          <AnimatePresence mode="wait">
            <motion.p
              key={currentLine}
              initial={{ x: -10, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: 20, opacity: 0 }}
              transition={{ duration: 0.3 }}
              style={{
                background:
                  "linear-gradient(135deg, #7b35cc, #8f58e9, #662cab)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontWeight: "bold",
              }}
              className="display-xs-b max-w-[600px] text-center text-gray"
            >
              {LOADING_TEXTS[currentLine]}
            </motion.p>
          </AnimatePresence>
        </div>
      </div>

      <div className="relative max-sm:w-[220px] w-[320px] h-4 my-[30px] bg-gray-200 rounded-lg">
        <div
          className="absolute top-0 left-0 h-full bg-gradient-to-r from-brand-500 via-amethyst-600 to-pink-500 rounded-lg animate-gradient bg-[length:200%_200%] transition-all duration-300"
          style={{ width: `${progressValue}%` }}
        ></div>
      </div>

      {currentBrand &&
        currentBrand.isBrandDataGenerated &&
        currentBrand.hasErrors && (
          <p className="text-sm-m text-red-600">{currentBrand.errorMessage}</p>
        )}

      {currentBrand &&
        currentBrand.isBrandDataGenerated &&
        currentBrand.hasErrors && (
          <div className="mt-[14px]">
            <SButton onClick={onRegenerateBrandOpen}>Regenerate</SButton>
          </div>
        )}
    </div>
  );
};

export default BrandCreatingLoader;
