function hexToRgb(hex: string) {
  let r = 0,
    g = 0,
    b = 0;
  if (hex.length == 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex.length == 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }
  return [r, g, b];
}

function rgbToHex(r: number, g: number, b: number) {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

function lightenDarkenColor(hex: string, amt: number) {
  const [r, g, b] = hexToRgb(hex);
  const adjust = (color: number, amount: number) =>
    Math.min(255, Math.max(0, color + amount));
  return rgbToHex(adjust(r, amt), adjust(g, amt), adjust(b, amt));
}

export function generateShades(primaryHex: string) {
  const shadesArray: string[] = [];
  const steps = [-400, -300, -200, -100, 0, 100, 200, 300, 400];
  steps.forEach((step) => {
    const adjustment = Math.round(step * 0.255);
    shadesArray.push(lightenDarkenColor(primaryHex, adjustment));
  });

  return shadesArray.reverse();
}
