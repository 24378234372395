import React, { useEffect, useState } from "react";

import ExpandedImageModal from "../../../utils/ExpandedImageModal";
import { ICopilotMessage } from "../../../../utils/copilot-base/ICopilotBase";
import { isVideo } from "../../../Library/hooks/getFileType";
import SButton from "../../../../design-system/SButton";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { Tooltip } from "react-tooltip";
import { useCreationLibraryHelperFunctions } from "../Media/hooks/useCreationLibraryHelperFunctions";

function UserMessage({ msg }: { msg: ICopilotMessage }) {
  const { insertMediaForAllSocialNetworkType, isMediaAlreadyInPost } =
    useCreationLibraryHelperFunctions();
  const [expandedImage, setExpandedImage] = useState<string | null>(null);
  const findLinks = (str: string) => {
    const regex =
      /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/;
    const words = str.split(" ").map((s) => {
      if (regex.test(s)) {
        return (
          <a
            href={s}
            target="_blank"
            rel="noreferrer"
            className="text-brand-600"
          >
            {s}{" "}
          </a>
        );
      }
      return s + " ";
    });
    return words;
  };
  return (
    <>
      {expandedImage && (
        <ExpandedImageModal
          isOpen={!!expandedImage}
          onClose={() => setExpandedImage(null)}
          source={expandedImage}
        />
      )}
      {msg.content?.Type === "UserMessage" && (
        <div className="flex items-end flex-col">
          <div className="flex flex-col items-end gap-[6px]">
            <p className="text-sm-m text-gray-700">You</p>
            {msg.content?.Message && (
              <pre className="whitespace-pre-wrap break-words max-w-[390px] w-full text-md max-md:text-sm text-gray-900 border border-gray-200 rounded-[8px] rounded-tr-none py-[10px] px-[14px] bg-gray-50">
                {findLinks(msg.content?.Message)}
              </pre>
            )}
          </div>
          {msg.files && (
            <div
              className={`
              grid 
              ${msg.files?.length === 1 && "grid-cols-1"}
              ${msg.files?.length === 2 && "grid-cols-2"}
              ${msg.files?.length > 2 && "grid-cols-3"}
              gap-[6px] mt-[12px]
              `}
            >
              {msg.files.map((item) => (
                <div
                  onClick={() => setExpandedImage(item.url)}
                  className="w-[112px] h-[112px] rounded-[6px] border border-gray-200 cursor-pointer shadow-xs group relative"
                >
                  <div className="w-full h-full absolute top-0 left-0 bg-black opacity-0 group-hover:opacity-20 transition-opacity duration-300"></div>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className="transition-all duration-300 absolute top-[6px] right-[6px] flex gap-[12px] opacity-0 group-hover:opacity-100"
                  >
                    <Tooltip id="add-to-post">Add to post</Tooltip>
                    <SButton
                      onClick={(e) => {
                        e.stopPropagation();
                        if (isMediaAlreadyInPost(item)) return;
                        insertMediaForAllSocialNetworkType(
                          item,
                          false,
                          false,
                          true
                        );
                      }}
                      icon={faPlus}
                      size="sm"
                      type="secondaryGray"
                      data-tooltip-id="add-to-post"
                    />
                  </div>
                  {isVideo(item.url) ? (
                    <video
                      className="w-full h-full shadow-xs object-cover"
                      controls={false}
                      disablePictureInPicture
                      onClick={(e) => {
                        e.stopPropagation();
                        insertMediaForAllSocialNetworkType(
                          item,
                          false,
                          false,
                          true
                        );
                      }}
                    >
                      <source src={item.url} />
                    </video>
                  ) : (
                    <img
                      className="object-contain w-full h-full"
                      src={item.thumbnailMediumUrl || item.url}
                      alt="thumbnail"
                    />
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default UserMessage;
