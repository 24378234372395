import { escapeLeadingUnderscores } from "typescript";

export const isVideo = (url: string) => {
  if (
    url.toLowerCase().endsWith("mp4") ||
    url.toLowerCase().endsWith("mov") ||
    url.toLowerCase().endsWith("avi") ||
    url.toLowerCase().endsWith("wmv") ||
    url.toLowerCase().endsWith("flv") ||
    url.toLowerCase().endsWith("webm") ||
    url.toLowerCase().endsWith("mkv")
  ) {
    return true;
  } else {
    return false;
  }
};
export const isImage = (url: string) => {
  if (
    url.toLocaleLowerCase().endsWith("jpg") ||
    url.toLocaleLowerCase().endsWith("png") ||
    url.toLocaleLowerCase().endsWith("jpeg")
  ) {
    return true;
  } else {
    return false;
  }
};
