import { useEffect } from "react";
import { useCreationStore } from "../../../store/creationStore";
import { useCreationHelperFunctions } from "./useCreationHelperFunctions";
import { usePostGenerateSocialScore } from "../../../api/creation/socialScore/post";
export const useSocialScore = () => {
  const { getActiveSocialNetworkPost } = useCreationHelperFunctions();
  const { activeSoc, setSocialScore, setIsSocialScoreLoading, postData } =
    useCreationStore();
  const { mutateAsync: postGenerateSocialScore } = usePostGenerateSocialScore();
  const generateSocialScore = async () => {
    setIsSocialScoreLoading(true);
    postGenerateSocialScore({
      socialNetworkPostId: getActiveSocialNetworkPost()!.id,
    })
      .then((res) => {
        if (
          useCreationStore.getState().activeSoc === res.data.socialNetworkType
        ) {
          setSocialScore(res.data);
        }
      })
      .catch((err) => {
        setSocialScore(undefined);
      })
      .finally(() => {
        setIsSocialScoreLoading(false);
      });
  };
  const appendSocialScore = () => {
    if (useCreationStore.getState().socialScore) {
      const socialNetworkPost = postData?.socialNetworkPosts.find(
        (item) =>
          item.socialNetworkType === useCreationStore.getState().activeSoc
      );
      if (socialNetworkPost?.socialScore) {
        setSocialScore(socialNetworkPost.socialScore);
      } else {
        generateSocialScore();
      }
    }
  };
  return { generateSocialScore, appendSocialScore };
};
