import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { IFolderCOE, ISingleFolderVM } from "../../../utils/interfaces/IFolders";
import { makeRequest } from "../../auth/axios";
import { Dispatch, SetStateAction } from "react";
import { ISingleFoldersAndFilesVM } from "../../../utils/interfaces/IAssets";


export const usePostFolder = ({setFoldersAndFiles, setPage}: {
  setFoldersAndFiles: Dispatch<SetStateAction<ISingleFoldersAndFilesVM[]>>;
  setPage: Dispatch<SetStateAction<number>>;
}) => {
  const queryClient = useQueryClient();
  return useMutation<IBaseData<ISingleFolderVM>, Error, IFolderCOE>({
    mutationFn: (postData) => makeRequest.post("/Folders", postData),
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["useGetFoldersAndFiles"] });
      setPage(1)
      setFoldersAndFiles([])
    },
  });
};
