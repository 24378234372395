import React, { useState } from "react";
import SBadge from "../../../../../../design-system/SBadge";
import {
  ImageGenerationAspectRatio,
  ImageGenerationStyle,
} from "../../../../../../utils/enums/Enums";
import { useHelperInputHelperFunctions } from "../useHelperInputHelperFunctions";
import { AspectRatioVisuals } from "./AspectRatioVisuals";

function GenerationActions({
  activeStyleTag,
  setActiveStyleTag,
  activeAspectRatio,
  setActiveAspectRatio,
}: {
  activeStyleTag: ImageGenerationStyle;
  setActiveStyleTag: React.Dispatch<React.SetStateAction<ImageGenerationStyle>>;
  activeAspectRatio: ImageGenerationAspectRatio;
  setActiveAspectRatio: React.Dispatch<
    React.SetStateAction<ImageGenerationAspectRatio>
  >;
}) {
  const {
    imageGenerationStyles,
    imageGenerationAspectRatios,
    getGenerationStyleNameById,
  } = useHelperInputHelperFunctions();
  const [showAll, setShowAll] = useState(false);

  const stylesToShow = showAll
    ? imageGenerationStyles
    : imageGenerationStyles.slice(0, 5);

  const remCount = imageGenerationStyles.length - 5;

  return (
    <div className="flex flex-col">
      <div className="w-full h-[1px] bg-gray-100 mt-[16px] mb-[12px]" />
      <div className="px-[24px]">
        <p className="text-xs-m text-gray-400">Predefine Style</p>
        <div className="flex flex-wrap gap-[8px] mt-[8px]">
          {stylesToShow.map((styleId) => (
            <SBadge
              onClick={() => {
                if (activeStyleTag === styleId) {
                  setActiveStyleTag(ImageGenerationStyle.NONE);
                } else {
                  setActiveStyleTag(styleId);
                }
              }}
              className={`${styleId === activeStyleTag && "bg-gray-600 border-gray-600 !text-base-white"} cursor-pointer`}
              size="lg"
              key={styleId}
            >
              {getGenerationStyleNameById(styleId)}
            </SBadge>
          ))}

          {!showAll && remCount > 0 && (
            <SBadge
              onClick={() => {
                setShowAll(!showAll);
              }}
              size="lg"
              className="cursor-pointer"
            >
              +{remCount}
            </SBadge>
          )}
        </div>
      </div>
      <div className="px-[24px] pt-[22px]">
        <p className="text-xs-m text-gray-400">Aspect Ratio</p>
        <div className="flex flex-wrap gap-[8px] mt-[8px]">
          {imageGenerationAspectRatios.map((aspectRatio) => (
            <SBadge
              hasBackground={false}
              onClick={() => {
                setActiveAspectRatio(aspectRatio);
              }}
              className={`${aspectRatio === activeAspectRatio && "bg-gray-600 border-gray-600  !text-base-white"} bg-gray-50 cursor-pointer`}
              size="lg"
              key={aspectRatio}
            >
              <AspectRatioVisuals
                aspectRatio={aspectRatio}
                activeAspectRatio={activeAspectRatio}
              />
              {aspectRatio}
            </SBadge>
          ))}
        </div>
      </div>
    </div>
  );
}

export default GenerationActions;
