import { faClose } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";

export const LibraryLoadingFilesModal = ({
  children,
  isPending,
  setFiles,
  setDropboxFiles,
}: {
  children: ReactNode;
  isPending: boolean;
  setFiles: Dispatch<SetStateAction<File[] | null>>;
  setDropboxFiles: Dispatch<SetStateAction<File[] | null>>;
}) => {
  const [displayFiles, setDisplayFiles] = useState(false);
  useEffect(() => {
    if (isPending) {
      setDisplayFiles(true);
    }
  }, [isPending]);

  return (
    <>
      {displayFiles && (
        <div className="fixed z-20 flex flex-col items-end gap-3 rounded-lg shadow-md bg-white border border-graycool-100 bottom-9 right-7 w-[562px] max-h-[500px] p-[24px] max-md:w-[90%] max-md:right-1/2 max-md:translate-x-1/2">
          <FontAwesomeIcon
            onClick={() => {
              setDisplayFiles(false);
              setFiles(null);
              setDropboxFiles(null);
            }}
            className="cursor-pointer absolute top-2 right-2"
            icon={faClose}
          />
          <div className="w-full h-full overflow-y-auto flex flex-col gap-3 ">
            {children}
          </div>
        </div>
      )}
    </>
  );
};
