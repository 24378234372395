import { useQuery } from "@tanstack/react-query";
import { IPostsQueryParams, IPostsVM } from "../../utils/interfaces/IPost";
import { IBaseData } from "../../utils/interfaces/IBase";
import { makeRequest } from "../auth/axios";
export const useGetAllPosts = ({
  page,
  pageSize,
  keyword,
  socialNetworkType,
  from,
  to,
  postStatus,
  groupId,
  sortByModifiedDate,
}: IPostsQueryParams) => {
  const isAuthenticated = !!localStorage.getItem("token");
  return useQuery<IBaseData<IPostsVM>>({
    queryKey: [
      "useGetAllCreation",
      page,
      pageSize,
      keyword,
      socialNetworkType,
      from,
      to,
      groupId,
      postStatus,
      sortByModifiedDate,
    ],
    queryFn: async () => {
      let requestURL = `Posts?Page=${page}&PageSize=${pageSize}`;
      if (keyword) requestURL += `&Keyword=${keyword}`;
      if (socialNetworkType)
        requestURL += `&SocialNetworkType=${socialNetworkType}`;
      if (groupId) requestURL += `&GroupId=${groupId}`;
      if (postStatus) requestURL += `&PostStatus=${postStatus}`;
      if (from && to) requestURL += `&From=${from}&To=${to}`;
      if (sortByModifiedDate)
        requestURL += `&sortByModifiedDate=${sortByModifiedDate}`;

      const response = await makeRequest.get(requestURL);

      return response as IBaseData<IPostsVM>;
    },
    enabled: !!isAuthenticated,
  });
};
