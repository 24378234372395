import {
  faFacebook,
  faInstagram,
  faPinterest,
  faTwitter,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { ISocialNetworkPostVM } from "../../../../utils/interfaces/IPost";
import {
  calendarStringByStatus,
  getCalendarItemBGColor,
  getCalendarItemOpacityBGColor,
  getCalendarItemTextColor,
} from "../../../Calendar/functions/calendarRenderHelper";
import { SocialNetworkType } from "../../../../utils/enums/Enums";
// @ts-ignore
import blankImage from "../../../../assets/images/no-image-gray.jpg";
import { formatDate } from "date-fns";

function CalendarItem({ post }: { post: ISocialNetworkPostVM }) {
  return (
    <div
      className={`flex ${getCalendarItemOpacityBGColor(post.postStatus)} min-w-[280px] rounded-[4px] overflow-hidden shadow-xs`}
    >
      <div
        className={`flex justify-center items-center ${getCalendarItemBGColor(post.postStatus)} min-w-[32px]`}
      >
        <FontAwesomeIcon
          icon={
            post.socialNetworkType === SocialNetworkType.FACEBOOK
              ? faFacebook
              : post.socialNetworkType === SocialNetworkType.INSTAGRAM
                ? faInstagram
                : post.socialNetworkType === SocialNetworkType.TWITTER
                  ? faTwitter
                  : post.socialNetworkType === SocialNetworkType.LINKEDIN
                    ? faXTwitter
                    : faPinterest
          }
          className="text-[16px] text-base-white"
        />
      </div>
      <div className="flex items-center justify-between w-full px-[8px] py-[6px]">
        <div className="flex gap-[6px] items-center">
          <img
            className="w-[32px] h-[32px] rounded-[6px]"
            src={post.files[0]?.url || blankImage}
            alt="post-image"
          />
          <div>
            <p className="text-xs-sb text-gray-800">{post.name}</p>
            <p
              className={`text-xs ${getCalendarItemTextColor(post.postStatus)} mt-[2px]`}
            >
              {calendarStringByStatus(post.postStatus)}
            </p>
          </div>
        </div>
        <p className="text-xs whitespace-nowrap text-gray-700">
          {post && formatDate(post.releaseDate as Date, "h:mm a")}
        </p>
      </div>
    </div>
  );
}

export default CalendarItem;
