export const EmptyStateChatMessage = ({
  image,
  name,
  message,
  isUser = false,
}: {
  image?: string;
  name: string;
  message: string;
  isUser?: boolean;
}) => {
  return (
    <div
      className={`flex ${isUser ? "flex-row-reverse" : "flex-row"} gap-3 items-start`}
    >
      {!isUser && (
        <img
          src={image}
          alt="avatar"
          className="w-[35px] h-[35px] object-cover rounded-full"
        />
      )}
      <div className="flex flex-col gap-[6px]">
        <p className={`text-sm-m text-gray-700 ${isUser && "self-end"}`}>
          {name}
        </p>
        <pre
          className={`whitespace-pre-wrap break-words bg-gray-50 text-gray-900 text-md flex flex-col justify-center items-start border rounded-[8px] ${
            isUser ? "rounded-tr-[0]" : "rounded-tl-[0]"
          } border-gray-200 py-[10px] px-[14px] max-w-[456px]`}
        >
          {message}
        </pre>
      </div>
    </div>
  );
};
