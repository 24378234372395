import { usePostConnectPage } from "../../../../../api/connections/connect/post";
import { makeRequest } from "../../../../../axios";
import { toast } from "../../../../../hooks/toast";
import { useCreationStore } from "../../../../../store/creationStore";
import { SocialNetworkType } from "../../../../../utils/enums/Enums";
import { ISocialNetworkVM } from "../../../../../utils/interfaces/ISocialNetworks";

export const useChannelsHelper = () => {
  const {
    selectedSocialNetworks,
    setWhichSocialNetworkTypeConnectIsLoading,
    setSelectedSocialNetworks,
    setRefetchConnections,
  } = useCreationStore();
  const { mutateAsync: postConnectPage } = usePostConnectPage();
  let socChannels = [
    {
      name: "X (Twitter)",
      website: "www.x.com",
      image:
        "https://cdn.pixabay.com/photo/2024/05/26/10/15/bird-8788491_1280.jpg",
      socialNetworkType: SocialNetworkType.TWITTER,
    },
    {
      name: "Linkedin",
      website: "www.linkedin.com",
      image:
        "https://cdn.pixabay.com/photo/2024/05/26/10/15/bird-8788491_1280.jpg",
      socialNetworkType: SocialNetworkType.LINKEDIN,
    },
    {
      name: "Instagram",
      website: "www.instagram.com",
      image:
        "https://cdn.pixabay.com/photo/2024/05/26/10/15/bird-8788491_1280.jpg",
      socialNetworkType: SocialNetworkType.INSTAGRAM,
    },
    {
      name: "Pinterest",
      website: "www.pinterest.com",
      image:
        "https://cdn.pixabay.com/photo/2024/05/26/10/15/bird-8788491_1280.jpg",
      socialNetworkType: SocialNetworkType.PINTEREST,
    },
    {
      name: "Facebook",
      website: "www.facebook.com",
      image:
        "https://cdn.pixabay.com/photo/2024/05/26/10/15/bird-8788491_1280.jpg",
      socialNetworkType: SocialNetworkType.FACEBOOK,
    },
  ];

  const handleSelect = (socNetwork: ISocialNetworkVM) => {
    let tmp = [...selectedSocialNetworks];
    if (tmp.some((item) => item.id === socNetwork.id)) {
      tmp = tmp.filter((item) => item.id !== socNetwork.id);
    } else {
      let isAlreadyWithThisSocialNetworkType: number | null = null;
      tmp.map((item) => {
        if (item.socialNetworkType === socNetwork.socialNetworkType)
          isAlreadyWithThisSocialNetworkType = item.id;
      });
      tmp = tmp.filter(
        (item) => item.id !== isAlreadyWithThisSocialNetworkType
      );
      tmp.push(socNetwork);
    }
    setSelectedSocialNetworks(tmp);
  };

  const handleConnect = (soc: SocialNetworkType) => {
    setWhichSocialNetworkTypeConnectIsLoading(soc);
    setRefetchConnections(true);
    postConnectPage({ socialNetworkType: soc })
      .then((res) => {
        window.open(res!.data, "_blank")!.focus();
      })
      .catch((err) => {
        toast(err.response.data.message);
      })
      .finally(() => {
        setWhichSocialNetworkTypeConnectIsLoading(null);
      });
  };

  return { socChannels, handleSelect, handleConnect };
};
