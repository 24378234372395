import { faInfinity } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface ProgressProps {
  current: number;
  total: number;
  unit?: string;
}

const Progress = ({ current, total, unit = "users" }: ProgressProps) => {
  const percentage = total < 9999 ? (current / total) * 100 : 100;

  return (
    <div className={"w-full relative"}>
      {total < 9999 && (
        <p className="text-sm font-medium text-gray-900">
          {current} {(unit !== 'Brands' && unit !== 'Social Accounts') && `of ${total}`} {unit}
        </p>
      )}
      {total === 9999 && (
        <div className="flex gap-1 items-center text-sm font-medium text-gray-900">
          <FontAwesomeIcon size='sm' icon={faInfinity} className="text-amethyst-600"/>
          <p>{current} of unlimited {unit.toLowerCase()}</p> 
        </div>
      )}
      <div aria-hidden="true" className="mt-2">
        <div className="overflow-hidden rounded-full bg-gray-200">
          <div
            style={{ width: `${percentage}%` }}
            className={`h-2 rounded-full ${total === 9999 && 'bg-gradient-to-r from-indigo-500 via-amethyst-600 to-pink-500'} bg-amethyst-600`}
          />
        </div>
      </div>
    </div>
  );
};

export default Progress;
