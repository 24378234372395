import React, { useCallback, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faCircleQuestion,
} from "@fortawesome/pro-regular-svg-icons";
import "../../../index.css";
import MembersListRow, { IMembersListRow } from "../MembersListRow";
import SButton from "../../../design-system/SButton";
import { faUserPlus } from "@fortawesome/pro-solid-svg-icons";
import SCheckbox from "../../../design-system/SCheckbox";
import { useSelectableItems } from "../../../hooks/useSelectableItems";
import { OrganizationUserRole, TeamRole } from "../../../utils/enums/Enums";

export interface MembersListProps {
  items: IMembersListRow[];
  className?: string;
  setChosenUser?: (value: {
    userId: string;
    name: string;
    email: string;
    invitationId?: number | null;
    isPending: boolean;
    role: TeamRole;
  }) => void;
  setEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  loggedInUserRole?: OrganizationUserRole;
}

function MembersList({
  items,
  className,
  setEditModal,
  setChosenUser,
  loggedInUserRole,
}: MembersListProps) {
  return (
    <div
      className={`${className} table-auto w-full border rounded-[8px] bg-base-white max-md:overflow-x-auto`}
    >
      <table>
        <thead className="bg-gray-50 border-b border-gray-200 rounded-t-[8px]">
          <tr>
            <th className="px-[24px] py-[16px] w-6/12 text-left text-xs-m text-slate-600 rounded-tl-[8px] max-md:pr-60">
              Name
            </th>
            <th className="py-[16px] w-4/12 text-left text-xs-m text-slate-600">
              Status <FontAwesomeIcon className="ml-[4px]" icon={faArrowDown} />
            </th>
            <th className="py-[16px] w-2/12 text-left text-xs-m text-slate-600">
              Role{" "}
              <FontAwesomeIcon className="ml-[4px]" icon={faCircleQuestion} />
            </th>
            <th className="py-[16px] w-7/12 text-left text-xs-m text-slate-600 rounded-tr-[8px]"></th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <MembersListRow
              setChosenUser={setChosenUser}
              onEdit={() => setEditModal(true)}
              loggedInUserRole={loggedInUserRole}
              key={index}
              {...item}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default MembersList;
