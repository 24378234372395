import SButton from "../../../design-system/SButton";
import SInput from "../../../design-system/SInput";
import React, { useEffect, useState } from "react";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";

import { useSignUp } from "../../../api/auth/signUp/post";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetInviteInfoByCode } from "../../../api/invitations/invitationByCode/get";
import { getQuery } from "../../../hooks/functions/queries";
//@ts-ignore
import logo from "../../../assets/images/logos/stori-full.svg";

function Form() {
  const { mutateAsync: signUp, isPending } = useSignUp();
  const [error, setError] = useState<string>("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isInvited, setIsInvited] = useState<boolean>(false);
  const [isGlobalInvited, setIsGlobalInvited] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const packageId = getQuery("package");
  const handleSubmit = () => {
    signUp({
      email: email,
      password: password,
      name: name,
      packageId: Number(packageId) || null,
      isInvited: isInvited,
      addSubscription: true,
    })
      .then((res) => {
        if (res.status === 201 && res.data.isSuccess) {
          localStorage.setItem("token", res.data.data.token);
          if (isGlobalInvited) {
            navigate(`/globalInvite${window.location?.search}`);
          } else {
            if (isInvited) {
              navigate(`/invite${window.location?.search}`);
            } else {
              navigate("/");
            }
          }
          // } else {
          //   navigate(`/onboarding${window.location?.search}`);
          // }
        }
      })
      .catch((err) => {
        setError(
          err.response?.data?.message || "An error occurred during signup."
        );
      });
  };

  const handleGoogleLogin = () => {
    const searchParams = new URLSearchParams(window.location.search);
    window.location.href = `${
      process.env.REACT_APP_API_ENDPOINT
    }auth/external-login?provider=Google&${
      searchParams.toString() ? `&${searchParams.toString()}` : ""
    }`;
  };

  const invitationCodeGlobal = getQuery("isGlobal");
  const invitationCode = !invitationCodeGlobal ? getQuery("code") : null;

  const { data: invitationByCode } = useGetInviteInfoByCode({
    code: invitationCode,
  });
  useEffect(() => {
    if (invitationCodeGlobal && getQuery("isGlobal") === "true")
      setIsGlobalInvited(true);
    if (invitationByCode) {
      setEmail(invitationByCode?.data.userEmail);
      setIsInvited(true);
    }
  }, [invitationByCode]);
  useEffect(() => {
    if (getQuery("email") && !invitationByCode) {
      setEmail(getQuery("email") as string);
    }
  }, []);
  return (
    <div className="max-w-[380px] w-full">
      <img alt={"logo"} src={logo} className="w-[100px] mb-[40px] md:hidden" />
      <p className="display-sm-sb text-gray-900">Sign up</p>
      <p className="text-md text-gray-600 mt-[6px]">
        Start your 5-day free trial.
      </p>
      {invitationByCode && (
        <p className="text-lg-sb mt-[14px] text-gray-900">
          Invitation from{" "}
          <span className="text-brand-700">
            {invitationByCode?.data.workspace?.name || "Team"}
          </span>
        </p>
      )}
      <div className="flex flex-col mt-[32px] gap-[10px]">
        <SInput
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Enter your name"
          label="Name*"
          onKeyDown={(e) => {
            if (e.code === "Enter") handleSubmit();
          }}
        />
        <SInput
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          label="Email*"
          onKeyDown={(e) => {
            if (e.code === "Enter") handleSubmit();
          }}
        />
        <SInput
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          placeholder="Enter your password"
          label="Password*"
          onKeyDown={(e) => {
            if (e.code === "Enter") handleSubmit();
          }}
        />
      </div>
      {error && (
        <pre className="whitespace-pre-wrap text-error-500 mt-[12px]">
          {error}
        </pre>
      )}
      <div className="mt-[24px] flex flex-col gap-[16px]">
        <SButton onClick={handleSubmit} size="lg" isLoading={isPending}>
          Create account
        </SButton>
        <SButton
          onClick={handleGoogleLogin}
          size="lg"
          type="secondaryGray"
          lIconProps={{
            color: "#EA4335",
          }}
          lIcon={faGoogle}
        >
          Sign up with Google
        </SButton>
      </div>
      <p className="text-sm mt-[32px] text-center text-gray-600">
        By clicking "Create account or Sign Up buttons", I agree to STORI AI's{" "}
        <a
          href={"https://storiai.com/doc/Terms.pdf"}
          target={"_blank"}
          className="text-sm-sb text-brand-700 cursor-pointer"
        >
          {" "}
          TOS
        </a>{" "}
        and
        <a
          href={"https://storiai.com/doc/privacy.pdf"}
          target={"_blank"}
          className="text-sm-sb text-brand-700 cursor-pointer"
        >
          {" "}
          Privacy Policy
        </a>
      </p>

      <p className="text-sm mt-[32px] text-center text-gray-600">
        Already have an account?
        <span
          className="text-sm-sb text-brand-700 cursor-pointer"
          onClick={() => navigate(`/login${location.search}`)}
        >
          {" "}
          Log in
        </span>
      </p>
    </div>
  );
}

export default Form;
