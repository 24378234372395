import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../../axios";
import { IBase } from "../../../utils/interfaces/IBase";

export const usePutBrandLogo = () => {
  const queryClient = useQueryClient();
  return useMutation<
    IBase<string>,
    Error,
    { isNewOrganization: boolean; file: FormData; isSecondLogo?: boolean }
  >({
    mutationFn: ({ file, isNewOrganization, isSecondLogo = false }) =>
      makeRequest.post(
        `/Organizations/Photo?isNewOrganization=${
          isNewOrganization || false
        }&isSecondLogo=${isSecondLogo}`,
        file,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "",
          },
        }
      ),
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["useGetBrand"] });
      queryClient.invalidateQueries({ queryKey: ["useGetCurrentBrand"] });
      queryClient.invalidateQueries({ queryKey: ["useGetAllBrands"] });
    },
  });
};
