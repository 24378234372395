import { useQuery } from "@tanstack/react-query";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { IInviteVM } from "../../../utils/interfaces/ITeamInvitation";
import { makeRequest } from "../../auth/axios";

export const useGetInviteInfoByCode = ({ code }: { code: string | null }) => {
  return useQuery<IBaseData<IInviteVM>>({
    queryKey: ["useGetInviteInfoByCode"],
    queryFn: async () => {
      const response = await makeRequest.get(
        `/WorkspaceUsers/Invitations/Code?code=${code}`
      );
      return response as IBaseData<IInviteVM>;
    },
    enabled: !!code,
  });
};
