import React from "react";

function NotificationSkeleton() {
  return (
    <div
      role="status"
      className="h-[78px] p-[16px] w-full animate-pulse relative"
    >
      <div className="h-full w-full z-[-1] absolute top-[0] left-[0] bg-gray-100 rounded-[12px]"></div>
      <div className="flex  gap-[16px]">
        <div className="h-[16px] w-[16px] bg-gray-400 rounded-[12px]"></div>
        <div className="w-full flex flex-col gap-[12px]">
          <div className="h-[16px] w-[90%] bg-gray-300 rounded-[12px]"></div>
          <div className="h-[16px] w-[70%] bg-gray-200 rounded-[12px]"></div>
        </div>
      </div>
    </div>
  );
}

export default NotificationSkeleton;
