import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { ISingleBrandVM } from "../utils/interfaces/IBrand";
import { IAccountVM } from "../utils/interfaces/IAccount";

export interface ICreationStoreInitialInterface {
  currentDate: Date;
  activeView: "month" | "week" | "day";
}

const initialState: ICreationStoreInitialInterface = {
  currentDate: new Date(),
  activeView: "month",
};

const store = (set: any) => ({
  ...initialState,
  setActiveView: (activeView: "month" | "week" | "day") => set({ activeView }),
  setCurrentDate: (currentDate: Date) => set({ currentDate }),
  reset: () => set(initialState),
});
export const useCalendarStore = create(devtools(store));
