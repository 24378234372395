import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import global_en from '../languages/interface/en.json';
import global_ge from '../languages/interface/ka.json';
import global_po from '../languages/interface/pl.json';
import global_jp from '../languages/interface/ja.json';
import global_fr from '../languages/interface/fr.json';
import global_nl from '../languages/interface/nl.json';
import global_de from '../languages/interface/de.json';
import global_pt from '../languages/interface/pt.json';
import global_es from '../languages/interface/es.json';
import global_tr from '../languages/interface/tr.json';
import global_zh from '../languages/interface/zh.json';
import global_sv from '../languages/interface/sv.json';
import global_it from '../languages/interface/it.json';

const storedLanguage = localStorage.getItem('la') || 'en';

const resources = {
  en: { translation: global_en },
  ge: { translation: global_ge },
  po: { translation: global_po },
  jp: { translation: global_jp },
  fr: { translation: global_fr },
  nl: { translation: global_nl },
  de: { translation: global_de },
  pt: { translation: global_pt },
  es: { translation: global_es },
  tr: { translation: global_tr },
  zh: { translation: global_zh },
  it: { translation: global_it },
  sv: { translation: global_sv },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    lng: storedLanguage,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
