import React, { useEffect, useState } from "react";
import Stepper from "../components/create-brand/Stepper";
import { CopilotType, OrganizationType } from "../utils/enums/Enums";
import BrandType from "../components/create-brand/steps/BrandType";
import Visual from "../components/create-brand/steps/Visual";
import CreateBrandChat from "../components/create-brand/steps/CreateBrandChat";
import { ICopilotSessionVM } from "../utils/interfaces/ICopilot";
import { usePostCopilotSession } from "../api/copilot/session/post";
import { usePostBrand } from "../api/brand/post";
import { toast } from "../hooks/toast";
import { usePutSwitchBrand } from "../api/brand/switch/put";
import { useNavigate } from "react-router-dom";
import ChangeBrandTypeAgreement from "../components/create-brand/ChangeBrandTypeAgreement";
import { useGetCurrentBrand } from "../api/brand/current/get";
import { useBrandStore } from "../store/brandStore";

export interface Step {
  name: string;
  status: "current" | "upcoming";
  visited: boolean;
}
export interface IBrandCreationData {
  type?: OrganizationType;
  about?: string;
  logo?: string;
  name?: string;
  website?: string;
  primaryColor?: string;
  secondaryColor?: string;
  tagline?: string;
}
function CreateBrand() {
  const { mutateAsync: createBrand, isPending: isBrandCreating } =
    usePostBrand();
  const { mutateAsync: switchBrand } = usePutSwitchBrand();
  const { refetch: refetchCurrentBrand } = useGetCurrentBrand();
  const [isChangeBrandTypeAgreementOpen, setIsChangeBrandTypeAgreementOpen] =
    useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState<IBrandCreationData>({});
  const [currentStep, setCurrentStep] = useState(0);
  const steps: Step[] = [
    {
      name: "Brand Type",
      status: currentStep === 0 ? "current" : "upcoming",
      visited: true,
    },
    {
      name: "Brand Creation",
      status: currentStep === 1 ? "current" : "upcoming",
      visited: false,
    },
    {
      name: "Brand Analyze",
      status: currentStep === 2 ? "current" : "upcoming",
      visited: false,
    },
  ];
  const [ready, setReady] = useState(false);
  const { setIsProgressLoading } = useBrandStore();
  useEffect(() => {
    if (data.type) {
      if (
        data.about &&
        data.name &&
        data.name.length > 0 &&
        data.about.length > 0
      ) {
        setReady(true);
      }
      if (
        data.website &&
        data.name &&
        data.name.length > 0 &&
        data.website.length > 0
      ) {
        setReady(true);
      }
    }
  }, [data]);

  const [activeSession, setActiveSession] = useState<ICopilotSessionVM | null>(
    null,
  );
  const { mutateAsync: createCopilotSession, isPending } =
    usePostCopilotSession();
  const handleCreateCopilotSession = async (type: CopilotType) => {
    await createCopilotSession({
      type: type,
    }).then((res) => {
      setActiveSession(res.data);
    });
  };

  const handleCreatBrand = () => {
    if (!data.name) return;
    createBrand({
      name: data.name,
      logo: data.logo,
      about: data.about,
      websiteToScrape: data.website,
      primaryColor: data.primaryColor,
      secondaryColor: data.secondaryColor,
      organizationType: data.type,
    })
      .then((res) => {
        switchBrand({ id: res.data.id }).then((res) => {
          refetchCurrentBrand().then((res) => {
            setIsProgressLoading(false);
            navigate("/brand");
          });
        });
      })
      .catch((err) => {
        setIsProgressLoading(false);
        toast(err.response.data.message);
      });
  };

  return (
    <div className="py-[24px] flex gap-[20px] flex-col items-center">
      {isChangeBrandTypeAgreementOpen && (
        <ChangeBrandTypeAgreement
          data={data}
          setData={setData}
          handleCreateCopilotSession={handleCreateCopilotSession}
          setCurrentStep={setCurrentStep}
          isOpen={isChangeBrandTypeAgreementOpen}
          setIsOpen={(p) => setIsChangeBrandTypeAgreementOpen(p)}
        />
      )}
      <p className="text-xl-sb text-gray-900 text-center">Brand Creation</p>
      <Stepper
        handleCreatBrand={handleCreatBrand}
        isBrandCreating={isBrandCreating}
        ready={ready}
        data={data}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        steps={steps}
      />
      {currentStep === 0 && (
        <BrandType
          setIsChangeBrandTypeAgreementOpen={setIsChangeBrandTypeAgreementOpen}
          handleCreateCopilotSession={handleCreateCopilotSession}
          setCurrentStep={setCurrentStep}
          setData={setData}
          data={data}
        />
      )}
      {currentStep === 1 && (
        <CreateBrandChat
          setCurrentStep={setCurrentStep}
          activeSession={activeSession}
          setActiveSession={setActiveSession}
          setData={setData}
          data={data}
        />
      )}
      {currentStep === 2 && <Visual handleCreatBrand={handleCreatBrand} />}
    </div>
  );
}

export default CreateBrand;
