import React from "react";
import { IAccountVM } from "../../utils/interfaces/IAccount";

const AccountHead = ({ data }: { data: IAccountVM }) => {
  return (
    <div className="flex gap-[8px]">
      <img
        src={data.photoUrl ?? "https://cdn.storiai.com/avatar.svg"}
        className="aspect-square object-cover w-full h-full max-w-[40px] max-h-[40px] rounded-full"
        alt="user-avatar"
      />
      <div className="flex flex-col justify-between">
        <p className="text-sm-m max-w-[150px] truncate">{data.name}</p>
        <p className="text-xs-m text-gray-500 lowercase max-w-[150px] truncate">
          {data.email}
        </p>
      </div>
    </div>
  );
};

export default AccountHead;
