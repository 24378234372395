import {
  faCalendarDay,
  faCreditCard,
  faGear,
  faGrid2,
  faImage,
  faLink,
  faPaintBrush,
  faShareNodes,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faCalendarDay as faSolidCalendarDay,
  faCreditCard as faSolidCreditCard,
  faGear as faSolidGear,
  faGrid2 as faSolidGrid2,
  faImage as faSolidImage,
  faLink as faSolidLink,
  faPaintBrush as faSolidPaintBrush,
  faShareNodes as faSolidShareNodes,
  faMegaphone as faSolidMegaphone,
} from "@fortawesome/pro-solid-svg-icons";
import NavigationGroup from "./NavigationGroup";
import { faMegaphone } from "@fortawesome/pro-regular-svg-icons/faMegaphone";
import {
  brandSubMenu,
  calendarSubMenu,
  contentSubMenu,
} from "../../utils/content";

function Navigation({
  setIsMobileMenuOpen,
}: {
  setIsMobileMenuOpen?: (open: boolean) => void;
}) {
  const handleClick = () => {
    setIsMobileMenuOpen && setIsMobileMenuOpen(false);
  };

  return (
    <div className="m-[12px] ">
      <div className="flex flex-col gap-[16px]">
        <NavigationGroup
          pages={[
            {
              title: "Content",
              icon: faMegaphone,
              solidIcon: faSolidMegaphone,
              path: "/",
              pathTitle: "",
              handleClick: () => handleClick(),
              subMenuItems: contentSubMenu,
            },
            {
              title: "Brand",
              icon: faPaintBrush,
              solidIcon: faSolidPaintBrush,
              path: "/brand?subsection=brandbook",
              pathTitle: "brand",
              handleClick: () => handleClick(),
              subMenuItems: brandSubMenu,
            },

            {
              title: "Assets",
              icon: faImage,
              solidIcon: faSolidImage,
              path: "/assets",
              pathTitle: "assets",
              handleClick: () => handleClick(),
            },
            {
              title: "Calendar",
              icon: faCalendarDay,
              solidIcon: faSolidCalendarDay,
              path: "/calendar",
              pathTitle: "calendar",
              handleClick: () => handleClick(),
              subMenuItems: calendarSubMenu,
            },
            {
              title: "Accounts",
              icon: faShareNodes,
              solidIcon: faSolidShareNodes,
              path: "/brand?section=accounts",
              pathTitle: "accounts",
              handleClick: () => handleClick(),
            },
          ]}
        />
      </div>
    </div>
  );
}

export default Navigation;
