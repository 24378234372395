import React, { InputHTMLAttributes } from "react";

interface SCheckboxProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "size"> {
  className?: string;
  size?: "sm" | "md";
}

const SCheckbox = ({ className, size = "sm", ...props }: SCheckboxProps) => {
  return (
    <input
      type="checkbox"
      className={`${className} rounded-[4px] border-gray-300 ${props.disabled ? 'bg-gray-200 text-gray-500' : 'bg-transparent text-amethyst-600'}  focus:ring-amethyst-600 ${size === "md" ? "w-[20px] h-[20px]" : "w-[16px] h-[16px]"}`}
      {...props}
    />
  );
};

export default SCheckbox;
