import axios from 'axios';

axios.defaults.withCredentials = true;

export const getBearerToken = () => {
  const token = localStorage.getItem('token');
  return token ? `Bearer ${token}` : 'invalidToken';
};
const getContentLanguage = () => {
  const lang = localStorage.getItem('la');
  return lang ? lang : 'en';
};

export const makeRequest = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': 'https://storiai.com',
    'Access-Control-Allow-Credentials': 'true',
    'access-control-allow-methods': 'GET POST PUT DELETE',
    'Content-Language': getContentLanguage(),
    Authorization: getBearerToken()
  }
});

makeRequest.interceptors.request.use((config) => {
  config.headers.Authorization = getBearerToken();
  return config;
});

makeRequest.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    const publicPaths = [
      '/login',
      '/signup',
      '/forgot-password',
      '/forgot-password-confirmation',
      '/external-signin-callback',
      '/appsumo/activate',
      '/register-with-invite',
      '/invite',
      '/terms-of-service-confirmation',
      '/canva-redirect',
      '/canva-login'
    ];
    if (error?.response?.status === 401 && !publicPaths.includes(window.location.pathname)) {
      localStorage.removeItem('token');
      window.location.reload();
    }
    return Promise.reject(error);
  }
);
