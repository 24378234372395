import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComments,
  faEllipsisVertical,
  faTrash,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faDiscord,
  faFacebook,
  faInstagram,
  faLinkedin,
  faPinterest,
  faReddit,
  faSlack,
  faTelegram,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { OrganizationUserRole, PostStatus } from "../../utils/enums/Enums";
import { ISinglePostVM } from "../../utils/interfaces/IPost";
import { useCreationHelperFunctions } from "../creation/hooks/useCreationHelperFunctions";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { formatDistanceToNowStrict, parseISO } from "date-fns";
import { isVideo } from "../Library/hooks/getFileType";
import SButton from "../../design-system/SButton";
import SSmallMenu from "../../design-system/SSmallMenu";
import { useUpdatePost } from "../creation/hooks/useUpdatePost";
import { ConfirmDelete } from "../Library/ConfirmDelete";
import { toast } from "../../hooks/toast";
import { useAccountStore } from "../../store/accountStore";
import WhichSocialNetworksSelector from "../utils/WhichSocialNetworksSelector";
import { useContentStore } from "../../store/contentStore";
import SCheckbox from "../../design-system/SCheckbox";
import ImagesGrid from "../creation/Tabs/Media/ImagesGrid";

export interface IPostCard {
  handleClick?: () => void;
  className?: string;
  post: ISinglePostVM;
}

const statusBackground = (status: PostStatus) => {
  switch (status) {
    case PostStatus.DRAFT:
      return "hidden";
    case PostStatus.PUBLISHED:
      return "bg-success-500";
    case PostStatus.SCHEDULED:
      return "bg-warning-500";
    case PostStatus.REMINDER:
      return "bg-brand-600";
  }
};

function PostCard({ post, className, handleClick }: IPostCard) {
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [firstMediaUrl, setFirstMediaUrl] = useState<string | null>(null);
  const { handleOpenPost } = useCreationHelperFunctions();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  let socialNetworks: { [key: number]: { icon: IconProp; color: string } } = {
    1: { icon: faFacebook, color: "#0866FF" },
    2: { icon: faInstagram, color: "#D62976" },
    3: { icon: faLinkedin, color: "#0077B5" },
    4: { icon: faXTwitter, color: "#000000" },
    5: { icon: faPinterest, color: "#E60023" },
    7: { icon: faSlack, color: "#4A154B" },
    8: { icon: faDiscord, color: "#5865F2" },
    9: { icon: faTelegram, color: "#0088cc" },
    10: { icon: faReddit, color: "#FF4500" },
  };
  const { handleDeletePost, isDeleting } = useUpdatePost();
  useEffect(() => {
    const socialNetworkPosts = post.socialNetworkPosts || [];

    for (let post of socialNetworkPosts) {
      if (post.files && post.files.length > 0) {
        setFirstMediaUrl(
          (post.files[0]?.templateOutputUrl
            ? post.files[0]?.templateOutputUrl
            : post.files[0]?.thumbnailMediumUrl)!
        );
        break;
      }
    }
  }, [post]);
  const { personalInfo } = useAccountStore();
  const [
    isWhichSocialNetworksSelectorOpen,
    setIsWhichSocialNetworksSelectorOpen,
  ] = useState(false);
  const { sortByModifiedDate } = useContentStore();
  const [checked, setChecked] = useState(false);

  return (
    <>
      {isWhichSocialNetworksSelectorOpen && (
        <WhichSocialNetworksSelector
          saveAllOnClose={true}
          isOpen={isWhichSocialNetworksSelectorOpen}
          onClose={() => setIsWhichSocialNetworksSelectorOpen(false)}
        />
      )}
      {openConfirmation && (
        <ConfirmDelete
          loading={isDeleting}
          setOpenModal={setOpenConfirmation}
          onConfirm={() => {
            handleDeletePost(post.id, checked).then((r) => {
              toast(r.data.message, "success");
              setOpenConfirmation(false);
            });
          }}
          onCancel={() => setOpenConfirmation(false)}
          openModal={openConfirmation}
          title="Are you sure you want to delete this post?"
          children={
            <>
              {post.socialNetworkPosts.some(
                (item) => item.postStatus === PostStatus.PUBLISHED
              ) && (
                <div className="flex items-center gap-2 mt-2">
                  <SCheckbox
                    checked={checked}
                    onChange={() => setChecked((val) => !val)}
                  />
                  <p
                    onClick={() => setChecked((val) => !val)}
                    className="text-sm text-gray-900 cursor-default select-none"
                  >
                    Delete this on social media account as well?
                  </p>
                </div>
              )}
            </>
          }
        />
      )}
      <div
        onMouseLeave={() => setIsMenuOpen(false)}
        onClick={() =>
          handleOpenPost({
            postId: post.id,
            onSocialNetworkSelectorOpen: () => {
              setIsWhichSocialNetworksSelectorOpen(true);
            },
          })
        }
        className={`${className} group relative cursor-pointer w-full rounded-[16px] overflow-hidden bg-base-white border border-gray-200`}
      >
        {firstMediaUrl &&
          (isVideo(firstMediaUrl) ? (
            <video
              controls={false}
              className="w-full max-h-[240px] object-contain"
            >
              <source src={firstMediaUrl} />
            </video>
          ) : (
            <div className="aspect-square max-h-[240px] w-full">
              <ImagesGrid
                images={post.socialNetworkPosts[0].files.map((item) =>
                  item.templateOutputUrl ? item.templateOutputUrl : item.url!
                )}
              />
            </div>
          ))}
        <div className="flex justify-between items-center mt-[16px] px-[16px]">
          <div>
            <p className="text-sm-sb text-gray-900">
              {post.name ? post.name : "New Post"}
            </p>
            <p className={`text-sm text-gray-600 ${firstMediaUrl && "mb-3"}`}>
              {sortByModifiedDate &&
                post.lastModified !== null &&
                "Modified " +
                  formatDistanceToNowStrict(
                    parseISO(post.lastModified.toString())
                  ) +
                  " ago"}
              {(!sortByModifiedDate || post.lastModified === null) &&
                formatDistanceToNowStrict(parseISO(post.created.toString())) +
                  " ago"}
            </p>
          </div>
          {personalInfo?.userRole !== OrganizationUserRole.VIEWER && (
            <SButton
              size="sm"
              onClick={(e) => {
                e.stopPropagation();
                setIsMenuOpen((prev) => !prev);
              }}
              id={`post-menu-opener-${post.id}`}
              type="secondaryGray"
              className={`${!firstMediaUrl && "!border-0"} ${firstMediaUrl && "sm:hidden group-hover:flex absolute top-[16px] right-[16px]"} `}
              icon={faEllipsisVertical}
            />
          )}
          {personalInfo?.userRole !== OrganizationUserRole.VIEWER && (
            <SSmallMenu
              isOpen={isMenuOpen}
              setIsOpen={(p) => setIsMenuOpen(p)}
              className={`
            left-[calc(100%-115px)]
            ${firstMediaUrl ? "top-[unset] bottom-[calc(100%-102px)]" : "!top-[unset] bottom-[calc(50%-26px)]"}
            `}
              excludedId={`post-menu-opener-${post.id}`}
              data={[
                [
                  {
                    text: "Delete",
                    icon: faTrash,
                    color: "text-error-500",
                    handleClick: (e) => {
                      e.stopPropagation();
                      setOpenConfirmation(true);
                    },
                  },
                ],
              ]}
            />
          )}
        </div>
        {!firstMediaUrl && (
          <div className="px-[40px]">
            <div className="w-full h-[1px] bg-gray-200 my-[16px]" />
          </div>
        )}
        <div
          className={`flex justify-between items-center px-[16px] pb-[16px] `}
        >
          <div className="flex">
            {post.socialNetworkPosts.slice(0, 5).map((item, index) => (
              <div
                key={item.id}
                style={{
                  right: `${index * 6}px`,
                }}
                className={`relative border-[2px] rounded-full border-base-white`}
              >
                <div className="w-[24px] h-[24px] relative rounded-full flex justify-center items-center bg-gray-100 border border-gray-200">
                  <FontAwesomeIcon
                    icon={socialNetworks[`${item.socialNetworkType}`]?.icon}
                    color={socialNetworks[`${item.socialNetworkType}`]?.color}
                    className="text-[14px]"
                  />
                  <div
                    className={`absolute top-[calc(100%-5px)] right-[-4px] ${statusBackground(item.postStatus)} w-[10px] h-[10px] rounded-full border-[2px] border-base-white`}
                  />
                </div>
              </div>
            ))}
            {post.socialNetworkPosts.length > 5 && (
              <div
                style={{
                  right: `30px`,
                }}
                className={`relative border-[2px] rounded-full border-base-white`}
              >
                <div className="w-[24px] h-[24px] relative rounded-full flex justify-center items-center bg-gray-100 border border-gray-200">
                  <p className="text-gray-400 text-xs">
                    +{post.socialNetworkPosts.length - 5}
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className="flex gap-[8px] items-center">
            {post.comments.length > 0 && (
              <div className="flex gap-[6px] items-center">
                <FontAwesomeIcon
                  icon={faComments}
                  className="text-[14px] text-gray-600"
                />
                <p className="text-xs-m text-gray-900">
                  {post.comments.length}
                </p>
              </div>
            )}
            <img
              className="max-w-[24px] max-h-[24px] min-w-[24px] min-h-[24px] w-full h-full object-cover rounded-full"
              src={post.userPhotoUrl}
              alt="author avatar"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default PostCard;
