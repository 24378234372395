import SBreadcrumbs from "../../../../../design-system/SBreadcrumbs";
import { useLibraryStore } from "../../../../../store/libraryStore";

function Head() {
  const {
    folderHistory,
    setFolderHistory,
    setFoldersAndFiles,
    setPage,
    setParentFolder,
  } = useLibraryStore();
  return (
    <>
      {folderHistory.length > 1 && (
        <div className="flex justify-between items-center pb-4">
          <SBreadcrumbs
            needGoBack={folderHistory.length >= 2}
            onGoBack={() => {
              const prevItem = folderHistory[folderHistory.length - 2];
              folderHistory.length > 2 &&
                setFolderHistory((prev) => prev.slice(0, -1));
              setFoldersAndFiles([]);
              setPage(1);
              setParentFolder({
                id: prevItem?.id ? prevItem?.id : null,
                title: prevItem.title,
              });
            }}
            needHome={false}
            pages={folderHistory}
          />
        </div>
      )}
    </>
  );
}

export default Head;
