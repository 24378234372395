import { usePostCopilotChatMessage } from "../../../api/copilot/chat/post";
import { toast } from "../../../hooks/toast";
import { ICopilotMessage } from "../../../utils/copilot-base/ICopilotBase";
import { CopilotRole } from "../../../utils/enums/Enums";
import { useUpdateBriefMessages } from "../../creation/Tabs/Brief/hooks/useUpdateBriefMessages";

export const useCreateBrandChatHelper = ({
  setDisable,
  setChatResponse,
  setValue,
}: {
  setDisable: React.Dispatch<React.SetStateAction<boolean>>;
  setChatResponse: React.Dispatch<React.SetStateAction<string>>;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { updateLoadingMessage } = useUpdateBriefMessages();
  const { mutateAsync: postMessage } = usePostCopilotChatMessage();

  const sendMessage = async ({
    text,
    setMessages,
    messages,
    copilotSessionId,
  }: {
    copilotSessionId: string;
    messages: ICopilotMessage[];
    setMessages: React.Dispatch<React.SetStateAction<ICopilotMessage[]>>;
    text: string;
  }) => {
    setDisable(true);
    setChatResponse("");
    setValue("");
    let tmpMessages = [...messages];
    const userMessage: ICopilotMessage = {
      role: CopilotRole.USER,
      content: {
        Type: "UserMessage",
        Message: text,
      },
      files: [],
    };
    const aiMessage: ICopilotMessage = {
      role: CopilotRole.ASSISTANT,
      content: undefined,
      files: [],
    };
    tmpMessages = [...tmpMessages, userMessage, aiMessage];
    setMessages(tmpMessages);
    postMessage({
      message: text,
      sessionId: copilotSessionId,
    })
      .then((res) => {
        setMessages(tmpMessages);
        setDisable(false);
        const aiMessage = res.data.data.find(
          (item) => item.role === CopilotRole.ASSISTANT
        );
        if (aiMessage) {
          tmpMessages = updateLoadingMessage({
            obj: {
              content: aiMessage.content,
              role: CopilotRole.ASSISTANT,
              files: [],
            },
            tmpMessages,
          });
        }
        setMessages(tmpMessages);
        setDisable(false);
      })
      .catch((err) => {
        tmpMessages = updateLoadingMessage({
          obj: {
            content: {
              Type: "CopilotMessage",
              Message: err.response.data.message,
            },
            role: CopilotRole.ASSISTANT,
            files: [],
          },
          tmpMessages,
        });
        setMessages(tmpMessages);
        setDisable(false);
      });
  };

  return { sendMessage };
};
