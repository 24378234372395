import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { makeRequest } from "../../auth/axios";
import { Dispatch, SetStateAction } from "react";
import { IFileUpdate, ISingleFIleVM, ISingleFoldersAndFilesVM } from "../../../utils/interfaces/IAssets";


export const usePutFile = ({setFoldersAndFiles, setPage}: {
  setFoldersAndFiles?: Dispatch<SetStateAction<ISingleFoldersAndFilesVM[]>>;
  setPage?: Dispatch<SetStateAction<number>>;
}) => {
  const queryClient = useQueryClient();
  return useMutation<IBaseData<ISingleFIleVM>, Error, {id: number, data: IFileUpdate}>({
    mutationFn: ({id, data}) => makeRequest.put(`/Files/${id}`, data),
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["useGetFoldersAndFiles"] });
      setPage && setPage(1);
      setFoldersAndFiles && setFoldersAndFiles([]);
    },
  });
};
