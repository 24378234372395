import { IBaseData } from "../../../utils/interfaces/IBase";
import { ITeamUserVM } from "../../../utils/interfaces/ITeamInvitation";
import { IMembersListRow } from "../../tables/MembersListRow";


export const displayActiveMembers = (data?: IBaseData<ITeamUserVM[]>): IMembersListRow[] => {
  const status: 'Active' = 'Active'
  const items = data ? data?.data.map((item, index: number) => ({
    key: index,
    userRole: item.role,
    userImg: item.photoUrl,
    userName: item.name,
    userEmail: item.email,
    userStatus: status,
    userId: item.userId,
  })) : [];
  return items;
}