import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { makeRequest } from "../../auth/axios";
import { IInviteVM } from "../../../utils/interfaces/ITeamInvitation";

export const useJoinWithGLobalLink = () => {
  const queryClient = useQueryClient();
  return useMutation<
    IBaseData<IInviteVM>,
    Error,
    {globalInvitationCode: string | null}
  >({
    mutationFn: ({ globalInvitationCode }) => {
      return makeRequest.post(
        `/WorkspaceUsers?globalInvitationCode=${globalInvitationCode}`
      );
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["useGetInvitationPending"] });
      queryClient.invalidateQueries({ queryKey: ["useGetActiveMembers"] });
      queryClient.invalidateQueries({ queryKey: ["useGetWorkspaces"] });
      queryClient.invalidateQueries({ queryKey: ["useGetCurrentWorkspace"] });
      queryClient.invalidateQueries({ queryKey: ["useGetCurrentBrand"] });
      queryClient.invalidateQueries({ queryKey: ["useGetFoldersAndFiles"] });
    },
  });
};
