import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../../axios";
import { IBase } from "../../../utils/interfaces/IBase";

export const useDeleteCopilotSession = () => {
  const queryClient = useQueryClient();
  return useMutation<IBase<null>, Error, { id: string }>({
    mutationFn: ({ id }) => makeRequest.delete(`/Copilot/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["useGetCopilotSessions"] });
    },
  });
};
