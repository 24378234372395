import React, { Dispatch, SetStateAction } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/pro-regular-svg-icons";
import "../../../index.css";
import BrandsListRow from "../BrandsListRow";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { ISingleBrandVM } from "../../../utils/interfaces/IBrand";
import { IWorkspaceUsersVM } from "../../../utils/interfaces/IWorkspace";
import { useGetWindowWidth } from "../../../hooks/useGetWindowWidth";

export interface BrandsListRowProps {
  items?: IBaseData<ISingleBrandVM[]>;
  className?: string;
  workspaceUsers?: IBaseData<IWorkspaceUsersVM[]>;
  handleSwitch?: (chosenBrand: number) => void;
  setIsConfirmDeleteOpen: Dispatch<SetStateAction<number | boolean>>;
}

function WorkspaceMembersView({
  items,
  className,
  workspaceUsers,
  handleSwitch,
  setIsConfirmDeleteOpen,
}: BrandsListRowProps) {
  const { windowWidth } = useGetWindowWidth();
  return (
    <div
      className={`${className} w-full bg-white border border-gray-200 rounded-[12px] shadow-xs`}
    >
      <table className="w-full">
        <thead className="border-b rounded-t-[12px] border-gray-200">
          <tr className="text-left">
            <th className="px-6 py-4 rounded-tl-[12px] bg-gray-50 text-sm font-semibold text-gray-600">
              Brand{" "}
              <FontAwesomeIcon icon={faArrowDown} className="ml-1 text-xs" />
            </th>
            <th className="px-6 py-4 bg-gray-50 text-sm font-semibold text-gray-600">
              Members
            </th>
            <th className="w-10 rounded-tr-[12px] bg-gray-50"></th>
          </tr>
        </thead>
        <tbody>
          {items &&
            items.data.map((item, index) => (
              <BrandsListRow
                onDetailsClick={() => handleSwitch && handleSwitch(item.id)}
                brand={item}
                key={index}
                index={index}
                className={`${(index + 1) % 2 === 0 ? "bg-gray-50" : "bg-white"}`}
                members={workspaceUsers?.data}
                setIsConfirmDeleteOpen={setIsConfirmDeleteOpen}
                windowWidth={windowWidth}
              />
            ))}
        </tbody>
      </table>
    </div>
  );
}

export default WorkspaceMembersView;
