import { useQuery } from "@tanstack/react-query";
import { makeRequest } from "../../auth/axios";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { IFrameGroupsVM } from "../../../utils/interfaces/IFrames";

export const useGetFramesGroups = () => {
  return useQuery<IBaseData<IFrameGroupsVM[]>>({
    queryKey: ["useGetFramesGroups"],
    queryFn: async () => {
      const response = await makeRequest.get(`/Templates/groups`);
      return response as IBaseData<IFrameGroupsVM[]>;
    },
    retry: 0,
  });
};
