import CalendarItem from "./CalendarItem";
import UpcomingPostsHead from "./UpcomingPostsHead";
import { useGetCalendarData } from "../../../../api/calendar/get";
import { addDays, format, isSameDay } from "date-fns";
import { useCalendarHelper } from "../../../Calendar/hooks/useCalendarHelper";
import { useEffect } from "react";

function UpcomingPosts({ selectedDate }: { selectedDate: Date }) {
  const { convertToISOAndChangeTimezone } = useCalendarHelper();
  const fromDate = convertToISOAndChangeTimezone(
    new Date(format(addDays(selectedDate, -1), "yyyy-MM-dd"))
  );
  const toDate = convertToISOAndChangeTimezone(
    new Date(format(addDays(selectedDate, +1), "yyyy-MM-dd"))
  );
  const sDate = convertToISOAndChangeTimezone(
    new Date(format(selectedDate, "yyyy-MM-dd"))
  );
  const { data: posts } = useGetCalendarData({
    from: fromDate,
    to: toDate,
  });
  const days = [fromDate, sDate, toDate];

  return (
    <div>
      <UpcomingPostsHead />
      <div className="bg-base-white border border-gray-200 rounded-[8px] overflow-hidden mt-[12px]">
        {days.map((day, index) => (
          <div key={index} className="flex border-b border-b-gray-200">
            <div className="px-[12px] py-[8px] flex items-center justify-center text-xs min-w-[58px] text-gray-500 border-r-[1px] border-gray-200">
              {format(day, "dd MMM")}
            </div>
            <div className="flex gap-[12px] px-[16px] py-[12px] overflow-auto">
              {posts &&
                posts.data
                  .filter((post) =>
                    isSameDay(new Date(post.releaseDate as string), day)
                  )
                  .map((post, postIndex) => (
                    <CalendarItem key={postIndex} post={post} />
                  ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default UpcomingPosts;
