import React from "react";
import { useCreationStore } from "../../store/creationStore";
import { AnimatePresence, motion } from "framer-motion";
import { Tooltip } from "react-tooltip";

function SuggestedPrompts({
  setValue,
  sendMessage,
  disable,
  files,
}: {
  setValue: React.Dispatch<React.SetStateAction<string>>;
  sendMessage: (t: string) => void;
  disable: boolean;
  files: File[] | null;
}) {
  const { suggestedPrompts, setSuggestedPrompts } = useCreationStore();
  const container = {
    hidden: { opacity: 0, translateY: 100 },
    show: {
      opacity: 1,
      translateY: 0,
      transition: { duration: 0.1, staggerChildren: 0.1 },
    },
  };
  const child = {
    hidden: { opacity: 0, translateY: 100 },
    show: { opacity: 1, translateY: 0, transition: { duration: 0.1 } },
  };

  return (
    <div className="min-h-[30px] mb-[10px] mt-[10px]">
      <AnimatePresence>
        {suggestedPrompts.length > 0 && !disable && (
          <motion.div
            variants={container}
            initial="hidden"
            animate="show"
            exit="hidden"
            className={
              "flex gap-[6px] h-full overflow-x-auto overflow-y-hidden px-[16px]"
            }
          >
            {suggestedPrompts.map((prompt) => (
              <>
                {files && files.length > 5 && (
                  <Tooltip id={`${prompt.value}-tooltip`}>
                    You can't upload more than 5 images
                  </Tooltip>
                )}
                <motion.p
                  data-tooltip-id={`${prompt.value}-tooltip`}
                  variants={child}
                  onClick={() => {
                    if (files && files.length > 5) {
                      return;
                    }
                    if (prompt.autoSend) {
                      setSuggestedPrompts([]);
                      sendMessage(prompt.value);
                    } else {
                      setValue(prompt.value);
                    }
                    document
                      .getElementById("create-brand-chat-textarea")
                      ?.focus();
                  }}
                  className={`${files && files.length > 5 ? "opacity-50 cursor-default" : "cursor-pointer opacity-100 hover:bg-gray-200 hover:text-gray-800"} min-w-fit border h-[30px] border-gray-300 rounded-full text-sm-m text-gray-700 px-[12px] py-[4px] transition-all`}
                >
                  {prompt.label}
                </motion.p>
              </>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default SuggestedPrompts;
