import { format, isSameDay } from "date-fns";
import React from "react";
import { useCalendarStore } from "../../../store/calendarStore";

function DayHead() {
  const { currentDate } = useCalendarStore();
  return (
    <div className="flex sticky top-0 z-10 bg-gray-50 border-b">
      <div className="w-[56px] border-r-[1px] border-gray-100"></div>
      <div className="flex flex-col pt-[16px] pb-[16px] pl-[16px] p">
        <h2 className="text-sm-m text-gray-400">
          {format(currentDate, "EEEE")}
        </h2>
        <div className="flex items-center justify-start gap-[8px]">
          <p className="text-sm-m text-gray-800">
            {format(currentDate, "d MMMM")}
          </p>
          {isSameDay(currentDate, new Date()) && (
            <div className="flex h-[8px] w-[8px] items-center justify-center rounded-full bg-amethyst-400"></div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DayHead;
