import React from "react";
import SButton from "../../../../../../design-system/SButton";
import { useCreationStore } from "../../../../../../store/creationStore";
import { useGetFiles } from "../../../../../../api/library/files/get";
import { ISingleFIleVM } from "../../../../../../utils/interfaces/IAssets";

export const GenerationsSkeleton = () => {
  return (
    <div className={`flex gap-[12px] py-[24px] px-[24px] animate-pulse`}>
      <div className="w-[96px] h-[96px] rounded-[12px] bg-gray-200" />
      <div className="w-[96px] h-[96px] rounded-[12px] bg-gray-200" />
      <div className="w-[96px] h-[96px] rounded-[12px] bg-gray-200" />
    </div>
  );
};

function Generations({
  setActiveTab,
  setSelected,
}: {
  setActiveTab: React.Dispatch<
    React.SetStateAction<
      "search" | "generator" | "stocks" | "selector" | "gifs"
    >
  >;
  setSelected: React.Dispatch<React.SetStateAction<ISingleFIleVM | null>>;
}) {
  const { postData } = useCreationStore();
  const { data: files, isFetching } = useGetFiles({
    page: 1,
    pageSize: 30,
    generatedOnly: true,
    postId: postData?.id,
  });

  if (isFetching) {
    return <GenerationsSkeleton />;
  }

  if (!files?.data.files[0]) {
    return null;
  }

  return (
    <>
      <div className="w-full h-[4px] bg-gray-100 mt-[12px] mb-[12px]" />
      <div className="px-[24px]">
        <div className="w-full flex items-center justify-between">
          <p className="text-xs-m text-gray-400">Generated Images</p>
          <SButton
            type="linkColor"
            size="sm"
            onClick={() => setActiveTab("selector")}
          >
            👉 See all
          </SButton>
        </div>
        <div className="mt-[8px] flex overflow-auto gap-[12px]">
          {files?.data.files.slice(0, 5).map((item) => (
            <img
              onClick={() => {
                setSelected(item);
                setActiveTab("selector");
              }}
              key={item?.thumbnailSmallUrl || item.url}
              className="max-w-[96px] cursor-pointer w-full aspect-square object-cover rounded-[12px]"
              src={item?.thumbnailSmallUrl || item.url}
              alt="generated-image"
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default Generations;
