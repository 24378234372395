import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  IChildBrandCOE,
  ISingleBrandVM,
} from "../../../utils/interfaces/IBrand";
import { makeRequest } from "../../auth/axios";
import { IBaseData } from "../../../utils/interfaces/IBase";

export const usePostChildBrand = () => {
  const queryClient = useQueryClient();
  return useMutation<IBaseData<ISingleBrandVM>, Error, IChildBrandCOE>({
    mutationFn: ({ id, subOrganizationIds }) => {
      const query = subOrganizationIds
        .map((id) => `subOrganizationIds=${id}`)
        .join("&");
      return makeRequest.post(`/Organizations/${id}/SubOrganizations?${query}`);
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["useGetCurrentBrand"] });
      queryClient.invalidateQueries({ queryKey: ["useGetAllBrands"] });
    },
  });
};
