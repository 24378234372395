import { useEffect } from "react";
import { useBrandStore } from "../../../store/brandStore";
import { useConnectBrandsHelpers } from "./useConnectBrandsHelpers";
import { OrganizationType } from "../../../utils/enums/Enums";

export const useConnectBrands = () => {
  const { selectedBrands, setSelectedBrands } = useBrandStore();
  const {
    getPersonalBrands,
    getCompanyBrands,
    currentBrand,
    addConnectBrandIds,
    addDisconnectBrandIds,
  } = useConnectBrandsHelpers();

  useEffect(() => {
    if (currentBrand) {
      if (currentBrand?.data.organizationType === OrganizationType.COMPANY) {
        setSelectedBrands(
          currentBrand?.data.subOrganizations.map((sub) => ({
            value: sub.id,
            label: sub.name,
            avatar: sub.logo,
          }))
        );
      }
      if (currentBrand?.data.organizationType === OrganizationType.PERSONAL) {
        setSelectedBrands(
          currentBrand?.data.parentOrganizations.map((sub) => ({
            value: sub.id,
            label: sub.name,
            avatar: sub.logo,
          }))
        );
      }
    }
  }, [currentBrand?.data.id]);

  useEffect(() => {
    const selectedBrandIds = selectedBrands.map((brand) => brand.value);
    addConnectBrandIds(selectedBrandIds);
    addDisconnectBrandIds(selectedBrandIds);
  }, [selectedBrands]);

  return {
    selectedBrands,
    setSelectedBrands,
    getPersonalBrands,
    getCompanyBrands,
    currentBrand,
  };
};
