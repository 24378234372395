import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

export interface IDesktopSubMenuProps {
  subMenuRef: any;
  subMenuDirection: "up" | "down";
  subMenuItems?: { title: string; path: string }[];
  title: string;
}

export const DesktopSubMenu = ({
  subMenuRef,
  subMenuDirection,
  subMenuItems,
  title,
}: IDesktopSubMenuProps) => {
  const navigate = useNavigate();
  return (
    <motion.div
      ref={subMenuRef}
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.95 }}
      transition={{ duration: 0.1 }}
      className={`absolute h-[70px] flex flex-col -right-2`}
    >
      <div
        className={`translate-x-[calc(100%+0px)] ${subMenuDirection === "up" && "-translate-y-[calc(100%-40px)]"}  p-3 rounded-lg border-2 border-gray-200 bg-gray-50 shadow-xl`}
      >
        {subMenuItems?.map((item) => {
          return (
            <p
              onClick={(e) => {
                e.stopPropagation();
                navigate(item.path);
              }}
              className="capitalize text-gray-700 text-md-m whitespace-nowrap rounded-lg px-3 py-2 hover:bg-gray-200"
            >
              {item.title}
            </p>
          );
        })}
      </div>
    </motion.div>
  );
};
