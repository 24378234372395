import { useEffect } from "react";
import { useCreationStore } from "../../../../../../store/creationStore";
import { usePostStockImages } from "../../../../../../api/creation/stocks/post";

export const useGenerateStocks = ({ isOpen }: { isOpen: boolean }) => {
  const { mutateAsync: generateStocks } = usePostStockImages();
  const {
    stocks,
    setStocks,
    defaultStockStory,
    postData,
    stocksPage,
    setStocksPage,
  } = useCreationStore();
  useEffect(() => {
    if (isOpen) {
      if (stocks.length === 0) {
        generateStocks({
          page: stocksPage,
          story: postData?.story || defaultStockStory,
        }).then((res) => {
          setStocks(res.data.images);
        });
      }
    }
  }, [isOpen]);
};
