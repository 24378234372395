import { useQuery } from "@tanstack/react-query";
import { BrandPostIdea } from "../../../utils/interfaces/IBrand";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { makeRequest } from "../../auth/axios";

export const useGetPostIdeas = () => {
  return useQuery<IBaseData<BrandPostIdea[]>>({
    queryKey: ["useGetPostIdeas"],
    queryFn: async () => {
      const response = await makeRequest.get(`/Organizations/PostIdeas`);
      return response as IBaseData<BrandPostIdea[]>;
    },
  });
};
