import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../axios";
import { IBaseData } from "../../utils/interfaces/IBase";
import { INotificationItem } from "../../components/notifications/NotificationItem";

export const usePutAllNotifications = () => {
  const queryClient = useQueryClient();
  return useMutation<IBaseData<INotificationItem>, Error>({
    mutationFn: () => makeRequest.put(`/Notifications/AllRead`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["useGetNotifications"] });
    },
  });
};
