import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../axios";
import { IBrandCOE, ISingleBrandVM } from "../../utils/interfaces/IBrand";
import { IBaseData } from "../../utils/interfaces/IBase";

export const usePostBrand = () => {
  const queryClient = useQueryClient();
  return useMutation<IBaseData<ISingleBrandVM>, Error, IBrandCOE>({
    mutationFn: (data) => makeRequest.post("/Organizations", { ...data }),
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["useGetBrand"] });
      queryClient.invalidateQueries({ queryKey: ["useGetOnboardingInfo"] });
      queryClient.invalidateQueries({ queryKey: ["useGetTeamsLimits"] });
      queryClient.invalidateQueries({ queryKey: ["useGetAllBrands"] });
    },
  });
};
