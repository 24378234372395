export function getMimeTypeFromUrl(url: string) {
  const extension = url!.split(".")!.pop()!.toLowerCase();
  const mimeTypes = {
    jpg: "image/jpeg",
    jpeg: "image/jpeg",
    png: "image/png",
    gif: "image/gif",
    svg: "image/svg+xml",
    webp: "image/webp",
    mp4: "video/mp4",
    mp3: "audio/mpeg",
    pdf: "application/pdf",
    txt: "text/plain",
    html: "text/html",
    css: "text/css",
    js: "application/javascript",
    json: "application/json",
  };

  return (
    mimeTypes[extension as keyof typeof mimeTypes] || "application/octet-stream"
  );
}
export function getFilenameFromUrl(url: string): string {
  const parsedUrl = new URL(url);
  const pathname = parsedUrl.pathname;
  const filename = pathname.substring(pathname.lastIndexOf("/") + 1);
  return filename.split(/[?#]/)[0];
}
export async function getFileFromUrl(url: string) {
  const response = await fetch(url);
  const blob = await response.blob();
  return new File([blob], getFilenameFromUrl(url), {
    type: getMimeTypeFromUrl(url),
  });
}
