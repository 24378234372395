import { useLocation } from "react-router-dom";
import { getQuery } from "../../../hooks/functions/queries";
import { useEffect } from "react";

export const useSetDefaultCalendarSection = ({
  setActiveView,
}: {
  setActiveView: (value: "month" | "week" | "day") => void;
}) => {
  const location = useLocation();
  const querySection = getQuery("section") || getQuery("subsection");
  const viewTypes: any = ["month", "week", "day"];
  useEffect(() => {
    if (querySection) {
      setActiveView(
        viewTypes.find((item: any) => item === querySection) || "month"
      );
    }
  }, [location]);
};
