import { faUnlock } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import SButton from "../../design-system/SButton";
import { useNavigate } from "react-router-dom";
function NeedBrandPageView({
  title,
  text,
  image,
}: {
  title: string;
  text: string;
  image: string;
}) {
  const navigate = useNavigate();
  return (
    <div className="relative flex justify-center items-center gap-[44px] h-[70dvh] max-md:flex-col-reverse max-md:gap-[20px] max-md:h-full max-md:py-[20px]">
      <div className="max-w-[412px] w-1/2 max-md:w-full flex flex-col gap-[16px]">
        <div className="flex items-center gap-[8px]">
          <div className="w-[20px] h-[20px] rounded-full flex justify-center items-center bg-gray-100 border border-brand-500">
            <FontAwesomeIcon
              icon={faUnlock}
              className="text-[12px] ml-[1px] text-brand-700"
            />
          </div>
          <p className="text-md text-gray-800">
            Unlock all STORI tools by creating your brand
          </p>
        </div>
        <p className="display-sm-b text-gray-700">{title}</p>
        <p className="text-md text-gray-800">{text}</p>
        <SButton onClick={() => navigate("/create-brand")} className="w-full">
          Create Brand To Start
        </SButton>
      </div>
      <img
        src={image}
        alt="stori-image"
        className="max-w-[560px] h-full object-contain w-1/2 max-md:w-full"
      />
    </div>
  );
}

export default NeedBrandPageView;
