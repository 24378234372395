import { useDeleteTemplate } from "../../../../api/creation/frames/remove/delete";
import SModal from "../../../../design-system/SModal";
import { toast } from "../../../../hooks/toast";
import { useCreationStore } from "../../../../store/creationStore";
import { useCreationHelperFunctions } from "../../hooks/useCreationHelperFunctions";
import { useFramesStore } from "../../../../store/framesStore";

export interface IConfirmDeleteFrame {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  url: string;
  onRemove?: () => void;
}

export const ConfirmDeleteFrame = ({
  isOpen,
  setIsOpen,
  url,
  onRemove,
}: IConfirmDeleteFrame) => {
  const { mutateAsync: deleteTemplate } = useDeleteTemplate();
  const { setPostData } = useCreationStore();
  const { getActiveSocialNetworkPost, postData } = useCreationHelperFunctions();
  const { setSelectedImage } = useFramesStore();
  const postId = getActiveSocialNetworkPost()?.postId;
  let fileIds: number[] | undefined = [];
  fileIds = postData?.socialNetworkPosts
    .flatMap((item) => item.files)
    .filter((item) => item.url === url && item.isTemplateUsed)
    .map((item) => item.id || 0);
  return (
    <SModal
      title={"Remove template"}
      subtitle={`Are you sure you want to remove this template?`}
      type="error"
      isOpen={isOpen}
      setIsOpen={() => setIsOpen(false)}
      handleSubmit={() => console.log("asd")}
      handleCancel={() => {
        setIsOpen(false);
      }}
      actionButtonText={"Remove"}
      cancelButtonText={"Cancel"}
      actionButtonProps={{
        onClick: () => {
          deleteTemplate({ postId: postId || 0, fileIds: fileIds || [] })
            .then((res) => {
              if (
                fileIds?.length === 0 &&
                getActiveSocialNetworkPost()?.files.length === 1
              ) {
                setSelectedImage(null);
              }
              toast("Frame removed successfully", "success");
              setPostData(res.data);
              onRemove && onRemove();
            })
            .catch(() => {
              toast("Failed to remove frame", "error");
            })
            .finally(() => {
              setIsOpen(false);
            });
        },
        className: "w-[400px]",
      }}
    />
  );
};
