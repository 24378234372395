export const LibrarySkeleton = ({ className }: { className?: string }) => {
  return (
    <div
      className={`${className} w-full h-[300px] cursor-pointer flex py-[24px] px-[24px] animate-pulse`}
    >
      <div className="w-full h-full rounded-xl bg-gray-100 flex justify-center flex-col gap-3 items-start">
        <div className="w-full h-full bg-gray-200 rounded-t-xl flex justify-center items-center" />
        <div className="w-full h-[20%] items-start flex flex-col gap-3 px-3 pb-3">
          <div className="w-3/4 h-[2rem] bg-gray-200 rounded-xl" />
          <div className="w-1/2 h-[2rem] bg-gray-300 rounded-xl" />
        </div>
      </div>
    </div>
  );
};
