import { useEffect, useState } from "react";
import SModalBase from "../../design-system/SModalBase";
import SButton from "../../design-system/SButton";
import SBadge from "../../design-system/SBadge";
import {
  faDiscord,
  faFacebook,
  faInstagram,
  faLinkedin,
  faPinterest,
  faReddit,
  faSlack,
  faTelegram,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { SocialNetworkType } from "../../utils/enums/Enums";
import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import { useBrandStore } from "../../store/brandStore";
import { usePutBrandActiveSocialNetworks } from "../../api/brand/selectSocialNetworks/put";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useCreationStore } from "../../store/creationStore";
import { useCreationHelperFunctions } from "../creation/hooks/useCreationHelperFunctions";

export interface WhichSocialNetworkTypeItem {
  icon: IconProp;
  name: string;
  type: SocialNetworkType;
  active: boolean;
  isCommunity?: boolean;
}

function WhichSocialNetworksSelector({
  additionalFunction,
  isOpen,
  onClose,
  saveAllOnClose,
}: {
  additionalFunction?: (
    socialNetworkType: SocialNetworkType[],
    append: boolean
  ) => void;
  saveAllOnClose: boolean;
  isOpen: boolean;
  onClose: () => void;
}) {
  const { currentBrand } = useBrandStore();
  const {
    mutateAsync: putBrandActiveSocialNetworks,
    isPending: isPutBrandActiveSocialNetworksPending,
  } = usePutBrandActiveSocialNetworks();
  const [selected, setSelected] = useState<SocialNetworkType[] | undefined>(
    undefined
  );
  const { activeSoc } = useCreationStore();
  const { handleSetFirstActiveSocialNetwork } = useCreationHelperFunctions();
  const [isRemoving, setIsRemoving] = useState(false);
  useEffect(() => {
    if (selected === undefined && currentBrand) {
      setSelected(currentBrand.activeSocialNetworks);
    }
  }, [selected, currentBrand]);

  const socialNetworks: WhichSocialNetworkTypeItem[] = [
    {
      icon: faXTwitter,
      name: "Twitter",
      type: SocialNetworkType.TWITTER,
      active: true,
      isCommunity: false,
    },
    {
      icon: faLinkedin,
      name: "Linkedin",
      type: SocialNetworkType.LINKEDIN,
      active: true,
      isCommunity: false,
    },
    {
      icon: faInstagram,
      name: "Instagram",
      type: SocialNetworkType.INSTAGRAM,
      active: true,
      isCommunity: false,
    },
    {
      icon: faFacebook,
      name: "Facebook",
      type: SocialNetworkType.FACEBOOK,
      active: true,
      isCommunity: false,
    },

    {
      icon: faPinterest,
      name: "Pinterest",
      type: SocialNetworkType.PINTEREST,
      active: true,
      isCommunity: false,
    },
    {
      icon: faReddit,
      name: "Reddit",
      type: SocialNetworkType.REDDIT,
      active: true,
      isCommunity: true,
    },
    {
      icon: faTelegram,
      name: "Telegram",
      type: SocialNetworkType.TELEGRAM,
      active: true,
      isCommunity: true,
    },
    {
      icon: faSlack,
      name: "Slack",
      type: SocialNetworkType.SLACK,
      active: true,
      isCommunity: true,
    },
    {
      icon: faDiscord,
      name: "Discord",
      type: SocialNetworkType.DISCORD,
      active: true,
      isCommunity: true,
    },
  ];

  const handleClose = () => {
    if (!currentBrand) return;
    if (saveAllOnClose) {
      putBrandActiveSocialNetworks({
        brandId: currentBrand.id,
        socialNetworkTypes: [
          SocialNetworkType.TWITTER,
          SocialNetworkType.LINKEDIN,
          SocialNetworkType.INSTAGRAM,
          SocialNetworkType.FACEBOOK,
          SocialNetworkType.PINTEREST,
          SocialNetworkType.REDDIT,
          SocialNetworkType.TELEGRAM,
          SocialNetworkType.DISCORD,
          SocialNetworkType.SLACK,
        ],
      }).then((res) => {
        onClose();
      });
    } else {
      onClose();
    }
  };

  const handleSave = () => {
    if (!currentBrand || !selected) return;
    putBrandActiveSocialNetworks({
      brandId: currentBrand.id,
      socialNetworkTypes: selected,
    }).then((res) => {
      if (additionalFunction) {
        additionalFunction(res.data.activeSocialNetworks, true);
      }
      if (isRemoving)
        handleSetFirstActiveSocialNetwork(res.data.activeSocialNetworks);
      onClose();
    });
  };

  return (
    <SModalBase
      dialogPanelClassName="w-full py-[12px] max-w-[544px]"
      isOpen={isOpen}
      onClose={handleClose}
      showX={false}
    >
      <h1 className="text-md-m mb-[12px] text-gray-600">
        Which platforms do you use? 🤔
      </h1>
      <hr className="w-auto mx-[-24px] mb-[16px]" />
      <div className="w-full flex flex-col">
        <p className="text-sm-m text-gray-600 mb-2">Social Networks</p>
        <div className="flex flex-wrap gap-[12px] mb-5">
          {socialNetworks
            .filter((item) => !item.isCommunity)
            .map((item, i) => (
              <>
                <Tooltip
                  id={`which-social-network-tooltip-comming-soon-${item.type}`}
                >
                  Coming Soon
                </Tooltip>
                <SBadge
                  onClick={() => {
                    if (!selected) return;
                    if (item.active) {
                      if (selected.includes(item.type)) {
                        setSelected((prev) =>
                          prev!.filter((t) => t !== item.type)
                        );
                        if (activeSoc === item.type) {
                          setIsRemoving(true);
                        }
                      } else {
                        setSelected((prev) => [...prev!, item.type]);
                      }
                    }
                  }}
                  size="lg"
                  data-tooltip-id={
                    !item.active
                      ? `which-social-network-tooltip-comming-soon-${item.type}`
                      : undefined
                  }
                  className={`select-none rounded-[8px] cursor-pointer ${!item.active && "opacity-40"} `}
                  hasBackground={
                    selected && selected.includes(item.type) ? true : false
                  }
                  color="success"
                  icon={item.icon}
                >
                  {item.name}
                </SBadge>
              </>
            ))}
        </div>
        <p className="text-sm-m text-gray-600 mb-2">Communities</p>
        <div className="flex flex-wrap gap-[12px] mb-5">
          {socialNetworks
            .filter((item) => item.isCommunity)
            .map((item, i) => (
              <>
                <Tooltip
                  id={`which-social-network-tooltip-comming-soon-${item.type}`}
                >
                  Coming Soon
                </Tooltip>
                <SBadge
                  onClick={() => {
                    if (!selected) return;
                    if (item.active) {
                      if (selected.includes(item.type)) {
                        setSelected((prev) =>
                          prev!.filter((t) => t !== item.type)
                        );
                        if (activeSoc === item.type) {
                          setIsRemoving(true);
                        }
                      } else {
                        setSelected((prev) => [...prev!, item.type]);
                      }
                    }
                  }}
                  size="lg"
                  data-tooltip-id={
                    !item.active
                      ? `which-social-network-tooltip-comming-soon-${item.type}`
                      : undefined
                  }
                  className={`select-none rounded-[8px] cursor-pointer ${!item.active && "opacity-40"} `}
                  hasBackground={
                    selected && selected.includes(item.type) ? true : false
                  }
                  color="success"
                  icon={item.icon}
                >
                  {item.name}
                </SBadge>
              </>
            ))}
        </div>
        <div className="w-full flex items-center justify-end gap-[12px]">
          <div className="flex items-center gap-[6px] text-[14px]">
            <Tooltip id="which-social-network-tooltip-info">
              We will use the selected social <br /> networks to generate
              content for you.
              <br /> Later you can change this.
            </Tooltip>
            <div data-tooltip-id="which-social-network-tooltip-info">
              <FontAwesomeIcon
                icon={faQuestionCircle}
                className="text-gray-400"
              />
            </div>
            <SButton
              type="secondaryGray"
              onClick={handleClose}
              isLoading={isPutBrandActiveSocialNetworksPending}
            >
              {saveAllOnClose ? "Close & Select all" : "Close"}
            </SButton>
          </div>
          {selected && selected.length === 0 && (
            <Tooltip id="which-social-network-tooltip-save">
              You need to select at least one social network
            </Tooltip>
          )}
          <SButton
            onClick={handleSave}
            isLoading={isPutBrandActiveSocialNetworksPending}
            data-tooltip-id="which-social-network-tooltip-save"
            disabled={selected && selected!.length === 0}
            children="Save"
            className="w-[90px]"
          />
        </div>
      </div>
    </SModalBase>
  );
}

export default WhichSocialNetworksSelector;
