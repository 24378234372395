import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../../axios";
import {
  IBrandRegenerationCOE,
  ISingleBrandVM,
} from "../../../utils/interfaces/IBrand";
import { IBaseData } from "../../../utils/interfaces/IBase";

export const usePostRegenerateBrand = () => {
  const queryClient = useQueryClient();
  return useMutation<
    IBaseData<ISingleBrandVM>,
    any,
    { brandId: number; regenerationData: IBrandRegenerationCOE }
  >({
    mutationFn: ({ brandId, regenerationData }) =>
      makeRequest.post(`Organizations/${brandId}/RegenerateBrandData`, {
        ...regenerationData,
      }),
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["useGetCurrentBrand"] });
      return res;
    },
  });
};
