import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { ISocialNetworkPostVM } from "../../../utils/interfaces/IPost";
import {
  calendarStringByStatus,
  getCalendarItemBGColor,
  getCalendarItemOpacityBGColor,
  getCalendarItemTextColor,
} from "../functions/calendarRenderHelper";
import { useCalendarHelper } from "../hooks/useCalendarHelper";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
// @ts-ignore
import noImage from "../../../assets/images/no-image-gray.jpg";

function DayCard({
  className,
  post,
}: {
  className?: string;
  post: ISocialNetworkPostVM;
}) {
  const { getIcon, openPostFromCalendar } = useCalendarHelper();
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        openPostFromCalendar(post.postId);
      }}
      className={`cursor-pointer flex w-[320px] gap-[6px] rounded-[8px] h-[62px] overflow-hidden ${getCalendarItemOpacityBGColor(post.postStatus)} items-center`}
    >
      <div
        className={`${getCalendarItemBGColor(post.postStatus)} px-[12px] h-full flex justify-center items-center`}
      >
        <FontAwesomeIcon
          icon={getIcon(post.socialNetworkType) as IconProp}
          className="text-[20px] text-base-white"
        />
      </div>
      <div className="flex gap-[8px] items-center">
        <img
          src={post.files[0]?.url ?? noImage}
          alt="post image"
          className="w-[48px] h-[48px] rounded-[8px]"
        />
        <div className="py-[10px]">
          <p className="text-gray-700 text-md-m">{post.name ?? "New Post"}</p>
          <p
            className={`${getCalendarItemTextColor(post.postStatus)} mt-[2px] text-xs`}
          >
            {calendarStringByStatus(post.postStatus)}
          </p>
        </div>
      </div>
    </div>
  );
}

export default DayCard;
