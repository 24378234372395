import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/pro-solid-svg-icons";
import { faCloudArrowDown, faExternalLink } from "@fortawesome/pro-regular-svg-icons";
import SCheckbox from "../../../design-system/SCheckbox";
import "../../../index.css";
import SBadge from "../../../design-system/SBadge";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { IWorkspaceInvoices } from "../../../utils/interfaces/IWorkspace";
import {
  formatDate,
  formatTimeFromDate,
} from "../../Calendar/functions/formatTimeFromDate";
//@ts-ignore
import PDFFile from '../../../assets/icons/pdf.svg'

export interface IBillingListRow {
  invoiceData: IWorkspaceInvoices;
  selected?: boolean;
  className?: string;
  onSelect?: () => void;
  onClickHyperLink?: () => void;
}

function BillingListRow({
  invoiceData,
  selected,
  className,
  onSelect,
  onClickHyperLink,
}: IBillingListRow) {
  return (
    <tr className={`${className} border-b border-gray-200 items-center`}>
      <td className="pl-[24px] py-[16px] w-7/12">
        <div className="flex items-center gap-4">
          <img src={PDFFile} className="w-8 h-10" alt='PDF File of the invoice' />
          <span className="text-gray-900 text-xs-m">
            {invoiceData.description}
          </span>
        </div>
      </td>
      <td className="py-[16px] w-2/12 text-xs-m text-slate-600">
        ${invoiceData.amountPaid}
      </td>
      <td className="py-[16px] w-2/12 text-xs-m text-slate-600">
        {formatDate(invoiceData.created)}
      </td>
      <td className="py-[16px] w-2/12 text-xs-m text-slate-600">
        <div className="flex items-center">
          <SBadge
            color={"success"}
            icon={faCheck}
            children={invoiceData.status}
            hasBackground={true}
            className={"capitalize"}
          />
        </div>
      </td>
      <td className="p-[16px] w-min flex items-center gap-[2px]">
        <button
          onClick={onClickHyperLink}
          aria-label="Download"
          className="p-[10px] text-gray-600 hover:text-gray-800"
        >
          <FontAwesomeIcon icon={faExternalLink} />
        </button>
      </td>
    </tr>
  );
}

export default BillingListRow;
