import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import "../../../index.css";
import { IWorkspaceUsersVM } from "../../../utils/interfaces/IWorkspace";
//@ts-ignore
import AvatarIcon from "../../../assets/icons/avatar.svg";
import SBadge from "../../../design-system/SBadge";
import { OrganizationType } from "../../../utils/enums/Enums";

export interface IWorkspaceMembersRow {
  userData: IWorkspaceUsersVM;
  className?: string;
  onDelete?: () => void;
  onEdit?: () => void;
  isViewer?: boolean;
}

function WorkspaceMembersRow({
  userData,
  className,
  onDelete,
  onEdit,
  isViewer,
}: IWorkspaceMembersRow) {
  const remainingCount = Math.max(0, userData.organizationUsers.length - 5);
  return (
    <tr className={`${className} border-b border-gray-200 items-center`}>
      <td className="px-[24px] py-[16px] w-7/12">
        <div className="flex items-center">
          {userData.photoUrl && (
            <img
              src={userData.photoUrl}
              alt={userData.name}
              className="w-[40px] h-[40px] rounded-full object-cover mr-[12px]"
            />
          )}
          {!userData.photoUrl && (
            <img
              src={AvatarIcon}
              alt={userData.name}
              className="w-[40px] h-[40px] rounded-full object-cover mr-[12px]"
            />
          )}
          <div className="flex flex-col">
            <span className="text-gray-900 text-sm-m">{userData.name}</span>
            {!userData.invitationStatus && (
              <span className="text-gray-600 text-sm">{userData.email}</span>
            )}
            {userData.invitationStatus && (
              <span className="text-gray-600 text-sm">Pending</span>
            )}
          </div>
        </div>
      </td>
      <td className="py-[16px] w-2/12 text-sm text-slate-600">
        <SBadge
          children={userData.invitationStatus ? "Pending" : "Active"}
          size={"sm"}
          color={userData.invitationStatus ? "warning" : "success"}
          hasBackground={false}
          dotted={true}
        />
      </td>
      <td className="py-[16px] w-2/12 text-sm text-slate-600 capitalize">
        {userData.role}
      </td>
      <td className="py-[16px] pr-[32px] w-2/12 max-md:w-4/12 max-md:pr-[26px]">
        <div className="flex align-center">
          {userData.organizationUsers.slice(0, 5).map((brand, index) => (
            <>
              {brand.organizationSecondLogo ? (
                <img
                  className="bg-white w-[30px] h-[30px] mr-[-5px] rounded-full border-[1px] object-contain border-base-white"
                  src={brand.organizationSecondLogo}
                  alt={`Avatar ${index + 1}`}
                />
              ) : (
                <div className="flex items-center justify-center bg-white p-2 w-[30px] h-[30px] mr-[-5px] rounded-full border-[1px] object-contain text-sm-sb text-gray-700 border-gray-200">
                  {brand?.name.slice(0, 2).toUpperCase()}
                </div>
              )}
            </>
          ))}
          {remainingCount > 0 && (
            <div className="flex items-center justify-center p-2 w-[30px] h-[30px] rounded-full bg-gray-200 border-[1px] border-white">
              <span className={"text-xs-sb text-gray-600"}>
                +{remainingCount}
              </span>
            </div>
          )}
        </div>
      </td>
      <td className="py-[16px] pl-[48px] pr-[32px] w-min flex items-center gap-[2px]">
        {userData.role !== "Owner" && !isViewer && (
          <button
            onClick={onEdit}
            aria-label="Edit"
            className="p-[10px] text-gray-600 hover:text-gray-800"
          >
            <FontAwesomeIcon icon={faPen} />
          </button>
        )}
      </td>
    </tr>
  );
}

export default WorkspaceMembersRow;
