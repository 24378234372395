import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ISingleBrandVM } from "../../../utils/interfaces/IBrand";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { makeRequest } from "../../auth/axios";

export const usePutBrandInfo = () => {
  const queryClient = useQueryClient();
  return useMutation<
    IBaseData<ISingleBrandVM>,
    Error,
    {
      brandId: number;
      contactData: {
        phone: string | null;
        website: string | null;
        email: string | null;
        name: string;
      };
    }
  >({
    mutationFn: ({ brandId, contactData }) =>
      makeRequest.put(`/Organizations/${brandId}/Info`, {
        ...contactData,
      }),
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["useGetBrand"] });
      queryClient.invalidateQueries({ queryKey: ["useGetCurrentBrand"] });
      queryClient.invalidateQueries({ queryKey: ["useGetAllBrands"] });
    },
  });
};
