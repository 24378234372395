import { faCheck, faClose, faSearch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  components,
  DropdownIndicatorProps,
  MultiValueGenericProps,
  OptionProps,
} from "react-select";
import SBadge from "../../design-system/SBadge";

export const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon icon={faSearch} />
    </components.DropdownIndicator>
  );
};

export const MultiValue = (props: MultiValueGenericProps) => {
  return (
    <components.MultiValueLabel {...props}>
      <SBadge className="rounded-[6px] mr-2 mt-[3.75px] shadow-none flex items-center gap-2">
        {props.data.avatar &&
          props.data.avatar !==
            "https://cdn.storiai.com/defaultOrganizationLogo.png" && (
            <img
              className="w-5 h-5 rounded-full object-cover"
              alt="Brand Logo"
              src={props.data.avatar}
            />
          )}
        {(!props.data.avatar ||
          props.data.avatar ===
            "https://cdn.storiai.com/defaultOrganizationLogo.png") && (
          <div className="flex bg-gray-50 rounded-[4px] leading-none items-center justify-center text-xs">
            {props.data.label?.slice(0, 2).toUpperCase()}
          </div>
        )}
        <p className="text-sm-m text-gray-700">{props.data.label}</p>
        <div
          // @ts-ignore
          onClick={props.removeProps.onClick}
          className="cursor-pointer pl-1"
        >
          <FontAwesomeIcon
            className="text-gray-400 hover:text-gray-600"
            size="sm"
            icon={faClose}
          />
        </div>
      </SBadge>
    </components.MultiValueLabel>
  );
};

export const OptionComponent = (props: OptionProps<any>) => {
  return (
    <components.Option {...props}>
      <div
        onClick={() => {
          if (props.isSelected) {
            props.selectProps.onChange(null, {
              action: "remove-value",
              removedValue: props.data,
            });
          } else {
            props.selectProps.onChange(props.data, {
              action: "select-option",
              option: props.data,
            });
          }
        }}
        className="w-full flex items-center gap-3"
      >
        {props.data.avatar &&
          props.data.avatar !==
            "https://cdn.storiai.com/defaultOrganizationLogo.png" && (
            <img
              className="w-5 h-5 rounded-full object-cover"
              alt="Brand Logo"
              src={props.data.avatar}
            />
          )}
        {(!props.data.avatar ||
          props.data.avatar ===
            "https://cdn.storiai.com/defaultOrganizationLogo.png") && (
          <div className=" flex bg-gray-50 rounded-[4px] items-center justify-center text-xs">
            {props.data.label?.slice(0, 2).toUpperCase()}
          </div>
        )}
        <p>{props.data.label}</p>
        {props.isSelected && (
          <FontAwesomeIcon className="text-brand-600 ml-auto" icon={faCheck} />
        )}
      </div>
    </components.Option>
  );
};
