import {
  faCheck,
  faInfoCircle,
  faInfoSquare,
  faLoader,
  faTriangleExclamation,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastOptions, TypeOptions } from "react-toastify";
import { toast as toastify } from "react-toastify";

export const toast = (
  text: string,
  type: TypeOptions | "loading" = "error",
  options?: ToastOptions,
) => {
  if (type === "info") {
    toastify(text, {
      type: type,
      autoClose: 5000,
      progressStyle: {
        backgroundColor: "#717BBC",
      },
      icon: (
        <div
          className="
        w-[36px] h-[36px] min-w-[36px] min-h-[36px] max-w-[36px] max-h-[36px]
        rounded-[8px] bg-gray-50 border border-grayblue-100 flex items-center justify-center"
        >
          <FontAwesomeIcon
            icon={faInfoSquare}
            className="text-grayblue-500 text-[18px]"
          />
        </div>
      ),
      ...options,
    });
  } else if (type === "success") {
    toastify(text, {
      type: type,
      autoClose: 5000,
      progressStyle: {
        backgroundColor: "#47CD89",
      },
      icon: (
        <div
          className="
        w-[36px] h-[36px] min-w-[36px] min-h-[36px] max-w-[36px] max-h-[36px]
        rounded-[8px] bg-success-50 border border-success-100 flex items-center justify-center"
        >
          <FontAwesomeIcon
            icon={faCheck}
            className="text-success-500 text-[18px]"
          />
        </div>
      ),
      ...options,
    });
  } else if (type === "error") {
    toastify(text, {
      type: type,
      autoClose: 5000,
      progressStyle: {
        backgroundColor: "#F97066",
      },
      icon: (
        <div
          className="
        w-[36px] h-[36px] min-w-[36px] min-h-[36px] max-w-[36px] max-h-[36px]
        rounded-[8px] bg-error-50 border border-error-100 flex items-center justify-center"
        >
          <FontAwesomeIcon
            icon={faInfoCircle}
            className="text-error-500 text-[18px]"
          />
        </div>
      ),
      ...options,
    });
  } else if (type === "warning") {
    toastify(text, {
      type: type,
      autoClose: 5000,
      progressStyle: {
        backgroundColor: "#FDB022",
      },
      icon: (
        <div
          className="
        w-[36px] h-[36px] min-w-[36px] min-h-[36px] max-w-[36px] max-h-[36px]
        rounded-[8px] bg-warning-50 border border-warning-100 flex items-center justify-center"
        >
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            className="text-warning-500 text-[18px]"
          />
        </div>
      ),
      ...options,
    });
  } else if (type === "loading") {
    toastify(text, {
      type: "default",
      autoClose: false,
      hideProgressBar: true,
      icon: (
        <div
          className="
        w-[36px] h-[36px] min-w-[36px] min-h-[36px] max-w-[36px] max-h-[36px]
        rounded-[8px] bg-gray-50 border border-gray-100 flex items-center justify-center"
        >
          <FontAwesomeIcon
            icon={faLoader}
            spin={true}
            className="text-gray-500 text-[18px]"
          />
        </div>
      ),
      ...options,
    });
  }
};
export const closeAllToast = () => {
  toastify.dismiss();
};
