import React, { useState, DragEvent, ChangeEvent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp } from "@fortawesome/pro-regular-svg-icons";
// @ts-ignore
import fileWithHand from "../../assets/icons/file-with-hand.svg";
export interface ISUploaderProps {
  onClick?: () => void;
  onDrop?: (file: File) => void; // Expect a single file
  haveDragAndDrop?: boolean;
  accept?: string;
  className?: string;
  showIcon?: boolean;
}

function SUploader({
  className,
  accept,
  haveDragAndDrop = true,
  showIcon = true,
  onClick,
  onDrop,
  ...props
}: ISUploaderProps) {
  const [isDragging, setIsDragging] = useState(false);

  const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const files = e.dataTransfer.files;
    if (files.length > 0 && onDrop) {
      onDrop(files[0]);
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0 && onDrop) {
      onDrop(files[0]);
    }
  };

  return (
    <div
      onClick={onClick}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      className={`${className} w-full cursor-pointer relative rounded-[12px] border-[2px] flex flex-col justify-center px-[24px] py-[16px] items-center border-brand-600 ${
        isDragging ? "bg-gray-100 border-brand-700" : ""
      }`}
      {...props}
    >
      <div className="flex justify-center items-center rounded-[8px] w-[40px] aspect-square bg-base border-[1px] border-gray-200 shadow-xs">
        <FontAwesomeIcon
          icon={faCloudArrowUp}
          fontSize="16px"
          className="text-gray-600"
        />
      </div>
      <p className="text-sm-sb text-brand-700 mt-[12px]">
        Click to upload
        {haveDragAndDrop && (
          <span className="text-sm text-gray-600"> or drag and drop</span>
        )}
      </p>
      {accept && <p className="mt-[4px] text-xs text-gray-600">{accept}</p>}
      {showIcon && (
        <img
          alt="file-with-hand"
          className="absolute shadow-xl2 bottom-[10px] right-[14px] max-md:bottom-[35px]"
          src={fileWithHand}
        />
      )}
      <input
        type="file"
        accept={accept}
        onChange={handleFileChange}
        className="absolute inset-0 opacity-0 cursor-pointer"
      />
    </div>
  );
}

export default SUploader;
