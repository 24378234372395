import { makeRequest } from "../axios";
import { useQuery } from "@tanstack/react-query";
import { IAccountVM } from "../utils/interfaces/IAccount";
import { IBase } from "../utils/interfaces/IBase";
import { useAccountStore } from "../store/accountStore";

export const useGetUser = () => {
  const isAuthenticated = !!localStorage.getItem("token");
  const { setPersonalInfo } = useAccountStore();

  return useQuery<IBase<IAccountVM>>({
    queryKey: ["useGetUser"],
    queryFn: async () => {
      const response = await makeRequest.get("/Account");
      setPersonalInfo(response.data.data);
      return response as IBase<IAccountVM>;
    },
    enabled: !!isAuthenticated,
  });
};
