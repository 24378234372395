import React from "react";

const LettersAvatar = ({
  text,
  className,
}: {
  className?: string;
  text: string;
}) => {
  // Extract initials from the text
  const getInitials = (name: string) => {
    const words = name.split(" ");
    if (words.length === 1) {
      // If there's only one word, take the first two letters
      return name.substring(0, 2).toUpperCase();
    } else {
      // Otherwise, take the first letter of the first two words
      return `${words[0].charAt(0)}${words[1].charAt(0)}`.toUpperCase();
    }
  };
  const initials = getInitials(text);

  return (
    <div
      className={`${className} aspect-square w-[36px] h-[36px] max-w-[36px] max-h-[36px] rounded-full bg-brand-300 text-base-white text-md-sb flex justify-center items-center uppercase`}
    >
      {initials}
    </div>
  );
};

export default LettersAvatar;
