import React from "react";

const Tab = ({
  isActive,
  size,
  text,
  number,
  handleClick,
  width,
}: {
  width: "fit" | "full";
  handleClick: () => void;
  text: string;
  number?: number;
  size: "sm" | "md";
  isActive: boolean;
}) => (
  <p
    onClick={handleClick}
    className={`   cursor-pointer px-[12px] text-center
        ${width === "full" && "w-[100%]"}
        ${size === "sm" ? "py-[8px]" : "py-[10px]"}
        ${size === "sm" ? "text-sm-sb" : "text-md-sb"}
        ${isActive && "border-b-[2px] border-brand-600"}
        ${isActive ? "text-brand-700" : "text-gray-500"}`}
  >
    {text}
    {number && (
      <p className="inline text-xs-m text-gray-700 py-[2px] px-[8px] ml-[8px] rounded-full aspect-square text-gray-700 bg-gray-50 border-[1px] border-gray-200">
        {number}
      </p>
    )}
  </p>
);

export interface ITabItem {
  text: string;
  number?: number;
  index: number;
}

interface ISUnderlineTabs extends React.HTMLAttributes<HTMLDivElement> {
  size?: "sm" | "md";
  items: ITabItem[];
  selected: number; // index
  width?: "fit" | "full";
  setSelected: React.Dispatch<React.SetStateAction<number>>;
}

function SUnderlineTabs({
  size = "sm",
  items,
  selected,
  setSelected,
  className,
  width = "fit",
  ...props
}: ISUnderlineTabs) {
  return (
    <div
      className={`
        ${className} flex ${width === "full" && "justify-between"}  
        border-b-[1px] border-b-gray-200 gap-[4px] bg-transparent 
      `}
      {...props}
    >
      {items.map((item) => (
        <Tab
          width={width}
          handleClick={() => {
            setSelected(item.index);
          }}
          isActive={item.index === selected}
          text={item.text}
          number={item.number}
          size={size}
        />
      ))}
    </div>
  );
}

export default SUnderlineTabs;
