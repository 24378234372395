import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { IFolderUpdate, ISingleFolderVM } from "../../../utils/interfaces/IFolders";
import { makeRequest } from "../../auth/axios";
import { Dispatch, SetStateAction } from "react";
import { ISingleFoldersAndFilesVM } from "../../../utils/interfaces/IAssets";


export const usePutFolder = ({setFoldersAndFiles, setPage}: {
  setFoldersAndFiles?: Dispatch<SetStateAction<ISingleFoldersAndFilesVM[]>>;
  setPage?: Dispatch<SetStateAction<number>>;
}) => {
  const queryClient = useQueryClient();
  return useMutation<IBaseData<ISingleFolderVM>, Error, IFolderUpdate>({
    mutationFn: ({id, folderName}) => makeRequest.put(`/Folders/${id}?folderName=${folderName}`),
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["useGetFoldersAndFiles"] });
      setPage && setPage(1);
      setFoldersAndFiles && setFoldersAndFiles([]);
    },
  });
};
