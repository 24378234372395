import { useQuery } from "@tanstack/react-query";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { makeRequest } from "../../auth/axios";

export const useGetInviteLink = () => {
  return useQuery<IBaseData<{ data?: string }>>({
    queryKey: ["useGetInviteLink"],
    queryFn: async () => {
      const response = await makeRequest.get(
        `/WorkspaceUsers/Invitations/Link`
      );
      return response as IBaseData<{ data?: string }>;
    },
  });
};
