import { useQuery } from "@tanstack/react-query";
import { IBaseData } from "../../utils/interfaces/IBase";
import { makeRequest } from "../auth/axios";
import {
  IScheduledPostsQueryParams,
  ISocialNetworkPostVM,
} from "../../utils/interfaces/IPost";

export const useGetCalendarData = ({
  from,
  to,
  isScheduled = true,
  isPublished = true,
  isPlanned = true,
}: IScheduledPostsQueryParams) => {
  return useQuery<IBaseData<ISocialNetworkPostVM[]>>({
    queryKey: ["useGetCalendarData", from, to],
    queryFn: async () => {
      const response = await makeRequest.get(
        `Posts/GetSocialNetworkPostsForCalendar`,
        {
          params: {
            from,
            to,
            isScheduled,
            isPublished,
            isPlanned,
          },
        }
      );
      return response as IBaseData<ISocialNetworkPostVM[]>;
    },
    retry: false,
    enabled: !!from && !!to,
  });
};
