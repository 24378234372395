//@ts-ignore
import Search from "../../assets/icons/search.svg";
//@ts-ignore
import BGPattern from "../../assets/icons/bg-pattern.svg";
import { ReactNode } from "react";

export interface ILibraryEmptyFolderProps {
  searchValue?: string;
  title: string;
  subtitle: string;
  actionButton?: ReactNode;
  className?: string;
}

export const EmptyState = ({
  searchValue,
  actionButton,
  title,
  subtitle,
  className,
}: ILibraryEmptyFolderProps) => {
  return (
    <div
      className={`md:absolute md:top-1/2 md:-translate-y-1/2 md:right-1/2 md:translate-x-1/2 flex-col gap-1 flex justify-center items-center ${className} `}
    >
      <div className="mb-3">
        <img src={Search} className="w-12" alt="Search Assets" />
        {/* <img
          src={BGPattern}
          className="w-[480px] absolute left-1/2 -translate-x-1/2 z-[-10] -bottom-1/2"
          alt="Search Assets Pattern"
        /> */}
      </div>
      <h1 className="text-gray-900 text-md-sb">{title}</h1>

      <p className="text-sm px-[12%] mb-4 text-center text-gray-600">
        {searchValue
          ? `Your search “${searchValue}” did not match any records. Please try again.`
          : subtitle}
      </p>
      {actionButton}
    </div>
  );
};
