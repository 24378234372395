import { useState } from 'react';

export const useSelectFiles = () => {
  const [files, setFiles] = useState<File[]>([]);

  const selectFile = (newFiles: File[]) => {
    setFiles(prevFiles => [...prevFiles, ...newFiles]);
  };

  const clearFiles = () => {
    setFiles([]);
  };

  return {
    files,
    selectFile,
    clearFiles,
  };
};
