import { useQuery } from "@tanstack/react-query";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { ISingleBrandVM } from "../../../utils/interfaces/IBrand";
import { makeRequest } from "../../auth/axios";
import { useBrandStore } from "../../../store/brandStore";

export const useGetCurrentBrand = () => {
  const isAuthenticated = !!localStorage.getItem("token");
  const { setIsBrandFetching, setCurrentBrand } = useBrandStore();
  return useQuery<IBaseData<ISingleBrandVM>>({
    queryKey: ["useGetCurrentBrand"],
    queryFn: async () => {
      setIsBrandFetching(true);
      try {
        const response = await makeRequest.get(
          "/Organizations/GetCurrentOrganization"
        );
        setCurrentBrand(response.data);
        return response as IBaseData<ISingleBrandVM>;
      } catch (error) {
        setCurrentBrand(null);
        throw error;
      } finally {
        setIsBrandFetching(false);
      }
    },
    retry: false,
    enabled: isAuthenticated,
  });
};
