import SButton from "../../design-system/SButton";
import {
  faCheckCircle,
  faInfoCircle,
  faRefresh,
  faTrash,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode } from "react";
import { ISingleNotification } from "../../utils/interfaces/INotifications";
import { NotificationType } from "../../utils/enums/Enums";
import { formatDistanceToNowStrict, parseISO } from "date-fns";

export type NotificationStyle = "success" | "warning" | "info";

export interface INotificationItem {
  type: NotificationStyle;
  title: ReactNode;
  date: Date;
  message: string;
  image?: string;
  handleSubmit?: () => void;
  handleCancel?: () => void;
  handleDeleteNotification?: () => void;
  systemNotification?: ISingleNotification;
  notificationType: NotificationType;
  isResponding: boolean;
  action: "accept" | "reject" | null;
}

function NotificationItem({
  isResponding,
  action,
  systemNotification,
  handleCancel,
  handleSubmit,
  type,
  title,
  message,
  image,
  handleDeleteNotification,
  notificationType,
  date,
}: INotificationItem) {
  const ago = formatDistanceToNowStrict(parseISO(date.toString()));

  return (
    <div className="flex justify-between shadow-xs border-[1px] border-gray-300 bg-base-white p-[16px] rounded-[12px]">
      <div className="flex justify-between items-start">
        <div className="flex gap-[16px]">
          {type === "success" && (
            <FontAwesomeIcon
              className="text-success-600"
              icon={faCheckCircle}
              fontSize={"16px"}
            />
          )}
          {type === "warning" && (
            <FontAwesomeIcon
              className="text-warning-600"
              icon={faInfoCircle}
              fontSize={"16px"}
            />
          )}
          {type === "info" && (
            <FontAwesomeIcon
              className="text-gray-700"
              icon={faInfoCircle}
              fontSize={"16px"}
            />
          )}
          <div>
            {!systemNotification && (
              <p className="text-sm-sb text-gray-700">{title}</p>
            )}
            <p className="text-sm-m text-gray-400">{ago} ago</p>
            {systemNotification ? (
              <p
                className="mt-[4px] text-sm text-gray-600"
                dangerouslySetInnerHTML={{
                  __html: systemNotification.customProperty.message || "",
                }}
              />
            ) : (
              <p className="mt-[4px] text-sm text-gray-600">{message}</p>
            )}
            {image && (
              <img
                src={image}
                className="mt-[12px] rounded-[4px] max-w-[260px] max-h-[146px]"
                alt="Notification"
              />
            )}
            {notificationType === NotificationType.INVITATION &&
              handleSubmit &&
              handleCancel && (
                <div className="flex mt-[12px] gap-[12px]">
                  <SButton onClick={handleSubmit} type="linkColor" size="sm">
                    Accept
                    {isResponding && action === "accept" && (
                      <span className="ml-1">
                        <FontAwesomeIcon
                          className="text-xs animate-spin"
                          icon={faRefresh}
                        />
                      </span>
                    )}
                  </SButton>
                  <SButton onClick={handleCancel} type="linkGray" size="sm">
                    Reject
                    {isResponding && action === "reject" && (
                      <span className="ml-1">
                        <FontAwesomeIcon
                          className="text-xs animate-spin"
                          icon={faRefresh}
                        />
                      </span>
                    )}
                  </SButton>
                </div>
              )}
          </div>
        </div>
      </div>
      {handleDeleteNotification && (
        <FontAwesomeIcon
          onClick={handleDeleteNotification}
          fontSize={"15px"}
          className="text-gray-400 cursor-pointer"
          icon={faTrash}
        />
      )}
    </div>
  );
}

export default NotificationItem;
