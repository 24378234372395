import { useQuery } from "@tanstack/react-query";
import { makeRequest } from "../../auth/axios";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { ISingleFrameVM } from "../../../utils/interfaces/IFrames";

export const useGetFavouriteFrames = () => {
  return useQuery<IBaseData<ISingleFrameVM[]>>({
    queryKey: ["useGetFavouriteFrames"],
    queryFn: async () => {
      const response = await makeRequest.get(`/Templates/Favorite`);
      return response as IBaseData<ISingleFrameVM[]>;
    },
    retry: 0,
  });
};
