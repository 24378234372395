import { useMutation } from "@tanstack/react-query";
import { IBase } from "../../../utils/interfaces/IBase";
import { ISignUpCOE, ISingUpVM } from "../../../utils/interfaces/IAuth";
import { makeRequest } from "../axios";

export const useSignUp = () => {
  return useMutation<IBase<ISingUpVM>, any, ISignUpCOE>({
    mutationFn: ({
      email,
      password,
      name,
      packageId,
      isInvited = false,
      addSubscription = true,
    }) =>
      makeRequest.post("/User", {
        email: email,
        password: password,
        userDetails: {
          name,
        },
        packageId,
        isInvited,
        addSubscription,
      }),
  });
};
