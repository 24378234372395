import {
  faCalendarDay,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import React from "react";

function DatePicker({
  isDatePickerOpen,
  selectedDate,
  setIsDatePickerOpen,
}: {
  isDatePickerOpen: boolean;
  selectedDate: Date;
  setIsDatePickerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <div
      id="schedule-date-picker-opener"
      onClick={() => setIsDatePickerOpen((prev) => !prev)}
      className="w-full flex justify-between items-center bg-base-white border cursor-pointer border-gray-300 rounded-[8px] px-[18px] py-[8px]"
    >
      <div className="flex gap-[12px] items-center">
        <FontAwesomeIcon
          icon={faCalendarDay}
          className="text-[14px] text-gray-600"
        />
        <div>
          <p className="text-xs text-gray-500">Date</p>
          <p className="text-md-m text-gray-900">
            {format(selectedDate, "MMMM d, yyyy")}
          </p>
        </div>
      </div>
      <FontAwesomeIcon
        icon={isDatePickerOpen ? faChevronUp : faChevronDown}
        className="text-[10px] text-gray-600"
      />
    </div>
  );
}

export default DatePicker;
