import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../axios";
import { IBaseData } from "../../utils/interfaces/IBase";
import { INotificationItem } from "../../components/notifications/NotificationItem";

export const usePutNotification = () => {
  const queryClient = useQueryClient();
  return useMutation<
    IBaseData<INotificationItem>,
    Error,
    { notificationId: number, isRead: boolean }
  >({
    mutationFn: ({ notificationId, isRead }) =>
      makeRequest.put(`/Notifications/${notificationId}?isRead=${isRead}`),
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["useGetNotifications"] });
    },
  });
};
