import { useEffect, useState } from "react";
import { useGetCurrentBrand } from "../../../api/brand/current/get";
import { usePostPost } from "../../../api/posts/post";
import { useCreationStore } from "../../../store/creationStore";
import { SocialNetworkType } from "../../../utils/enums/Enums";
import { usePostGeneratePostName } from "../../../api/creation/generateName/post";
import { useUpdatePost } from "./useUpdatePost";
import { useGetPostById } from "../../../api/posts/getById";
import { useCreationHelperFunctions } from "./useCreationHelperFunctions";
import { useTemplateRendererHelperFunctions } from "./useTemplateRendererHelperFunctions";
import { useFramesStore } from "../../../store/framesStore";
import { useBrandStore } from "../../../store/brandStore";

export const useInitializePost = () => {
  const {
    postData,
    postId,
    inAdvanceMedia,
    setActiveSoc,
    activeSoc,
    setPostData,
    setPostId,
  } = useCreationStore();
  const { data: postById, refetch: refetchPostById } = useGetPostById({
    postId: postId ?? null,
  });
  const { currentBrand } = useBrandStore();
  const { mutateAsync: createPost } = usePostPost();
  const { mutateAsync: generateName } = usePostGeneratePostName();
  const { handleSetFirstActiveSocialNetwork } = useCreationHelperFunctions();
  const { updateName } = useUpdatePost();
  useEffect(() => {
    if (postId) {
      refetchPostById().then((res) => {
        if (res.data) {
          setPostData(res.data.data);
        }
      });
    }
  }, [postId]);
  const [isPostFetching, setIsPostFetching] = useState(false);
  useEffect(() => {
    if (!postId && currentBrand && !isPostFetching) {
      setIsPostFetching(true);
      const socialNetworkTypes = currentBrand.activeSocialNetworks.map(
        (socialNetwork) => socialNetwork
      );
      createPost({
        socialNetworkPosts: socialNetworkTypes.map((socialNetwork) => ({
          socialNetworkType: socialNetwork,
          files: inAdvanceMedia.length > 0 ? inAdvanceMedia : undefined,
        })),
        story: "",
      }).then((res) => {
        setPostId(res.data.id);
        setPostData(res.data);
        setIsPostFetching(false);
      });
    }
  }, [postId, currentBrand]);

  useEffect(() => {
    if (postData && !postData.name) {
      if (postData.story.length > 0) {
        generateName({ story: postData.story }).then((res) => {
          updateName(res.data.name);
        });
      } else if (
        postData.socialNetworkPosts.length > 0 &&
        postData.socialNetworkPosts[0].text &&
        postData.socialNetworkPosts[0].text?.length > 20
      ) {
        generateName({ story: postData.socialNetworkPosts[0].text }).then(
          (res) => {
            updateName(res.data.name);
          }
        );
      }
    }
  }, [postData]);

  useEffect(() => {
    if (postData && activeSoc === null && currentBrand?.activeSocialNetworks) {
      handleSetFirstActiveSocialNetwork(currentBrand.activeSocialNetworks);
    }
  }, [postData]);
};
