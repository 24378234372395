import SInput, { ISInputProps } from "../../design-system/SInput";
import React from "react";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import STertiaryTabs, {
  ISTertiaryTabs,
} from "../../design-system/STertiaryTabs";

interface IPageHeading {
  title: string;
  subtitle?: string;
  inputProps?: ISInputProps;
  withInput?: boolean;
  rightAdditionalContent?: React.ReactNode;
  tabsProps?: ISTertiaryTabs;
  className?: string;
}

function PageHeading({
  title,
  inputProps,
  subtitle,
  withInput = false,
  rightAdditionalContent,
  tabsProps,
  className,
}: IPageHeading) {
  return (
    <div className={`${className} w-[100%] pt-[0]`}>
      <div className="flex justify-between items-center gap-[20px] max-md:flex-col max-md:items-start">
        <div>
          <p className="text-lg-sb text-gray-900">{title}</p>
          {subtitle && (
            <p className="text-sm text-gray-600 mt-[4px]">{subtitle}</p>
          )}
        </div>
        <div className="flex gap-4">
          {withInput && (
            <SInput
              className="max-w-[320px] max-md:w-full"
              icon={faSearch}
              placeholder="Search"
              {...inputProps}
            />
          )}
          {rightAdditionalContent && rightAdditionalContent}
        </div>
      </div>
      {tabsProps && <STertiaryTabs className="mt-[20px]" {...tabsProps} />}
    </div>
  );
}

export default PageHeading;
