import { makeRequest } from "../../axios";
import { useQuery } from "@tanstack/react-query";
import { IBaseData } from "../../utils/interfaces/IBase";
import { ISocialNetworkVM } from "../../utils/interfaces/ISocialNetworks";
import { useCreationStore } from "../../store/creationStore";
export const useGetAllConnections = () => {
  const { refetchConnections } = useCreationStore();
  return useQuery<IBaseData<ISocialNetworkVM[]>>({
    refetchOnWindowFocus: refetchConnections,
    queryKey: ["useGetAllConnections"],
    queryFn: async () => {
      const response = await makeRequest.get(`/SocialNetworks`);
      return response as IBaseData<ISocialNetworkVM[]>;
    },
  });
};
