"use client";

import React, { useRef, useState } from "react";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { faX } from "@fortawesome/pro-solid-svg-icons";
import BrandSwitcher from "../newSidebar/BrandSwitcher";
import Navigation from "../newSidebar/Navigation";
import ActiveWorkspace from "../newSidebar/ActiveWorkspace";
// @ts-ignore
import logo from "../../assets/images/logos/stori-full.svg";
import SButton from "../../design-system/SButton";
import { useNavigate } from "react-router-dom";
import { useSubscriptionActions } from "../../hooks/useSubscriptionActions";
import { useGetCurrentPackage } from "../../api/subscriptions/current/get";
import { BillingInfo } from "../billing/BillingInfo";
import { TeamPackageStatus } from "../../utils/enums/Enums";

export default function MobileDrawer({
  isMobileMenuOpen,
  setIsMobileMenuOpen,
}: {
  isMobileMenuOpen: boolean;
  setIsMobileMenuOpen: (open: boolean) => void;
}) {
  const { data: currentPlan } = useGetCurrentPackage();
  const { handleClick } = useSubscriptionActions();
  const ref = useRef<HTMLDivElement>(null);
  const [isBrandSelectorOpen, setIsBrandSelectorOpen] = useState(false);
  const [isWorkspaceSelectorOpen, setIsWorkspaceSelectorOpen] = useState(false);
  const navigate = useNavigate();
  return (
    <Dialog
      open={isMobileMenuOpen}
      onClose={setIsMobileMenuOpen}
      className="relative z-[101]"
    >
      <DialogBackdrop
        transition
        onClick={() => setIsMobileMenuOpen(false)}
        className="fixed z inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0"
      />
      <div ref={ref} className="fixed z-[101] overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 left-right left-0 flex max-w-full">
            <DialogPanel
              transition
              className="pointer-events-auto w-[calc(100vw-100px)] max-w-md transform transition duration-500 ease-in-out data-[closed]:-translate-x-full sm:duration-700"
            >
              <div
                className={`flex h-full flex-col overflow-y-scroll bg-white pt-[24px] shadow-sm ${isBrandSelectorOpen || isWorkspaceSelectorOpen ? "bg-[rgba(0,0,0,0.04)]" : "bg-base-white"}`}
              >
                <div className="px-[24px]">
                  <div className="flex items-center justify-between">
                    <img
                      src={logo}
                      alt="logo"
                      onClick={() => {
                        navigate("/");
                        setIsMobileMenuOpen(false);
                      }}
                      className="h-[24px]"
                    />
                    <SButton
                      icon={faX}
                      type={"secondaryGray"}
                      onClick={() => setIsMobileMenuOpen(false)}
                    />
                  </div>
                </div>
                <div className="relative my-[16px] flex-1">
                  <div className="mx-[16px] max-md:mx-[12px]">
                    <BrandSwitcher
                      isBrandSelectorOpen={isBrandSelectorOpen}
                      setIsBrandSelectorOpen={setIsBrandSelectorOpen}
                      setIsMobileMenuOpen={setIsMobileMenuOpen}
                    />
                  </div>

                  <Navigation setIsMobileMenuOpen={setIsMobileMenuOpen} />
                  {currentPlan &&
                    currentPlan.data.status !== TeamPackageStatus.ACTIVE && (
                      <div className="p-4 w-full">
                        <BillingInfo className="relative" />
                      </div>
                    )}
                </div>
                <ActiveWorkspace
                  isWorkspaceSelectorOpen={isWorkspaceSelectorOpen}
                  setIsWorkspaceSelectorOpen={setIsWorkspaceSelectorOpen}
                  setIsMobileMenuOpen={setIsMobileMenuOpen}
                />
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
