import { useQuery } from "@tanstack/react-query";
import { IWorkspaceUsersVM } from "../../../utils/interfaces/IWorkspace";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { makeRequest } from "../../../axios";

export const useGetWorkspaceUsers = () => {
  return useQuery<IBaseData<IWorkspaceUsersVM[]>>({
    queryKey: ["useGetWorkspaceUsers"],
    queryFn: async () => {
      const response = await makeRequest.get("/WorkspaceUsers");
      return response as IBaseData<IWorkspaceUsersVM[]>;
    },
  });
};
