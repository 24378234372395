import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { makeRequest } from "../../auth/axios";
import {
  IInviteUserCOE,
  IInviteVM,
} from "../../../utils/interfaces/ITeamInvitation";
import { Dispatch, SetStateAction } from "react";

export const useInviteByEmail = ({
  setError,
}: {
  setError?: Dispatch<SetStateAction<boolean>>;
}) => {
  const queryClient = useQueryClient();
  return useMutation<IBaseData<IInviteVM>, Error, IInviteUserCOE>({
    mutationFn: (postData) => {
      try {
        return makeRequest.post("/WorkspaceUsers/Invitations", postData);
      } catch (error: any) {
        throw error;
      }
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["useGetOnboardingInfo"] });
      queryClient.invalidateQueries({ queryKey: ["useGetInvitationPending"] });
      queryClient.invalidateQueries({ queryKey: ["useGetActiveMembers"] });
    },
    onError: (error: any) => {
      setError && setError(error.response.data.message);
    },
  });
};
