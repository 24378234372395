import { removeExtension } from "./removeExtension";

export const truncateName = (name: string, extension?: string) => {
  if (extension) {
    name = removeExtension(name, extension)
  }
  if (name.length > 20) {
    name = name.substring(0, 20);
  }
  return name;
};