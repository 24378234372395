import React, { useCallback, useState } from "react";
import SCheckbox from "../../../design-system/SCheckbox";
import "../../../index.css";
import BillingListRow, { IBillingListRow } from "../BillingListRow";
import { IWorkspaceInvoices } from "../../../utils/interfaces/IWorkspace";
import { IBaseData } from "../../../utils/interfaces/IBase";
//@ts-ignore
// import { saveAs } from "file-saver";
// import { getLastSegment } from "../../../hooks/functions/queries";
// import { downloadFile } from "../../../hooks/functions/downloadFile";

export interface BillingListProps {
  items?: IBaseData<IWorkspaceInvoices[]>;
  className?: string;
}

function BillingList({ items, className }: BillingListProps) {
  const [selectedItems, setSelectedItems] = useState<Set<number>>(new Set());
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = useCallback(() => {
    if (selectAll) {
      setSelectedItems(new Set());
    } else {
      setSelectedItems(new Set(items?.data.map((_, index) => index)));
    }
    setSelectAll(!selectAll);
  }, [selectAll, items?.data]);

  const handleSelectItem = useCallback((index: number) => {
    setSelectedItems((prevSelectedItems) => {
      const newSelectedItems = new Set(prevSelectedItems);
      if (newSelectedItems.has(index)) {
        newSelectedItems.delete(index);
      } else {
        newSelectedItems.add(index);
      }
      return newSelectedItems;
    });
  }, []);

  return (
    <div
      className={`${className} w-full bg-white border border-gray-200 rounded-[12px] shadow-xs max-md:overflow-x-auto`}
    >
      <table className="w-full">
        <thead className={"border-b border-gray-200"}>
          <tr>
            <th className="pl-[24px] rounded-tl-[12px] py-[16px] bg-gray-50 w-7/12 text-left text-xs-m text-slate-600">
              Invoice
            </th>
            <th className="py-[16px] bg-gray-50 w-2/12 text-left text-xs-m text-slate-600 ">
              Amount
            </th>
            <th className="py-[16px] bg-gray-50 w-2/12 text-left text-xs-m text-slate-600 ">
              Date
            </th>
            <th className="py-[16px] bg-gray-50 w-2/12 text-left text-xs-m text-slate-600">
              Status
            </th>
            <th className="p-2 w-min rounded-tr-[12px] bg-gray-50 text-left"></th>
          </tr>
        </thead>
        <tbody>
          {items &&
            items.data.map((item, index) => (
              <BillingListRow
                key={index}
                invoiceData={item}
                selected={selectedItems.has(index)}
                onSelect={() => handleSelectItem(index)}
                onClickHyperLink={() => {
                  window.open(item.hostedInvoiceUrl, "_blank");
                  // downloadFile(
                  //   item.hostedInvoiceUrl,
                  //   `${item.description}.pdf`,
                  // );
                }}
              />
            ))}
        </tbody>
      </table>
    </div>
  );
}

export default BillingList;
