import { makeRequest } from "../../../axios";
import { useQuery } from "@tanstack/react-query";
import { IBaseData } from "../../../utils/interfaces/IBase";

export const useGetHasPaymentMethod = () => {
  const isAuthenticated = localStorage.getItem("token");
  return useQuery<IBaseData<null>>({
    queryKey: ["useGetHasPaymentMethod"],
    retry: false,
    queryFn: async () => {
      const response = await makeRequest.get(
        "/Subscriptions/WorkspaceHasActivePaymentMethod",
      );
      return response as IBaseData<null>;
    },
    enabled: !!isAuthenticated,
  });
};
