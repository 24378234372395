import { useMutation } from "@tanstack/react-query";
import { IBase } from "../../utils/interfaces/IBase";
import { IAccountDeleteCOE } from "../../utils/interfaces/IAccount";
import { makeRequest } from "../../axios";

export const useDeleteUser = () => {
  return useMutation<IBase<null>, Error, IAccountDeleteCOE>({
    mutationFn: (data: IAccountDeleteCOE) =>
      makeRequest.delete("/Account/DeleteAccount", {
        data: data.password,
      }),
  });
};
