import { useMutation, useQueryClient } from '@tanstack/react-query';
import { makeRequest } from '../axios';
import { IBase } from '../utils/interfaces/IBase';
import { IAccountPhotoCOE } from '../utils/interfaces/IAccount';

export const usePostAccountPhoto = () => {
  const queryClient = useQueryClient();

  return useMutation<IBase<null>, Error, FormData>({
    mutationFn: (data) =>
      makeRequest.post('/Account/Photo', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['useGetUser'] });
    }
  });
};
