import { useEffect, useState } from "react";
import { useBrandStore } from "../../../store/brandStore";
import { ISingleBrandVM } from "../../../utils/interfaces/IBrand";

const baseChanges = {
  contentLanguage: false,
  brandingData: false,
  connections: false,
  hasChanges: false,
};

export const useHasChanges = ({
  values,
  contentLanguage,
}: {
  values: {
    name: string;
    email: string;
    phone: string;
    website: string;
  };
  contentLanguage: number | null;
}) => {
  const { currentBrand, connectBrandIds, disconnectBrandIds } = useBrandStore();
  const [hasChanges, setHasChanges] = useState({ ...baseChanges });
  useEffect(() => {
    if (currentBrand) {
      let hasChanged = false;
      let changes = { ...baseChanges };
      if (currentBrand.contentLanguage !== contentLanguage) {
        hasChanged = true;
        changes.contentLanguage = true;
      }
      if (currentBrand.brandingData?.name !== values.name) {
        hasChanged = true;
        changes.brandingData = true;
      }
      if (connectBrandIds.length > 0 || disconnectBrandIds.length > 0) {
        hasChanged = true;
        changes.connections = true;
      }

      Object.entries(values).forEach(([key, value]) => {
        if (key === "name") return;
        const val = value.length > 0 ? value : null;
        if (currentBrand[key as keyof ISingleBrandVM] !== val) {
          hasChanged = true;
          changes.brandingData = true;
        }
      });
      setHasChanges({
        ...changes,
        hasChanges: hasChanged,
      });
    }
  }, [
    currentBrand,
    values,
    contentLanguage,
    connectBrandIds,
    disconnectBrandIds,
  ]);
  return { hasChanges };
};
