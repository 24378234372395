import { MouseEventHandler, useRef } from "react";

export const useAssetTouch = ({
  onClick,
}: {
  onClick?: MouseEventHandler<HTMLDivElement>;
}) => {
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const handleTouchStart = () => {
    if (!onClick) return;
    timerRef.current = setTimeout<any>(onClick, 500);
  };

  const handleTouchEnd = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  };

  return { handleTouchStart, handleTouchEnd };
};
