import { faUpload, faSparkle } from "@fortawesome/pro-regular-svg-icons";
import { useState } from "react";
import { useLibraryStore } from "../../../../store/libraryStore";
import { IAssetSourceFilterProps } from "../../filters/AssetSourceFilter";

export const useFilterAssetSource = ({
  source,
  setSource,
}: IAssetSourceFilterProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { setFoldersAndFiles, setPage } = useLibraryStore();
  const items = [
    {
      text: "Uploaded",
      handleClick: () => {
        if (source.uploadedOnly) return;
        setFoldersAndFiles([]);
        setPage(1);
        setSource({ uploadedOnly: true, generatedOnly: false });
      },
      icon: faUpload,
    },
    {
      text: "Generated",
      handleClick: () => {
        if (source.generatedOnly) return;
        setFoldersAndFiles([]);
        setPage(1);
        setSource({ uploadedOnly: false, generatedOnly: true });
      },
      icon: faSparkle,
    },
  ];
  return {
    isOpen,
    setIsOpen,
    items,
    setFoldersAndFiles,
    setPage,
  };
};
