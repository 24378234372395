import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { makeRequest } from "../../auth/axios";
import { ImageGenerationStyle } from "../../../utils/enums/Enums";
import { ISingleFIleVM } from "../../../utils/interfaces/IAssets";

export const usePostGenerateImage = () => {
  const queryClient = useQueryClient();

  return useMutation<
    IBaseData<ISingleFIleVM>,
    any,
    {
      story?: string;
      prompt: string;
      imageGenerationStyle: ImageGenerationStyle;
      aspectRatio?: string;
      postId: number;
    }
  >({
    mutationFn: ({
      story,
      prompt,
      imageGenerationStyle,
      aspectRatio,
      postId,
    }) =>
      makeRequest.post("/SuggestedImages/GenerateImage", {
        story,
        prompt,
        imageGenerationStyle: imageGenerationStyle,
        aspectRatio: aspectRatio,
        postId,
      }),

    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["useGetFiles"] });
    },
  });
};
