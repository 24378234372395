import React, { useEffect, useState } from "react";
import SModalBase from "../../../design-system/SModalBase";
import Sketch from "@uiw/react-color-sketch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCopy,
  faQuestion,
} from "@fortawesome/pro-regular-svg-icons";
import { useBrandStore } from "../../../store/brandStore";
import { usePutBrand } from "../../../api/brand/put";
import { IBrandingDataVM } from "../../../utils/interfaces/IBrand";
import { toast } from "../../../hooks/toast";
import SButton from "../../../design-system/SButton";

const Picker = ({
  isPrimary = true,
  colorSelected,
  setColorSelected,
}: {
  isPrimary?: boolean;
  setColorSelected: React.Dispatch<React.SetStateAction<string | null>>;
  colorSelected: string | null;
}) => {
  const [copied, setCopied] = useState(false);
  return (
    <div className="w-full">
      <p className="text-sm-b text-text-s">
        {isPrimary ? "Primary color" : "Secondary Color"}
      </p>
      <Sketch
        color={colorSelected || ""}
        onChange={(color) => setColorSelected(color.hex)}
        className="mt-3 w-full"
        disableAlpha
      />
      <div className="relative mt-6 bg-transparent flex items-center">
        <div
          className="w-5 h-5"
          style={{
            backgroundColor: colorSelected || "",
            position: "absolute",
            top: "50%",
            left: "12px",
            transform: "translateY(-50%)",
            borderRadius: "4px",
          }}
        />
        <input
          type="text"
          className="pl-10 h-10 w-full border border-stroke-s rounded-[6px] border-gray-300"
          value={colorSelected || ""}
          onChange={(e) => setColorSelected(e.target.value.trim())}
        />
        {copied ? (
          <div
            className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
            onClick={() => {
              setCopied(true);
              navigator.clipboard.writeText(colorSelected || "");
              setTimeout(() => {
                setCopied(false);
              }, 2000);
            }}
          >
            <FontAwesomeIcon
              icon={faCheck}
              fontSize="14px"
              className="text-gray-500"
            />
          </div>
        ) : (
          <div
            className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
            onClick={() => {
              setCopied(true);
              navigator.clipboard.writeText(colorSelected || "");
              setTimeout(() => {
                setCopied(false);
              }, 2000);
            }}
          >
            <FontAwesomeIcon
              icon={faCopy}
              fontSize="14px"
              className="text-gray-500"
            />
          </div>
        )}
      </div>
    </div>
  );
};

function ColorsEditModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const { currentBrand } = useBrandStore();
  const [primarySelected, setPrimarySelected] = useState<string | null>(
    currentBrand?.brandingData?.brandColors?.primary || null
  );
  const [secondarySelected, setSecondarySelected] = useState<string | null>(
    currentBrand?.brandingData?.brandColors?.secondary || null
  );

  const { mutateAsync: updateBrand } = usePutBrand();

  useEffect(() => {
    if (currentBrand) {
      setPrimarySelected(
        currentBrand?.brandingData?.brandColors?.primary || null
      );
      setSecondarySelected(
        currentBrand?.brandingData?.brandColors?.secondary || null
      );
    }
  }, [currentBrand]);

  const handleSubmitEdit = () => {
    if (currentBrand && primarySelected && secondarySelected) {
      const tmp = JSON.parse(JSON.stringify(currentBrand));
      const tmpBrandingData = { ...tmp.brandingData } as IBrandingDataVM;
      tmpBrandingData.brandColors.primary = primarySelected;
      tmpBrandingData.brandColors.secondary = secondarySelected;
      updateBrand({ brandingData: tmpBrandingData, brandId: currentBrand!.id })
        .then((res) => {
          onClose();
        })
        .catch((err) => {
          toast(err.response.data.message);
        });
    }
  };

  return (
    <SModalBase
      dialogPanelClassName="min-w-[544px] z-10 py-4 text-gray-600 max-md:min-w-[100%] max-md:overflow-y-scroll"
      isOpen={isOpen}
      onClose={onClose}
    >
      <h1 className="text-md-m mb-3">Brand colors</h1>
      <hr className="w-auto mx-[-24px]" />
      <div className="flex w-full flex-col gap-3 max-md:mb-0 max-md:h-[600px]">
        <div className="mt-6 gap-4 flex justify-center max-sm:flex-col">
          <Picker
            colorSelected={primarySelected}
            setColorSelected={setPrimarySelected}
          />
          <Picker
            colorSelected={secondarySelected}
            setColorSelected={setSecondarySelected}
            isPrimary={false}
          />
        </div>

        <div className="flex gap-2 justify-end pb-[16px]">
          <SButton type="secondaryColor" size="md" onClick={handleSubmitEdit}>
            Save
          </SButton>
        </div>
      </div>
    </SModalBase>
  );
}

export default ColorsEditModal;
