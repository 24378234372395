import { useQuery } from "@tanstack/react-query";
import { ISingleBrandVM } from "../../utils/interfaces/IBrand";
import { IBaseData } from "../../utils/interfaces/IBase";
import { makeRequest } from "../auth/axios";

export const useGetAllBrands = ({
  includeAllWorkspaces = false,
}: {
  includeAllWorkspaces?: boolean;
}) => {
  const isAuthenticated = !!localStorage.getItem("token");
  return useQuery<IBaseData<ISingleBrandVM[]>>({
    queryKey: ["useGetAllBrands", includeAllWorkspaces],
    queryFn: async () => {
      const response = await makeRequest.get(
        `/Organizations?includeAllWorkspaces=${includeAllWorkspaces}`
      );
      return response as IBaseData<ISingleBrandVM[]>;
    },
    retry: false,
    enabled: isAuthenticated,
  });
};
