import React, { useEffect, useState } from "react";
import TextsSection from "../TextsSection";
import { useBrandStore } from "../../../store/brandStore";
import { usePutBrand } from "../../../api/brand/put";
import _ from "lodash";
import { IBrandingDataVM } from "../../../utils/interfaces/IBrand";
import { toast } from "../../../hooks/toast";
function Voice() {
  const { currentBrand } = useBrandStore();
  const { mutateAsync: updateBrand } = usePutBrand();
  const [tags, setTags] = useState<string[] | string[][]>([]);

  useEffect(() => {
    if (currentBrand?.brandingData?.brandVoice?.voiceTone1) {
      let tmpTags = [];
      tmpTags.push(currentBrand.brandingData.brandVoice.voiceTone1.split(", "));
      tmpTags.push(currentBrand.brandingData.brandVoice.voiceTone2.split(", "));
      tmpTags.push(currentBrand.brandingData.brandVoice.voiceTone3.split(", "));
      setTags(tmpTags);
    }
  }, [currentBrand]);

  return (
    <>
      <TextsSection
        loadingCount={1}
        title="📣 Brand Voice"
        fieldName="BrandVoice"
        subtitle="How you express your brand"
        isLoading={!currentBrand?.brandingData?.brandVoice?.expressingMessage}
        texts={[
          {
            key: "expressingMessage",
            content: currentBrand?.brandingData?.brandVoice?.expressingMessage,
            title: "Expressing Message",
            showBorder: false,
            tags: tags,
            handleChangeText: (text: string) => {
              if (
                currentBrand &&
                currentBrand?.brandingData?.brandVoice?.expressingMessage !==
                  text
              ) {
                let tmpBrandingData = _.cloneDeep(currentBrand.brandingData);
                tmpBrandingData.brandVoice.expressingMessage = text;
                updateBrand({
                  brandId: currentBrand.id,
                  brandingData: tmpBrandingData,
                })
                  .then(() => {
                    toast("Brand Voice updated successfully", "success");
                  })
                  .catch((err) => {
                    toast(
                      `Failed to update Brand Voice, ${err.response.data.message}`,
                      "error"
                    );
                  });
              }
            },
            handleChangeTag({ text, index, sKey, parentIndex }) {
              let tmpBrandingData = _.cloneDeep(currentBrand!.brandingData);
              let tmpTags = [...tags];
              //@ts-ignore
              if (
                parentIndex !== undefined &&
                tmpTags.length > 0 &&
                Array.isArray(tmpTags[parentIndex])
              ) {
                //@ts-ignore
                tmpTags[parentIndex][index] = text;
              }
              if (tmpBrandingData && Array.isArray(tmpTags[0])) {
                tmpBrandingData.brandVoice.voiceTone1 = tmpTags[0].join(", ");
              }
              if (tmpBrandingData && Array.isArray(tmpTags[1]))
                tmpBrandingData.brandVoice.voiceTone2 = tmpTags[1].join(", ");
              if (tmpBrandingData && Array.isArray(tmpTags[2]))
                tmpBrandingData.brandVoice.voiceTone3 = tmpTags[2].join(", ");
              if (tmpBrandingData)
                if (
                  tmpBrandingData.brandVoice.voiceTone1 !==
                    currentBrand?.brandingData?.brandVoice?.voiceTone1 ||
                  tmpBrandingData.brandVoice.voiceTone2 !==
                    currentBrand?.brandingData?.brandVoice?.voiceTone2 ||
                  tmpBrandingData.brandVoice.voiceTone3 !==
                    currentBrand?.brandingData?.brandVoice?.voiceTone3
                )
                  updateBrand({
                    brandId: currentBrand!.id,
                    brandingData: tmpBrandingData,
                  })
                    .then(() => {
                      toast("Brand Voice updated successfully", "success");
                    })
                    .catch((err) => {
                      toast(
                        `Failed to update Brand Voice, ${err.response.data.message}`,
                        "error"
                      );
                    });
            },
          },
        ]}
      />
    </>
  );
}

export default Voice;
