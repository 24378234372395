import SButton from "../../../design-system/SButton";
import SModalBase from "../../../design-system/SModalBase";
//@ts-ignore
import Tick from "../../../assets/icons/tick.svg";
//@ts-ignore
import BGPattern from "../../../assets/icons/bg-pattern.svg";
import { getQuery } from "../../../hooks/functions/queries";
import { useGetInviteInfoByCode } from "../../../api/invitations/invitationByCode/get";
import { useRespondInvitation } from "../../../api/invitations/invitationPending/put";
import { useNavigate } from "react-router-dom";
import { usePutSwitchBrand } from "../../../api/brand/switch/put";
import { usePutSwitchTeam } from "../../../api/workspaces/swich/put";

export const InviteModal = ({
  setInviteModal,
  inviteModal,
}: {
  setInviteModal: (value: boolean) => void;
  inviteModal: boolean;
}) => {
  const code = getQuery("code");
  const { data: inviteInfo } = useGetInviteInfoByCode({ code });
  const { mutateAsync: acceptInvite } = useRespondInvitation();
  const { mutateAsync: switchBrand } = usePutSwitchBrand();
  const { mutateAsync: switchWorkspace } = usePutSwitchTeam();
  const navigate = useNavigate();
  return (
    <SModalBase
      dialogPanelClassName="min-w-[400px]  overflow-hidden z-10 py-4 text-gray-600"
      isOpen={inviteModal}
      onClose={() => {
        navigate("/");
        setInviteModal(false);
      }}
    >
      <div className="w-full relative">
        <img
          style={{ maskSize: "contain" }}
          src={BGPattern}
          className="w-[336px] -translate-y-[145px] z-[-10] -translate-x-[145px] absolute"
          alt="Invitation BG Pattern"
        />
        <img src={Tick} className="w-12" alt="Invitation Tick" />
      </div>
      <div className="flex w-full z-1 flex-col gap-[8px] my-6">
        <h1 className="text-lg text-gray-400">
          <span className="text-lg-sb text-gray-700">
            {inviteInfo?.data.workspaceOwnerName}
          </span>{" "}
          invited you to join {inviteInfo?.data.workspace.name}.
        </h1>
        <div className="w-full flex gap-3 items-center mt-6">
          <SButton
            onClick={() => {
              acceptInvite({
                id: inviteInfo ? inviteInfo?.data.id : 0,
                statusId: 3,
              });
              setInviteModal(false);
            }}
            className="w-full"
            size="lg"
            type="secondaryGray"
            children="Reject"
          />
          <SButton
            onClick={async () => {
              await acceptInvite({
                id: inviteInfo ? inviteInfo?.data.id : 0,
                statusId: 2,
              }).then((res) => {
                if (res.data.organizations.length > 0) {
                  switchBrand({
                    id: res.data.organizations[0].id,
                  });
                } else {
                  if (res?.data?.workspace?.id)
                    switchWorkspace({
                      teamId: res.data.workspace.id,
                    });
                }
              });
              setInviteModal(false);
            }}
            className="w-full"
            size="lg"
            type="primary"
            children="Accept"
          />
        </div>
      </div>
    </SModalBase>
  );
};
