import { IChangePasswordCOE } from "../../../utils/interfaces/IAccount";
import { IBase } from "../../../utils/interfaces/IBase";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../../axios";

export const usePutChangePassword = () => {
  const queryClient = useQueryClient();
  return useMutation<IBase<null>, Error, IChangePasswordCOE>({
    mutationFn: (data) =>
      makeRequest.put("/Account/ChangePassword", { ...data }),
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["useGetUser"] });
    },
  });
};
