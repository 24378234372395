import {
  faMicrophone,
  faSend,
  faStop,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { useCopilotHelper } from "./hooks/useCopilotHelper";
import SButton from "../../design-system/SButton";
import { useCopilotStore } from "../../store/copilotStore";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { toast } from "../../hooks/toast";
import VoiceRecorder from "../creation/Tabs/Brief/VoiceRecorder";
import { useBrandStore } from "../../store/brandStore";

function CopilotInput() {
  const { isInputDisabled } = useCopilotStore();
  const [value, setValue] = useState("");
  const { sendMessage } = useCopilotHelper();
  const { transcript, resetTranscript, listening } = useSpeechRecognition();
  const [lastState, setLastState] = useState("");
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { currentBrand } = useBrandStore();

  useEffect(() => {
    if (listening) setValue(transcript);
  }, [transcript]);

  useEffect(() => {
    if (textareaRef.current) {
      adjustTextareaHeight();
    }
  }, [value]);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${Math.min(textarea.scrollHeight, 250)}px`;
    }
  };
  return (
    <div className="w-full flex gap-[12px] items-center px-[24px] py-[12px] bg-gray-50 border-t border-t-gray-200">
      <div className="h-auto relative w-full">
        <textarea
          ref={textareaRef}
          disabled={isInputDisabled || !currentBrand?.isBrandDataGenerated}
          value={listening ? lastState + " " + value : value}
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.code === "Enter" && !e.shiftKey) {
              e.preventDefault();
              if (value.length > 0) sendMessage({ value, setValue });
              setValue("");
            }
          }}
          placeholder="Send a message..."
          className={`${(isInputDisabled || !currentBrand?.isBrandDataGenerated) ? "cursor-not-allowed" : "default"} h-fit max-h-[250px] disabled:bg-gray-50 pl-[16px] pr-[32px] focus:border-gray-300 focus:ring-0 focus:outline-0 outline-none resize-none w-full rounded-[12px] border-gray-200 bg-base-white text-md placeholder:text-gray-500 text-gray-800`}
        />
        <VoiceRecorder
          value={value}
          setValue={setValue}
          setLastState={setLastState}
          lastState={lastState}
          listening={listening}
          transcript={transcript}
          resetTranscript={resetTranscript}
          className={`${listening ? "bg-green-100 !text-green-500" : "bg-brand-600 !text-base-white"}
            absolute translate-y-[-170%] right-[16px] w-[32px] h-[32px] 
            cursor-pointer
          `}
          canvasProps="-right-4 bottom-[70px]"
        />
      </div>
      <SButton
        onClick={() => {
          if (value.length > 0) sendMessage({ value, setValue });
          setValue("");
        }}
        disabled={isInputDisabled}
        icon={faSend}
        type="secondaryColor"
        className="rounded-full"
      />
    </div>
  );
}

export default CopilotInput;
