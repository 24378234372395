import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../axios";
import { IBase } from "../../utils/interfaces/IBase";
import { IHandleSubscriptionCOE } from "../../utils/interfaces/ISubscriptions";

export const usePutSubscription = () => {
  const queryClient = useQueryClient();

  return useMutation<IBase<string>, Error, IHandleSubscriptionCOE>({
    mutationFn: (data) =>
      makeRequest.post(`/Subscriptions/HandleSubscription`, {
        packageId: data.packageId ?? 0,
        workspaceId: data.workspaceId,
      }),
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["useGetCurrentPackage"] });
      queryClient.invalidateQueries({ queryKey: ["useGetLastPackage"] });
    },
  });
};
