import { useQuery } from "@tanstack/react-query";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { IInviteVM } from "../../../utils/interfaces/ITeamInvitation";
import { makeRequest } from "../../auth/axios";

export const useGetInvitationPending = () => {
  return useQuery<IBaseData<IInviteVM[]>>({
    queryKey: ["useGetInvitationPending"],
    queryFn: async () => {
      const response = await makeRequest.get(
        `/WorkspaceUsers/Invitations`
      );
      return response as IBaseData<IInviteVM[]>;
    },
  });
};
