import React from "react";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import { Link, useLocation } from "react-router-dom";
//@ts-ignore
import illustration from "../../assets/images/illustrations/loader2.svg";
import SButton from "../../design-system/SButton";
interface IErrorBoundary {
  children: React.ReactNode;
}

const ErrorBoundary = ({ children }: IErrorBoundary) => {
  const location = useLocation();
  return (
    <ReactErrorBoundary
      key={location.pathname}
      fallback={
        <div className="w-full h-[100dvh] justify-center items-center flex flex-col">
          <img src={illustration} className="w-[130px] h-[130px]" />
          <p className="mt-[20px] mb-[28px] text-center text-m-sb text-gray-900">
            An error has occurred, try again.
          </p>
          <Link to={"/"}>
            <SButton>Go to home page</SButton>
          </Link>
        </div>
      }
    >
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
