import { useEffect, useRef, useState } from "react";

export const useGridCardResize = () => {
  const sizeRef = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState(sizeRef.current?.clientWidth);

  useEffect(() => {
    const element = sizeRef.current;

    const handleResize = (entries: ResizeObserverEntry[]) => {
      for (let entry of entries) {
        setSize(entry.contentRect.width)
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    if (element) {
      resizeObserver.observe(element);
    }

    return () => {
      if (element) {
        resizeObserver.unobserve(element);
      }
    };
  }, [window.innerWidth]);

  return {sizeRef, size}
}
