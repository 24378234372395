import React from "react";

export interface CurrentPlanSkeletonProps {
  className?: string;
}

const CurrentPlanSkeleton = ({ className }: CurrentPlanSkeletonProps) => {
  return (
    <div
      className={`${className} rounded-[12px] border-[1px] border-gray-200 bg-white shadow-xs animate-pulse`}
    >
      <div className="flex flex-col p-[24px] gap-[24px]">
        <div className="flex items-center justify-between gap-[12px]">
          <div className="flex flex-col gap-[4px]">
            <div className="h-6 bg-gray-300 rounded w-40 mb-2"></div>
            <div className="h-4 bg-gray-300 rounded w-24"></div>
          </div>
          <div className="text-[48px] font-semibold text-gray-900">
            <div className="h-12 bg-gray-300 rounded w-24"></div>
            <div className="text-md-m text-gray-600">
              <div className="h-4 bg-gray-300 rounded w-20 mt-2"></div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-3 auto-rows-auto w-full gap-[24px]">
          {[1, 2, 3].map((_, index) => (
            <div key={index} className="flex flex-col gap-[8px]">
              <div className="h-3 bg-gray-300 rounded w-full"></div>
              <div className="h-6 bg-gray-300 rounded w-full"></div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex py-[16px] px-[24px] justify-end border-t border-gray-200">
        <div className="h-10 bg-gray-300 rounded w-36"></div>
      </div>
    </div>
  );
};

export default CurrentPlanSkeleton;
