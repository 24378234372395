import { useRef, useState } from "react";
import { TeamRole } from "../../../utils/enums/Enums";
import { useInviteByEmail } from "../../../api/invitations/invitationByEmail/post";
import { useHandleOutsideClick } from "../../../hooks/useHandleOutsideClick";

export const useInviteMemberModal = () => {
  const [email, setEmail] = useState("");
  const [openRoles, setOpenRoles] = useState(false);
  const [role, setRole] = useState<TeamRole>(TeamRole.MEMBER);
  const [error, setError] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false)
  const { mutateAsync: inviteMemberByEmail } = useInviteByEmail({setError: setError});
  const dropdownRef = useRef(null);
  useHandleOutsideClick({
    ref: dropdownRef,
    callBack: () => setOpenRoles(false),
  });

  return {
    email,
    setEmail,
    openRoles,
    setOpenRoles,
    role,
    setRole,
    inviteMemberByEmail,
    dropdownRef,
    error,
    setConfirmModal,
    confirmModal,
  };
};
