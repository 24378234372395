import SInput from "../../design-system/SInput";
import React, { useState } from "react";
import { usePutChangePassword } from "../../api/auth/changePassword/put";
import SButton from "../../design-system/SButton";
import { faEye } from "@fortawesome/pro-regular-svg-icons";
import { faEye as faEyeSolid } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "../../hooks/toast";

const ChangePassword = ({ hasPassword }: { hasPassword?: boolean | null }) => {
  const { mutateAsync: changePassword } = usePutChangePassword();

  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const [currentPasswordShow, setCurrentPasswordShow] = useState(false);
  const [newPasswordShow, setNewPasswordShow] = useState(false);
  const [repeatPasswordShow, setRepeatPasswordShow] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleChangePassword = () => {
    if (newPassword === repeatPassword) {
      changePassword({ password: password || null, newPassword })
        .then(() => {
          setPassword("");
          setNewPassword("");
          setRepeatPassword("");
          setError(null);
          toast("Password changed successfully", "success");
        })
        .catch((err) => {
          setError(err.response.data.message);
          toast(error as string, "error");
        });
    } else {
      setError("Passwords do not match");
      toast(error as string, "error");
    }
  };

  return (
    <div className="border-b-[1px] border-gray-200 pb-[20px]">
      <p className="text-sm-sb text-gray-700">Reset Password</p>
      {hasPassword && (
        <SInput
          label="Old password"
          placeholder="Type your old password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="mt-[12px] max-w-[376px] max-md:w-[68%] max-md:max-w-full"
          rightAdditionalContent={
            <div
              onClick={() => setCurrentPasswordShow(!currentPasswordShow)}
              className={"cursor-pointer"}
            >
              {!currentPasswordShow && (
                <FontAwesomeIcon icon={faEye} className={"text-gray-600"} />
              )}
              {currentPasswordShow && (
                <FontAwesomeIcon
                  icon={faEyeSolid}
                  className={"text-gray-600"}
                />
              )}
            </div>
          }
          type={currentPasswordShow ? "text" : "password"}
        />
      )}

      <div className="mt-[12px] flex align-middle gap-[16px] max-w-[768px] max-md:flex-col">
        <SInput
          label="New password"
          placeholder="Type your new password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          className="w-1/3 max-md:w-[68%]"
          rightAdditionalContent={
            <div
              onClick={() => setNewPasswordShow(!newPasswordShow)}
              className={"cursor-pointer"}
            >
              {!newPasswordShow && (
                <FontAwesomeIcon icon={faEye} className={"text-gray-600"} />
              )}
              {newPasswordShow && (
                <FontAwesomeIcon
                  icon={faEyeSolid}
                  className={"text-gray-600"}
                />
              )}
            </div>
          }
          type={newPasswordShow ? "text" : "password"}
        />
        <SInput
          label="Repeat password"
          placeholder="Repeat your password"
          value={repeatPassword}
          onChange={(e) => setRepeatPassword(e.target.value)}
          className="w-1/3 max-md:w-[68%]"
          rightAdditionalContent={
            <div
              onClick={() => setRepeatPasswordShow(!repeatPasswordShow)}
              className={"cursor-pointer"}
            >
              {!repeatPasswordShow && (
                <FontAwesomeIcon icon={faEye} className={"text-gray-600"} />
              )}
              {repeatPasswordShow && (
                <FontAwesomeIcon
                  icon={faEyeSolid}
                  className={"text-gray-600"}
                />
              )}
            </div>
          }
          type={repeatPasswordShow ? "text" : "password"}
        />
        <SButton
          type="primary"
          className="mt-[32px] w-fit"
          onClick={handleChangePassword}
        >
          Change Password
        </SButton>
      </div>
    </div>
  );
};

export default ChangePassword;
