import React, { useEffect } from "react";
import { useBrandStore } from "../store/brandStore";
import { usePutSwitchTeam } from "../api/workspaces/swich/put";
import { useGetCurrentWorkspace } from "../api/workspaces/current/get";
import { usePutSwitchBrand } from "../api/brand/switch/put";
import { getQuery } from "./functions/queries";
import { useCreationHelperFunctions } from "../components/creation/hooks/useCreationHelperFunctions";

export const useGetCat = ({
  setIsWhichSocialNetworksSelectorOpen,
}: {
  setIsWhichSocialNetworksSelectorOpen: (value: boolean) => void;
}) => {
  const { currentBrand, isBrandFetching } = useBrandStore();
  const { mutateAsync: switchTeam } = usePutSwitchTeam();
  const { data: currentTeam } = useGetCurrentWorkspace();
  const { mutateAsync: switchBrand } = usePutSwitchBrand();
  const redirectType = getQuery("redirectType");
  const teamId = Number(getQuery("workspaceId"));
  const organizationId = Number(getQuery("organizationId"));
  const postId = Number(getQuery("postId"));
  const { handleOpenPost } = useCreationHelperFunctions();

  const handleBrandSwitch = async () => {
    if (organizationId && currentBrand?.id !== organizationId) {
      await switchBrand({ id: organizationId }).then(() => {
        redirectType === "post" &&
          handleOpenPost({
            postId: Number(postId),
            onSocialNetworkSelectorOpen: () =>
              setIsWhichSocialNetworksSelectorOpen(true),
          });
      });
    } else {
      redirectType === "post" &&
        handleOpenPost({
          postId: Number(postId),
          onSocialNetworkSelectorOpen: () =>
            setIsWhichSocialNetworksSelectorOpen(true),
        });
      return Promise.resolve();
    }
  };
  useEffect(() => {
    if (redirectType && !isBrandFetching && currentTeam) {
      if (redirectType === "post" && postId && teamId && organizationId) {
        if (teamId === currentTeam?.data.id) {
          handleBrandSwitch();
        } else {
          switchTeam({ teamId: teamId }).then(() => {
            handleBrandSwitch();
          });
        }
      }
      if (redirectType === "organization" && teamId && organizationId) {
        if (teamId === currentTeam?.data.id) {
          handleBrandSwitch();
        } else {
          switchTeam({ teamId: teamId }).then(() => {
            handleBrandSwitch();
          });
        }
      }
      const url = new URL(window.location.href);
      url.search = "";
      window.history.replaceState({}, "", url.toString());
    }
  }, [currentTeam, isBrandFetching, redirectType]);
};

export default useGetCat;
