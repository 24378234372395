export interface ICharacterProgressProps {
  progress: number;
  limit: number;
}

export const CharacterProgress = ({
  progress,
  limit,
}: ICharacterProgressProps) => {
  const strokeWidth = 2;
  const size = 20;
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (progress / limit) * circumference;
  return (
    <div className="mt-3 w-fit ml-auto">
      <svg width={size} height={size} className="rotate-[-90deg]">
        {/* Background Circle */}
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          className="stroke-gray-300"
          strokeLinecap="round"
          strokeWidth={strokeWidth}
          fill="transparent"
        />

        {/* Progress Circle */}
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={strokeWidth}
          fill="transparent"
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          strokeLinecap="round"
          className="transition-stroke stroke-brand-600 duration-300 ease-linear"
        />
      </svg>
      {/* <span className="absolute text-xl font-bold text-brand-500">
        {Math.round(progress / 280 * 100)}%
      </span> */}
    </div>
  );
};
