import { useFramesStore } from "../../../../store/framesStore";
import { useTemplateRendererHelperFunctions } from "../../hooks/useTemplateRendererHelperFunctions";
import { useGetFavouriteFrames } from "../../../../api/frames/favourites/get";
import FramesGroup from "./FramesGroup";
import { useGetFrames } from "../../../../api/frames/get";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFaceMonocle } from "@fortawesome/pro-regular-svg-icons";
import { FrameSkeleton } from "./FrameSkeleton";

function FramesList() {
  const { searchValue, groupFilter } = useFramesStore();
  const { data: frames, isFetching: isFramesFetching } = useGetFrames({
    keywords: searchValue,
    templateGroupId: groupFilter?.id || undefined,
  });
  const groupBy = <T extends Record<string, any>>(
    array: T[],
    key: keyof T
  ): Record<string, T[]> => {
    return array.reduce((result: Record<string, T[]>, currentValue: T) => {
      const groupKey = String(currentValue[key]);
      if (!result[groupKey]) {
        result[groupKey] = [];
      }
      result[groupKey].push(currentValue);
      return result;
    }, {});
  };
  const groupedFrames = frames
    ? groupBy(frames?.data, "templateGroupName")
    : ([] as any);
  const { renderTemplate } = useTemplateRendererHelperFunctions();
  const { data: favouriteFrames } = useGetFavouriteFrames();
  const { selectedImage, whichFrameIsRendering, activeFrame } =
    useFramesStore();
  const isActive = (templateId: number) => {
    if (activeFrame) {
      if (templateId === activeFrame?.id) {
        return true;
      }
    }
    if (selectedImage?.template) {
      if (activeFrame && activeFrame?.id !== selectedImage?.template?.id) {
        return false;
      }
      if (templateId === selectedImage?.template?.id) {
        return true;
      }
    }
    return false;
  };
  return (
    <div
      className={`px-[36px] sm:h-full sm:overflow-auto max-md:px-[18px] mb-[16px] flex flex-col gap-[16px] mt-4`}
    >
      {favouriteFrames && favouriteFrames.data.length > 0 && (
        <FramesGroup
          groupName="Favorites"
          favourites={favouriteFrames.data}
          templates={favouriteFrames.data}
        />
      )}
      {frames &&
        groupedFrames &&
        Object.keys(groupedFrames).map((item) => {
          return (
            <FramesGroup
              favourites={favouriteFrames?.data}
              key={item}
              dKey={item}
              groupName={item}
              templates={groupedFrames[item]}
            />
          );
        })}
      {frames && !frames.data.length && !isFramesFetching && (
        <div className="w-full flex flex-col mt-7 gap-2 justify-center">
          <FontAwesomeIcon
            icon={faFaceMonocle}
            className="text-4xl text-gray-700"
          />
          <p className="text-md-m text-center text-gray-600">
            {" "}
            No templates found for <br />{" "}
            <span className="text-md-sb">{searchValue}...</span>
          </p>
        </div>
      )}
      {isFramesFetching && (
        <div className="w-full grid max-lg:grid-cols-2 max-sm:grid-cols-1 grid-cols-3 gap-3">
          <FrameSkeleton />
          <FrameSkeleton />
          <FrameSkeleton />
        </div>
      )}
    </div>
  );
}

export default FramesList;
