import { useState, useEffect, useRef } from "react";
import { useCreationStore } from "../../../store/creationStore";
import { useHandleOutsideClick } from "../../../hooks/useHandleOutsideClick";
import { useCreationHelperFunctions } from "./useCreationHelperFunctions";
import { socialScoreColor } from "../functions/socialScoreColor";
import { socialScoreCategories } from "../functions/socialScoreCategories";
import { socialScoreIcons } from "../functions/socialScoreIcons";

export const useRenderSocialScore = () => {
  const {
    socialScore,
    isSocialScoreLoading,
    setImproveIdea,
    briefMessages,
    activeTab,
    setActiveTab,
  } = useCreationStore();
  const { isPostPublishedOrScheduled } = useCreationHelperFunctions();
  const [openMessage, setOpenMessage] = useState(false);
  const [copy, setCopy] = useState(false);
  const socialScoreRef = useRef<HTMLDivElement>(null);
  useHandleOutsideClick({
    ref: socialScoreRef,
    callBack: () => setOpenMessage(false),
    excludedIds: ["social-scoring"],
  });
  useEffect(() => {
    if (copy) {
      setTimeout(() => {
        setCopy(false);
      }, 1000);
    }
  }, [copy]);
  const category = socialScoreCategories(socialScore?.score);
  const color = socialScoreColor(socialScore?.score);
  const icon = socialScoreIcons(socialScore?.score);

  const isMessaging =
    briefMessages.length > 0
      ? !briefMessages[briefMessages.length - 1].content
      : true;

  return {
    socialScore,
    isSocialScoreLoading,
    openMessage,
    setOpenMessage,
    color,
    icon,
    setCopy,
    copy,
    setImproveIdea,
    isMessaging,
    activeTab,
    setActiveTab,
    socialScoreRef,
    isPostPublishedOrScheduled,
    category,
  };
};
