import {
  faCalendarDay,
  faChevronDown,
  faChevronUp,
  faClock,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import React from "react";

function TimePicker({
  selectedTime,
  setIsTimePickerOpen,
  isTimePickerOpen,
}: {
  isTimePickerOpen: boolean;
  selectedTime: Date;
  setIsTimePickerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <div
      onClick={() => setIsTimePickerOpen((prev) => !prev)}
      id="schedule-time-picker-opener"
      className="w-full flex justify-between items-center bg-base-white border cursor-pointer border-gray-300 rounded-[8px] px-[18px] py-[8px]"
    >
      <div className="flex gap-[12px] items-center">
        <FontAwesomeIcon icon={faClock} className="text-[14px] text-gray-600" />
        <div>
          <p className="text-xs text-gray-500">Time</p>
          <p className="text-md-m text-gray-900">
            {format(selectedTime, "hh:mm a")}
          </p>
        </div>
      </div>
      <FontAwesomeIcon
        icon={isTimePickerOpen ? faChevronUp : faChevronDown}
        className="text-[10px] text-gray-600"
      />
    </div>
  );
}

export default TimePicker;
