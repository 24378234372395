import { downloadFile } from "../../../../../hooks/functions/downloadFile";
import { getLastSegment } from "../../../../../hooks/functions/queries";
import { useCreationStore } from "../../../../../store/creationStore";
import {
  ISingleFIleVM,
  IUploadFIleVM,
} from "../../../../../utils/interfaces/IAssets";
import {
  ISinglePostVM,
  ISocialNetworkPostFileVM,
  IStockImageVM,
} from "../../../../../utils/interfaces/IPost";
import { useCreationHelperFunctions } from "../../../hooks/useCreationHelperFunctions";
import { useUpdatePost } from "../../../hooks/useUpdatePost";
import { convertLibraryFileIntoSocialNetworkPostFile } from "./creationLibraryConverters";
import _ from "lodash";
import { usePostFile } from "../../../../../api/library/files/post";
import { usePostGenerateImage } from "../../../../../api/creation/generateImage/post";
import {
  ImageGenerationAspectRatio,
  ImageGenerationStyle,
} from "../../../../../utils/enums/Enums";
import { IBaseData } from "../../../../../utils/interfaces/IBase";
import { isImage, isVideo } from "../../../../Library/hooks/getFileType";
import { toast } from "../../../../../hooks/toast";
import { useFramesStore } from "../../../../../store/framesStore";

export const useCreationLibraryHelperFunctions = () => {
  const { updateFilesUI, updateFullPost } = useUpdatePost();
  const {
    postData,
    setPostData,
    setVideoSelectionAgreementModalOpen,
    activeSoc,
  } = useCreationStore();
  const { setSelectedImage } = useFramesStore();
  const { getActiveSocialNetworkPost } = useCreationHelperFunctions();
  const { mutateAsync: uploadFile } = usePostFile();
  const { mutateAsync: generateImageRequest } = usePostGenerateImage();

  const handleMediaSelection = (
    files: ISocialNetworkPostFileVM[],
    newFile: ISingleFIleVM,
    isVideoFile: boolean,
    agreed: boolean,
    forAll: boolean
  ): boolean => {
    if (
      isVideoFile &&
      files.some((file) => isImage((file.url || file.templateOutputUrl)!)) &&
      !agreed
    ) {
      setVideoSelectionAgreementModalOpen({ file: newFile, forAll });
      return true;
    }
    if (
      !isVideoFile &&
      files.some((file) => isVideo((file.url || file.templateOutputUrl)!)) &&
      !agreed
    ) {
      setVideoSelectionAgreementModalOpen({ file: newFile, forAll });
      return true;
    }
    return false;
  };

  const updateFiles = (
    files: ISocialNetworkPostFileVM[],
    newFile: ISingleFIleVM,
    removeIfExist = true,
    shouldExcludeId = false
  ): ISocialNetworkPostFileVM[] => {
    if (isVideo(newFile.url || newFile.templateOutputUrl || "")) {
      if (
        newFile.id
          ? files.some((item) => item.id === newFile.id)
          : files.some((item) => item.url === newFile.url)
      ) {
        files = [];
      } else {
        files = [convertLibraryFileIntoSocialNetworkPostFile(newFile)];
      }
    } else {
      files = files.filter(
        (file) => !isVideo((file.url || file.templateOutputUrl)!)
      );
      if (files.some((item) => item.url === newFile.url) && removeIfExist) {
        files = files.filter((item) => {
          if (!shouldExcludeId) {
            return newFile.id
              ? item.id !== newFile.id
              : item.url !== newFile.url;
          } else {
            return item.url !== newFile.url;
          }
        });
      } else {
        files.push(convertLibraryFileIntoSocialNetworkPostFile(newFile));
      }
    }
    return files;
  };

  const selectUploadedMedia = async (res: IBaseData<IUploadFIleVM>) => {
    if (!postData) return;
    let tmpPostData = _.cloneDeep(postData);
    tmpPostData.socialNetworkPosts.forEach((item) => {
      res.data.uploadedFiles.forEach((file) => {
        item.files = updateFiles(item.files, file);
      });
    });
    setPostData(tmpPostData);
    await updateFullPost(tmpPostData);
  };

  const selectMedia = (file: ISingleFIleVM, agreed = false) => {
    let activeFiles = _.cloneDeep(getActiveSocialNetworkPost()?.files) || [];
    if (
      handleMediaSelection(activeFiles, file, isVideo(file.url), agreed, false)
    )
      return;
    activeFiles = updateFiles(activeFiles, file);
    updateFilesUI({ sendRequest: true, files: activeFiles });
  };

  const insertMediaForAllSocialNetworkType = async (
    file: ISingleFIleVM,
    agreed = false,
    removeIfExist = true,
    sendRequest = true,
    shouldExcludeId = false
  ) => {
    let tmpPostData = _.cloneDeep(useCreationStore.getState().postData);
    if (!tmpPostData) return;
    if (
      handleMediaSelection(
        [],
        file,
        isVideo(file.url || file.templateOutputUrl || ""),
        agreed,
        true
      )
    )
      return;
    tmpPostData.socialNetworkPosts.forEach((item) => {
      const maxOrderId = item.files.reduce((maxOrderId, file) => {
        return file.orderId > maxOrderId ? file.orderId : maxOrderId;
      }, 0);
      file.orderId = maxOrderId + 1;
      item.files = updateFiles(
        item.files,
        file,
        removeIfExist,
        shouldExcludeId
      );
    });
    // setPostData(tmpPostData);
    if (sendRequest) {
      const response = await updateFullPost(tmpPostData);
      setPostData(response.data);
      return response;
    }
  };

  const downloadMediasFromActiveSocialNetworkType = async () => {
    const activeFiles = getActiveSocialNetworkPost()?.files || [];
    if (activeFiles.length === 0) {
      toast("No images to download", "info");
      return;
    }

    const downloadPromises = activeFiles.map((item) => {
      if (item.url) {
        downloadFile(item.url, getLastSegment(item.url));
      }
      if (item.templateOutputUrl) {
        downloadFile(
          item.templateOutputUrl,
          getLastSegment(item.templateOutputUrl)
        );
      }
    });

    await Promise.all(downloadPromises);
    toast("Selected social media asset(s) downloaded successfully", "success");
  };

  const selectStockImage = async (image: IStockImageVM): Promise<void> => {
    const response = await fetch(image.url);
    const imageBlob = await response.blob();
    const formData = new FormData();
    formData.append("files", imageBlob, "image.jpeg");

    const uploadResponse = await uploadFile({
      files: formData,
      isTemplateUsed: true,
      shouldRefetch: false,
    });

    const newFile = convertLibraryFileIntoSocialNetworkPostFile(
      uploadResponse.data.uploadedFiles[0]
    );
    insertMediaForAllSocialNetworkType(newFile as ISingleFIleVM, true, false);
    // let activeFiles = _.cloneDeep(getActiveSocialNetworkPost()?.files) || [];
    // activeFiles.push(newFile);

    // updateFilesUI({ files: activeFiles, sendRequest: true });
  };

  const handleGenerateImage = ({
    prompt,
    style,
    aspectRatio,
  }: {
    prompt: string;
    style: ImageGenerationStyle;
    aspectRatio: ImageGenerationAspectRatio;
  }): Promise<IBaseData<ISingleFIleVM>> => {
    return generateImageRequest({
      imageGenerationStyle: style,
      aspectRatio: aspectRatio,
      postId: postData!.id,
      prompt,
    });
  };

  const isMediaAlreadyInPost = (file: ISingleFIleVM) => {
    let alreadyInPost = false;
    getActiveSocialNetworkPost()?.files.forEach((f) => {
      if (f.url === file.url) {
        alreadyInPost = true;
        return;
      }
    });
    return alreadyInPost;
  };

  const setSelectedImageFromResponse = (response: IBaseData<ISinglePostVM>) => {
    const activePost = response?.data.socialNetworkPosts.find(
      (item) => item.socialNetworkType === activeSoc
    );
    if (activePost && activePost.files.length > 0) {
      setSelectedImage(activePost.files[0]);
    } else {
      setSelectedImage(null);
    }
  };

  return {
    selectUploadedMedia,
    handleGenerateImage,
    selectStockImage,
    selectMedia,
    insertMediaForAllSocialNetworkType,
    downloadMediasFromActiveSocialNetworkType,
    isMediaAlreadyInPost,
    setSelectedImageFromResponse,
  };
};
