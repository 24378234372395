import React, { useState, useEffect, useCallback } from "react";
import SButton from "../../design-system/SButton";

interface INotificationToast {
  id?: string;
  title: string;
  subtitle?: string;
  handleSubmit?: () => void;
  handleCancel?: () => void;
  duration: number;
}

const useNotificationToast = () => {
  const [toasts, setToasts] = useState<INotificationToast[]>([]);

  const generateId = () => Math.random().toString(36).substr(2, 9);

  const addToast = useCallback((notification: INotificationToast) => {
    const id = Math.random().toString(36).substr(2, 9);
    setToasts((prevToasts) => [
      ...prevToasts,
      { ...notification, id: notification.id || id },
    ]);
    setTimeout(() => removeToast(id), notification.duration * 1000);
  }, []);

  const removeToast = useCallback((id: string) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  }, []);

  const ToastContainer = () => (
    <div className="toast-container">
      {toasts.map((toast) => (
        <div className="absolute top-[16px] right-[16px] w-fit max-w-[400px] p-[16px] bg-base-white rounded-[12px] border-[1px] border-gray-300 shadow-lg">
          <p className="text-sm-sb text-gray-900">{toast.title}</p>
          {toast.subtitle && (
            <p className="text-sm text-gray-600 mt-[4px]">{toast.subtitle}</p>
          )}
          {toast.handleSubmit && toast.handleCancel && (
            <div className="flex gap-[12px] mt-[12px]">
              <SButton onClick={toast.handleCancel} size="sm" type="linkGray">
                Dismiss
              </SButton>
              <SButton size="sm" type="linkColor" onClick={toast.handleSubmit}>
                Accept
              </SButton>
            </div>
          )}
        </div>
      ))}
    </div>
  );

  return { addToast, ToastContainer, removeToast, generateId };
};

export default useNotificationToast;
