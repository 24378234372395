import React from "react";
//@ts-ignore
import brenda from "../../../assets/images/agents/brenda.jpeg";
import SButton from "../../../design-system/SButton";
import { ICopilotMessage } from "../../../utils/copilot-base/ICopilotBase";
import AgentInfo from "../../creation/Tabs/Brief/AgentInfo";
function AiMessage({
  messages,
  msg,
  index,
  sendMessage,
  handleNextStep,
}: {
  handleNextStep: ({
    summery,
    website,
    name,
  }: {
    name: string;
    summery?: string;
    website?: string;
  }) => void;
  sendMessage: (t?: string) => void;
  messages: ICopilotMessage[];
  index: number;
  msg: ICopilotMessage;
}) {
  return (
    <div className="h-fit flex gap-[12px] items-center relative group">
      <div className="flex gap-[12px]">
        <AgentInfo
          logo={brenda}
          agentName={"Brenda"}
          agentRole={"Brand Specialist"}
          agentDescription={
            "Hi, AI'm, Brenda, your brand expert.\n\n" +
            "I'm here to help you set up, manage and evolve your brand."
          }
        >
          <img
            src={brenda}
            alt="brad-image"
            className="w-[40px] h-[40px] rounded-full"
          />
        </AgentInfo>
        <div
          className={`bg-base-white flex flex-col justify-center items-start border rounded-[8px] rounded-tl-[0] border-gray-200 py-[10px] px-[14px] max-w-[556px]
        w-fit`}
        >
          {!msg.content ? (
            <div className="typing__animation">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
          ) : (
            <>
              <pre className="whitespace-pre-wrap break-words text-md text-gray-900">
                {msg.content &&
                  (msg.content.Type === "CopilotMessage" ||
                    msg.content?.Type === "WebsiteScraping" ||
                    msg.content?.Type === "BrandCreationSummary") &&
                  msg.content.Message.length > 0 && (
                    <pre className="whitespace-pre-wrap break-words text-md text-gray-900">
                      {msg.content.Message.trim()}
                      {msg.content.Type === "BrandCreationSummary" && (
                        <pre className="whitespace-pre-wrap break-words text-md-m text-gray-900">
                          {msg.content.Data.SummerizedBrandInformation}
                        </pre>
                      )}
                    </pre>
                  )}
              </pre>
              {messages.length - 1 === index &&
                msg.content &&
                (msg.content.Type === "BrandCreationSummary" ||
                  msg.content?.Type === "WebsiteScraping") && (
                  <div className="flex gap-[12px] justify-end mt-[12px] mb-[12px] max-md:flex-col">
                    <SButton
                      type="secondaryGray"
                      onClick={() => {
                        if (msg!.content!.Type === "BrandCreationSummary") {
                          sendMessage("Continue conversation");
                        } else if (msg!.content!.Type === "WebsiteScraping") {
                          sendMessage("Change website");
                        }
                      }}
                    >
                      {msg.content.Type === "BrandCreationSummary"
                        ? "Continue conversation"
                        : "Change website"}
                    </SButton>
                    <SButton
                      onClick={() => {
                        if (
                          msg.content &&
                          msg!.content!.Type === "BrandCreationSummary"
                        ) {
                          handleNextStep({
                            summery:
                              msg.content.Data.SummerizedBrandInformation,
                            name: msg.content.Data.BrandName,
                          });
                        }
                        if (msg!.content!.Type === "WebsiteScraping") {
                          handleNextStep({
                            //@ts-ignore
                            website: msg!.content!.Data.WebsiteToScrape,
                            //@ts-ignore
                            name: msg!.content!.Data.BrandName,
                          });
                        }
                      }}
                    >
                      Go to next step
                    </SButton>
                  </div>
                )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default AiMessage;
