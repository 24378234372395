import { useState } from "react";
import WorkspaceMembersView from "../tables/WorkspaceMembersView";
import { useGetWorkspaceUsers } from "../../api/workspaces/users/get";
import MembersListSkeleton from "../Skeletons/MembersListSkeleton";
import { WorkspaceEditMemberModal } from "./WorkspaceEditMemberModal";
import { useGetAllBrands } from "../../api/brand/getAll";
import { useGetInvitationPending } from "../../api/invitations/invitationPending/get";
import { TeamRole } from "../../utils/enums/Enums";

const WorkspaceMembers = ({isViewer}: {isViewer?: boolean;}) => {
  const { data: workspaceUsers, isLoading: isWorkspaceUsersLoading } =
    useGetWorkspaceUsers();
  const { data: pendingUsers } = useGetInvitationPending();
  const { data: organizations } = useGetAllBrands({
    includeAllWorkspaces: false,
  });

  const displayPendingMembers = pendingUsers
    ? pendingUsers?.data.map((member, index) => {
        return {
          id: member.id,
          workspaceId: member.workspace.id,
          userId: member.userEmail,
          email: member.userEmail,
          photoUrl: "",
          role: TeamRole[member.role].toLowerCase(),
          invitationStatus: member.invitationStatus,
          organizationUsers: member.organizations.map((brand) => ({
            name: brand.brandingData?.name,
            organizationId: brand.id,
            workspaceUserId: member.workspace.id,
            workspaceName: member.workspace.name,
            organizationName: brand.brandingData?.name,
            organizationLogo: brand.logo,
            photoUrl: "",
            email: member.userEmail,
            role: TeamRole[member.role].toLowerCase(),
          })),
          workspaceName: member.workspace.name,
          name: member.userEmail,
        };
      })
    : [];

  const [editModal, setEditModal] = useState(false);
  const [chosenUser, setChosenUser] = useState<{
    userId: string;
    name: string;
    email: string;
    role: TeamRole;
    id: number;
  }>();

  return (
    <div className="flex flex-col gap-[32px] w-full">
      {editModal && !isViewer && (
        <WorkspaceEditMemberModal
          editModal={editModal}
          setEditModal={setEditModal}
          chosenUser={chosenUser}
          workspaceUsers={workspaceUsers?.data.concat(displayPendingMembers)}
          allOrganizations={organizations?.data}
        />
      )}
      {isWorkspaceUsersLoading ? (
        <MembersListSkeleton />
      ) : (
        <WorkspaceMembersView
          className="mb-10"
          setChosenUser={setChosenUser}
          setEditModal={setEditModal}
          items={workspaceUsers?.data.concat(displayPendingMembers)}
          isViewer={isViewer}
        />
      )}
    </div>
  );
};

export default WorkspaceMembers;
