import { formatDate } from "date-fns";
import { useGetCurrentPackage } from "../../api/subscriptions/current/get";
import { TeamPackageStatus } from "../../utils/enums/Enums";
import { formatTimeFromDate } from "../Calendar/functions/formatTimeFromDate";
import { BillingInfoIcons } from "./BillingInfoIcons";
import { format } from "date-fns";
import { useSubscriptionActions } from "../../hooks/useSubscriptionActions";

export const BillingInfoTexts = ({
  type,
  isTrial,
}: {
  type: TeamPackageStatus;
  isTrial: boolean;
}) => {
  const { currentPlan } = useSubscriptionActions();
  return (
    <div className="flex flex-col items-start">
      <div className="text-lg-sb flex items-center gap-2">
        <BillingInfoIcons type={type} isTrial={isTrial} />
        <p>
          {type === TeamPackageStatus.CANCELED && `Reactivate Your STORI!`}
          {type === TeamPackageStatus.PAUSED && `Subscription Paused!`}
          {type === TeamPackageStatus.ACTIVE &&
            !isTrial &&
            `Your STORI is Active`}
          {type === TeamPackageStatus.ACTIVE &&
            isTrial &&
            currentPlan &&
            `STORI Trial is Active Until ${format(currentPlan.data.trialEndDate, "MMMM d")}`}
        </p>
      </div>
      <p className="text-gray-700 text-sm">
        {type === TeamPackageStatus.CANCELED &&
          `Your plan is on hold. Press “Activate now” to add a payment method and access all STORI features.`}
        {type === TeamPackageStatus.PAUSED &&
          `Your subscription is paused due to trial or payment expiration. Press “Upgrade Now” Reactivate your subscription and keep using STORI.`}
        {type === TeamPackageStatus.ACTIVE &&
          !isTrial &&
          `Subscription is active and good to go! You can upgrade your plan to unlock even more opportunities.`}
        {type === TeamPackageStatus.ACTIVE &&
          isTrial &&
          `You can enjoy all STORI features for now. Upgrade now to unlock the full potential of STORI.`}
      </p>
    </div>
  );
};
