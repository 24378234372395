import React from "react";

export interface BillingListSkeletonProps {
  className?: string;
}

function BillingListSkeleton({ className }: BillingListSkeletonProps) {
  return (
    <div className={`${className} w-full bg-white rounded-[12px] shadow-xs`}>
      <table className="w-full animate-pulse">
        <thead className="border-b border-gray-200">
          <tr>
            <th className="pl-[24px] pr-[16px] py-[16px] w-1/24">
              <div className="h-4 w-4 bg-gray-300 rounded"></div>
            </th>
            <th className="py-[16px] w-7/12 text-left text-xs-m text-gray-600">
              Invoice
            </th>
            <th className="py-[16px] w-2/12 text-left text-xs-m text-gray-600">
              Amount
            </th>
            <th className="py-[16px] w-2/12 text-left text-xs-m text-gray-600">
              Date
            </th>
            <th className="py-[16px] w-2/12 text-left text-xs-m text-gray-600">
              Status
            </th>
            <th className="p-2 w-min text-left"></th>
          </tr>
        </thead>
        <tbody>
          {[1, 2, 3, 4, 5].map((_, index) => (
            <tr key={index} className="border-b border-gray-200">
              <td className="pl-[24px] pr-[16px] py-[16px] w-1/24">
                <div className="h-4 w-4 bg-gray-300 rounded"></div>
              </td>
              <td className="py-[16px] w-7/12">
                <div className="flex items-center">
                  <div className="h-5 w-5 bg-gray-300 rounded mr-[8px]"></div>
                  <span className="h-4 bg-gray-300 rounded w-24"></span>
                </div>
              </td>
              <td className="py-[16px] w-2/12">
                <span className="h-4 bg-gray-300 rounded w-12"></span>
              </td>
              <td className="py-[16px] w-2/12">
                <span className="h-4 bg-gray-300 rounded w-16"></span>
              </td>
              <td className="py-[16px] w-2/12">
                <span className="h-4 bg-gray-300 rounded w-16"></span>
              </td>
              <td className="p-[16px] w-min flex items-center gap-[2px]">
                <div className="h-5 w-5 bg-gray-300 rounded"></div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default BillingListSkeleton;
