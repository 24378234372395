import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IStockGroupVM } from "../../../utils/interfaces/IPost";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { makeRequest } from "../../auth/axios";
import { useCreationStore } from "../../../store/creationStore";

export const usePostStockImages = () => {
  const { setStocksResponse, setIsStocksFetching } = useCreationStore();
  return useMutation<
    IBaseData<IStockGroupVM>,
    Error,
    { story?: string; prompt?: string; page: number }
  >({
    mutationFn: ({ story, prompt, page }) =>
      makeRequest.post("/SuggestedImages/StockImages", {
        story,
        prompt,
        page,
        pageSize: 25,
      }),
    onMutate: () => {
      setIsStocksFetching(true);
    },
    onSettled: () => {
      setIsStocksFetching(false);
    },
    onSuccess: (data) => {
      setStocksResponse(data);
    },
  });
};
