const timezones = [
  {
    label: "International Date Line West (GMT-12:00)",
    value: "Pacific/Kwajalein",
  },
  {
    label: "Midway Islands (GMT-11:00)",
    value: "Pacific/Midway",
  },
  {
    label: "Hawaii (GMT-10:00)",
    value: "Pacific/Honolulu",
  },
  {
    label: "Alaska (GMT-09:00)",
    value: "America/Juneau",
  },
  {
    label: "Pacific Time (US & Canada) (GMT-08:00)",
    value: "America/Los_Angeles",
  },
  {
    label: "Tijuana (GMT-08:00)",
    value: "America/Tijuana",
  },
  {
    label: "Mountain Time (US & Canada) (GMT-07:00)",
    value: "America/Denver",
  },
  {
    label: "Arizona (GMT-07:00)",
    value: "America/Phoenix",
  },
  {
    label: "Chihuahua (GMT-07:00)",
    value: "America/Chihuahua",
  },
  {
    label: "Mazatlan (GMT-07:00)",
    value: "America/Mazatlan",
  },
  {
    label: "Central Time (US & Canada) (GMT-06:00)",
    value: "America/Chicago",
  },
  {
    label: "Saskatchewan (GMT-06:00)",
    value: "America/Regina",
  },
  {
    label: "Guadalajara (GMT-06:00)",
    value: "America/Mexico_City",
  },
  {
    label: "Mexico City (GMT-06:00)",
    value: "America/Mexico_City",
  },
  {
    label: "Monterrey (GMT-06:00)",
    value: "America/Monterrey",
  },
  {
    label: "Central America (GMT-06:00)",
    value: "America/Guatemala",
  },
  {
    label: "Eastern Time (US & Canada) (GMT-05:00)",
    value: "America/New_York",
  },
  {
    label: "Indiana (East) (GMT-05:00)",
    value: "America/Indiana/Indianapolis",
  },
  {
    label: "Bogota (GMT-05:00)",
    value: "America/Bogota",
  },
  {
    label: "Lima (GMT-05:00)",
    value: "America/Lima",
  },
  {
    label: "Quito (GMT-05:00)",
    value: "America/Lima",
  },
  {
    label: "Atlantic Time (Canada) (GMT-04:00)",
    value: "America/Halifax",
  },
  {
    label: "Caracas (GMT-04:00)",
    value: "America/Caracas",
  },
  {
    label: "La Paz (GMT-04:00)",
    value: "America/La_Paz",
  },
  {
    label: "Santiago (GMT-04:00)",
    value: "America/Santiago",
  },
  {
    label: "Newfoundland (GMT-03:30)",
    value: "America/St_Johns",
  },
  {
    label: "Brasilia (GMT-03:00)",
    value: "America/Sao_Paulo",
  },
  {
    label: "Buenos Aires (GMT-03:00)",
    value: "America/Argentina/Buenos_Aires",
  },
  {
    label: "Greenland (GMT-03:00)",
    value: "America/Godthab",
  },
  {
    label: "Montevideo (GMT-03:00)",
    value: "America/Montevideo",
  },
  {
    label: "Mid-Atlantic (GMT-02:00)",
    value: "Atlantic/South_Georgia",
  },
  {
    label: "Azores (GMT-01:00)",
    value: "Atlantic/Azores",
  },
  {
    label: "Cape Verde Is. (GMT-01:00)",
    value: "Atlantic/Cape_Verde",
  },
  {
    label: "GMT (GMT+00:00)",
    value: "Europe/London",
  },
  {
    label: "Dublin (GMT+00:00)",
    value: "Europe/Dublin",
  },
  {
    label: "Edinburgh (GMT+00:00)",
    value: "Europe/London",
  },
  {
    label: "Lisbon (GMT+00:00)",
    value: "Europe/Lisbon",
  },
  {
    label: "London (GMT+00:00)",
    value: "Europe/London",
  },
  {
    label: "Casablanca (GMT+00:00)",
    value: "Africa/Casablanca",
  },
  {
    label: "Monrovia (GMT+00:00)",
    value: "Africa/Monrovia",
  },
  {
    label: "UTC (GMT+00:00)",
    value: "UTC",
  },
  {
    label: "Belgrade (GMT+01:00)",
    value: "Europe/Belgrade",
  },
  {
    label: "Bratislava (GMT+01:00)",
    value: "Europe/Bratislava",
  },
  {
    label: "Budapest (GMT+01:00)",
    value: "Europe/Budapest",
  },
  {
    label: "Ljubljana (GMT+01:00)",
    value: "Europe/Ljubljana",
  },
  {
    label: "Prague (GMT+01:00)",
    value: "Europe/Prague",
  },
  {
    label: "Sarajevo (GMT+01:00)",
    value: "Europe/Sarajevo",
  },
  {
    label: "Skopje (GMT+01:00)",
    value: "Europe/Skopje",
  },
  {
    label: "Warsaw (GMT+01:00)",
    value: "Europe/Warsaw",
  },
  {
    label: "Zagreb (GMT+01:00)",
    value: "Europe/Zagreb",
  },
  {
    label: "Brussels (GMT+01:00)",
    value: "Europe/Brussels",
  },
  {
    label: "Copenhagen (GMT+01:00)",
    value: "Europe/Copenhagen",
  },
  {
    label: "Madrid (GMT+01:00)",
    value: "Europe/Madrid",
  },
  {
    label: "Paris (GMT+01:00)",
    value: "Europe/Paris",
  },
  {
    label: "Amsterdam (GMT+01:00)",
    value: "Europe/Amsterdam",
  },
  {
    label: "Berlin (GMT+,01:00)",
    value: "Europe/Berlin",
  },
  {
    label: "Rome (GMT+01:00)",
    value: "Europe/Rome",
  },
  {
    label: "Stockholm (GMT+01:00)",
    value: "Europe/Stockholm",
  },
  {
    label: "Vienna (GMT+01:00)",
    value: "Europe/Vienna",
  },
  {
    label: "West Central Africa (GMT+01:00)",
    value: "Africa/Algiers",
  },
  {
    label: "Bucharest (GMT+02:00)",
    value: "Europe/Bucharest",
  },
  {
    label: "Cairo (GMT+02:00)",
    value: "Africa/Cairo",
  },
  {
    label: "Helsinki (GMT+02:00)",
    value: "Europe/Helsinki",
  },
  {
    label: "Kyiv (GMT+02:00)",
    value: "Europe/Kiev",
  },
  {
    label: "Riga (GMT+02:00)",
    value: "Europe/Riga",
  },
  {
    label: "Sofia (GMT+02:00)",
    value: "Europe/Sofia",
  },
  {
    label: "Tallinn (GMT+02:00)",
    value: "Europe/Tallinn",
  },
  {
    label: "Vilnius (GMT+02:00)",
    value: "Europe/Vilnius",
  },
  {
    label: "Athens (GMT+02:00)",
    value: "Europe/Athens",
  },
  {
    label: "Istanbul (GMT+03:00)",
    value: "Europe/Istanbul",
  },
  {
    label: "Minsk (GMT+03:00)",
    value: "Europe/Minsk",
  },
  {
    label: "Jerusalem (GMT+03:00)",
    value: "Asia/Jerusalem",
  },
  {
    label: "Harare (GMT+02:00)",
    value: "Africa/Harare",
  },
  {
    label: "Pretoria (GMT+02:00)",
    value: "Africa/Johannesburg",
  },
  {
    label: "Kaliningrad (GMT+03:00)",
    value: "Europe/Kaliningrad",
  },
  {
    label: "Moscow (GMT+03:00)",
    value: "Europe/Moscow",
  },
  {
    label: "St. Petersburg (GMT+03:00)",
    value: "Europe/Moscow",
  },
  {
    label: "Volgograd (GMT+03:00)",
    value: "Europe/Volgograd",
  },
  {
    label: "Kuwait (GMT+03:00)",
    value: "Asia/Kuwait",
  },
  {
    label: "Riyadh (GMT+03:00)",
    value: "Asia/Riyadh",
  },
  {
    label: "Nairobi (GMT+03:00)",
    value: "Africa/Nairobi",
  },
  {
    label: "Baghdad (GMT+03:00)",
    value: "Asia/Baghdad",
  },
  {
    label: "Tehran (GMT+03:30)",
    value: "Asia/Tehran",
  },
  {
    label: "Abu Dhabi (GMT+04:00)",
    value: "Asia/Dubai",
  },
  {
    label: "Muscat (GMT+04:00)",
    value: "Asia/Muscat",
  },
  {
    label: "Baku (GMT+04:00)",
    value: "Asia/Baku",
  },
  {
    label: "Tbilisi (GMT+04:00)",
    value: "Asia/Tbilisi",
  },
  {
    label: "Yerevan (GMT+04:00)",
    value: "Asia/Yerevan",
  },
  {
    label: "Kabul (GMT+04:30)",
    value: "Asia/Kabul",
  },
  {
    label: "Ekaterinburg (GMT+05:00)",
    value: "Asia/Yekaterinburg",
  },
  {
    label: "Islamabad (GMT+05:00)",
    value: "Asia/Karachi",
  },
  {
    label: "Karachi (GMT+05:00)",
    value: "Asia/Karachi",
  },
  {
    label: "Tashkent (GMT+05:00)",
    value: "Asia/Tashkent",
  },
  {
    label: "Chennai (GMT+05:30)",
    value: "Asia/Kolkata",
  },
  {
    label: "Kolkata (GMT+05:30)",
    value: "Asia/Kolkata",
  },
  {
    label: "Mumbai (GMT+05:30)",
    value: "Asia/Kolkata",
  },
  {
    label: "New Delhi (GMT+05:30)",
    value: "Asia/Kolkata",
  },
  {
    label: "Sri Jayawardenepura (GMT+05:30)",
    value: "Asia/Colombo",
  },
  {
    label: "Kathmandu (GMT+05:45)",
    value: "Asia/Kathmandu",
  },
  {
    label: "Astana (GMT+06:00)",
    value: "Asia/Almaty",
  },
  {
    label: "Dhaka (GMT+06:00)",
    value: "Asia/Dhaka",
  },
  {
    label: "Almaty (GMT+06:00)",
    value: "Asia/Almaty",
  },
  {
    label: "Urumqi (GMT+06:00)",
    value: "Asia/Urumqi",
  },
  {
    label: "Rangoon (GMT+06:30)",
    value: "Asia/Yangon",
  },
  {
    label: "Novosibirsk (GMT+07:00)",
    value: "Asia/Novosibirsk",
  },
  {
    label: "Bangkok (GMT+07:00)",
    value: "Asia/Bangkok",
  },
  {
    label: "Hanoi (GMT+07:00)",
    value: "Asia/Bangkok",
  },
  {
    label: "Jakarta (GMT+07:00)",
    value: "Asia/Jakarta",
  },
  {
    label: "Krasnoyarsk (GMT+07:00)",
    value: "Asia/Krasnoyarsk",
  },
  {
    label: "Beijing (GMT+08:00)",
    value: "Asia/Shanghai",
  },
  {
    label: "Chongqing (GMT+08:00)",
    value: "Asia/Chongqing",
  },
  {
    label: "Hong Kong (GMT+08:00)",
    value: "Asia/Hong_Kong",
  },
  {
    label: "Kuala Lumpur (GMT+08:00)",
    value: "Asia/Kuala_Lumpur",
  },
  {
    label: "Singapore (GMT+08:00)",
    value: "Asia/Singapore",
  },
  {
    label: "Taipei (GMT+08:00)",
    value: "Asia/Taipei",
  },
  {
    label: "Perth (GMT+08:00)",
    value: "Australia/Perth",
  },
  {
    label: "Irkutsk (GMT+08:00)",
    value: "Asia/Irkutsk",
  },
  {
    label: "Ulaanbaatar (GMT+08:00)",
    value: "Asia/Ulaanbaatar",
  },
  {
    label: "Seoul (GMT+09:00)",
    value: "Asia/Seoul",
  },
  {
    label: "Osaka (GMT+09:00)",
    value: "Asia/Tokyo",
  },
  {
    label: "Sapporo (GMT+09:00)",
    value: "Asia/Tokyo",
  },
  {
    label: "Tokyo (GMT+09:00)",
    value: "Asia/Tokyo",
  },
  {
    label: "Yakutsk (GMT+09:00)",
    value: "Asia/Yakutsk",
  },
  {
    label: "Darwin (GMT+09:30)",
    value: "Australia/Darwin",
  },
  {
    label: "Adelaide (GMT+09:30)",
    value: "Australia/Adelaide",
  },
  {
    label: "Canberra (GMT+10:00)",
    value: "Australia/Sydney",
  },
  {
    label: "Melbourne (GMT+10:00)",
    value: "Australia/Melbourne",
  },
  {
    label: "Sydney (GMT+10:00)",
    value: "Australia/Sydney",
  },
  {
    label: "Brisbane (GMT+10:00)",
    value: "Australia/Brisbane",
  },
  {
    label: "Hobart (GMT+10:00)",
    value: "Australia/Hobart",
  },
  {
    label: "Vladivostok (GMT+10:00)",
    value: "Asia/Vladivostok",
  },
  {
    label: "Guam (GMT+10:00)",
    value: "Pacific/Guam",
  },
  {
    label: "Port Moresby (GMT+10:00)",
    value: "Pacific/Port_Moresby",
  },
  {
    label: "Magadan (GMT+11:00)",
    value: "Asia/Magadan",
  },
  {
    label: "Solomon Is. (GMT+11:00)",
    value: "Pacific/Guadalcanal",
  },
  {
    label: "New Caledonia (GMT+11:00)",
    value: "Pacific/Noumea",
  },
  {
    label: "Fiji (GMT+12:00)",
    value: "Pacific/Fiji",
  },
  {
    label: "Kamchatka (GMT+12:00)",
    value: "Asia/Kamchatka",
  },
  {
    label: "Marshall Is. (GMT+12:00)",
    value: "Pacific/Majuro",
  },
  {
    label: "Auckland (GMT+13:00)",
    value: "Pacific/Auckland",
  },
  {
    label: "Wellington (GMT+13:00)",
    value: "Pacific/Auckland",
  },
  {
    label: "Nuku'alofa (GMT+13:00)",
    value: "Pacific/Tongatapu",
  },
  {
    label: "Tokelau Is. (GMT+13:00)",
    value: "Pacific/Fakaofo",
  },
  {
    label: "Chatham Is. (GMT+13:45)",
    value: "Pacific/Chatham",
  },
  {
    label: "Samoa (GMT+14:00)",
    value: "Pacific/Apia",
  },
];

export default timezones;
