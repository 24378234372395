import { useGetActiveMembers } from "../../../api/brand/activeMembers/get";
import { useGetCurrentBrand } from "../../../api/brand/current/get";
import { useGetInvitationPending } from "../../../api/invitations/invitationPending/get";
import { displayActiveMembers } from "../functions/displayActiveMembers";
import { displayPendingMembers } from "../functions/displayPendingMembers";

export const useDisplayMembers = () => {
  const { data: pendingMembers } = useGetInvitationPending();
  const { data: activeMembers, isLoading: isMembersLoading } =
    useGetActiveMembers();
  const { data: currentOrganization } = useGetCurrentBrand();

  const activeMemberItems = displayActiveMembers(activeMembers);
  const pendingMemberItems = displayPendingMembers(
    pendingMembers,
    currentOrganization,
  );

  return {
    activeMembers,
    pendingMembers,
    activeMemberItems,
    pendingMemberItems,
    isMembersLoading,
  };
};
