import { useState } from "react";
import SButton from "../../design-system/SButton";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { toast } from "../../hooks/toast";
import { useAddMemberToBrand } from "../../api/brand/activeMembers/post";
import { IWorkspaceUsersVM } from "../../utils/interfaces/IWorkspace";

export const BrandInviteWorkspaceMembersRow = ({member}: {member: IWorkspaceUsersVM}) => {
  const [loading, setLoading] = useState(false);
  const { mutateAsync: inviteMember } = useAddMemberToBrand();
  return (
      <div
        className="w-full flex items-center justify-between px-6 py-4"
        key={member.userId}
      >
        <div className="w-full flex items-center gap-3">
          <img
            className="rounded-full w-10 h-10 object-cover"
            src={member.photoUrl}
            alt={`STORIAI member of workspace ${member.workspaceName}`}
          />
          <div>
            <h1 className="text-sm-m text-gray-900">{member.name}</h1>
            <h1 className="text-gray-600 text-sm">{member.email}</h1>
          </div>
        </div>
        <div className="flex items-center justify-between w-1/2">
          <h1 className="text-gray-600 text-sm">{member.role}</h1>
          <SButton
            onClick={() => {
              setLoading(true)
              inviteMember({userId: member.userId}).then(res => {
                setLoading(false);
                toast(`${member.name} has been added to your organization`, "success");
              }).catch(res => {
                setLoading(false);
                toast(res.message, "error");
              });
            }}
            size="sm"
            type="secondaryColor"
            icon={faPlus}
            isLoading={loading}
          />
        </div>
      </div>
    );
}
