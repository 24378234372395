import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/pro-regular-svg-icons";
import "../../../index.css";
import WorkspaceMembersRow from "../WorkspaceMembersRow";
import { IWorkspaceUsersVM } from "../../../utils/interfaces/IWorkspace";
import { TeamRole } from "../../../utils/enums/Enums";
import { getRole } from "../../workspace/functions/getRole";

export interface WorkspaceMembersViewProps {
  items?: IWorkspaceUsersVM[];
  className?: string;
  setEditModal?: (value: boolean) => void;
  setChosenUser?: (value: {
    userId: string;
    name: string;
    email: string;
    role: TeamRole;
    id: number;
  }) => void;
  isViewer?: boolean;
}

function WorkspaceMembersView({
  items,
  className,
  setEditModal,
  setChosenUser,
  isViewer,
}: WorkspaceMembersViewProps) {
  return (
    <div
      className={`${className} w-full bg-white border border-gray-200 rounded-[12px] shadow-xs max-md:overflow-x-auto`}
    >
      <table className="w-full">
        <thead className="border-b rounded-t-[12px] border-gray-200">
          <tr className="text-left">
            <th className="px-[24px] rounded-tl-[12px] bg-gray-50 py-[16px] w-7/12 text-xs-m text-gray-600 max-md:pr-60">
              Name
            </th>
            <th className="py-[16px] bg-gray-50 w-2/12 text-xs-m text-gray-600 max-md:pr-20">
              Status
            </th>
            <th className="py-[16px] bg-gray-50 w-2/12 text-xs-m text-gray-600 max-md:pr-20">
              Role
            </th>
            <th className="py-[16px] bg-gray-50 w-2/12 text-xs-m text-gray-600 max-md:pr-10">
              Brands
            </th>
            <th className="p-2 w-min rounded-tr-[12px] bg-gray-50 text-left"></th>
          </tr>
        </thead>
        <tbody>
          {items &&
            items.map((item, index) => (
              <WorkspaceMembersRow
                onEdit={() => {
                  setChosenUser &&
                    setChosenUser({
                      userId: item.userId,
                      email: item.email,
                      name: item.name,
                      role: TeamRole[getRole(item.role)],
                      id: item.id,
                    });
                  setEditModal && setEditModal(true);
                }}
                key={index}
                className={`${(index + 1) % 2 === 0 ? "bg-gray-50" : "bg-white"}`}
                userData={item}
                isViewer={isViewer}
              />
            ))}
        </tbody>
      </table>
    </div>
  );
}

export default WorkspaceMembersView;
