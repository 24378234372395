import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import SpeechRecognition from "react-speech-recognition";
import Brief from "./Tabs/Brief";
import Channels from "./Tabs/Channels";
import Media from "./Tabs/Media";
import Frames from "./Tabs/Frames";
import PublishedTab from "./Tabs/PublishedTab";
import CreationHeader from "./CreationHeader";
import SButton from "../../design-system/SButton";
import { faMinus, faX } from "@fortawesome/pro-regular-svg-icons";
import { useCreationStore } from "../../store/creationStore";
import SBadge from "../../design-system/SBadge";
import SessionErrored from "./Tabs/Brief/SessionErrored";

import { BillingInfo } from "../billing/BillingInfo";

import SOptionButon from "../../design-system/SOptionButton";
import { toast } from "../../hooks/toast";
import { PostStatus } from "../../utils/enums/Enums";
import { usePublishing } from "./hooks/usePublishing";
import ScheduleModal from "./modals/ScheduleModal";


interface MobileSectionsDrawerProps {
  setIsUpdatedByCopilot: Dispatch<SetStateAction<boolean>>;
  onCreationClose: () => void;
}

const MobileSectionsDrawer = ({
  setIsUpdatedByCopilot,
  onCreationClose,
}: MobileSectionsDrawerProps) => {
  const [showShareButton, setShowShareButton] = useState(true);
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const { handlePublishNow } = usePublishing();
  const {
    isMobileSectionsDrawerOpen,
    setIsMobileSectionsDrawerOpen,
    activeTab,
    setActiveTab,
    sessionErrored,
    isPublishing,
    selectedSocialNetworks,
    setSelectedSocialNetworks,
    postData,
  } = useCreationStore();

  useEffect(() => {
    if (!postData) return;
    if (selectedSocialNetworks.length === 0) {
      setShowShareButton(true);
      return;
    }
    const unpublishedNetworks = selectedSocialNetworks.filter((item) => {
      const postWithStatus = postData.socialNetworkPosts.find(
        (sPost) => sPost.socialNetworkType === item.socialNetworkType
      );
      return !(
        postWithStatus?.postStatus === PostStatus.SCHEDULED ||
        postWithStatus?.postStatus === PostStatus.PUBLISHED
      );
    });
    setShowShareButton(unpublishedNetworks.length === 0);
  }, [postData, selectedSocialNetworks]);

  return (
    <>
      <ScheduleModal
        isOpen={isScheduleModalOpen}
        onClose={() => setIsScheduleModalOpen(false)}
      />
      <CreationHeader
        onClose={async () => {
          await onCreationClose();
        }}
      />
      <Drawer
        open={isMobileSectionsDrawerOpen}
        onClose={async () => {
          setIsMobileSectionsDrawerOpen(false);
        }}
        direction="bottom"
        className="min-h-[94dvh] rounded-t-[24px] z-[1001]"
        lockBackgroundScroll={true}
      >
        <div
          className={"flex items-center justify-between px-[16px] py-[12px]"}
        >
          <p className="text-lg-m">
            {activeTab === "brief" && "Creation"}
            {activeTab === "accounts" && "Accounts"}
            {activeTab === "media" && "Assets"}
            {activeTab === "elements" && (
              <div className="flex items-center gap-2">
                Templates{" "}
                <SBadge size="sm" hasBackground={false} className="shadow-none">
                  Beta
                </SBadge>
              </div>
            )}
            {activeTab === "published" && "Published"}
          </p>
          <div className="flex items-end gap-[6px]  max-md:ml-auto mr-4">
            {showShareButton ? (
              <SButton
                type="secondaryColor"
                onClick={() => {
                  activeTab !== "accounts" && setActiveTab("accounts");
                  toast(
                    "Please select at least one social network first",
                    "info"
                  );
                  setIsMobileSectionsDrawerOpen(true);
                }}
              >
                Share
              </SButton>
            ) : (
              <SOptionButon
                isLoading={!!isPublishing}
                type="secondaryColor"
                disabled={!!isPublishing}
                className="min-w-[120px]"
                label={
                  isPublishing
                    ? isPublishing === "publishing"
                      ? "Publishing"
                      : isPublishing === "scheduling"
                        ? "Scheduling"
                        : "Planning"
                    : selectedSocialNetworks.length > 0
                      ? "Schedule"
                      : "Share"
                }
                onClick={() => {
                  if (selectedSocialNetworks.length === 0) {
                    activeTab !== "accounts" && setActiveTab("accounts");
                    toast(
                      "Please select at least one social network first",
                      "info"
                    );
                    setIsMobileSectionsDrawerOpen(true);
                    return;
                  }

                  setIsScheduleModalOpen(true);
                }}
                items={[
                  {
                    label: "Publish Now",
                    buttonProps: {
                      type: "secondaryColor",
                    },
                    onClick: () => {
                      if (selectedSocialNetworks.length === 0) {
                        activeTab !== "accounts" && setActiveTab("accounts");
                        toast(
                          "Please select at least one social network first",
                          "info"
                        );
                        return;
                      }
                      handlePublishNow().then((res) => {
                        setSelectedSocialNetworks([]);
                      });
                    },
                  },
                ]}
              />
            )}
          </div>
          <SButton
            icon={faMinus}
            type={"secondaryGray"}
            onClick={() => {
              setIsMobileSectionsDrawerOpen(false);
              setActiveTab(null);
            }}
            className={"bg-base-white text-gray-600"}
          />
        </div>
        <div className={"h-[80dvh] overflow-y-auto w-full"}>
          {activeTab === "brief" &&
            (sessionErrored ? (
              <SessionErrored />
            ) : (
              <Brief setIsUpdatedByCopilot={setIsUpdatedByCopilot} />
            ))}
          {activeTab === "accounts" && <Channels />}
          {activeTab === "media" && <Media />}
          {activeTab === "elements" && <Frames />}
          {activeTab === "published" && <PublishedTab />}
        </div>
      </Drawer>
    </>
  );
};

export default MobileSectionsDrawer;
