import React from "react";
import SBadge from "../../../../../design-system/SBadge";
import { useHelperInputHelperFunctions } from "./useHelperInputHelperFunctions";
import {
  ImageGenerationAspectRatio,
  ImageGenerationStyle,
} from "../../../../../utils/enums/Enums";
import { AspectRatioVisuals } from "./DefaultState/AspectRatioVisuals";

function SelectorTags({
  activeAspectRatio,
  setActiveAspectRatio,
  activeStyleTag,
  setActiveStyleTag,
}: {
  activeAspectRatio: ImageGenerationAspectRatio;
  setActiveAspectRatio: React.Dispatch<
    React.SetStateAction<ImageGenerationAspectRatio>
  >;
  activeStyleTag: ImageGenerationStyle;
  setActiveStyleTag: React.Dispatch<React.SetStateAction<ImageGenerationStyle>>;
}) {
  const {
    imageGenerationAspectRatios,
    imageGenerationStyles,
    getGenerationStyleNameById,
  } = useHelperInputHelperFunctions();
  return (
    <div className="flex flex-col mt-[12px] mx-[24px] flex-wrap gap-[16px]">
      <div className="flex flex-wrap gap-[8px]">
        {imageGenerationStyles.map((styleId) => (
          <SBadge
            onClick={() => {
              if (activeStyleTag === styleId) {
                setActiveStyleTag(ImageGenerationStyle.NONE);
              } else {
                setActiveStyleTag(styleId);
              }
            }}
            className={`${styleId === activeStyleTag && "bg-gray-600 border-gray-600 !text-base-white"} cursor-pointer`}
            size="sm"
            key={styleId}
          >
            {getGenerationStyleNameById(styleId)}
          </SBadge>
        ))}
      </div>
      <div className="w-full h-[1px] bg-gray-100" />
      <div className="flex flex-wrap gap-[8px]">
        {imageGenerationAspectRatios.map((aspectRatio) => (
          <SBadge
            hasBackground={false}
            onClick={() => {
              setActiveAspectRatio(aspectRatio);
            }}
            className={`${aspectRatio === activeAspectRatio && "bg-gray-600 border-gray-600 !text-base-white"} bg-gray-50 cursor-pointer`}
            size="sm"
            key={aspectRatio}
          >
            <AspectRatioVisuals
              aspectRatio={aspectRatio}
              activeAspectRatio={activeAspectRatio}
            />
            {aspectRatio}
          </SBadge>
        ))}
      </div>
    </div>
  );
}

export default SelectorTags;
