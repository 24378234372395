import { makeRequest } from "../../../axios";
import { useQuery } from "@tanstack/react-query";
import { IBaseData } from "../../../utils/interfaces/IBase";

export const useManageSubscription = () => {
  return useQuery<IBaseData<{data: string}>>({
    queryKey: ["useManageSubscription"],
    queryFn: async () => {
      const response = await makeRequest.get(
        "/Subscriptions/ManageSubscription",
      );
      return response as IBaseData<{data: string}>;
    },
  });
};
