import { ISocialNetworkVM } from "../../../../utils/interfaces/ISocialNetworks";
import LettersAvatar from "../../../utils/LettersAvatar";
import { Tooltip } from "react-tooltip";
import { PostStatus } from "../../../../utils/enums/Enums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faSquareXmark } from "@fortawesome/pro-solid-svg-icons";
import SRadio from "../../../../design-system/SRadio";
import SButton from "../../../../design-system/SButton";
import {
  faCalendarXmark,
  faExternalLink,
} from "@fortawesome/pro-regular-svg-icons";

interface ITableTrItemChannel {
  isDisabled: boolean;
  page: ISocialNetworkVM;
  isChecked: boolean;
  handleSelect: (socNetwork: ISocialNetworkVM) => void;
  dataTooltipId?: string;
  status: PostStatus;
  oneDisabled?: boolean;
  isScheduled?: boolean;
  isPublished?: boolean;
  onRemoveSchedule?: () => void;
  viewURL?: string | null;
  windowWidth: number;
}

function TableTrItemChannel({
  isDisabled,
  status,
  isChecked,
  handleSelect,
  dataTooltipId,
  page,
  oneDisabled,
  onRemoveSchedule,
  isScheduled,
  isPublished,
  viewURL,
  windowWidth,
}: ITableTrItemChannel) {
  const initial = page?.name ? page.name.charAt(0).toUpperCase() : "";

  return (
    <>
      <div
        onClick={() => !isDisabled && !oneDisabled && handleSelect(page)}
        className={`flex flex-wrap items-center gap-[12px] px-[16px] rounded-[8px] cursor-pointer
          py-[8px] ${(isDisabled || oneDisabled) && "!cursor-default"} w-fit border-[1px] border-graymodern-300 bg-base-white *:
          ${oneDisabled ? "opacity-50" : ""}
          `}
      >
        {!isDisabled && !oneDisabled && (
          <SRadio size="md" checked={isChecked} />
        )}
        {isDisabled && (
          <FontAwesomeIcon
            icon={faCheck}
            className="text-lg text-success-500"
          />
        )}
        {oneDisabled && (
          <FontAwesomeIcon
            icon={faSquareXmark}
            className="text-lg text-gray-300"
          />
        )}
        {page?.logoUrl ? (
          <img
            src={page.logoUrl}
            alt="logo"
            className="w-[28px] h-[28px] rounded-full"
          />
        ) : (
          <LettersAvatar
            text={page?.name}
            className="!w-[28px] !h-[28px] text-xs-m"
          />
        )}
        <p data-tooltip-id={dataTooltipId} className="text-sm-m text-gray-900">
          {page?.name}
        </p>
        {isScheduled && (
          <div>
            <Tooltip
              className={"z-[100]"}
              place="bottom-start"
              id={`remove-channel-${page.id}`}
            >
              Remove schedule
            </Tooltip>
            <SButton
              lIcon={faCalendarXmark}
              data-tooltip-id={`remove-channel-${page.id}`}
              type="secondaryColor"
              onClick={(e) => {
                e.stopPropagation();
                onRemoveSchedule && onRemoveSchedule();
              }}
              iconProps={{
                className: "text-sm",
              }}
              className="bg-error-50 max-sm:py-[0px] box-content sm:!py-[3px] sm:!px-[6px] sm:!h-[20px] border border-error-200 hover:bg-error-100 hover:text-error-600 text-error-500"
            >
              {windowWidth <= 680 && "Unschedule"}
            </SButton>
          </div>
        )}
        {isPublished && (
          <>
            <Tooltip
              className={"z-[100]"}
              place="bottom-start"
              id={`view-post-${page.id}`}
            >
              View post
            </Tooltip>
            <SButton
              data-tooltip-id={`view-post-${page.id}`}
              onClick={() => {
                window.open(viewURL as string, "_blank");
              }}
              size="sm"
              type={windowWidth <= 680 ? "secondaryColor" : "tertiaryColor"}
              className="max-sm:w-full max-sm:py-[0px] box-content sm:!py-[3px] sm:!px-[6px] sm:!h-[20px] max-sm:!py-[0px] box-content sm:!h-[20px]"
              lIcon={faExternalLink}
            >
              {windowWidth <= 680 && "View"}
            </SButton>
          </>
        )}
      </div>
    </>
  );
}

export default TableTrItemChannel;
