import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { makeRequest } from "../../auth/axios";
import { IInviteVM } from "../../../utils/interfaces/ITeamInvitation";

export const useUpdateGlobalLink = () => {
  const queryClient = useQueryClient();
  return useMutation<IBaseData<IInviteVM>, Error>({
    mutationFn: () => {
      return makeRequest.put(`/WorkspaceUsers/Invitations/Link`);
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["useGetInviteLink"] });
    },
  });
};
