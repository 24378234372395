import {
  faEllipsisVertical,
  faPencil,
  faPlus,
  faPlusCircle,
  faRefresh,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useGetCopilotSession } from "../../api/copilot/session/get";
import { CopilotType } from "../../utils/enums/Enums";
import { useCopilotStore } from "../../store/copilotStore";
import { usePostCopilotSession } from "../../api/copilot/session/post";
import RenameSessionModal from "./modals/RenameSessionModal";
import { ICopilotSessionVM } from "../../utils/interfaces/ICopilot";
import SSmallMenu from "../../design-system/SSmallMenu";
import { useCopilotHelper } from "./hooks/useCopilotHelper";
import SessionItem from "./SessionItem";

function Sessions() {
  const { handleCreateCopilotSession } = useCopilotHelper();
  const { data: sessions } = useGetCopilotSession({
    enabled: false,
    type: CopilotType.DEFAULT,
  });
  const [isCopilotSessionCreating, setIsCopilotSessionCreating] =
    useState(false);
  const { isWriting } = useCopilotStore();

  return (
    <>
      <div className="min-w-[164px] max-w-[164px] w-full border-r border-r-gray-200 h-full max-md:hidden">
        <div className="flex items-center justify-between py-[12px] px-[16px] border-b border-b-gray-200">
          <p className="text-sm-sb text-gray-700">Chats</p>
          <FontAwesomeIcon
            onClick={() => {
              setIsCopilotSessionCreating(true);
              handleCreateCopilotSession().finally(() => {
                setIsCopilotSessionCreating(false);
              });
            }}
            spin={isCopilotSessionCreating}
            icon={isCopilotSessionCreating ? faRefresh : faPlus}
            className={`${isWriting ? "pointer-events-none" : "pointer-events-auto"} text-[14px] text-brand-600 cursor-pointer`}
          />
        </div>
        <div className="px-[16px] py-[12px] flex flex-col gap-[16px]">
          {sessions?.data.map((item) => <SessionItem session={item} />)}
        </div>
      </div>
    </>
  );
}

export default Sessions;
