import TableTrItem from "./Channels/TableTrItem";
import { useGetAllConnections } from "../../../api/connections/get";
import { useChannelsHelper } from "./Channels/functions/useChannelsHelper";
import TableTrItemSkeleton from "./Channels/TableTrItemSkeleton";
import { useBrandStore } from "../../../store/brandStore";
import PublishedTab from "./PublishedTab";
import { useCreationHelperFunctions } from "../hooks/useCreationHelperFunctions";
import { PostStatus } from "../../../utils/enums/Enums";
import { useGetWindowWidth } from "../../../hooks/useGetWindowWidth";

function Channels() {
  const { data: connections } = useGetAllConnections();
  const { socChannels } = useChannelsHelper();
  const { currentBrand } = useBrandStore();
  const { postData } = useCreationHelperFunctions();
  const isScheduledOrPublished = postData?.socialNetworkPosts.find(
    (item) =>
      item.postStatus === PostStatus.SCHEDULED ||
      item.postStatus === PostStatus.PUBLISHED
  );
  const { windowWidth } = useGetWindowWidth();
  return (
    <>
      {isScheduledOrPublished && <PublishedTab />}
      <div className="overflow-x-hidden h-full overflow-y-auto pb-[50px] rounded-[12px] border-[1px] border-gray-200">
        <table className="min-w-full text-xs rounded-[12px]">
          <thead className="rounded-t-[12px] border-b-[1px] border-b-gray-200">
            <tr className="text-left">
              <th className="text-sm-m text-gray-600 px-[24px] py-[20px] w-[35%]">
                Networks
              </th>
              <th className="text-sm-m text-gray-600 px-[24px] py-[20px] w-[100%]">
                Accounts
              </th>
            </tr>
          </thead>
          <tbody>
            {currentBrand &&
              socChannels.map((item, i) =>
                connections ? (
                  currentBrand.activeSocialNetworks.includes(
                    item.socialNetworkType
                  ) && (
                    <TableTrItem
                      image={item.image}
                      title={item.name}
                      website={item.website}
                      socialNetworkType={item.socialNetworkType}
                      index={i}
                      hasBackground={i % 2 === 0}
                      pages={connections.data.filter(
                        (cItem) =>
                          cItem.socialNetworkType === item.socialNetworkType
                      )}
                    />
                  )
                ) : (
                  <>
                    <TableTrItemSkeleton index={1} />
                    <TableTrItemSkeleton index={1} />
                  </>
                )
              )}
          </tbody>
        </table>
      </div>
      {windowWidth <= 880 && (
        <p className="w-full pt-2 text-sm text-gray-600  px-6">
          *Note: in order to connect your account, please allow redirects in
          your browser.
        </p>
      )}
    </>
  );
}

export default Channels;
