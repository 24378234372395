import { PostStatus, SocialNetworkType } from "../../../utils/enums/Enums";
import { useCreationHelperFunctions } from "../hooks/useCreationHelperFunctions";

function PublishedTab() {
  const { getActiveSocialNetworkPost, postData } = useCreationHelperFunctions();
  const determineResult = () => {
    let result = "";
    let counter = 0;
    let socPostType = 0;
    postData?.socialNetworkPosts.forEach((element) => {
      if (
        element.postStatus === PostStatus.SCHEDULED ||
        element.postStatus === PostStatus.PUBLISHED
      ) {
        counter++;
      }
      if (element.postStatus === PostStatus.SCHEDULED && result === "") {
        result = "scheduled";
        socPostType = element.socialNetworkType;
      }
      if (
        element.postStatus === PostStatus.SCHEDULED &&
        result === "published"
      ) {
        result = "scheduled and published";
        socPostType = element.socialNetworkType;
      }
      if (
        element.postStatus === PostStatus.PUBLISHED &&
        result === "scheduled"
      ) {
        result = "scheduled and published";
        socPostType = element.socialNetworkType;
      }
      if (element.postStatus === PostStatus.PUBLISHED && result === "") {
        result = "published";
        socPostType = element.socialNetworkType;
      }
    });
    if (counter === 1) {
      let name =
        SocialNetworkType[socPostType as number][0] +
        SocialNetworkType[socPostType as number].toLowerCase().substring(1);
      if (name === "Twitter") name = "X (Twitter)";
      return result + " on " + name;
    }
    return result + " on following accounts";
  };
  return (
    <>
      {getActiveSocialNetworkPost() && (
        <div className="overflow-hidden mb-3">
          <p className="max-sm-m:text-sm max-sm-m:px-4 text-lg-m text-gray-600">
            🎉 Your post has been {determineResult()}:
          </p>
        </div>
      )}
    </>
  );
}

export default PublishedTab;
