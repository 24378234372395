import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../../axios";
import { IBaseData } from "../../../utils/interfaces/IBase";

//TODO: ak team idi rato chirdeba backs
//TODO: vm ar akvs backshi
export const useDeleteBrandCustomField = () => {
  const queryClient = useQueryClient();
  return useMutation<IBaseData<null>, Error, { fieldId: number }>({
    mutationFn: ({ fieldId }) =>
      makeRequest.delete(`/Organizations/CustomField/${fieldId}`),
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["useGetCurrentBrand"] });
      return res;
    },
  });
};
