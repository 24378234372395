import PageHeading from "../components/PageHeading";
import PostList from "../components/content/PostList";
import { ISinglePostVM } from "../utils/interfaces/IPost";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/pro-regular-svg-icons";
import { tabItems } from "../components/content/utils/tabItems";
import WhichSocialNetworksSelector from "../components/utils/WhichSocialNetworksSelector";
import { ContentIdea } from "../components/content/ContentIdea";
import { OrganizationUserRole, TeamPackageStatus } from "../utils/enums/Enums";
import { EmptyContentState } from "../components/creation/EmptyState/EmptyContentState";
import { SortPosts } from "../components/content/SortPosts";
import { ContentIdeaSkeleton } from "../components/content/ContentIdeaSkeleton";
import { useContent } from "../components/content/hooks/useContent";
import { useContentCount } from "../components/content/hooks/useContentCount";
import { useContentScroll } from "../components/content/hooks/useConntentScroll";

function Content() {
  const {
    posts,
    setPosts,
    setPage,
    isFetching,
    calculateTotalCount,
    totalCount,
  } = useContentScroll();
  const {
    currentPlan,
    windowWidth,
    handleOpenPost,
    personalInfo,
    workspaceUsage,
    isWhichSocialNetworksSelectorOpen,
    setIsWhichSocialNetworksSelectorOpen,
    setIsPostOpen,
    searchValue,
    setSearchValue,
    navigate,
  } = useContent();
  const { selected, setSelected, currentBrand, selectedSort, setSelectedSort } =
    useContentCount();

  return (
    <>
      {isWhichSocialNetworksSelectorOpen && (
        <WhichSocialNetworksSelector
          saveAllOnClose={true}
          isOpen={isWhichSocialNetworksSelectorOpen}
          onClose={() => {
            setIsWhichSocialNetworksSelectorOpen(false);
            setIsPostOpen(true);
          }}
        />
      )}
      <div className="w-full py-[24px]">
        <PageHeading
          title="Content"
          subtitle="Manage your posts and content."
          withInput={true}
          inputProps={{
            placeholder: "Search...",
            value: searchValue,
            onChange: (e) => setSearchValue(e.target.value),
            rightAdditionalContent: (
              <div
                className={`${searchValue.length > 0 ? "block" : "hidden"}`}
                onClick={() => setSearchValue("")}
              >
                <FontAwesomeIcon
                  icon={faX}
                  fontSize={"14px"}
                  className="text-gray-700 cursor-pointer"
                />
              </div>
            ),
          }}
          tabsProps={{
            items: tabItems,
            selected: selected,
            setSelected: (i) => {
              if (i === selected) return;
              const index = i === 4 ? 3 : i;
              setPosts([]);
              setPage(1);
              setSelected(index as number);
              navigate(
                "/?subsection=" +
                  tabItems[index as number]?.text?.toLocaleLowerCase()
              );
            },
          }}
        />
        {totalCount === 0 && !isFetching && searchValue.length === 0 && (
          <EmptyContentState
            setIsWhichSocialNetworksSelectorOpen={
              setIsWhichSocialNetworksSelectorOpen
            }
          />
        )}
        {/* {currentPlan?.data.status === TeamPackageStatus.ACTIVE && (
          <div className="min-h-[175px] mt-4">
            {!isFetching &&
              !isPostIdeasFetching &&
              posts.length > 0 &&
              currentBrand?.data.isBrandDataGenerated &&
              workspaceUsage?.data.canUserAddPosts &&
              personalInfo?.userRole !== OrganizationUserRole.VIEWER && (
                <ContentIdea
                  setIsWhichSocialNetworksSelectorOpen={
                    setIsWhichSocialNetworksSelectorOpen
                  }
                  isPostIdeasFetching={isPostIdeasFetching}
                  postIdeas={postIdeas?.data}
                />
              )}
            {(isFetching || isPostIdeasFetching) && (
              <div className="flex h-[175px] flex-col bg-gray-50 border rounded-xl p-3 border-gray-200">
                <div className="mb-3 w-[10%] h-7 bg-gray-100 rounded" />
                <div className="h-full items-center w-full grid gap-3 grid-cols-5 max-lg:grid-cols-5 max-md:grid-cols-4 max-sm-m:grid-cols-2 max-sm:grid-cols-2">
                  <ContentIdeaSkeleton />
                  <ContentIdeaSkeleton />
                  {windowWidth > 880 && <ContentIdeaSkeleton />}
                  {windowWidth > 880 && <ContentIdeaSkeleton />}
                  {windowWidth > 1000 && <ContentIdeaSkeleton />}
                </div>
              </div>
            )}
          </div>
        )} */}

        <div className="h-[100px] flex items-center">
          {calculateTotalCount() > 0 ? (
            <div
              className={`py-[30px] w-full flex justify-between items-center`}
            >
              <p className="flex max-sm:text-sm whitespace-nowrap gap-[8px] pr-[20px] display-xs-sb text-gray-700 md:pl-[16px]">
                {selectedSort === "created" ? "Recently" : "Last"}{" "}
                {selectedSort} posts
              </p>
              <hr className="w-full border-gray-200" />
              <SortPosts
                className="whitespace-nowrap ml-[20px] md:mr-[16px] max-sm:text-sm"
                selectedSort={selectedSort}
                setSelectedSort={setSelectedSort}
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <PostList
          handleOpenPost={(p: ISinglePostVM) => {
            handleOpenPost({
              postId: p.id,
              onSocialNetworkSelectorOpen: () =>
                setIsWhichSocialNetworksSelectorOpen(true),
            });
          }}
          handleCreateNew={() =>
            handleOpenPost({
              onSocialNetworkSelectorOpen: () =>
                setIsWhichSocialNetworksSelectorOpen(true),
            })
          }
          posts={posts}
          totalCount={totalCount || 0}
          searchValue={searchValue}
          isLoading={isFetching}
          selected={selected}
        />
      </div>
    </>
  );
}

export default Content;
