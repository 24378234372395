import { faX } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useCopilotStore } from "../../store/copilotStore";
import SButton from "../../design-system/SButton";

function Head() {
  const { setIsCopilotOpen } = useCopilotStore();
  return (
    <div className="w-full h-[56px] flex justify-between items-center px-[24px] py-[16px] bg-base-white border-b border-b-gray-200">
      <p className="text-md-m text-gray-600">STORI Assistant</p>
      <SButton
        size="sm"
        type="secondaryGray"
        onClick={() => setIsCopilotOpen(false)}
      >
        <FontAwesomeIcon icon={faX} className="text-gray-500 cursor-pointer" />
      </SButton>
    </div>
  );
}

export default Head;
