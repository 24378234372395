import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import NotificationProvider from "./components/notifications/NotificationProvider";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
const originalError = console.error;
console.error = (...args) => {
  if (
    args[0] &&
    typeof args[0] === "string" &&
    args[0].includes("A component is `contentEditable`")
  ) {
    return;
  }
  originalError.call(console, ...args);
};
root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <NotificationProvider>
        <ToastContainer />
        <App />
      </NotificationProvider>
    </BrowserRouter>
  </QueryClientProvider>
);
