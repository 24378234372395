import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { makeRequest } from "../../auth/axios";

export const useDeleteFavouriteFrame = () => {
  const queryClient = useQueryClient();
  return useMutation<IBaseData<null>, Error, { templateIds: number[] }>({
    mutationFn: ({ templateIds }) =>
      makeRequest.delete(`/Templates/Favorite?templateIds=${templateIds}`),
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["useGetFavouriteFrames"] });
    },
  });
};
