import { makeRequest } from "../../../axios";
import { useQuery } from "@tanstack/react-query";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { IPackageVM } from "../../../utils/interfaces/ISubscriptions";

export const useGetPackages = () => {
  return useQuery<IBaseData<IPackageVM[]>>({
    queryKey: ["useGetPackages"],
    queryFn: async () => {
      const response = await makeRequest.get(
        "/Subscriptions/AvailablePackages",
      );
      return response as IBaseData<IPackageVM[]>;
    },
  });
};
