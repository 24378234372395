import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IBaseData } from "../../utils/interfaces/IBase";
import { IPostCOE, ISinglePostVM } from "../../utils/interfaces/IPost";
import { makeRequest } from "../auth/axios";

export const useDeleteConnection = () => {
  const queryClient = useQueryClient();
  return useMutation<IBaseData<string>, Error, { id: number }>({
    mutationFn: ({ id }) => makeRequest.delete(`/SocialNetworks/${id}`),
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["useGetAllConnections"] });
    },
  });
};
