import React, { useEffect, useState } from "react";
import SModalBase from "../../../design-system/SModalBase";
import SInput from "../../../design-system/SInput";
import SButton from "../../../design-system/SButton";
import { ICopilotSessionVM } from "../../../utils/interfaces/ICopilot";
import { usePutCopilotSession } from "../../../api/copilot/session/put";

function RenameSessionModal({
  isOpen,
  onClose,
  actionSession,
}: {
  actionSession: ICopilotSessionVM | null;
  isOpen: boolean;
  onClose: () => void;
}) {
  const [value, setValue] = useState("");
  const { mutateAsync: putCopilot, isPending } = usePutCopilotSession();
  useEffect(() => {
    if (actionSession) setValue(actionSession.name);
  }, [actionSession]);

  const handleRename = () => {
    if (actionSession)
      putCopilot({ name: value, id: actionSession.id }).then((res) => {
        onClose();
      });
  };

  return (
    <SModalBase
      dialogPanelClassName="w-[544px] py-4"
      isOpen={isOpen}
      onClose={onClose}
    >
      <h1 className="text-md-m mb-3 text-gray-500">Rename session</h1>
      <hr className="w-auto mx-[-24px]" />
      <div className="w-full flex flex-col gap-3 mt-3">
        <SInput
          value={value}
          onChange={(e) => setValue(e.target.value)}
          label={`Session name`}
          placeholder={`write a new name`}
        />
        <div className="w-full flex items-center justify-end gap-3">
          <SButton onClick={onClose} children="Cancel" type="secondaryGray" />
          <SButton
            onClick={handleRename}
            isLoading={isPending}
            children="Save"
          />
        </div>
      </div>
    </SModalBase>
  );
}

export default RenameSessionModal;
