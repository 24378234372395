import { Tooltip } from "react-tooltip";
import {
  SocialNetworkCharacterLimit,
  SocialNetworkType,
} from "../../../utils/enums/Enums";
import { CharacterProgress } from "./CharacterProgress";

export const CharacterCounter = ({
  text,
  activeSoc,
}: {
  text: string;
  activeSoc: SocialNetworkType;
}) => {
  const limit =
    SocialNetworkCharacterLimit[
      SocialNetworkType[activeSoc] as keyof typeof SocialNetworkCharacterLimit
    ];
  const getSocialMediaName = () => {
    const rawName = SocialNetworkType[activeSoc];
    if (rawName === "TWITTER") return "X (Twitter)";
    return rawName.toLowerCase();
  };
  return (
    <>
      <Tooltip place="top" id="character-counter">
        Character limit on{" "}
        <span className="capitalize">{getSocialMediaName()}</span>
      </Tooltip>
      <div className="w-fit mt-3 ml-auto" data-tooltip-id="character-counter">
        {text.length > 0 &&
          limit - text.length <= 300 &&
          limit - text.length > 30 && (
            <CharacterProgress progress={text.length} limit={limit} />
          )}
        {limit - text.length <= 30 && (
          <div className={`mt-3 ml-auto leading-none w-fit text-xs rounded-md`}>
            {text.length <= limit && (
              <p
                className={`text-xs ${limit - text.length <= 10 ? "text-yellow-500" : "text-gray-900"}`}
              >
                {limit - text.length}
              </p>
            )}
            {text.length > limit && (
              <p className="text-xs text-error-500">
                -{(limit - text.length) * -1}
              </p>
            )}
          </div>
        )}
      </div>
    </>
  );
};
