import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getQuery } from "../hooks/functions/queries";
import { useGetInviteInfoByCode } from "../api/invitations/invitationByCode/get";
import { useUpdateGAClientId } from "../api/auth/googleAnalytics/put";

const GoogleExternalSignInCallback = () => {
  const { mutateAsync: updateClientId } = useUpdateGAClientId();
  function getCookie(cname: string) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const getGaClientId = (): string => {
    const cookie: { [key: string]: string } = {};
    document.cookie.split(";").forEach((el) => {
      const [key, value] = el.split("=").map((part) => part.trim());
      cookie[key] = value;
    });
    return cookie["_ga"]?.substring(6) || "";
  };

  const navigate = useNavigate();
  const invitationCode = getQuery("code");

  const { data: invitationByCode } = useGetInviteInfoByCode({
    code: invitationCode,
  });
  const location = useLocation();
  useEffect(() => {
    let externalTokenResponse = JSON.parse(getCookie("google-external-token"));
    const needsOnboarding = getQuery("needsonboarding");
    if (externalTokenResponse?.success) {
      localStorage.setItem("token", externalTokenResponse?.accessToken);
      const isFromCanva = getQuery("isFromCanva");
      const clientId = getGaClientId();
      updateClientId({ clientId: clientId });
      if (isFromCanva) {
        const searchParams = new URLSearchParams(location.search);
        navigate(
          `/canva-redirect${searchParams.toString() ? `?${searchParams.toString()}` : ""}`
        );
      } else if (invitationCode) {
        if (invitationByCode) {
          const searchParams = new URLSearchParams(location.search);
          navigate(
            `/?dontNavigateOnboarding=${true}${
              searchParams.toString() ? `&${searchParams.toString()}` : ""
            }`
          );
        }
      } else {
        const searchParams = new URLSearchParams(location.search);
        navigate(
          `/${searchParams.toString() ? `?${searchParams.toString()}` : ""}`
        );
      }
    } else {
      navigate("/login");
    }
  }, [navigate, updateClientId, invitationByCode]);

  return <></>;
};

export default GoogleExternalSignInCallback;
