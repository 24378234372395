import { useLocation } from "react-router-dom";
import { getQuery } from "../../../hooks/functions/queries";
import { useEffect } from "react";

export const useSetDefaultBrandSection = ({
  setSelected,
}: {
  setSelected: (value: number) => void;
}) => {
  const querySection = getQuery("section") || getQuery("subsection");
  const location = useLocation();
  useEffect(() => {
    if (querySection) {
      switch (querySection) {
        case "brandbook":
          setSelected(0);
          break;
        case "members":
          setSelected(1);
          break;
        case "accounts":
          setSelected(2);
          break;
        case "settings":
          setSelected(3);
          break;
        default:
          setSelected(0);
      }
    }
  }, [location]);
};
