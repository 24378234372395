import ImageSelectorSidebar from "./Frames/ImageSelectorSidebar";
import SelectedFrameOptions from "./Frames/SelectedFrameOptions";
import FramesList from "./Frames/FramesList";
import { useFramesStore } from "../../../store/framesStore";
import { useTemplateRenderer } from "../hooks/useTemplateRenderer";
import { useCreationHelperFunctions } from "../hooks/useCreationHelperFunctions";
import { isVideo } from "../../Library/hooks/getFileType";
import SInput from "../../../design-system/SInput";
import {
  faChevronDown,
  faClose,
  faSearch,
} from "@fortawesome/pro-regular-svg-icons";
import SButton from "../../../design-system/SButton";
import { useGetFramesGroups } from "../../../api/frames/groups/get";
import SSmallMenu from "../../../design-system/SSmallMenu";
import { useState } from "react";
function Frames() {
  const [isGroupMenuOpen, setIsGroupMenuOpen] = useState(false);
  const { getActiveSocialNetworkPost } = useCreationHelperFunctions();
  const {
    selectedImage,
    activeFrame,
    searchValue,
    setSearchValue,
    setGroupFilter,
    groupFilter,
  } = useFramesStore();
  useTemplateRenderer();
  const { data: templateGroups } = useGetFramesGroups();

  const data =
    templateGroups?.data.map((item) => ({
      text: item.name,
      handleClick: () => setGroupFilter({ id: item.id, name: item.name }),
    })) || [];
  return (
    <div
      style={{ height: "inherit" }}
      className="flex max-sm:flex-col max-sm:w-full"
    >
      {getActiveSocialNetworkPost() &&
        getActiveSocialNetworkPost()!.files.filter(
          (item) => !isVideo(item?.url || "")
        ).length > 0 && <ImageSelectorSidebar />}
      <div className="bg-base-white w-full overflow-auto flex flex-col">
        <div className="w-full flex items-end gap-2 px-[36px] max-md:px-[18px] mb-[16px] mt-[24px]">
          <SInput
            placeholder="Business, quotes, etc..."
            icon={faSearch}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            label="Search Templates"
            className="w-full"
            inputClassName={"max-sm:text-sm max-sm:placeholder:text-sm"}
          />
          <div className="flex items-center">
            <SButton
              id="templates-group-button"
              onClick={() => setIsGroupMenuOpen((val) => !val)}
              className={`py-[20.5px] max-w-[125px] relative z-30 flex items-center justify-between ${groupFilter && "rounded-r-none border-r-0"}`}
              type={groupFilter ? "secondaryColor" : "secondaryGray"}
              rIcon={faChevronDown}
            >
              <p className="truncate">
                {groupFilter === undefined ? "Groups" : groupFilter.name}
              </p>
              <SSmallMenu
                className={`z-20 right-0 left-auto h-[150px] overflow-y-scroll `}
                excludedId="templates-group-button"
                data={[data]}
                isOpen={isGroupMenuOpen}
                setIsOpen={setIsGroupMenuOpen}
              />
            </SButton>
            {groupFilter && (
              <SButton
                onClick={() => setGroupFilter(undefined)}
                type="secondaryColor"
                className="py-[20.5px] z-30 rounded-l-none"
                icon={faClose}
              />
            )}
          </div>
        </div>
        {(selectedImage?.templateOutputUrl || activeFrame) && (
          <SelectedFrameOptions />
        )}
        <FramesList />
      </div>
    </div>
  );
}

export default Frames;
