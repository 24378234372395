import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  IPostCommentCOE,
  IPostSingleCommentVM,
} from "../../../utils/interfaces/IPost";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { makeRequest } from "../../auth/axios";

export const usePostComment = () => {
  const queryClient = useQueryClient();
  return useMutation<IBaseData<IPostSingleCommentVM>, Error, IPostCommentCOE>({
    mutationFn: (data) => makeRequest.post("/Posts/Comment", data),
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["useGetPost"] });
      queryClient.invalidateQueries({ queryKey: ["useGetPostGroups"] });
    },
  });
};
