import React, { useState } from "react";
import PageHeading from "../PageHeading";
import SButton from "../../design-system/SButton";
import {
  faBrush,
  faPaintBrush,
  faPaintbrush,
  faPhotoFilm,
  faPlusCircle,
  faUserGroup,
  faUserPlus,
  faUsers,
} from "@fortawesome/pro-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CurrentPlan from "../billing/CurrentPlan";
import { IWorkspaceUsageVM } from "../../utils/interfaces/IWorkspace";
import { ISubscriptionVM } from "../../utils/interfaces/ISubscriptions";
import { IBaseData } from "../../utils/interfaces/IBase";
import CurrentPlanSkeleton from "../Skeletons/CurrentPlanSkeleton";
import { BrandInviteMemberModal } from "../brand/BrandInviteMemberModal";
import { useNavigate } from "react-router-dom";
import { faMegaphone } from "@fortawesome/pro-regular-svg-icons/faMegaphone";
import { toast } from "../../hooks/toast";
import { useCreationHelperFunctions } from "../creation/hooks/useCreationHelperFunctions";
import WhichSocialNetworksSelector from "../utils/WhichSocialNetworksSelector";
import { useGetWorkspaceUsage } from "../../api/workspaces/limits/get";
import { WorkspaceSettings } from "./WorkspaceSettings";

interface MetricItemProps {
  title: string;
  value?: number;
  icon: IconProp;
  badge?: React.ReactNode;
}

interface IWorkspaceOverviewProps {
  currentPlan?: IBaseData<ISubscriptionVM>;
  limits?: IBaseData<IWorkspaceUsageVM> | undefined;
  isViewer?: boolean;
  setInviteModal?: (value: boolean) => void;
}

export const MetricItem = ({ title, value, icon, badge }: MetricItemProps) => {
  return (
    <div className="flex flex-col gap-[20px] p-[24px] border border-gray-200 rounded-[12px] shadow-xs bg-base-white">
      <div
        className={
          "rounded-[10px] border-[1px] border-gray-200 bg-white shadow-xs w-[48px] h-[48px] p-[12px]"
        }
      >
        <FontAwesomeIcon
          icon={icon}
          className="text-gray-700 h-[24px] w-[24px] shrink-0"
        />
      </div>
      <div className={"flex flex-col justify-between "}>
        <span className="text-gray-800 text-sm-m">{title}</span>
        <div className="flex justify-between items-center gap-[8px]">
          <span className="text-gray-900 display-md-sb">{value}</span>
          {badge}
        </div>
      </div>
    </div>
  );
};

const WorkspaceOverview = ({
  currentPlan,
  limits,
  isViewer,
  setInviteModal,
}: IWorkspaceOverviewProps) => {
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const navigate = useNavigate();
  const { handleOpenPost } = useCreationHelperFunctions();
  const [
    isWhichSocialNetworksSelectorOpen,
    setIsWhichSocialNetworksSelectorOpen,
  ] = useState(false);
  const { data: workspaceUsage } = useGetWorkspaceUsage();
  return (
    <div className="flex flex-col justify-between gap-[32px]">
      {isWhichSocialNetworksSelectorOpen && (
        <WhichSocialNetworksSelector
          saveAllOnClose={true}
          isOpen={isWhichSocialNetworksSelectorOpen}
          onClose={() => setIsWhichSocialNetworksSelectorOpen(false)}
        />
      )}
      {openInviteModal && (
        <BrandInviteMemberModal
          setInviteModal={setOpenInviteModal}
          inviteModal={openInviteModal}
          isWorkspaceOnly={true}
        />
      )}
      <div className="grid grid-cols-3 gap-[24px] max-md:grid-cols-1">
        <MetricItem
          title={"Total workspace members"}
          value={
            limits && limits.data.numberOfMembers + limits.data.numberOfViewers
          }
          badge={
            !isViewer && (
              <SButton
                onClick={() => setInviteModal && setInviteModal(true)}
                type={"secondaryColor"}
                size={"sm"}
                lIcon={faPlusCircle}
              >
                Add More
              </SButton>
            )
          }
          icon={faUserGroup}
        />
        <MetricItem
          title={"Posts"}
          value={limits && limits.data.numberOfPosts}
          icon={faPhotoFilm}
          badge={
            !isViewer && (
              <SButton
                onClick={() => {
                  if (!workspaceUsage?.data.canUserAddPosts) {
                    toast(
                      "You have reached the limit of posts for your workspace.",
                      "error"
                    );
                    return;
                  }
                  handleOpenPost({
                    onSocialNetworkSelectorOpen: () => {
                      setIsWhichSocialNetworksSelectorOpen(true);
                    },
                  });
                }}
                type={"secondaryColor"}
                size={"sm"}
                lIcon={faPlusCircle}
              >
                Create Post
              </SButton>
            )
          }
        />
        <MetricItem
          title={"Brands"}
          value={limits && limits.data.numberOfOrganizations}
          icon={faPaintBrush}
          badge={
            !isViewer && (
              <SButton
                onClick={() => navigate("/create-brand")}
                type={"secondaryColor"}
                size={"sm"}
                lIcon={faPlusCircle}
              >
                Create Brand
              </SButton>
            )
          }
        />
      </div>
      <WorkspaceSettings />
    </div>
  );
};

export default WorkspaceOverview;
